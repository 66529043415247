// auth
export const login_api = "/api/auth/login";
export const logout_api = "/api/auth/logout/:refreshToken";
export const login_with_facebook_api = "/api/auth/login/facebook/device";
export const login_with_google_api = "/api/auth/login/google/device";
export const login_with_apple_api = "/api/auth/login/apple";
export const register_api = "/api/auth/register";
export const register_redirect_api = "/api/auth/register/redirect";
export const register_anonymously_api = "/api/auth/register/anonymous";
export const get_access_token_api = "/api/auth/get-access-token";

// user
export const user_api = "/api/users";
export const user_name_api = "/api/users/user-name";
export const user_password_api = "/api/users/password";
export const deactivate_account_api = "/api/users/deactivate-account";
export const reset_password_api = "/api/password-reset";
export const reset_password_redirect_api = "/api/password-reset/redirect";
export const user_loyalty_program = "/api/users/loyalty-program";

// restaurant
export const get_restaurants_api = "/api/restaurants";
export const get_restaurant_api = "/api/restaurants/:restaurantId";
export const restaurant_menu_api = "/api/restaurants/:restaurantId/menu";
export const restaurant_top_menu_api = "/api/restaurants/:restaurantId/top-menu";
export const restaurant_table_api = "/api/restaurants/:restaurantId/table/:tableNumber";
export const code_list_api = "/api/restaurants/code-list";

// booking
export const book_table_api = "/api/bookings/book";
export const create_reservation_api = "/api/bookings/reservation";
export const start_booked_order_api = "/api/bookings/start";
export const get_reservations_api = "/api/bookings/reservations";
export const get_current_reservation_api = "/api/bookings/current-reservation";
export const get_reservation_details_api = "/api/bookings/reservation/:orderId";
export const expiration_time_api = `/api/bookings/:orderId/expiration-time`;
export const get_invitation_details = "/api/bookings/invite-details/:reservationToken";
export const accept_invitation_api = "/api/bookings/accept-invitation";
export const decline_invitation_api = "/api/bookings/decline-invitation";
export const cancel_reservation_api = "/api/bookings/cancel-table/:orderId";
export const get_invitation_message_api = "/api/bookings/invite-guest/:orderId";
export const check_table_validity_api = "/api/bookings/check-table-validity";
export const get_available_times_api = "/api/bookings/free-tables";
export const has_booked_reservation = "/api/bookings/has-booked-reservation";
export const update_reservation_users_presence_api = "/api/bookings/users-presence";

// orders
export const get_orders_api = "/api/orders/:orderId";
export const add_to_order_api = "/api/orders/items/:orderId";
export const order_item_api = "/api/orders/items/:roundItemId";
export const edit_feedback_api = "/api/orders/feedback/:orderId";
export const get_orders_rounds_api = "/api/orders/rounds/:orderId";
export const get_orders_no_rounds_api = "/api/orders/no-rounds/:orderId";
export const get_last_round_orders_api = "/api/orders/last-round/:orderId";
export const get_checkout_info_api = "/api/orders/checkout/:orderId";
export const edit_rating_api = "/api/orders/rating/:orderId";
export const item_info_api = "/api/orders/item-info/:orderId/:itemId";
export const get_menu_item_modifiers = "/api/orders/modifiers";
export const get_menu_item_selected_modifiers = "/api/orders/selected-modifiers";
export const orders_loyalty_program_api = "/api/orders/loyalty-points";
export const orders_loyalty_program_pending_api = "api/orders/loyalty-points/pending";
export const orders_loyalty_program_applied_api = "api/orders/loyalty-points/applied";
export const user_transaction_loyalty_program = "/api/orders/transaction/loyalty-program";
export const users_loyalty_program_on_order = "/api/orders/users-loyalty-program-on-order/:orderId";

// payment
export const get_receipt_api = "/api/payments/receipt/:orderId";
export const complete_orders_api = "/api/payments/pay-orders";
export const send_to_kitchen_api = "/api/payments/send-to-kitchen";
export const get_payment_methods_api = "/api/payments/payment-methods";
export const payment_method_api = "/api/payments/payment-method";
export const validate_apple_pay_session_api = "/api/payments/apple-pay/validate-session";

// just pay
export const just_pay_table_orders_api = "/api/just-pay/restaurant/:restaurantGuid/table/:tableGuid";
export const just_pay_table_order_api = "/api/just-pay/restaurant/:restaurantGuid/table/:tableGuid/order/:orderGuid";
export const just_pay_add_payment_to_check_api =
  "/api/just-pay/restaurant/:restaurantGuid/order/:orderGuid/check/:checkGuid";
export const just_pay_get_check_receipt_api =
  "/api/just-pay/restaurant/:restaurantGuid/order/:orderGuid/check/:checkGuid/receipt";

// email
export const send_client_info_email_api = "/api/email/send-client-info";
export const send_support_email_api = "/api/email/send-support-email";

// version
export const version_config = "/api/version-config";
