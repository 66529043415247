import { useState } from "react";

const CheckoutModalDefinitions = () => {
  const [showCheckSplitModal, setShowCheckSplitModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showPaymentTipModal, setShowPaymentTipModal] = useState(false);

  const onOpenCheckSplitModalHandler = () => setShowCheckSplitModal(true);
  const onCloseCheckSplitModalHandler = () => setShowCheckSplitModal(false);

  const onOpenPaymentModalHandler = () => setShowPaymentModal(true);
  const onClosePaymentModalHandler = () => setShowPaymentModal(false);

  const onOpenPaymentTipModalHandler = () => setShowPaymentTipModal(true);
  const onClosePaymentTipModalHandler = () => setShowPaymentTipModal(false);

  return {
    showCheckSplitModal,
    showPaymentModal,
    showPaymentTipModal,
    onOpenCheckSplitModalHandler,
    onCloseCheckSplitModalHandler,
    onOpenPaymentModalHandler,
    onClosePaymentModalHandler,
    onOpenPaymentTipModalHandler,
    onClosePaymentTipModalHandler,
  };
};

export default CheckoutModalDefinitions;
