import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
// import { cancelReservation } from '../../pages/book/services/actions'
import { reservations_path_upcoming } from "../../config/pages_paths";
import Column from "../../containers/layout/Column";
import Row from "../../containers/layout/Row";
import { cancelReservation } from "../../pages/book/services/actions";
import { setActiveModal } from "../../store/actions/feedbackActions";
import ModalHeading from "../common/ModalHeading";
import CustomButton from "../common/CustomButton";
import Modal from "../modal/Modal";

function CancelReservationModal({ show, close }) {
  const dispatch = useDispatch();
  const {
    booking: { orderId },
    feedback: { responseMessage },
  } = useSelector((state) => state);
  const history = useHistory();

  const onCancelReservation = () => {
    dispatch(cancelReservation(orderId));
    onDismissHandler();
  };

  const onDismissHandler = () => {
    dispatch(setActiveModal(""));
  };

  // useEffect(() => {
  //   if (responseMessage) {
  //     dispatch(setActiveModal(''))
  //     history.push(reservations_path_upcoming)
  //   }
  // }, [responseMessage, dispatch, history])

  return (
    <Modal className="cancel-reservation-modal" isPopUp show={show} close={close}>
      <ModalHeading className="password-reset-header" onClick={close} title="Are you sure?" />
      <Row>
        <Column className="text-center align-items-center m-4" md={12} lg={12}>
          <div className="cancel-reservation-heading">
            <span>You reservation will be canceled, along with the orders that you and your friends selected.</span>
          </div>
          <div className="cancel-reservation-btns">
            <CustomButton
              onClick={onCancelReservation}
              text={"Confirm"}
              wrapperClassName="cancel-reservation-btn dismiss"
              textClassName="cancel-reservation-btn-text dismiss"
            />
            <CustomButton
              text={"Cancel"}
              onClick={onDismissHandler}
              wrapperClassName="cancel-reservation-btn"
              textClassName="cancel-reservation-btn-text"
            />
          </div>
        </Column>
      </Row>
    </Modal>
  );
}

export default CancelReservationModal;
