import Validator from "validator";
import isEmpty from "lodash.isempty";

function validateUserNewProfile({ email, first_name, last_name, phone, new_password, confirm_password }) {
  const errors = {};

  if (isEmpty(first_name)) errors.first_name = "Please enter your first name";

  if (isEmpty(last_name)) errors.last_name = "Please enter your last name";

  if (isEmpty(email)) errors.email = "Please enter your email address";
  else if (!Validator.isEmail(email))
    errors.email = "Please enter a valid email address (it must contain the '@' symbol, a prefix and an email domain)";

  if (isEmpty(phone)) errors.phone = "Please enter your phone number";
  else if (!Validator.isLength(phone, { min: 7 }))
    errors.phone = "Please enter a valid phone number (it must have a minimum of 7 digits)";

  if (isEmpty(new_password)) errors.new_password = "Please enter a new password";
  else if (!Validator.isLength(new_password, { min: 8 }))
    errors.new_password = "New password must contain at least 8 characters";
  else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])/.test(new_password))
    errors.new_password =
      "New password must contain at least one lowercase and one uppercase letter, one number, and one special character (!@#$%^&*()_+)";

  if (isEmpty(confirm_password)) errors.confirm_password = "Please confirm your password";
  else if (new_password !== confirm_password) errors.confirm_password = "Passwords do not match";

  return { errors, isValid: isEmpty(errors) };
}

export default validateUserNewProfile;
