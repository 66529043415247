import isEmpty from "lodash.isempty";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { home_path } from "../../config/pages_paths";
import Column from "../../containers/layout/Column";
import Row from "../../containers/layout/Row";
import { acceptInvitation, declineInvitation } from "../../pages/book/services/actions";
import { setActiveModal } from "../../store/actions/feedbackActions";
import SeparationLine from "../common/SeparationLine";
import Modal from "../modal/Modal";

function AcceptOptions({ onAccept, onDecline }) {
  return (
    <div style={{ width: "100%" }} className="text-center">
      <h3 style={{ margin: "1rem 0 0" }}>Do you accept the invitation?</h3>
      <div className="booking-btns-2 accept-invitation-btn-wrapper mt-4">
        <button onClick={onAccept} className="btn accept-invitation-btn">
          <span>Yes, confirm</span>
        </button>
        <button onClick={onDecline} className="btn decline-invitation-btn">
          <span>No, decline</span>
        </button>
      </div>
    </div>
  );
}

function ReservationRestaurantItem({ image, name, address, date = "", sizeOfParty }) {
  return (
    <Fragment>
      <div className="reservation-restaurant-item">
        <div className="image-section">
          <img src={image} alt="restaurant" />
        </div>
        <div className="details-section">
          <h2>{name}</h2>
          <span>{address}</span>
        </div>
      </div>
      <div className="invite-reservation-details">
        <span>Reservation:</span>{" "}
        <span>
          <strong>
            {date.replace(/-/g, "/")} | {sizeOfParty} {+sizeOfParty !== 1 ? "people" : "person"}{" "}
          </strong>
        </span>
      </div>
    </Fragment>
  );
}

function AcceptInvitationModal({ show, close }) {
  const {
    booking: { invitationDetails, invitationToken },
    user: { user },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const onAcceptInvitationHandler = () => {
    dispatch(acceptInvitation(invitationToken));
  };

  const onDeclineInvitationHandler = () => {
    dispatch(declineInvitation(invitationToken));
    dispatch(setActiveModal(""));
    history.push(home_path);
  };

  const onCloseHandler = () => {
    history.push(home_path);
    close();
  };

  useEffect(() => {
    if (!isEmpty(invitationDetails))
      if (invitationDetails.reservation.is_closed || invitationDetails.reservation.user_id === user.user_id) {
        dispatch(setActiveModal(""));
        history.push(home_path);
      }
  }, [invitationDetails, history, dispatch, user]);

  return (
    <Modal isPopUp className="sent-to-kitchen-modal" show={show} close={onCloseHandler}>
      {/* <ModalHeading title="Invitation for dinning" onClick={onCloseHandler} /> */}
      <h3 style={{ display: "flex", justifyContent: "center" }}>Invitation for dinning</h3>
      <div className="invitation-content-wrapper">
        <Row>
          <Column className="text-center invitation-header" col={12}>
            <h5>{invitationDetails.host}</h5>

            <p>is inviting you for wining and dining at:</p>
          </Column>
          {/* <SeparationLine /> */}
        </Row>
        <ReservationRestaurantItem
          name={invitationDetails.reservation?.name}
          address={invitationDetails.reservation?.address}
          image={invitationDetails.reservation?.restaurant_image}
          date={invitationDetails.reservation?.date_string}
          sizeOfParty={invitationDetails.reservation?.size_of_party}
        />
        <SeparationLine width="100%" />
      </div>
      <AcceptOptions onAccept={onAcceptInvitationHandler} onDecline={onDeclineInvitationHandler} />
    </Modal>
  );
}

export default AcceptInvitationModal;
