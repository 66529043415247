import CheckLabel from "../../CheckLabel";

const DiscountsForOrder = ({ entity }) => {
  const { checks } = entity;

  return (
    <>
      {checks?.map((check) => (
        <div key={check.guid}>
          {check.appliedDiscounts.length > 0 && (
            <CheckLabel label={`Check #${check.displayNumber}:`} divClassName="check-label-check padding-zero" />
          )}

          {check?.appliedDiscounts?.map((discount) => (
            <div className="check-label-secondary ">
              <CheckLabel
                key={discount.guid}
                label={discount.name}
                amount={
                  check?.appliedDiscounts?.length > 1
                    ? discount?.discountAmount
                    : checks.length > 1
                    ? discount?.discountAmount
                    : null
                }
                divClassName="check-label-secondary padding-zero"
              />
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default DiscountsForOrder;
