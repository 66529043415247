import classNames from "classnames";
import React from "react";
import MenuItemBadge from "../../components/orders/MenuItemBadge";

const MenuSectionListApproved = ({
  isMenuOpened,
  activeMenuId,
  activeMenuSectionId,
  menuSections,
  sectionItemsQuantity,
  onReservation,
  menuId,
  handleChangeMenuSection,
}) => {
  // const validSectionIds = selectedMenuAndSectionsByStep.flatMap(section => section.section_id);

  // const sectionItemsQuantityWithSteps = sectionItemsQuantity.filter(item => validSectionIds.includes(item.sectionId));

  // const itemsQuantityFinalizado = isMenuStepsFeatureEnabled ? sectionItemsQuantityWithSteps : sectionItemsQuantity

  return (
    <ul style={{ padding: "0 0.5rem", margin: 0 }}>
      {isMenuOpened && activeMenuId === menuId ? (
        <>
          {menuSections?.map((section, idx) => {
            return (
              <li
                key={section.section_id ?? idx}
                onClick={() => handleChangeMenuSection(section.section_id, section.menu_section_name)}
                className="side-menu-sub-item"
              >
                <div
                  className={classNames("side-menu-line", {
                    active: activeMenuSectionId === section.section_id,
                  })}
                />

                <span
                  className={classNames({
                    active: activeMenuSectionId === section.section_id,
                  })}
                >
                  {section.menu_section_name}
                </span>

                {onReservation &&
                  sectionItemsQuantity?.map(
                    (item, idx) =>
                      section.section_id === item.sectionId && (
                        <MenuItemBadge key={section.section_id ?? idx} number={item.quantity} />
                      ),
                  )}
              </li>
            );
          })}
          <div className="side-menu-item-separator" />
        </>
      ) : null}
    </ul>
  );
};

export default MenuSectionListApproved;
