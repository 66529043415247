import MenuIcon from "@mui/icons-material/Menu";
// import ProfileActive from '../../assets/profile-active.svg'
// import ProfileInactive from '../../assets/profile-inactive.svg'
import { register_modal } from "../../config/modal_paths";
import {
  aboutus_page_path,
  credit_card_information_path,
  help_page_path,
  home_path,
  privacy_page_path,
  profile_page_path,
  terms_page_path,
} from "../../config/pages_paths";
import OutlineButton from "../common/OutlineButton";
import NavItemWithIcon from "./NavItemWithIcon";
// import MenuIcon from '@mui/icons-material/Menu';
// import RestaurantIcon from '@mui/icons-material/Restaurant';
// import DiningRoundedIcon from '@mui/icons-material/DiningRounded';
// import HouseIcon from '@mui/icons-material/House';

export const navItems = {
  landing: ({ openModalHandler, onNavItemClick }) => [
    {
      component: OutlineButton,
      props: {
        text: "See restaurants",
        className: "nav-item m-2 round-btn",
        color: "primary",
        path: home_path,
        onClick: () => onNavItemClick(home_path),
      },
    },
    {
      component: OutlineButton,
      props: {
        text: "Sign up",
        className: "nav-item m-2 round-btn",
        color: "primary",
        path: home_path,
        onClick: () => openModalHandler(register_modal),
      },
    },
    //  Change temporarily for production, when apps are done change back
    // {
    //   component: GetTheAppButton,
    //   props: {
    //     text: <span>Get the app</span>,
    //     className: 'nav-item m-2 round-btn'
    //   }
    // }
  ],
  user: ({ onNavItemClick, onAuthProtectedClick, numOfReservations, toggleMenuContent, isNewNavigationMenu }) => [
    // {
    //   component: NavItemWithIcon,
    //   props: {
    //     text: 'Home',
    //     className: 'mx-2',
    //     activeIcon: HomeActiveIcon,
    //     inactiveIcon: HomeInactiveIcon,
    //     path: home_path,
    //     onClick: () => onNavItemClick(home_path),
    //     isNewNavigationMenu,
    //   }
    // },
    // {
    //   component: NavItemWithIcon,
    //   props: {
    //     text: 'Orders',
    //     className: 'mx-2',
    //     activeIcon: ReservationsActive,
    //     inactiveIcon: ReservationsInactive,
    //     badgeNumber: numOfReservations || 0,
    //     path: reservations_path,
    //     onClick: () => onAuthProtectedClick(reservations_path),
    //     isNewNavigationMenu,
    //   }
    // },
    {
      component: NavItemWithIcon,
      props: {
        text: "",
        icon: <MenuIcon sx={{ color: "#020083" }} fontSize="medium" />,
        // activeIcon: !isNewNavigationMenu ? ProfileActive : <MenuIcon style={{ color: "#020083" }} />,
        // inactiveIcon: !isNewNavigationMenu ? ProfileInactive : <MenuIcon style={{ color: "#020083" }} />,
        path: [
          profile_page_path,
          credit_card_information_path,
          aboutus_page_path,
          help_page_path,
          terms_page_path,
          privacy_page_path,
        ],
        // onClick: (e) => {
        //   !isNewNavigationMenu ?
        //     onAuthProtectedClick(profile_page_path) :
        //     toggleMenuContent(e)
        // },
        onClick: (e) => toggleMenuContent(e),
        isNewNavigationMenu,
      },
    },
  ],
};
