import React, { useRef } from "react";
import CustomButton from "../../common/CustomButton";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import Calendar from "../../../assets/calendar.svg";

function DateButtons({ onTodayClick, onTomorrowClick, onDatePickerChange, activeDateBtn, activeDate }) {
  const dateRef = useRef();

  return (
    <div className="booking-btns">
      <CustomButton
        wrapperClassName={classnames("booking-btn", {
          active: activeDateBtn === 0,
        })}
        textClassName={classnames("booking-btn-text", {
          active: activeDateBtn === 0,
        })}
        onClick={onTodayClick}
        text="Today"
      />
      <CustomButton
        style={{ width: "90px" }}
        wrapperClassName={classnames("booking-btn", {
          active: activeDateBtn === 1,
        })}
        textClassName={classnames("booking-btn-text", {
          active: activeDateBtn === 1,
        })}
        onClick={onTomorrowClick}
        text="Tomorrow"
      />

      <DatePicker
        minDate={new Date()}
        selected={activeDate || new Date(new Date().setHours(11, 0))}
        wrapperClassName="date-picker-wrapper"
        calendarClassName="date-picker"
        onChange={onDatePickerChange}
        customInputRef={dateRef}
        customInput={
          <CustomButton
            style={{ width: "90px" }}
            wrapperClassName={classnames("booking-btn", {
              active: activeDateBtn === 2,
            })}
            textClassName={classnames("booking-btn-text", {
              active: activeDateBtn === 2,
            })}
            icon={Calendar}
            iconClassName={classnames("booking-calendar", {
              active: activeDateBtn === 2,
            })}
            text={activeDateBtn === 2 ? transformToUsDate(activeDate) : "Other"}
          />
        }
      />
    </div>
  );
}

export default DateButtons;

function transformToUsDate(date) {
  const [day, month] = date.toLocaleString("default", { format: "dd/MM" }).split("/");
  return `${day.padStart(2, "0")}/${month.padStart(2, "0")}`;
}
