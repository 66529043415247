import isEmpty from "lodash.isempty";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useForm from "../../hooks/useForm";
import { updateUserPassword } from "../../pages/auth/services/actions";
import validateUserPassword from "../../validation/validateUserPassword";
import InputField from "../common/InputField";
import "./ProfilePW.css";
import useFloatingIslandActions from "../../hooks/useFloatingIslandActions";
import { useHistory } from "react-router";

function ProfilePW() {
  const {
    user: { user: userDetails },
    booking: { currentOrder },
    errors,
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const history = useHistory();
  const [formErrors, setErrors] = useState({});

  // #region Event Handlers

  const handleSubmit = (e) => {
    // Prevent form submission
    e && e.preventDefault();
    onUpdateUserPassword(e);
  };

  const { values, handleChange, setValues } = useForm(
    {
      email: userDetails.email || "",
      first_name: userDetails.first_name || "",
      last_name: userDetails.last_name || "",
      phone: userDetails.phone || "",
      password: "",
      new_password: "",
      confirm_password: "",
    },
    handleSubmit,
  );

  useEffect(() => {
    if (!isEmpty(userDetails)) {
      setValues((values) => ({ ...values, ...userDetails }));
    }
  }, [userDetails, setValues]);

  const onUpdateUserPassword = useCallback(
    (e) => {
      e && e.preventDefault();

      const newValues = userDetails.has_pw
        ? {
            password: values.password.trim(),
            new_password: values.new_password.trim(),
            confirm_password: values.confirm_password.trim(),
            has_pw: userDetails.has_pw,
          }
        : {
            new_password: values.new_password.trim(),
            confirm_password: values.confirm_password.trim(),
            has_pw: userDetails.has_pw,
          };

      const { errors, isValid } = validateUserPassword(newValues);

      setValues(newValues);
      if (!isValid) {
        setErrors(errors);
        return null;
      } else setErrors({});

      dispatch(updateUserPassword(newValues, !userDetails.has_pw, history));
    },
    [dispatch, setValues, values, userDetails],
  );

  // #endregion

  // #region Floating Island

  const { DOMslot1, DOMslot2, createButtonPortal, backToOrderShortcut, saveChanges } = useFloatingIslandActions();

  // #endregion

  return (
    <React.Fragment>
      <div style={{ margin: "1rem 0" }}>
        <h3 className="account-information">{userDetails.has_pw ? "Change Password" : "Enter Password"}</h3>
        <form id="update-user-password" onSubmit={(e) => handleSubmit(e, "update-user-password")}>
          {userDetails.has_pw && (
            <InputField
              inputClassName="profile-input"
              labelClassName="profile-input-label"
              errorClassName="profile-input-invalid-feedback"
              onChange={handleChange}
              name="password"
              value={values.password}
              placeholder={"******"}
              error={formErrors.password || errors.password}
              label="Current Password"
              type="password"
            />
          )}
          <InputField
            inputClassName="profile-input"
            labelClassName="profile-input-label"
            errorClassName="profile-input-invalid-feedback"
            onChange={handleChange}
            name="new_password"
            value={values.new_password}
            placeholder={"******"}
            error={formErrors.new_password || errors.new_password}
            label="New Password"
            type="password"
          />
          <InputField
            inputClassName="profile-input"
            labelClassName="profile-input-label"
            errorClassName="profile-input-invalid-feedback"
            onChange={handleChange}
            name="confirm_password"
            value={values.confirm_password}
            placeholder={"******"}
            error={formErrors.confirm_password || errors.confirm_password}
            label="Confirm Password"
            type="password"
          />
          <div className="submit-button">
            {/* <div className={"submit-btn-primary"}>
            <button type="submit" className="btn btn-primary"><span>Save changes</span></button>
          </div> */}

            {createButtonPortal(saveChanges(onUpdateUserPassword), DOMslot1)}
            {backToOrderShortcut(DOMslot2)}
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

export default ProfilePW;
