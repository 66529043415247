import React, { useMemo } from "react";
import InputField from "../../common/InputField";
import { maximumLoyaltyPoints } from "../../../utils/loyaltyCreditsUsageCalculation";
import { userLoyaltyStepEnum } from "../../../enums/userLoyaltyStepEnum";
import { useSelector } from "react-redux";

const UserLoyaltyProgram = ({
  timeLeft,
  setLoyaltyCreditsInput,
  loyaltyCreditsInput,
  isMoreThanOneGuestOnTable,
  allGuestSelectedLoyaltyOption,
}) => {
  const {
    auth: {
      user: { id },
    },
    booking: { payingForOthersState },
    orders: {
      checkoutInfo: { subTotalInt, checkoutInfoPerUsers },
      appliedLoyaltyPoints,
      loyaltyOnCurrentRestaurant,
      userLoyaltyStep,
    },
    restaurant: { restaurant },
  } = useSelector((state) => state);

  const currentPoints = useMemo(() => loyaltyOnCurrentRestaurant?.current_points, [
    loyaltyOnCurrentRestaurant?.current_points,
  ]);

  const maxUsablePointsWithCurrentBalance = useMemo(
    () => maximumLoyaltyPoints(subTotalInt, restaurant?.loyalty_program_max_conversion_rate, currentPoints),
    [currentPoints, restaurant?.loyalty_program_max_conversion_rate, subTotalInt],
  );

  const minUsablePoints = useMemo(() => restaurant?.loyalty_program_min_redeemable_points, [
    restaurant?.loyalty_program_min_redeemable_points,
  ]);

  const calculatedMaxUsableCreditsForUserOnOrder = useMemo(() => {
    const currentUserPaying = payingForOthersState.find((user) => user.payerId === id);

    if (!currentUserPaying) return 0;

    const maxUsableCreditsForUserCalculation = checkoutInfoPerUsers.reduce((total, user) => {
      if (currentUserPaying.userIds.includes(user.userId)) {
        return total + Number(user.maxUsableCredits);
      }
      return total;
    }, 0);

    return maxUsableCreditsForUserCalculation.toFixed(2);
  }, [checkoutInfoPerUsers, id, payingForOthersState]);

  const handlePendingLoyaltyPoints = (e) => {
    setLoyaltyCreditsInput(e.target.value);
  };

  return (
    <>
      {+currentPoints > 0 && (
        <>
          <h2 style={{ fontSize: "26px", fontFamily: "Cormorant-Regular" }}>Loyalty Program</h2>

          {userLoyaltyStep === userLoyaltyStepEnum.toDecideWhetherToUse && (
            <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <p> Your balance is {currentPoints} loyalty credits. </p>
              <p> Would you like to use loyalty credits on this order?</p>
            </div>
          )}

          {(userLoyaltyStep === userLoyaltyStepEnum.toApplyPending ||
            userLoyaltyStep === userLoyaltyStepEnum.toApplyPendingModal) && (
            <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <p>
                Your balance is {currentPoints} loyalty credits. You can use min {minUsablePoints}, max{" "}
                {calculatedMaxUsableCreditsForUserOnOrder} credits on this order.
              </p>
              <p> Currently you have {appliedLoyaltyPoints} pending loyalty credits. </p>

              {timeLeft.seconds >= 0 && isMoreThanOneGuestOnTable && !allGuestSelectedLoyaltyOption && (
                <>
                  <p>Please wait for all guests to select a loyalty option or the time is up.</p>
                  <p>
                    <b>{timeLeft.time}</b>
                  </p>
                </>
              )}
            </div>
          )}

          {(userLoyaltyStep === userLoyaltyStepEnum.toDecideWhichToUse ||
            userLoyaltyStep === userLoyaltyStepEnum.toTypeAmount) && (
            <div className="">
              <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                <p>
                  Your balance is {currentPoints} loyalty credits. You can use min {minUsablePoints}, max{" "}
                  {calculatedMaxUsableCreditsForUserOnOrder} credits on this order.
                </p>

                {userLoyaltyStep === userLoyaltyStepEnum.toTypeAmount ? (
                  <InputField
                    inputClassName="profile-input"
                    labelClassName="profile-input-label"
                    errorClassName="profile-input-invalid-feedback"
                    onChange={handlePendingLoyaltyPoints}
                    name="pendingLoyaltyCredits"
                    type="number"
                    value={loyaltyCreditsInput || appliedLoyaltyPoints}
                    error={
                      +loyaltyCreditsInput > +currentPoints
                        ? "You cannot apply more credits than you currently have."
                        : +loyaltyCreditsInput > +maxUsablePointsWithCurrentBalance
                        ? "You cannot apply more credits than the maximum allowed."
                        : +loyaltyCreditsInput < +minUsablePoints
                        ? "You cannot apply less credits than the minimum required."
                        : null
                    }
                    label="Applied Loyalty Credits"
                  />
                ) : null}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UserLoyaltyProgram;
