import { useState, useEffect, useCallback } from "react";

function useIsCurrentTabActive() {
  const [isCurrentTabActive, setIsCurrentTabActive] = useState(!document.hidden);

  const handleVisibility = useCallback(() => {
    setIsCurrentTabActive(!document.hidden);
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibility);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibility);
    };
  }, [handleVisibility]);

  return { isCurrentTabActive };
}

export default useIsCurrentTabActive;
