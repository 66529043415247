import React, { Fragment, useEffect, useState } from "react";
import Image from "../../components/common/Image";
import MenuItemBadge from "../../components/orders/MenuItemBadge";
import Column from "../../containers/layout/Column";
import inventoryStatusEnum from "../../enums/inventoryStatusEnum";
import { just_pay_menu_path } from "../../config/pages_paths";
import { useRouteMatch } from "react-router-dom";

const ActiveSectionItems = ({
  items = [],
  emptyMofifierGroup,
  onReservation = false,
  showSpecial = false,
  onRecommendations = false,
  handleMenuItemDetails,
  handleRecommendedItemDetails,
  lastRoundOrders,
}) => {
  const [menuItemsWithOrderInfo, setMenuItemsWithOrderInfo] = useState([]);
  const isJustPayMenuPath = useRouteMatch(just_pay_menu_path);

  // Create a dictionary of item_id to quantity from lastRoundOrders

  // const sortedItems = items && items.slice().sort((a, b) => {
  //   // Move items with inventory_status 'out_of_stock' to the bottom
  //   if (a.inventory_status === inventoryStatusEnum.OUT_OF_STOCK && b.inventory_status !== inventoryStatusEnum.OUT_OF_STOCK) {
  //     return 1;
  //   }
  //   if (a.inventory_status !== inventoryStatusEnum.OUT_OF_STOCK && b.inventory_status === inventoryStatusEnum.OUT_OF_STOCK) {
  //     return -1;
  //   }
  //   // Keep the original order for other cases
  //   return 0;
  // });

  useEffect(() => {
    // Function to merge item with order info
    const mergeOrderInfo = (item, orders) => {
      const matchingOrder = orders.find((order) => order.item_id === item.item_id);
      if (matchingOrder) {
        return {
          ...item,
          user_order_round_id: matchingOrder.user_order_round_id,
          round_item_id: matchingOrder.round_item_id,
          quantity: matchingOrder.quantity,
          remaining_quantity: matchingOrder.remaining_quantity,
          selected_quantity: matchingOrder.selected_quantity,
        };
      }
      return item;
    };

    // Update items with order information
    const updatedItems = items
      ?.map((item) => mergeOrderInfo(item, lastRoundOrders))
      .slice()
      .sort((a, b) => {
        // Move items with inventory_status 'out_of_stock' to the bottom
        if (
          a.inventory_status === inventoryStatusEnum.OUT_OF_STOCK &&
          b.inventory_status !== inventoryStatusEnum.OUT_OF_STOCK
        ) {
          return 1;
        }
        if (
          a.inventory_status !== inventoryStatusEnum.OUT_OF_STOCK &&
          b.inventory_status === inventoryStatusEnum.OUT_OF_STOCK
        ) {
          return -1;
        }
        // Keep the original order for other cases
        return 0;
      });
    setMenuItemsWithOrderInfo(updatedItems);
  }, [items, lastRoundOrders]);

  const handleOnClick = async (item) => {
    if (onReservation && item.inventory_status !== inventoryStatusEnum.OUT_OF_STOCK && !item.is_invalid) {
      !onRecommendations ? await handleMenuItemDetails(item) : await handleRecommendedItemDetails(item);
    } else if (!onReservation) {
      await handleMenuItemDetails(item);
    }
    return;
  };

  return (
    <>
      {menuItemsWithOrderInfo?.map((item, idx) => {
        // console.log(item)
        return (
          <Fragment key={item.item_id ?? idx}>
            <Column justifyContent="center" className="p-0 m-0 mx-auto" col={12}>
              <div
                onClick={() => handleOnClick(item)}
                className="grid-container-1-4"
                style={{
                  ...(item?.special_id &&
                    showSpecial &&
                    item.inventory_status !== inventoryStatusEnum.OUT_OF_STOCK && {
                      backgroundColor: "hsla(0,0%,83%,.3)",
                      borderRadius: "1rem",
                    }),
                  maxWidth: "27rem",
                  justifyContent: "center!important",
                  padding: "0 0.5rem 0 0",
                }}
              >
                <div className="user-order-item-image-wrapper_">
                  <div className="image-wrapper_">
                    {/* Check if the item_id exists in orderQuantities */}
                    {onReservation && item?.quantity ? <MenuItemBadge number={item.quantity} /> : null}
                    <Image src={item?.image} menu alt="menu-item" className={`user-order-item-image_`} />
                    {(
                      !emptyMofifierGroup
                        ? (onReservation || isJustPayMenuPath) &&
                          item.inventory_status === inventoryStatusEnum.OUT_OF_STOCK
                        : (onReservation || isJustPayMenuPath) &&
                          (item.inventory_status === inventoryStatusEnum.OUT_OF_STOCK || item.is_invalid)
                    ) ? (
                      <div className="reservation-box-label">Unavailable</div>
                    ) : item?.special_id &&
                      item?.special_name &&
                      (item.inventory_status !== inventoryStatusEnum.OUT_OF_STOCK || !item.is_invalid) ? (
                      <div className="reservation-box-label-2">{item.special_name}</div>
                    ) : null}
                  </div>
                </div>
                <div style={{ display: "grid", height: "100%" }}>
                  <div className="grid-container-3-1">
                    <div className="user-order-item-details_">
                      <h6>{item.menu_item_name}</h6>
                    </div>
                    <div className="user-order-item-actions-wrapper_">
                      <div>
                        <h6>${Number.parseFloat(item.price).toFixed(2)}</h6>
                      </div>
                    </div>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <small
                      className="user-order-item-description"
                      style={{
                        fontFamily: "Lato-Bold",
                        fontSize: "13px",
                        color: "#877a74",
                        textAlign: "left",
                      }}
                    >
                      {item.about}
                    </small>
                  </div>
                </div>
              </div>
            </Column>
            <div className="text-center menu-item-separator" />
          </Fragment>
        );
      })}
    </>
  );
};

export default ActiveSectionItems;
