import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router";
import LoadingSpinner from "../common/loader/LoadingSpinner";
import jwt_decode from "jwt-decode";
import { getAccessToken } from "../../pages/auth/services/actions";
import { home_path } from "../../config/pages_paths";

function AnonymousRedirectRoute({ path, component: Component, render: RenderComponent, ...rest }) {
  const {
    feedback: { numOfLoadingSpinnerCalls },
    auth: { isAuthenticated, user },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (localStorage.jwtToken && location.pathname === path) {
      const decoded = jwt_decode(localStorage.jwtToken.split(" ")[1]);
      if (decoded.exp && decoded.exp < Date.now() / 1000) {
        dispatch(getAccessToken());
      }
    }
  }, [dispatch, location, path]);

  return (
    <Route
      path={path}
      render={(props) =>
        numOfLoadingSpinnerCalls > 0 ? (
          <LoadingSpinner />
        ) : isAuthenticated && !user.is_anonymous ? (
          Component ? (
            <Component {...props} />
          ) : (
            RenderComponent()
          )
        ) : (
          <Redirect to={{ pathname: home_path }} />
        )
      }
    />
  );
}

export default AnonymousRedirectRoute;
