import classnames from "classnames";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setToastMessage } from "../../../store/actions/feedbackActions";
import "./ToastMessage.css";
import UncreatedExternalItems from "./UncreatedExternalItems";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import WarningIcon from "@mui/icons-material/WarningOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/HighlightOff";
import { colorsEnum } from "../../../enums/colorsEnum";

function ToastMessage({ severity, message, autoHide = true, timer, unrecreatedExternalItems, showCloseIcon }) {
  const dispatch = useDispatch();

  const handleResetToastMessage = useCallback(() => {
    dispatch(setToastMessage({ show: false, severity: "", timer: 0, autoHide: true, showCloseIcon: false }));
  }, [dispatch]);

  useEffect(() => {
    autoHide &&
      setTimeout(() => {
        handleResetToastMessage();
      }, timer ?? 5000);
  }, [autoHide, timer, handleResetToastMessage]);

  return (
    <div
      className={classnames("toast-message-wrapper", {
        "toast-error": severity === "error",
        "toast-info": severity === "info",
        "toast-success": severity === "success",
        "toast-auto-width": unrecreatedExternalItems,
        // 'toast-warning': severity === 'warning',
        // 'toast-success': severity === 'success'
      })}
    >
      <div style={{ display: "block" }}>
        {severity === "success" ? <CheckCircleIcon fontSize="large" sx={{ color: colorsEnum.navyBlue }} /> : null}
        {severity === "info" ? <InfoIcon fontSize="large" sx={{ color: colorsEnum.navyBlue }} /> : null}

        {severity === "error" ? <ErrorIcon fontSize="large" sx={{ color: colorsEnum.red }} /> : null}

        {severity === "warning" ? <WarningIcon fontSize="large" sx={{ color: colorsEnum.orange }} /> : null}
      </div>

      {/* {showCloseIcon && (
        <div
          className='toast-close'
          onClick={handleResetToastMessage}
        >
          <CloseIcon />
        </div>
      )} */}

      <div className="toast-message">
        <div className="toast-close" onClick={handleResetToastMessage}>
          <CloseIcon fontSize="large" />
        </div>

        {unrecreatedExternalItems ? (
          <UncreatedExternalItems unrecreatedExternalItems={unrecreatedExternalItems} />
        ) : (
          message
        )}
      </div>
    </div>
  );
}

export default ToastMessage;
