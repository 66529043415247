import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavigationSideMenu from "../../components/navigation/NavigationSideMenu";
import Column from "../../containers/layout/Column";
import Row from "../../containers/layout/Row";
import {
  getUserLoyaltyProgram,
  getUserTransactionLoyaltyProgram,
  setUserLoyaltyProgramAccumulatedPoints,
} from "../order/services/actions";
import LoyaltyDetails from "./LoyaltyDetails";
import useFloatingIslandActions from "../../hooks/useFloatingIslandActions";

export default function LoyaltyProgramPage() {
  const {
    restaurants: { restaurants },
    auth: {
      user: { id },
    },
    orders: {
      userTransactionsLoyaltyProgram,
      userLoyaltyProgram,
      userLoyaltyProgramAccumulatedPoints,
      // appliedLoyaltyPoints,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  useEffect(() => {
    dispatch(getUserLoyaltyProgram(id));
    dispatch(getUserTransactionLoyaltyProgram(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (userLoyaltyProgram && userTransactionsLoyaltyProgram) {
      setFilteredRestaurants(
        restaurants.filter((restaurant) =>
          userLoyaltyProgram.some((loyaltyProgram) => loyaltyProgram.restaurant_id === restaurant.restaurant_id),
        ),
      );
    }
  }, [restaurants, userLoyaltyProgram, userTransactionsLoyaltyProgram]);

  useEffect(() => {
    if (!isEmpty(userLoyaltyProgram) && !isEmpty(userTransactionsLoyaltyProgram)) {
      dispatch(setUserLoyaltyProgramAccumulatedPoints(userLoyaltyProgram));
    }
  }, [dispatch, userLoyaltyProgram, userTransactionsLoyaltyProgram]);

  const filterLoyalty = useCallback(() => {
    if (isEmpty(selectedFilters)) {
      setFilteredTransactions(userTransactionsLoyaltyProgram);
    } else {
      setFilteredTransactions(
        userTransactionsLoyaltyProgram.filter((transaction) =>
          selectedFilters.some((filter) => filter.option.restaurant_id === transaction.restaurant_id),
        ),
      );
    }
  }, [selectedFilters, userTransactionsLoyaltyProgram]);

  useEffect(() => {
    filterLoyalty();
  }, [filterLoyalty]);

  const handleChange = (event, value) => {
    setSelectedFilters(value);
  };

  const { backToOrderShortcut, DOMslot1 } = useFloatingIslandActions();

  return (
    <>
      {backToOrderShortcut(DOMslot1)}
      <Row className="m-4 reservations-row">
        <Column lg={12}>
          <Row>
            <Column lg="2">
              <NavigationSideMenu />
            </Column>
            <Column className="scrollable-list" lg="5">
              <LoyaltyDetails
                userLoyaltyProgram={userLoyaltyProgram}
                userTransactionsLoyaltyProgram={userTransactionsLoyaltyProgram}
                userLoyaltyProgramAccumulatedPoints={userLoyaltyProgramAccumulatedPoints}
                filteredTransactions={filteredTransactions}
                restaurants={filteredRestaurants}
                selectedFilters={selectedFilters}
                handleChange={handleChange}
              />
            </Column>
            <Column lg="2" />
          </Row>
        </Column>
      </Row>
    </>
  );
}
