import Row from "../../containers/layout/Row";
import Column from "../../containers/layout/Column";
import NavigationSideMenu from "../../components/navigation/NavigationSideMenu";
import useFloatingIslandActions from "../../hooks/useFloatingIslandActions";

export default function AboutUsPage() {
  const { backToOrderShortcut, DOMslot1 } = useFloatingIslandActions();

  return (
    <>
      {backToOrderShortcut(DOMslot1)}
      <Row className="m-4 reservations-row">
        <Column lg={12}>
          <Row>
            <Column lg="2">
              <NavigationSideMenu />
            </Column>
            <Column className="scrollable-list" lg="5">
              <AboutUs />
            </Column>
            <Column lg="2" />
          </Row>
        </Column>
      </Row>
    </>
  );
}

export function AboutUs() {
  return (
    <>
      <h3>
        <strong>About us</strong>
      </h3>
      <p>
        Picture a dining experience that it's all about safety, convenience, delicious food, good friends and Joie de
        Vivre. That’s us. We offer a selection of handpicked, top restaurants in NYC that can provide a dining
        experience following all safety guidelines. We make no compromises on safety and quality in oder to give you
        peace of mind and pleasure while you wine & dine. With voilà you can still enjoy dining even in these special
        circumstances. We designed every part of the dining experience to be without contact - from the booking of your
        table all the way down to the ordering of your food and paying of the check.
      </p>
    </>
  );
}
