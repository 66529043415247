const hexColors = [
  "rgba(2, 0, 131, 1)",
  "rgba(255, 105,51, 1)",
  "rgba(1, 0, 59, 1)",
  "rgba(237, 217, 183, 1)",
  "rgba(15, 33, 33, 1)",
  "rgba(122, 113, 98, 1)",
];

export default hexColors;
