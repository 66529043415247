import React from "react";
import NavigationSideMenu from "../../components/navigation/NavigationSideMenu";
import SpreedlyCreditCardVault from "../../components/profile/spreedly/SpreedlyCreditCardVault";
import Column from "../../containers/layout/Column";
import Row from "../../containers/layout/Row";

function CreditCardInformationPage() {
  return (
    <Row className="m-4 reservations-row">
      <Column lg={12}>
        <Row>
          <Column lg="2">
            <NavigationSideMenu />
          </Column>

          <Column className="scrollable-list" lg="5">
            <h3 className="account-information">Credit card information</h3>
            <SpreedlyCreditCardVault />
          </Column>
          <Column lg="2" />
        </Row>
      </Column>
    </Row>
  );
}

export default CreditCardInformationPage;
