import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { considerUserIdleAfterXAmoutOfTime } from "../config/keys";

const useIsIdle = () => {
  const [isIdle, setIsIdle] = useState(false);

  const onIdle = () => {
    setIsIdle(true);
  };

  const onActive = () => {
    setIsIdle(false);
  };

  useIdleTimer({
    onIdle,
    onActive,
    timeout: 60000 * considerUserIdleAfterXAmoutOfTime,
    throttle: 500,
    startOnMount: true,
  });

  return { isIdle };
};

export default useIsIdle;
