import Validator from "validator";
import isEmpty from "lodash.isempty";

function validateUserProfile({ first_name, last_name, phone }) {
  const errors = {};

  if (isEmpty(first_name)) errors.first_name = "Please enter your first name";

  if (isEmpty(last_name)) errors.last_name = "Please enter your last name";

  if (isEmpty(phone)) errors.phone = "Please enter your phone number";
  else if (!Validator.isLength(phone, { min: 7 }))
    errors.phone = "Please enter a valid phone number (it must have a minimum of 7 digits)";

  return { errors, isValid: isEmpty(errors) };
}

export default validateUserProfile;
