import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/modal/Modal";
import { booked_reservation_modal, password_reset_request_modal, register_modal } from "../../../config/modal_paths";
import Column from "../../../containers/layout/Column";
import Row from "../../../containers/layout/Row";
import useForm from "../../../hooks/useForm";
import { setActiveModal, setOnFullPageModal } from "../../../store/actions/feedbackActions";
import "../Auth.css";
import { login, loginWithGoogle } from "../services/actions";
import "./LoginPage.css";
// import FacebookImg from '../../../assets/facebook.svg'
import GoogleImg from "../../../assets/google.svg";
import ExternalLoginButton from "../../../components/auth/ExternalLoginButton";
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from "react-google-login";
import LoginButton from "../../../components/auth/login/LoginButton";
import InputField from "../../../components/common/InputField";
import ModalHeading2 from "../../../components/common/ModalHeading2";
import validateLogin from "../../../validation/login";
import AppleLogin from "./AppleLogin";
import useFloatingIslandActions from "../../../hooks/useFloatingIslandActions";

function LoginPage({ show, close }) {
  const {
    auth: { isAuthenticated },
    booking: { invitationToken, hasBookedReservation },
    feedback: { activeModalMetadata, hasFullPageModal },
    errors,
  } = useSelector((state) => state);

  const { proceed, DOMslot1, createButtonPortal } = useFloatingIslandActions();

  const dispatch = useDispatch();

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    dispatch(setOnFullPageModal(true));
    return () => dispatch(setOnFullPageModal(false));
  }, [dispatch]);

  const loginUser = () => {
    const { errors, isValid } = validateLogin(values);

    if (!isValid) {
      setFormErrors(errors);
      return null;
    } else setFormErrors({});

    dispatch(
      login({
        ...values,
        reservation: invitationToken,
        cb: activeModalMetadata?.redirectToMenu,
        cbParams: activeModalMetadata?.redirectToMenuParams,
      }),
    );
  };

  const { values, handleSubmit, handleChange } = useForm({ email: "", password: "" }, loginUser);

  const onSignUpClickHandler = () => {
    dispatch(setActiveModal(register_modal, { ...activeModalMetadata }));
  };

  const onPasswordResetClickHandler = () => {
    dispatch(setActiveModal(password_reset_request_modal, { ...activeModalMetadata }));
  };

  // const responseFacebook = response => {
  //   response && dispatch(loginWithFacebook(response.accessToken, invitationToken))
  // }

  const responseGoogle = (response) => {
    let token = "";
    if (response.access_token) token = response.access_token;
    if (response.tokenObj) token = response.tokenObj.id_token;
    response && dispatch(loginWithGoogle(token, invitationToken));
  };

  const onGoogleLoginFailure = (response) => {
    console.log("Google login failure ", response);
  };

  const handleClose = () => {
    dispatch(setOnFullPageModal(false));
    close();
    if (!isAuthenticated && hasBookedReservation) {
      dispatch(setActiveModal(booked_reservation_modal));
    }
  };

  return (
    <Modal className="login-modal" show={show} close={handleClose}>
      {/* <ModalHeading className="login-header" onClick={handleClose} title="Log-in" /> */}
      <ModalHeading2
        className="login-header"
        title1="Log-in"
        title2="Sign-up"
        activeTitle={"Log-in"}
        onSignUp={onSignUpClickHandler}
        onClick={handleClose}
      />
      <Row>
        <Column className="text-center align-items-center m-4" md={12} lg={12}>
          <div className="login-heading">
            <h3>Hey, good to see you again!</h3>
          </div>
        </Column>
        <Column className="mx-auto align-items-center" md={12} lg={12}>
          <form onSubmit={handleSubmit}>
            <InputField
              type="email"
              required={true}
              name="email"
              onChange={handleChange}
              label="E-mail address"
              value={values.email}
              error={formErrors.email || errors.email}
            />
            <InputField
              type="password"
              name="password"
              onChange={handleChange}
              label="Password"
              value={values.password}
              error={formErrors.password || errors.password}
            />

            <div className="external-login-btns mx-auto">
              <span className="login-with" style={{ marginRight: "16px" }}>
                Log-in with
              </span>{" "}
              {/* <FacebookLogin
                appId="847432139083677"
                autoLoad={false}
                callback={responseFacebook}
                disableMobileRedirect={true}
                fields="name,email,picture"
                scope="public_profile,email"
                isMobile={false}
                render={renderProps => (
                  <ExternalLoginButton
                    className="facebook"
                    onClick={renderProps.onClick}
                    img={FacebookImg}
                  />
                )}
              /> */}
              <GoogleLogin
                clientId="55700200557-8un2ipb96sqmhpuuf8ilh9riv062st5j.apps.googleusercontent.com"
                render={(renderProps) => <ExternalLoginButton onClick={renderProps.onClick} img={GoogleImg} />}
                onSuccess={responseGoogle}
                onFailure={() => onGoogleLoginFailure}
              />
              <AppleLogin />
            </div>
            <button style={{ display: "none" }} type="submit" onSubmit={handleSubmit} />
            {/*
            <LoginButton onClick={loginUser} />
          */}
            {createButtonPortal(proceed(loginUser, null, null, true), DOMslot1)}
          </form>
        </Column>
        <div id="footer" className="login-footer">
          <div className="forgot-password-container">
            <span>Forgot password? Happens to the best.</span>
            <br />
            <span className="reset-password" onClick={onPasswordResetClickHandler}>
              {" "}
              Re-set your password{" "}
            </span>
          </div>

          <div className="create-account-container">
            <span>
              New to Voila?{" "}
              <span className=" create-account" onClick={onSignUpClickHandler}>
                Create an account
              </span>
            </span>
          </div>
        </div>
      </Row>
    </Modal>
  );
}

export default LoginPage;
