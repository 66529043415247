export const login_modal = "login_modal";
export const register_modal = "register_modal";
export const register_success_modal = "register_success_modal";
export const accept_invitation_modal = "accept_invitation_modal";
export const accept_invitation_success_modal = "accept_invitation_success_modal";
export const password_reset_request_modal = "password_reset_request_modal";
export const password_reset_request_sent_modal = "password_reset_request_sent_modal";
export const password_reset_modal = "password_reset_modal";
export const cancel_reservation_modal = "cancel_reservation_modal";
export const update_user_modal = "update_user_modal";
export const update_user_name_modal = "update_user_name_modal";
export const sent_to_kitchen_modal = "sent_to_kitchen_modal";
export const sent_to_kitchen_modal_out_of_stock_items = "sent_to_kitchen_modal_out_of_stock_items";
export const ordering_not_available_modal = "ordering_not_available_modal";
export const available_times_modal = "available_times_modal";
export const orders_paid_modal = "orders_paid_modal";
export const payment_done_modal = "payment_done_modal";
export const payment_done_anon_user_modal = "payment_done_anon_user_modal";
export const expiration_timer_modal = "expiration_timer_modal";
export const timer_expired_message_modal = "timer_expired_message_modal";
export const is_idle_modal = "is_idle_modal";
export const just_pay_is_idle_modal = "just_pay_is_idle_modal";
export const menu_item_quantity_changed_modal = "menu_item_quantity_changed_modal";
export const booked_reservation_modal = "booked_reservation_modal";
export const recommended_menu_items_modal = "recommended_menu_items_modal";
