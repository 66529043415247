import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { available_times_modal, register_modal, update_user_modal } from "../../config/modal_paths";
import { register } from "../../pages/auth/services/actions";
import { setActiveModal, setToastMessage } from "../../store/actions/feedbackActions";
import ModalHeading from "../common/ModalHeading";
import Modal from "../modal/Modal";

function ConfirmationSentModal({ close, show }) {
  const dispatch = useDispatch();
  const {
    user: { newUser },
    feedback: { activeModalMetadata },
  } = useSelector((state) => state);

  const [canResendEmail, setCanResendEmail] = useState(true);

  const handleResendMail = async () => {
    try {
      await dispatch(register(newUser));
      setCanResendEmail(false);
    } catch (err) {
      dispatch(setToastMessage({ message: "Failed to resend mail" }));
    }
  };

  const handleChangeMail = () => {
    !!activeModalMetadata?.onSignUp
      ? dispatch(setActiveModal(register_modal))
      : dispatch(setActiveModal(update_user_modal));
  };

  const handleReturnToBooking = () => {
    dispatch(setActiveModal(available_times_modal));
  };

  return (
    <Modal isPopUp className="text-center confirmation-link-modal" close={close} show={show}>
      <ModalHeading title="" onClick={close} />
      <div style={{ margin: "1rem 0" }}>
        <h2>Confirmation link sent!</h2>
        <p>
          Please let us know this email <b>{activeModalMetadata?.email}</b> belongs to you. Open the confirmation link
          we sent to you!
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          margin: "2rem auto auto",
        }}
      >
        <button
          disabled={!canResendEmail}
          className="btn btn-primary  white-rounded-button"
          onClick={handleResendMail}
          style={{ width: "80%" }}
        >
          Resend Email
        </button>
        <button className="btn btn-primary  white-rounded-button" style={{ width: "80%" }} onClick={handleChangeMail}>
          Change Email
        </button>

        {activeModalMetadata?.onBooking ? (
          <button
            className="btn btn-primary big-round-btn-auto-width"
            style={{ width: "80%" }}
            onClick={handleReturnToBooking}
          >
            Proceed
          </button>
        ) : null}
      </div>
    </Modal>
  );
}

export default ConfirmationSentModal;
