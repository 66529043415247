import axios from "axios";
import {
  SET_IS_PAY,
  SET_IS_PROCEEDED_TO_CHECKOUT_SELECTION,
  SET_IS_USER_UPDATED,
  SET_NEW_USER,
  SET_NEW_USER_MAIL,
  SET_UPDATE_USER_RECEIPT,
  SET_USER_DETAILS,
} from "../../config/actionTypes";
import { user_api } from "../../config/api_endpoints";
import { setErrors } from "./errorsActions";

export const getUser = () => async (dispatch) => {
  try {
    const user = await axios.get(user_api);
    dispatch(setUser(user.data));
  } catch (err) {
    dispatch(setErrors(err, () => getUser()));
  }
};

export const setUser = (payload) => (dispatch) => {
  return dispatch({ type: SET_USER_DETAILS, payload });
};

export const setNewUser = (payload) => (dispatch) => {
  return dispatch({ type: SET_NEW_USER, payload });
};
export const setNewUserMail = (payload) => (dispatch) => {
  dispatch({ type: SET_NEW_USER_MAIL, payload });
};

export const setUserUpdated = (payload) => (dispatch) => {
  return dispatch({ type: SET_IS_USER_UPDATED, payload });
};

export const setUpdateUserReceipt = (payload) => (dispatch) => {
  dispatch({ type: SET_UPDATE_USER_RECEIPT, payload });
};

export const setIsPay = (payload) => (dispatch) => {
  dispatch({ type: SET_IS_PAY, payload });
};

export const setIsProceededToCheckoutSelection = (payload) => (dispatch) => {
  dispatch({ type: SET_IS_PROCEEDED_TO_CHECKOUT_SELECTION, payload });
};
