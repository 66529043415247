import CloseIcon from "@mui/icons-material/Close";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import TuneIcon from "@mui/icons-material/Tune";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { CustomAutocomplete } from "../../components/common/previewComponents";
import EmptyReservation from "../../components/reservations/EmptyReservation";
import { colorsEnum } from "../../enums/colorsEnum";
import { fontFamiliesEnum } from "../../enums/fontFamiliesEnum";
import { timeToAMPM3 } from "../../utils/timeToAMPM";

const LoyaltyDetails = ({
  userLoyaltyProgram,
  userTransactionsLoyaltyProgram,
  filteredTransactions,
  userLoyaltyProgramAccumulatedPoints,
  restaurants,
  selectedFilters,
  handleChange,
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const [isAutocompletePopoverOpen, setIsAutocompletePopoverOpen] = useState(false);

  const menuItems = [
    {
      label: "Restaurants",
      data: restaurants,
      icon: <RestaurantIcon />,
    },
  ];

  const handleIsAutocompeltePopoverOpen = () => {
    setIsAutocompletePopoverOpen((prevState) => !prevState);
  };
  const handleIsAutocompeltePopoverOnOpen = () => {
    setIsAutocompletePopoverOpen(true);
  };
  const handleIsAutocompeltePopoverOnClose = () => {
    setIsAutocompletePopoverOpen(false);
  };

  const popupIcon = <TuneIcon sx={{ color: "#020083" }} fontSize="medium" />;

  const closeIcon = <CloseIcon sx={{ color: "#fff" }} />;

  return userLoyaltyProgram && userTransactionsLoyaltyProgram && restaurants ? (
    <Grid container sx={{ display: "flex" }}>
      <Grid item xs={12}>
        <h3>Summary</h3>
        <p>Credits Earned: {userLoyaltyProgramAccumulatedPoints?.total_points || "0.00"}</p>
        <p>Credits Used: {userLoyaltyProgramAccumulatedPoints?.redeemed_points || "0.00"}</p>
        <p>Current Balance: {userLoyaltyProgramAccumulatedPoints?.current_points || "0.00"}</p>
      </Grid>
      {!isEmpty(filteredTransactions) ? (
        <>
          <Grid item xs={12} sx={{ margin: "1rem 0 0" }}>
            <h3>Transactions:</h3>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <CustomAutocomplete
              menuItems={menuItems}
              selectedMenuItems={selectedFilters}
              label={"Filter by Restaurants"}
              multiple
              disableCloseOnSelect
              open={isAutocompletePopoverOpen}
              groupItemsBadgeContentLoyalty={userLoyaltyProgram}
              onToggle={handleIsAutocompeltePopoverOpen}
              handleIsAutocompeltePopoverOnOpen={handleIsAutocompeltePopoverOnOpen}
              handleIsAutocompeltePopoverOnClose={handleIsAutocompeltePopoverOnClose}
              popupIcon={popupIcon}
              closeIcon={closeIcon}
              onChange={handleChange}
              mainColor={colorsEnum.white}
              secondaryColor={colorsEnum.navyBlue}
              alternativeColor={colorsEnum.trout}
              backgroundColor={colorsEnum.alto}
              mainFontFamily={fontFamiliesEnum.cormorant}
              secondaryFontFamily={fontFamiliesEnum.lato}
            />
            {/* <h1>Loyalty Program</h1>
          <h5>Current Loyalty Points: {userLoyaltyProgram?.current_points}</h5>
          <h5>Redeemed Loyalty Points: {userLoyaltyProgram?.redeemed_points}</h5>
        <h5>Total Loyalty Points: {userLoyaltyProgram?.total_points}</h5> */}
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              alignItems: "flex-start",
              margin: " 1rem auto",
            }}
          >
            <TableContainer
              sx={{
                borderRadius: 2,
                border: "1px solid #rgba(0, 0, 0, 0.6)",
              }}
            >
              <Table
                sx={{
                  borderCollapse: "collapse",
                  borderStyle: "hidden",
                  // "& td": {
                  //   border: 1
                  // }
                }}
                size="medium"
              >
                <TableHead sx={{ backgroundColor: "#e8ecef" }}>
                  <TableRow sx={{ borderRadius: "0.5rem" }}>
                    <TableCell sx={{ paddingX: "0.5rem" }}>#</TableCell>
                    <TableCell sx={{ paddingX: "0.5rem" }}>Restaurant</TableCell>
                    <TableCell sx={{ paddingX: "0.5rem" }}>Date</TableCell>
                    <TableCell sx={{ paddingX: "0.5rem" }}>Credits</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredTransactions.map((transaction, index) => {
                    const { date, time } = timeToAMPM3(transaction.created_at);
                    return (
                      <TableRow key={index} sx={{ backgroundColor: index % 2 ? "#ffffff" : "#f2f2f2", borderStyle: "hidden" }}>
                        <TableCell sx={{ paddingX: "0.5rem" }}>{index + 1}</TableCell>
                        <TableCell sx={{ paddingX: "0.5rem" }}>{transaction.restaurant_name}</TableCell>
                        <TableCell sx={{ paddingX: "0.5rem", display: "flex", flexDirection: "column", borderStyle: "hidden" }}>
                          {isMobile ? (
                            <>
                              <Typography variant="body">{date}</Typography>
                              <Typography variant="body">{time}</Typography>
                            </>
                          ) : (
                            <Typography variant="body">{`${date} ${time}`}</Typography>
                          )}
                        </TableCell>
                        <TableCell sx={{ paddingX: "0.5rem" }}>
                          <TransactionPoints points={transaction.points} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      ) : null}
    </Grid>
  ) : (
    <Grid>
      <EmptyReservation text={"No transactions yet.."} paragraph={"Ready when you are"} />
    </Grid>
  );
};

function TransactionPoints({ points }) {
  return (
    <Typography
      sx={{
        font: "Lato",
        color: points > 0 ? "green" : "red",
      }}
    >
      {points > 0 ? `+${points}` : `${points}`}
    </Typography>
  );
}

export default LoyaltyDetails;
