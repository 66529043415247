import isEmpty from "lodash.isempty";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentAccordion, setCurrentAccordionOrder } from "../../../store/actions/feedbackActions";
import UserOrderItem from "./UserOrderItem";
// import { ordering_not_available_modal } from '../../../config/modal_paths'
import classnames from "classnames";
import UserOrderDetailsModal from "../../orders/UserOrderDetailsModal";

import { Fade } from "@mui/material";
import {
  CheckCircleIcon400,
  DoNotDesturbIcon,
  ExpandLessIcon200,
  ExpandMoreIcon200,
} from "../../../assets/svgs-google/GoogleIcons";
import classNames from "classnames";

function UserOrders({
  userDisplayName = "",
  userStatus = "",
  orders = [],
  firstLetter,
  avatarBackground,
  areOwnOrders,
  round,
  // isPaymentChecked,
  // onPaymentCheckboxChange,
  // canPay,
  canOrder,
  // order_id,
  is_archived,
  // user_id,
  roundPosition,
  orderPosition,
  user,
  avatarColors,
  isActiveReservation,
  currentUserId,
  hasTableId,
  isPresent = true,
}) {
  const [isOpened, setIsOpened] = useState(false);
  const [sortedOrders, setSortedOrders] = useState([]);
  // const [areOrdersPaid, setAreOrdersPaid] = useState(false)
  // const [hasAnimation, setHasAnimation] = useState(false)
  // const [hasUnpaidItems, setHasUnpaidItems] = useState(false)
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [hasOrders, setHasOrders] = useState(false);
  const fadeRef = useRef();

  const {
    auth: { user: currentUser },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(sortedOrders)) {
      setHasOrders(true);
    } else {
      setHasOrders(false);
    }
  }, [sortedOrders]);

  useEffect(() => {
    if (+user.user_id === +currentUser.id || +currentUserId === +currentUser.id) {
      setIsCurrentUser(true);
      setIsOpened(true);
    }
    return () => {
      setIsCurrentUser(false);
    };
  }, [user, currentUser, isCurrentUser, avatarBackground, currentUserId]);

  // useEffect(() => {
  //   if (user?.unpaid_items > 0) {
  //     setHasUnpaidItems(true)
  //   }
  //   else {
  //     setHasUnpaidItems(false)
  //   }
  // }, [user])

  useEffect(() => {
    // console.log(orders)
    let updatedOrders = orders.reduce((acc, cur) => {
      let sectionIdx = acc.findIndex((el) => el.section === cur.sub_type_name);
      if (sectionIdx < 0) {
        let newSection = {
          section: cur.sub_type_name,
          orders: [cur].sort(),
        };
        acc.push(newSection);
      } else acc[sectionIdx].orders.push(cur);

      return acc;
    }, []);
    // console.log(updatedOrders)

    setSortedOrders(updatedOrders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(sortedOrders)) {
      setHasOrders(true);
    } else {
      setHasOrders(false);
    }

    // let paid = true
    // sortedOrders.forEach(section => {
    //   section.orders.forEach(order => {
    //     if (!order.paid) paid = false
    //   })
    // })
    // setAreOrdersPaid(paid)
  }, [sortedOrders]);

  const handleUserStatusColor = () => {
    switch (user.user_order_status) {
      case "No orders":
        return "#767676";
      // case 'Order not sent':
      //   return '#FF9933'
      // case 'Order not paid':
      //   return '#dc3545'
      case "All orders paid":
        return "#349651";
      default:
        return "#767676";
    }
  };

  useEffect(() => {
    return () => {
      setSortedOrders([]);
    };
  }, []);

  const onOpenOrders = () => {
    if (!is_archived) {
      setIsOpened((val) => !val);
      dispatch(setCurrentAccordion(roundPosition));
      dispatch(setCurrentAccordionOrder(orderPosition));
    }
  };

  const handleCloseOrderDetails = () => {
    setShowOrderDetails((prevState) => !prevState);
    // setHasAnimation(prevState => !prevState)
  };

  return (
    <>
      {showOrderDetails && isActiveReservation && (
        <UserOrderDetailsModal show={showOrderDetails} close={handleCloseOrderDetails} user={user} />
      )}
      {/* {
      onMenuItemDetails &&
      <MenuItemDetailsModal show={onMenuItemDetails} close={handleMenuItemDetails} item={user?.orders[0]} />
    } */}
      <Fade ref={fadeRef} in={true} style={{ transitionDelay: "300ms" }}>
        <div className={classNames("user-orders-wrapper", !isPresent && "user-orders-wrapper-not-present")}>
          {/* <div className={["user-orders-wrapper", !isPresent && "user-orders-wrapper-not-present" ]}> */}
          <div className="grid-container-5-1" onClick={onOpenOrders}>
            <div
              className="grid-container-1-5"
              // onClick={() => { setShowOrderDetails(prevState => !prevState) }}
            >
              <div
                className="user-order-header-avatar-wrapper"
                style={{
                  border: isCurrentUser && `1px solid ${avatarBackground}`,
                }}
              >
                <div
                  style={{
                    backgroundColor: !isEmpty(avatarBackground) ? avatarBackground : "rgb(1, 0, 59)",
                    padding: isCurrentUser && "5px",
                    // animation: (hasUnpaidItems) && isCurrentUser &&
                    //   'pulse 2s ease-in-out infinite, blink 2s ease-in-out infinite',
                  }}
                  className="user-orders-header-avatar"
                >
                  <span style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)" }}>
                    {firstLetter}
                  </span>
                </div>
              </div>
              <div
                className={classnames("user-orders-details", {
                  deactivated: is_archived,
                })}
              >
                <div>
                  <h6 className="user-orders-details-name">{userDisplayName}</h6>
                  <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
                    {isActiveReservation && (
                      <>
                        {user?.user_order_status === "All orders paid" && CheckCircleIcon400(16, "#349651", false)}
                        {user?.user_order_status === "Order not sent" &&
                          hasTableId &&
                          DoNotDesturbIcon(16, "#767676", false)}
                        {user?.user_order_status === "Order not paid" &&
                          hasTableId &&
                          DoNotDesturbIcon(16, "#767676", false)}

                        {!hasOrders && isActiveReservation && (
                          <span style={{ margin: "0 0 6px 0" }}>{DoNotDesturbIcon(16, "#767676", false)}</span>
                        )}
                        <p
                          style={{
                            color: handleUserStatusColor(),
                            fontFamily: "Lato-Bold",
                            fontSize: "12px",
                            letterSpacing: "0.04rem",
                          }}
                        >
                          {user?.user_order_status}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {hasOrders && (
              <div style={{ display: "grid" }}>
                {/* <h6 style={{ fontFamily: "Lato-Regular, Lato", fontSize: "20px", textAlign: "right" }}>{user.sub_total_due && `$${user.sub_total_due}`}</h6> */}
                <div
                  className="user-orders-button"
                  // onClick={onOpenOrders}
                >
                  {isOpened ? ExpandLessIcon200(32, "#020083 ") : ExpandMoreIcon200(32, "#D5D5D5")}
                </div>
              </div>
            )}
          </div>

          <div style={{ display: isOpened ? "grid" : "none" }}>
            {is_archived ? (
              <div className="grid-container-1-3-1 no-order-wrapper">
                <div className="grid-item-1"></div>
                <div className="grid-item-2">
                  <h5>Your friend deactivated their account</h5>
                </div>
                <div className="grid-item-3"></div>
              </div>
            ) : hasOrders ? (
              sortedOrders.map((section) => {
                return (
                  <div key={section.section}>
                    <div className="section-name">
                      <div className="name-separator" /> <span>{section.section}</span>
                      <div className="name-separator" />
                    </div>
                    {section.orders.map((order, idx) => {
                      return (
                        <UserOrderItem
                          key={idx}
                          round={round}
                          isOwnItem={areOwnOrders}
                          canOrder={canOrder}
                          orderItem={order}
                          orders={section.orders}
                          lastItem={section.orders.length === idx + 1}
                        />
                      );
                    })}
                  </div>
                );
              })
            ) : null}
          </div>
        </div>
      </Fade>
    </>
  );
}

export default UserOrders;
