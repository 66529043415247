import React, { useEffect } from "react";
import MenuItemDetails, { CloseButton } from "./MenuItemDetails";
import Modal from "../modal/Modal";

function MenuItemDetailsModal({ show, close, item, modifierList, specialRequirments }) {
  useEffect(() => {}, []);

  return (
    <div className={"add-menu-item-wrapper menu-details-wrapper"}>
      <Modal className="menu-details-modal" show={show} close={close} isPopUp>
        <CloseButton onClick={close} />
        <MenuItemDetails
          reduceDetailSize={false}
          {...item}
          modifierList={modifierList}
          specialRequirments={specialRequirments}
          close={close}
        />
      </Modal>
    </div>
  );
}

export default MenuItemDetailsModal;
