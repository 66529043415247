import React, { useEffect, useState } from "react";
import Modal from "../../modal/Modal";
import ModalHeading from "../../common/ModalHeading";
import CustomButton from "../../common/CustomButton";
import OutlineButton from "../../common/OutlineButton";
import { useSelector } from "react-redux";
import "./styles/SendToKitchenModal.css";
import isEmpty from "lodash.isempty";

function SendToKitchenModal({ show, close, onSend }) {
  const {
    orders: { orderRoundsUserQuantityBasedOffStep },
    menu: { isMenuStepsFeatureEnabled },
  } = useSelector((state) => state);

  return (
    <Modal isPopUp className="pop-up-modal sent-to-kitchen-modal send-to-kitchen-modal" show={show} close={close}>
      <ModalHeading title={"Send orders"} onClick={close} />
      {/* <p className='message-margin-bottom'>
        Not everyone from your table has finished their order yet. The timing of
        your food can be different if you proceed.
      </p> */}
      <div className="users-info-wrapper">
        {orderRoundsUserQuantityBasedOffStep && orderRoundsUserQuantityBasedOffStep.length > 1 ? (
          <>
            <p>This action sends the selected items from all guests. Current selection:</p>
            {orderRoundsUserQuantityBasedOffStep.map((user) =>
              user.is_present ? (
                // Render for present users
                <div className="user-pill-wrapper" key={user.user_id}>
                  <div className="user-pill-name">
                    <div>
                      {user.user}
                      <span>{" - "}</span>
                      {isMenuStepsFeatureEnabled ? (
                        <>
                          {isEmpty(user.quantitySteps)
                            ? "0 Items"
                            : user.quantitySteps.map((step, idx) => (
                                <div key={step.step} className="user-pill-steps">
                                  <span>{step.step}</span>
                                  <span>{" : "}</span>
                                  <span>{step.quantity}</span>
                                  <span> Items</span>
                                  <span>{idx !== user.quantitySteps.length - 1 ? ", " : ""}</span>
                                </div>
                              ))}
                        </>
                      ) : (
                        <div className="user-pill-quantity">
                          <span>{user.ordersTotalQuantity ?? 0} Items</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                // Render for not present users
                <div className="not-present-user-pill-wrapper" key={user.user_id}>
                  {user.user} - Not Present
                </div>
              ),
            )}
            <p>
              Please ask the other guests to confirm if they have finished with their items selection The timing of your
              food can be different if you send them separately
            </p>
            <p></p>
            <p>
              Are you sure you want to send the orders to kitchen? Once you proceed you will no longer be able to modify
              them.
            </p>
            <p></p>
          </>
        ) : (
          <>
            <p>Are you sure you want to send the orders to kitchen?</p>
            <p>Once you proceed you will no longer be able to modify them.</p>
          </>
        )}
      </div>
      <div className={"send-to-kitchen-modal-buttons"}>
        <OutlineButton text={"Confirm"} onClick={onSend} />
        <CustomButton text={"Cancel"} onClick={close} />
      </div>
    </Modal>
  );
}

export default SendToKitchenModal;
