import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash.isempty";
import LoadingSpinner from "../../components/common/loader/LoadingSpinner";
import { getReservationDetails, setReservationDetails } from "../book/services/actions";
import { useHistory, useParams } from "react-router";
import { initialReservationDetails } from "../book/services/reducers";

function BookingPageWrapper({ component: Component }) {
  const {
    booking: {
      reservationDetails: { reservation },
    },
  } = useSelector((state) => state);
  const [loading, setLoading] = useState(true);
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    orderId && dispatch(getReservationDetails(orderId, history));
    return () => {
      dispatch(setReservationDetails(initialReservationDetails));
    };
  }, [orderId, dispatch, history]);

  useEffect(() => {
    if (!isEmpty(reservation)) {
      setLoading(false);
    }
  }, [reservation]);

  return loading ? <LoadingSpinner /> : Component ? <Component /> : null;
}

export default BookingPageWrapper;
