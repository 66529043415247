import React from "react";
import CustomButton from "../../components/common/CustomButton";
import ModalHeading from "../../components/common/ModalHeading";
import Modal from "../../components/modal/Modal";

const ReturnToReservationModal = ({ show, close, title, description }) => {
  return (
    <Modal show={show} close={close}>
      <ModalHeading title={title} onClick={close} />
      {/* <p>
                ReturnToReservationModal
            </p> */}
      <div style={{ margin: "0 0 1rem", textAlign: "center" }}>
        <h5>{description}</h5>
      </div>
      {/* <Link to={reservations_path_now}>Back to reservation</Link> */}
      <CustomButton text={"Okay"} onClick={close} />
    </Modal>
  );
};

export default ReturnToReservationModal;
