import Row from "../../containers/layout/Row";
import Column from "../../containers/layout/Column";
import NavigationSideMenu from "../../components/navigation/NavigationSideMenu";
import useFloatingIslandActions from "../../hooks/useFloatingIslandActions";

export default function HelpPage() {
  const { backToOrderShortcut, DOMslot1 } = useFloatingIslandActions();

  return (
    <>
      {backToOrderShortcut(DOMslot1)}
      <Row className="m-4 reservations-row">
        <Column lg={12}>
          <Row>
            <Column lg="2">
              <NavigationSideMenu />
            </Column>
            <Column className="scrollable-list" lg="5">
              <Help />
            </Column>
            <Column lg="2" />
          </Row>
        </Column>
      </Row>
    </>
  );
}

export function Help() {
  return (
    <>
      <h3>
        <strong>Help</strong>
      </h3>
      <p>
        For any problem regarding the quality of the food or the dining experience in our listed restaurant partners,
        please discuss with the restaurant staff.
      </p>
      <p>For any problem with the app feel free to reach out to us on:</p>
      <p>contact@voila.nyc</p>
    </>
  );
}
