import { useState, useEffect, useCallback } from "react";

function useScroll(ref) {
  const [isDown, setIsDown] = useState(false);

  const handleScroll = useCallback(
    (e) => {
      // console.log('[e]]', e.target)
      let cur = ref.current;
      if (cur) {
        setIsDown(cur.offsetTop !== 0);
      }
    },
    [ref],
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, [handleScroll]);

  return [isDown];
}

export default useScroll;
