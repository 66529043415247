import isEmpty from "lodash.isempty";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import CheckIcon from "../../assets/check.svg";
import CustomButton from "../../components/common/CustomButton";
import { booking_reservation_path, home_path, order_page_path } from "../../config/pages_paths";
import Column from "../../containers/layout/Column";
import Row from "../../containers/layout/Row";
import { copyToClipboard } from "../../utils/copyToClipboard";
import { setOrderNextRoute } from "../order/services/actions";
import "./BookingPage.css";
import { getInviteMessage, getReservationDetails, setInviteMessage } from "./services/actions";

export function BookingSuccess({ onNextClick, onInviteClick, isInviteCopied, sizeOfParty }) {
  return (
    <div style={{ margin: "1rem 0 0" }}>
      <img
        alt="restaurant"
        style={{
          width: "86px",
          height: "86px",
          objectFit: "contain",
          marginBottom: "16px",
        }}
        src={CheckIcon}
      />
      <div style={{ margin: "auto" }} className="booking-success-text">
        <span>Successfully booked</span>
        <br /> We are setting the table
      </div>
      <div className="booking-success-p">
        <span>You will receive an e-mail from OpenTable confirming the reservation.</span>
      </div>
      {/* <br />
      <CustomButton
        style={{
          width: '194px',
          height: '54px',
          backgroundColor: '#020083',
          borderRadius: '33px',
          marginBottom: '33px'
        }}
        textStyle={{
          width: '170px',
          height: '29px',
          fontSize: '1em',
          letterSpacing: '0.29px',
          lineHeight: '29px'
        }}
        wrapperClassName="booking-btn active mx-auto"
        textClassName="booking-btn-text active"
        text={'Proceed'}
        onClick={onNextClick}
      />
      {+sizeOfParty > 1 &&
        <div onClick={onInviteClick} className="text-btn">
          {isInviteCopied ? 'Invite link copied' : 'Invite friends'}
        </div>
      } */}
    </div>
  );
}

function BookingSuccessPage(props) {
  const {
    booking: {
      inviteMessage,
      reservationDetails: { reservation },
    },
  } = useSelector((state) => state);

  const { restaurantId, orderId } = useParams();
  const [isInviteCopied, setInviteCopied] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    // dispatch(setRouteName(page_paths[booking_success_path]))
  }, [dispatch]);

  useEffect(() => {
    orderId && isEmpty(reservation) && dispatch(getReservationDetails(+orderId, history));
  }, [dispatch, orderId, reservation, history]);

  const onNextClick = () => {
    dispatch(
      setOrderNextRoute({
        path: order_page_path.replace(":orderId", orderId),
        tabIndex: 1,
      }),
    );
    history.push(
      booking_reservation_path
        // .replace(':restaurantId', restaurantId)
        .replace(":orderId", orderId),
    );
    // history.push(order_page_path.replace(':orderId', orderId))
  };

  useEffect(() => {
    orderId && reservation?.size_of_party > 1 && dispatch(getInviteMessage(orderId));
    console.log(orderId, reservation?.size_of_party);
    console.log(orderId && reservation?.size_of_party);
  }, [dispatch, orderId, reservation]);

  const onInviteClick = () => {
    copyToClipboard(inviteMessage);
    setInviteCopied(true);
  };

  useEffect(() => {
    return () => {
      dispatch(setInviteMessage(""));
      setInviteMessage("");
    };
  }, [dispatch]);

  useEffect(() => {
    history.listen((location) => {
      if (history.action === "POP") {
        history.push(home_path);
      }
    });
  }, [history]);

  return (
    <Row>
      <Column className="text-right book-success-left-column" md={6} lg={4} style={{ margin: "1rem 0 0" }}>
        <img alt="restaurant" className="booking-restaurant-image" src={reservation.restaurant_image} />
      </Column>
      <Column className="text-center" md={6} lg={4} style={{ margin: "1rem 0 0" }}>
        <BookingSuccess
          onInviteClick={onInviteClick}
          onNextClick={onNextClick}
          isInviteCopied={isInviteCopied}
          sizeOfParty={reservation.size_of_party}
        />
      </Column>
    </Row>
  );
}

export default BookingSuccessPage;
