import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { past_order_page_path, reservations_path_now } from "../../config/pages_paths";
import Column from "../../containers/layout/Column";
import Row from "../../containers/layout/Row";
import useSocket from "../../hooks/useSocket";
import { setIsForWhomToPayConfirmed, setRefreshDataForOpenedMenuItem } from "../../store/actions/feedbackActions";
import { getUser, setIsPay, setIsProceededToCheckoutSelection } from "../../store/actions/usersActions";
import localStorageHelper from "../../utils/localStorageHelper";
import { getReservationDetails, setPayingForOthers } from "../book/services/actions";
import { setIsMenuStepsFeatureEnabled } from "../menu/services/actions";
import {
  getCheckoutInfo,
  getLastRoundOrders,
  getOrders,
  getOrdersNoRounds,
  getOrdersRounds,
  setAllGuestSelectedLoyaltyOption,
  setChooseIfGuestWantToUseCreditsModal,
  setLoyaltyProcess,
  setNewNameForAnonGuest,
  setUserLoyaltyStep,
} from "../order/services/actions";
import ReservationTypes from "./ReservationTypes";
import "./ReservationsPage.css";
import { getIdsSomeonePaysFor, isSomeonePayingForCurrentUser } from "./Reservationutils";
import {
  closeCurrentReservation,
  getCurrentReservation,
  getReservations,
  setOtherTryingToPay,
  setRefreshCheckout,
  setUserIdsSomeonePaysFor,
} from "./services/actions";
import { clearRestaurantReservation } from "../../store/actions/restaurantsActions";
import { maximumLoyaltyPoints } from "../../utils/loyaltyCreditsUsageCalculation";
import { userLoyaltyStepEnum } from "../../enums/userLoyaltyStepEnum";
// import { MENU_STEP_FEATURE } from '../../constants/index'

function ReservationsPageWrapper({ component: Component }) {
  const {
    booking: { reservations, currentOrder, payingForOthersState, otherTryingToPay },
    auth: { isAuthenticated, user },
    user: { isProceededToCheckoutSelection },
    feedback: { connectSocketAfterIdleTimeout, refreshDataForOpenedMenuItem, numOfLoadingSpinnerCalls },
    orders: {
      lastRoundOrders,
      checkoutInfo: { checkoutInfoPerUsers, isLoyaltyDiscountApplied, areAnyDiscountsApplied, subTotalInt },
      usersLoyaltyProgramOnOrder,
      loyaltyOnCurrentRestaurant,
    },
    versionConfig: { navbar_links: navbarLinks, menu_steps },
    restaurant: { restaurant },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  const [showOrdersRoundsComponent, setShowOrdersRoundsComponent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const currentPoints = useMemo(() => +loyaltyOnCurrentRestaurant?.current_points, [loyaltyOnCurrentRestaurant]);

  const minUsablePoints = useMemo(() => restaurant?.loyalty_program_min_redeemable_points, [restaurant]);

  const maxUsablePoints = useMemo(
    () => maximumLoyaltyPoints(subTotalInt, restaurant?.loyalty_program_max_conversion_rate, currentPoints),
    [currentPoints, restaurant?.loyalty_program_max_conversion_rate, subTotalInt],
  );

  const canUserUseLoyaltyProgram = useMemo(
    () =>
      restaurant.is_loyalty_program_enabled &&
      !areAnyDiscountsApplied &&
      +currentPoints > 0 &&
      +currentPoints >= +minUsablePoints &&
      +maxUsablePoints >= +minUsablePoints,
    [areAnyDiscountsApplied, currentPoints, maxUsablePoints, minUsablePoints, restaurant.is_loyalty_program_enabled],
  );

  useEffect(() => {
    if (numOfLoadingSpinnerCalls > 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [numOfLoadingSpinnerCalls]);

  const onRefresh = useCallback(
    (order_id) => {
      if (order_id) {
        dispatch(getCurrentReservation());
        dispatch(getOrders(order_id));
        dispatch(getOrdersRounds(order_id));
        dispatch(getOrdersNoRounds(order_id));
        dispatch(setRefreshCheckout(true));
        dispatch(setIsPay(false));
        dispatch(getLastRoundOrders(order_id));
        isProceededToCheckoutSelection && dispatch(setIsProceededToCheckoutSelection(false));

        if (refreshDataForOpenedMenuItem.itemId) {
          dispatch(setRefreshDataForOpenedMenuItem({ itemId: refreshDataForOpenedMenuItem.itemId, refresh: true }));
        } else {
          dispatch(setRefreshDataForOpenedMenuItem({ itemId: null, refresh: false }));
        }
      }
    },
    [dispatch, refreshDataForOpenedMenuItem.itemId, isProceededToCheckoutSelection],
  );

  const onPaymentExpired = useCallback(
    ({ data }) => {
      // workaround for when the payment resets properly the socket is called 2 times
      // if the payment hasn't been reset properly the socket is called once
      for (let i = 0; i < 2; i++) {
        dispatch(setPayingForOthers(payingForOthersState.filter((state) => state !== data)));
        if (data.payerId === user.id) {
          dispatch(getCheckoutInfo(currentOrder?.order_id, [user.id]));
          dispatch(setIsPay(false));
          dispatch(setIsProceededToCheckoutSelection(false));
        }
      }
    },
    [dispatch, user.id, currentOrder?.order_id, payingForOthersState],
  );

  const onRefreshWithoutResetingIsPay = useCallback(
    (order_id) => {
      if (order_id) {
        dispatch(getOrders(order_id, true));
        dispatch(getOrdersRounds(order_id));
        dispatch(getOrdersNoRounds(order_id));
        dispatch(setRefreshCheckout(true));
        dispatch(getLastRoundOrders(order_id));
      }
    },
    [dispatch],
  );

  const updateIsMenuStepsFeatureEnaled = useCallback(() => {
    const menuStepsFeatureExists = localStorageHelper.exists("MENU_STEP_FEATURE");
    const menuStepFeature = localStorageHelper.getItem("MENU_STEP_FEATURE");
    dispatch(setIsMenuStepsFeatureEnabled(menuStepsFeatureExists ? menuStepFeature : menu_steps));
  }, [dispatch, menu_steps]);

  const onOtherTryingToPay = useCallback(
    ({ usersTryingToPay }) => {
      dispatch(setOtherTryingToPay(isSomeonePayingForCurrentUser(usersTryingToPay, user.id)));

      dispatch(setUserIdsSomeonePaysFor(getIdsSomeonePaysFor(usersTryingToPay, user.id)));

      dispatch(setPayingForOthers(usersTryingToPay));
    },
    [dispatch, user.id],
  );

  const onUserInfoChanged = useCallback(
    (data) => {
      dispatch(setNewNameForAnonGuest(data.userId, data.userName));
    },
    [dispatch],
  );

  const onGuestsPresenceChanged = useCallback(
    (orderId) => {
      dispatch(getReservationDetails(orderId, history));
      dispatch(getOrdersRounds(orderId));
    },
    [dispatch, history],
  );

  const onOrderClosed = useCallback(
    (orderId) => {
      history.push(past_order_page_path.replace(":orderId", orderId));

      dispatch(closeCurrentReservation());
      dispatch(clearRestaurantReservation());
    },
    [dispatch, history],
  );

  const checkIfAllGuestsHaveSelectedLoyaltyOption = useCallback(
    (loyaltyProcess) => {
      // CHECK IF ALL GUESTS HAVE SELECTED LOYALTY OPTION, TO TOGGLE THE 'APPLY CREDITS' BUTTON
      const hasAllGuestsSelectedLoyaltyOption = usersLoyaltyProgramOnOrder?.filter((loyaltyUser) => {
        const hasUserInPayingForOthersState = payingForOthersState?.some(
          (stateUser) =>
            stateUser?.userIds?.includes(loyaltyUser?.user_id) &&
            stateUser?.userIds?.length > 1 &&
            stateUser?.payerId !== loyaltyUser?.user_id,
        );

        const userLoyaltyStep = loyaltyProcess?.usersLoyaltyStep[loyaltyUser.user_id]?.userLoyaltyStep;

        const currentUserCheckoutInfo = checkoutInfoPerUsers?.find((info) => info.userId === loyaltyUser.user_id);
        const userMaxUsablePoints = maximumLoyaltyPoints(
          +currentUserCheckoutInfo?.subTotal,
          restaurant?.loyalty_program_max_conversion_rate,
          +loyaltyUser.current_points,
        );

        return (
          loyaltyUser?.user_id !== user.id &&
          (!(loyaltyProcess?.usersLoyaltyStep && loyaltyUser?.user_id in loyaltyProcess?.usersLoyaltyStep) ||
            (userLoyaltyStep !== userLoyaltyStepEnum.toApplyPending &&
              userLoyaltyStep !== userLoyaltyStepEnum.skipped)) &&
          !hasUserInPayingForOthersState &&
          +loyaltyUser.current_points >= +minUsablePoints &&
          +userMaxUsablePoints >= +minUsablePoints
        );
      });

      dispatch(setAllGuestSelectedLoyaltyOption(hasAllGuestsSelectedLoyaltyOption.length > 0 ? false : true));
    },
    [
      checkoutInfoPerUsers,
      dispatch,
      minUsablePoints,
      payingForOthersState,
      restaurant?.loyalty_program_max_conversion_rate,
      user.id,
      usersLoyaltyProgramOnOrder,
    ],
  );

  const onUserLoyaltyStepChange = useCallback(
    ({ loyaltyProcess }) => {
      dispatch(setLoyaltyProcess(loyaltyProcess));
      checkIfAllGuestsHaveSelectedLoyaltyOption(loyaltyProcess);

      // SHOW MODAL: ASK GUESTS TO CHOOSE LOYALTY OPTION
      const isCurrentUserPayingForOtherGuests = payingForOthersState.find(
        (stateUser) => stateUser.payerId === user.id && stateUser.userIds.length > 0,
      );

      if (loyaltyProcess.expirationTimerStarted && canUserUseLoyaltyProgram) {
        if (!otherTryingToPay && !isCurrentUserPayingForOtherGuests) {
          dispatch(setChooseIfGuestWantToUseCreditsModal(true));
        }
      }

      // CHECK TO SEE IF SOMEONE HAS APPLIED LOYATLY CREDITS, IF IT HAS, THEN MOVE ALL USERS TO CONFIRM PAYMENT
      const someoneAppliedLoyaltyCredits = Object.values(loyaltyProcess.usersLoyaltyStep)?.some(
        (user) => user.userLoyaltyStep === userLoyaltyStepEnum.alreadyApplied,
      );
      if (isLoyaltyDiscountApplied || someoneAppliedLoyaltyCredits) {
        dispatch(setUserLoyaltyStep(userLoyaltyStepEnum.alreadyApplied));
        dispatch(setRefreshCheckout(true));
      }
    },
    [
      dispatch,
      checkIfAllGuestsHaveSelectedLoyaltyOption,
      payingForOthersState,
      canUserUseLoyaltyProgram,
      isLoyaltyDiscountApplied,
      user.id,
      otherTryingToPay,
    ],
  );

  const { emitFriendSelected, emitUserLoyaltyStepChange } = useSocket({
    socketData: { orderId: currentOrder?.order_id, userId: user.id },
    shouldConnect: location.pathname === reservations_path_now && !connectSocketAfterIdleTimeout,
    onRefresh,
    onGuestsPresenceChanged,
    onOtherTryingToPay,
    onRefreshWithoutResetingIsPay,
    onOrderClosed,
    onPaymentExpired,
    onUserInfoChanged,
    onUserLoyaltyStepChange,
  });

  useEffect(() => {
    updateIsMenuStepsFeatureEnaled();
  }, [updateIsMenuStepsFeatureEnaled]);

  useEffect(() => {
    async function fetchData() {
      await dispatch(getCurrentReservation());
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!user.id) {
      dispatch(getUser());
    }
  }, [dispatch, user]);

  useEffect(() => {
    isAuthenticated && dispatch(getReservations());
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (currentOrder?.order_id) {
      dispatch(getLastRoundOrders(currentOrder?.order_id));
      setShowOrdersRoundsComponent(true);
    }
  }, [dispatch, currentOrder?.order_id]);

  useEffect(() => {
    return () => {
      dispatch(setIsPay(false));
      dispatch(setIsProceededToCheckoutSelection(false));
      dispatch(setIsForWhomToPayConfirmed(false));
    };
  }, [dispatch]);

  return (
    <>
      <Row className="reservations-row justify-content-center">
        <Column lg={12}>
          <Row>
            {!!navbarLinks ? (
              <Column lg="2">
                {/*Renamed from Reservations to Dining*/}
                <h2 className="page-heading">Dining</h2>
                <ReservationTypes
                  upcomingBadge={reservations?.upcoming.length ?? 0}
                  ongoingBadge={reservations?.ongoing ? 1 : 0}
                  pastBadge={reservations?.past.length ?? 0}
                />
              </Column>
            ) : (
              <Column lg="2" />
            )}
            <Column className="scrollable-list" lg="6">
              {
                <Component
                  emitFriendSelected={emitFriendSelected}
                  showOrdersRoundsComponent={showOrdersRoundsComponent}
                  orderId={currentOrder?.order_id}
                  pastReservations={reservations.past}
                  upcomingReservations={reservations.upcoming}
                  // handleReservationHavigation={handleReservationHavigation}
                  history={history}
                  isLoading={isLoading}
                />
              }
            </Column>
            <Column lg="2" />
          </Row>
        </Column>
      </Row>
    </>
  );
}

export default ReservationsPageWrapper;
