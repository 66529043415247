export const discountTypeEnum = {
  PERCENTAGE: "PERCENTAGE",
  LOYALTY_CREDITS: "LOYALTY_CREDITS",
};

export const toastDiscountTypeEnum = {
  OPEN_FIXED: "OPEN_FIXED",
  PERCENT: "PERCENT",
  FIXED: "FIXED",
};
