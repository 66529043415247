export const paymentStatuses = {
  OPEN: "OPEN",
  PROCESSING: "PROCESSING",
  AUTHORIZED_AT_RISK: "AUTHORIZED_AT_RISK",
  AUTHORIZED: "AUTHORIZED",
  ERROR: "ERROR",
  ERROR_NETWORK: "ERROR_NETWORK",
  DENIED: "DENIED",
  PROCESSING_VOID: "PROCESSING_VOID",
  VOIDED_AT_RISK: "VOIDED_AT_RISK",
  CANCELLED: "CANCELLED",
  CAPTURE_IN_PROGRESS: "CAPTURE_IN_PROGRESS",
  CAPTURED: "CAPTURED",
  VOIDED: "VOIDED",
};
