import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItemInfo } from "../pages/order/services/actions";
import { setRefreshDataForOpenedMenuItem } from "../store/actions/feedbackActions";

const useQuantityActions = () => {
  const dispatch = useDispatch();
  const {
    orders: { lastRoundOrders },
  } = useSelector((state) => state);

  const handleItemQuantityUpdate = useCallback(
    (itemId, orderId, menuItemDetails, setMenuItemDetails) => {
      if (itemId && orderId) {
        dispatch(getItemInfo(itemId, orderId)).then((data) => {
          let tmpOrderItem;
          let existingItemInCurrentRoundFound = false;

          for (let i = 0; i < lastRoundOrders.length; i++) {
            if (lastRoundOrders[i].item_id === menuItemDetails.item_id) {
              existingItemInCurrentRoundFound = true;
              tmpOrderItem = lastRoundOrders[i];
              break;
            }
          }

          if (!existingItemInCurrentRoundFound) {
            tmpOrderItem = { ...menuItemDetails };
            delete tmpOrderItem.round_item_id;
            delete tmpOrderItem.quantity;
            delete tmpOrderItem.description;
          }

          tmpOrderItem = {
            ...tmpOrderItem,
            inventory_status: data.inventory_status,
            remaining_quantity: data.remaining_quantity,
            reservation_selected_quantity: data?.reservation_selected_quantity || null,
            users: data?.users || [],
          };

          setMenuItemDetails(tmpOrderItem);
        });

        dispatch(setRefreshDataForOpenedMenuItem({ itemId: itemId, refresh: false }));
      }
    },
    [dispatch, lastRoundOrders],
  );

  const handleGetItemInfo = (item, setItem, orderId) => {
    if (item.item_id && orderId) {
      dispatch(getItemInfo(item.item_id, orderId)).then((data) => {
        item = {
          ...item,
          inventory_status: data.inventory_status,
          remaining_quantity: data.remaining_quantity,
          reservation_selected_quantity: data?.reservation_selected_quantity || null,
          users: data?.users || [],
        };

        setItem(item);
      });

      dispatch(setRefreshDataForOpenedMenuItem({ itemId: item.item_id, refresh: false }));
    } else {
      setItem(item);
    }
  };

  return { handleItemQuantityUpdate, handleGetItemInfo };
};

export default useQuantityActions;
