import React, { useEffect } from "react";
import ActiveSectionItems from "../../pages/menu/ActiveSectionItems";
import Image from "../common/Image";
import SeparationLine from "../common/SeparationLine";
import Modal from "../modal/Modal";
import { CloseButton } from "../restaurant/MenuItemDetails";
import CustomButton from "../common/CustomButton";
import useFloatingIslandActions from "../../hooks/useFloatingIslandActions";
import { setOnFullPageModal } from "../../store/actions/feedbackActions";
import { useDispatch } from "react-redux";

const RecommendedMenuItemsModal = ({
  show,
  close,
  items,
  menuItemDetails,
  lastRoundOrders,
  handleMenuItemDetails,
  handleOnCloseAddMenuItem,
  onMenuItemRecommendationsModal,
  handleRecommendedItemDetails,
}) => {
  const dispatch = useDispatch();

  //#region FloatingIsland

  useEffect(() => {
    dispatch(setOnFullPageModal(true));
    return () => dispatch(setOnFullPageModal(false));
  }, [dispatch]);

  const { createButtonPortal, closeModal, DOMslot1 } = useFloatingIslandActions();

  //#endregion

  return (
    <div className={"add-menu-item-wrapper"}>
      <Modal className="add-menu-item-modal" show={show} close={close}>
        <CloseButton onClick={close} />
        {/* <div className={'menu-item-details-container'}>
                    <Image
                        src={''}
                        menu
                    />
                    <div> */}
        <Image src={menuItemDetails.image} menu height={"auto"} className={"menu-item-details-img"} />

        <h3 style={{ textAlign: "center", margin: "2rem 0" }}>Our recommended suggestions</h3>

        <SeparationLine />

        <div style={{ margin: "1rem" }}>
          <ActiveSectionItems
            onReservation
            onRecommendations
            items={items}
            handleMenuItemDetails={handleMenuItemDetails}
            emptyMofifierGroup={true}
            onMenuItemRecommendationsModal={onMenuItemRecommendationsModal}
            handleRecommendedItemDetails={handleRecommendedItemDetails}
            showSpecial
            lastRoundOrders={lastRoundOrders}
          />
        </div>

        {/* <div style={{ position: 'sticky', bottom: '0', padding: "0 0 1rem 0" }} className='add-to-order-btn-wrapper'>
                    <CustomButton
                        text="Close"
                        wrapperClassName="booking-btn add-menu-item-submit-btn add-to-order-btn mx-auto"
                        textClassName="booking-btn-text add-menu-item-submit-btn-text add-to-order-btn-text active"
                        onClick={close}
                    />
                </div> */}

        {createButtonPortal(closeModal(close), DOMslot1)}
      </Modal>
    </div>
  );
};

export default RecommendedMenuItemsModal;
