import React from "react";
import { ReactComponent as CheckMarkPlaceholder } from "../../assets/check-mark.svg";
import "./CheckMarkPoint.css";

function CheckMarkPoint() {
  return (
    <div className="points-wrapper">
      <div className="point-wrapper">
        <CheckMarkPlaceholder
          fill="#ffffff"
          style={{
            backgroundColor: "#3c9957",
            width: "24px",
            height: "24px",
            padding: "5px",
            borderRadius: "50%",
            marginTop: "5px",
            marginRight: "10px",
          }}
        />
        <p>Book a table through the app and prepare your order to save time with convenience.</p>
      </div>
      <div className="point-wrapper">
        <CheckMarkPlaceholder
          fill="#ffffff"
          style={{
            backgroundColor: "#3c9957",
            width: "24px",
            height: "24px",
            padding: "5px",
            borderRadius: "50%",
            marginTop: "5px",
            marginRight: "10px",
          }}
        />
        <p>See all your past orders and download the pdf receipts.</p>
      </div>
      <div className="point-wrapper">
        <CheckMarkPlaceholder
          fill="#ffffff"
          style={{
            backgroundColor: "#3c9957",
            width: "24px",
            height: "24px",
            padding: "5px",
            borderRadius: "50%",
            marginTop: "5px",
            marginRight: "10px",
          }}
        />
        <p>Store your payment details so you can easily check out on your future orders.</p>
      </div>
    </div>
  );
}

export default CheckMarkPoint;
