import CloseIcon from "@mui/icons-material/Close";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import TuneIcon from "@mui/icons-material/Tune";
import { Grid } from "@mui/material";
import isEmpty from "lodash.isempty";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import codeListEnum from "../../enums/codeListEnum";
import { colorsEnum } from "../../enums/colorsEnum";
import { fontFamiliesEnum } from "../../enums/fontFamiliesEnum";
import { CustomAutocomplete } from "../common/previewComponents";

const RestaurantsFilter = ({ types, cities, menuKeywords, handleChange, selectedFiltersForRestaurants }) => {
  const menuItems = [
    {
      label: codeListEnum.CITIES,
      data: cities,
      icon: <LocationCityIcon />,
    },
    {
      label: codeListEnum.TYPES,
      data: types,
      icon: <LocalDiningIcon />,
    },
    // {
    //     label: codeListEnum.MENU_KEYWORDS,
    //     data: menuKeywords,
    //     icon: <DinnerDiningIcon />
    // },
  ];

  const popupIcon = (
    <TuneIcon
      sx={{
        color: "#020083",
      }}
      fontSize="medium"
    />
  );

  const closeIcon = <CloseIcon style={{ color: "#fff" }} />;

  const {
    feedback: { activeModal },
  } = useSelector((state) => state);

  const [isAutocompletePopoverOpen, setIsAutocompletePopoverOpen] = useState(false);

  const handleIsAutocompeltePopoverOpen = () => {
    setIsAutocompletePopoverOpen((prevState) => !prevState);
  };
  const handleIsAutocompeltePopoverOnOpen = () => {
    setIsAutocompletePopoverOpen(true);
  };
  const handleIsAutocompeltePopoverOnClose = () => {
    setIsAutocompletePopoverOpen(false);
  };

  useEffect(() => {
    if (!isEmpty(activeModal)) {
      setIsAutocompletePopoverOpen(false);
    }
  }, [activeModal]);

  return (
    <Grid
      container
      maxWidth="md"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "auto", marginBottom: "1rem" }}
    >
      <Grid
        item
        xs={12}
        mx="auto"
        sx={{
          paddingX: {
            md: 3,
            xs: 0,
          },
        }}
      >
        <CustomAutocomplete
          menuItems={menuItems}
          selectedMenuItems={selectedFiltersForRestaurants}
          label={"Filter by Cities, Cuisines"}
          multiple
          disableCloseOnSelect
          open={isAutocompletePopoverOpen}
          onToggle={handleIsAutocompeltePopoverOpen}
          handleIsAutocompeltePopoverOnOpen={handleIsAutocompeltePopoverOnOpen}
          handleIsAutocompeltePopoverOnClose={handleIsAutocompeltePopoverOnClose}
          popupIcon={popupIcon}
          closeIcon={closeIcon}
          onChange={handleChange}
          mainColor={colorsEnum.white}
          secondaryColor={colorsEnum.navyBlue}
          alternativeColor={colorsEnum.trout}
          backgroundColor={colorsEnum.alto}
          mainFontFamily={fontFamiliesEnum.cormorant}
          secondaryFontFamily={fontFamiliesEnum.lato}
        />
      </Grid>
    </Grid>
  );
};

export default RestaurantsFilter;
