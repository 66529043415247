import Row from "../../containers/layout/Row";
import Column from "../../containers/layout/Column";
import NavigationSideMenu from "../../components/navigation/NavigationSideMenu";
import useFloatingIslandActions from "../../hooks/useFloatingIslandActions";
import "./TermsPage.css";

function TermsPage() {
  const { backToOrderShortcut, DOMslot1 } = useFloatingIslandActions();

  return (
    <>
      {backToOrderShortcut(DOMslot1)}
      <Row className="m-4 reservations-row">
        <Column lg={12}>
          <Row>
            <Column lg="2">
              <NavigationSideMenu />
            </Column>
            <Column className="scrollable-list" lg="5">
              <TermsAndConditions />
            </Column>
            <Column lg="2" />
          </Row>
        </Column>
      </Row>
    </>
  );
}

export function TermsAndConditions() {
  return (
    <>
      <h3>Terms of service</h3>
      <h4>INTRODUCTION</h4>
      <p>
        VOILA DINING LLC, doing business as Voila, ("we" or "Voila" or "Company"), provides a platform for ordering,
        booking, mobile payment and loyalty rewards program and related services ("Services" or "Voila Services"), and
        provides and/or enables related mobile applications, software applications (“Apps”), websites, for use by you
        ("Customer") to make payments for goods and/or services and participate in rewards programs at restaurants.
        Voila provides access to these Services and Apps, including this App, subject to your acceptance of all of the
        following Terms and Conditions ("User Terms", “TOS” or "Agreement").
      </p>
      <p>
        By accessing, downloading, and/or using a Voila App, including this App, and/or the Services, you agree to these
        User Terms. THESE USER TERMS CREATE A BINDING LEGAL AGREEMENT BETWEEN YOU AND VOILA.
      </p>
      <p>
        PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY. THEY CONTAIN IMPORTANT INFORMATION ABOUT YOUR RIGHTS
        AND OBLIGATIONS, AS WELL AS LIMITATIONS AND EXCLUSIONS THAT APPLY TO YOUR PURCHASES.
      </p>
      <h4>THE APPS AND THE VOILA SERVICES</h4>
      <p>
        Voila is a restaurant booking and food ordering application and platform. We list a range of restaurants where
        you can book a table, order food and pay the check. Our unique selling points are safety (by reducing contact) &
        convenience (all done through an app, no waiting time for order and settling the check).
      </p>
      <p>
        The app will be distributed on the Apple App Store and Google’s Play Store. We will also have a web app offering
        the same functionality accessible on https://voila.nyc.
      </p>
      <h4>ACCOUNT REGISTRATION</h4>
      <p>
        Booking and ordering will be available for registered customers only. Without an account, a user can only browse
        the restaurants and menus, without booking and ordering. The personal data collected for registered customers
        (username, email, password, name and telephone number) will be stored by us only for the needs of the booking
        and ordering process. We ask for this information on your first booking or ordering only.
      </p>
      <p>
        You must provide accurate and complete information in response to our questions and keep that information
        current.
      </p>
      <p>You are solely responsible and liable for any activities that occur under your Voila Account.</p>
      <p>
        We reserve the right to suspend or terminate the Voila Account of any user who provides inaccurate, untrue, or
        incomplete information, or who fails to comply with the account registration requirements and complaints from
        our restaurants and third party partners.
      </p>
      <h4>REVISIONS, DISCLOSURES AND NOTICES</h4>
      <p>
        Your continued use of Our App and Services following the posting of modified Terms will be subject to the Terms
        in effect at the time of your use. Please review these Terms periodically for changes. If you object to any
        provision of these Terms or any subsequent modifications to these Terms or become dissatisfied with Our Website
        in any way, your only recourse is to immediately terminate use of Our App and Services.
      </p>
      <p>
        We may amend the TOS or our Policies, at any time with notice that we deem to be reasonable in the
        circumstances, by posting the revised version on our website or communicating it to you through the Services
        (each a “Revised Version”).
      </p>
      <h4>LIMITATIONS ON USE AND RULES OF CONDUCT</h4>
      <p>
        By using the Apps, Website and Services, you agree not to: use the Services for commercial purposes or in any
        way that is unlawful, or harms us or any other person or entity; use any information obtained from the Website
        or the Services in order to contact, advertise to, solicit, or sell to any user or restaurant; upload, download,
        post, email, transmit, store or otherwise make available any Content that is unlawful, harassing, threatening,
        harmful, tortious, defamatory, libelous, abusive, violent, obscene, vulgar, invasive of another's privacy,
        hateful, racially or ethnically offensive, or otherwise objectionable; stalk, harass, threaten or harm another;
        pretend to be anyone, or any entity, you are not — you may not impersonate or misrepresent yourself as another
        person (including celebrities), entity, another subscriber, a Voila employee, or a civic or government leader,
        or otherwise misrepresent your affiliation with a person or entity (including creation of additional accounts
        after termination by us for violation of the TOS); engage in any copyright infringement or other intellectual
        property infringement, or disclose any trade secret or confidential information in violation of a
        confidentiality, employment, or nondisclosure agreement; post, send, transmit or otherwise make available any
        unsolicited or unauthorized email messages, advertising, promotional materials, junk mail, spam, or chain
        letters, including, without limitation, bulk commercial advertising and informational announcements; forge any
        TCP-IP packet header or any part of the header information in an email or a news group posting, or otherwise
        putting information in a header designed to mislead recipients as to the origin of any Content transmitted
        through the Service ("spoofing"); upload, post, email, transmit, store or otherwise make available any material
        that contains viruses or any other computer code, files or programs designed to harm, interfere or limit the
        normal operation of the Service (or any part thereof), or any other computer software or hardware; access or
        monitor any material or information on any Voila system using any manual process or robot, spider, scraper or
        other automated means; perform or attempt to perform any actions that would interfere with or disrupt the
        Service (including accessing the Service through any automated means, like scripts or web crawlers), or any
        servers or networks connected to the Service, or any policies, requirements or regulations of networks connected
        to the Service (including any unauthorized access to, use or monitoring of data or traffic thereon); plan or
        engage in any illegal activity; and/or gather and store personal information on any other users of the Service
        to be used in connection with any of the foregoing prohibited activities.
      </p>
      <p>
        We cannot and do not assure that other users are or will be complying with the foregoing limitations on use and
        rules of conduct or any other provisions of these TOS, and, as between you and us, you assume all risk of harm
        or injury resulting from any such lack of compliance.
      </p>
      <p>
        Violations of any of these TOS may result in termination of your account. We reserve the right to modify these
        limitations on use and rules of conduct in accordance with Section “REVISIONS, DISCLOSURES AND NOTICES”.
      </p>

      <h4>COMMUNICATIONS</h4>
      <p>
        By registering, booking a table or placing an order with us, you are consenting to receive emails and push
        notifications from us or our third party service providers.
      </p>
      <p>
        Such notifications may include messages regarding your registration and account, booking and dining
        confirmations, interactions resulting from your actions in the app, and transactional messages sent to your
        device, within the app or on email. You may choose to turn off such push and in-app notifications by changing
        your settings on your mobile device or through the settings in the mobile application.
      </p>
      <h4>FEES</h4>
      <p>
        You agree to pay all charges, including gratuities, fees and taxes, and any part thereof, when you purchase,
        use, or participate in a Service from Voila. All fees are exclusive of applicable taxes, unless otherwise
        stated, and you are solely responsible for the payment of any such taxes that may be imposed on your use of the
        services provided to you. Voila may change the fees for the Service at any time. Cancellations are subject to
        our cancellation policy, posted here.
      </p>
      <h4>PRICES AND REWARDS</h4>
      <p>
        In some cases, the prices in the app will be higher than specified in the physical restaurant menu. The prices
        are set by the restaurant and we don’t have control over them. The difference in price (printed menu in
        restaurant vs. higher price in the Voila menu) will be distributed in three equal parts - one part as rewards
        for the customer, another part for the Voila app, the last part for the restaurant. Accumulated rewards can only
        be used as discounts for following meals booked via the app.
      </p>
      <p>
        Rewards will only be obtained and used in participating restaurants. In other restaurants, the rewards and
        loyalty scheme does not apply. Rewards obtained in a rewards scheme participating restaurants can only be used
        in that particular restaurant.
      </p>
      <h4>CREDIT CARD OR OTHER PAYMENT SERVICE AUTHORIZATION</h4>
      <p>
        For the payments, we will use Spreedly as a credit card payment processor. Payment data won’t be stored on our
        side, Spreedly will provide masked data via tokens for repeat purchases.
      </p>
      <p>
        Customers by default will pay for their own orders but optionally they can choose to settle the bill for other
        invited customers. The rewards will be received by the customer settling the bill.
      </p>
      <p>
        You may be asked to provide us with a credit card number from a card issuer or other payment information that we
        accept in order to activate and/or pay for any fees related to the Service. We may seek pre-authorization of
        your credit card or other payment information prior to a purchase to verify that the credit card or payment
        information is valid and/or has the necessary funds or credit available to cover your purchase. These
        pre-authorizations will reduce your available balance by the authorization amount until it is released or
        reconciled with the actual charge. Please contact your card issuer if you have additional questions regarding
        when an authorization amount will be removed from your statement.
      </p>
      <h4>ADDITIONAL OBLIGATIONS OR TERMS OF USE</h4>
      <p>
        Particular components or features of our Service, provided by us and/or our third party service providers, may
        be subject to separate software or other license agreements or terms of use. You must read, accept, and agree to
        be bound by any such separate agreements as a condition of using these particular components or features of the
        Service. Voila is not responsible for the performance of any third party services.
      </p>
      <h4>THIRD PARTY SERVICES</h4>
      <p>
        Third Party Services are not under our control, and you acknowledge that we are not responsible or liable for
        the content, functions, accuracy, legality, appropriateness or any other aspect of such websites or resources.
        Because Voila may have no control over such third party services, Voila is not responsible for the availability
        of such services or resources, and does not endorse or warrant the accuracy of any such sites or resources, and
        will in no way be liable or responsible for their functioning. For the booking of tables, we will rely on third
        party services and api’s provided by OpenTable. The booking of the table will be done in the background and
        registered by OpenTable. Confirmation and cancelation of the reservation will come by OpenTable as well. Voila
        is not responsible for any problems arising during and after the booking of tables and is not liable for any
        damages or loss caused either directly or indirectly, as a result of your use and/or reliance upon any such
        functionality.
      </p>
      <p>
        For the ordering of food, we will rely on third party services and api’s by Toast. Voila is not responsible for
        any problems arising during and after the ordering of food and is not liable for any damages or loss caused
        either directly or indirectly, as a result of your use and/or reliance upon any such functionality.
      </p>
      <p>
        For the payment, we rely on third party services and api’s by the payment processor Spreedly. Voila is not
        responsible for any problems arising during and after the payment process and payment itself and is not liable
        for any damages or loss caused either directly or indirectly, as a result of your use and/or reliance upon any
        such functionality.
      </p>
      <h4>TRANSACTIONS INVOLVING ALCOHOLIC BEVERAGES</h4>
      <p>
        You may not use the Services to purchase alcohol products unless you and the intended recipient of the products
        meet the minimum age requirements under applicable state or provincial laws and present a valid photo
        identification verifying your age at the time you (or the intended recipient) pick(s) up or receive(s) delivery
        of your order. Restaurant, not Voila, is responsible for complying with all applicable laws concerning the sale
        and delivery of alcohol products. All sales of alcohol products are between you and the Restaurant, and Voila is
        not a party to any such transaction.
      </p>
      <h4>TERMINATION</h4>
      <p>
        You may terminate your account and/or stop using the Service at any time. To terminate your account see your
        account settings. Any fees paid by you prior to your termination are nonrefundable (except as expressly
        permitted otherwise by these TOS). Termination of your account will not relieve you of any obligation to pay any
        accrued fees or charges. We may at any time, under any circumstances and without prior notice, immediately
        terminate or suspend all or a portion of your account and/or access to the Service. Any such termination or
        suspension will be made by us in our sole discretion, without any refund to you including any accumulated
        rewards, and we will not be responsible to you or any third party for any damages that may result or arise out
        of such termination or suspension of your account and/or access to the Service.
      </p>
      <h4>EFFECT OF TERMINATION</h4>
      <p>
        Upon termination of your account you lose all access to the Service and any portions thereof, including, but not
        limited to, your Voila Account. In addition, Voila may delete all information and data stored in or as a part of
        your account(s) including, but not limited to, data, email, preferences and other data you may have submitted.
        Any individual components of the Service that you may have used subject to separate agreement will also be
        terminated in accordance with such separate license.
      </p>
      <h4>DISCLAIMER OF WARRANTIES</h4>
      <p>
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK AND THE SERVICES ARE
        PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. VOILA AND ITS AFFILIATES, SUBSIDIARIES, OFFICERS, DIRECTORS,
        EMPLOYEES, AGENTS, PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
        IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE, AND NON-INFRINGEMENT. IN PARTICULAR, VOILA AND ITS AFFILIATES, SUBSIDIARIES, OFFICERS, DIRECTORS,
        EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO WARRANTY THAT (I) THE SERVICES WILL MEET YOUR REQUIREMENTS;
        (II) YOUR USE OF THE SERVICES WILL BE TIMELY, UNINTERRUPTED, SECURE OR ERROR-FREE; (III) ANY INFORMATION
        OBTAINED BY YOU AS A RESULT OF THE SERVICES WILL BE ACCURATE OR RELIABLE; AND (IV) ANY DEFECTS OR ERRORS IN THE
        REWARDS PROGRAMME OR OTHER ITEMS PROVIDED TO YOU AS PART OF THE SERVICES WILL BE CORRECTED.
      </p>
      <p>
        ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS ACCESSED AT YOUR OWN DISCRETION
        AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR LOSS OF DATA THAT RESULTS FROM THE
        DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM VOILA OR
        THROUGH OR FROM THE SERVICE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TOS.
      </p>
      <h4>LIMITATION OF LIABILITY</h4>
      <p>
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT VOILA AND ITS AFFILIATES, SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES,
        AGENTS, PARTNERS AND LICENSORS WILL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
        CONSEQUENTIAL OR EXEMPLARY DAMAGES , INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE,
        DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR OTHER INTANGIBLE LOSSES (EVEN IF VOILA HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (I) THE USE OR INABILITY TO USE THE SERVICES; (II)
        ANY CHANGES MADE TO THE SERVICES OR ANY TEMPORARY OR PERMANENT CESSATION OF THE SERVICES OR ANY PART THEREOF;
        (III) THE UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV) THE DELETION OF, CORRUPTION
        OF, OR FAILURE TO STORE AND/OR SEND OR RECEIVE YOUR TRANSMISSIONS OR DATA ON OR THROUGH THE SERVICES; (V)
        STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICES; AND (VI) ANY OTHER MATTER RELATING TO THE SERVICES. IN
        NO EVENT WILL VOILA’S LIABILITY IN CONNECTION WITH ANY SERVICE, INCLUDING ANY VOUCHER, EXCEED THE AMOUNT PAID
        FOR ANY SUCH SERVICE BY YOU.
      </p>
      <h4>INDEMNIFICATION</h4>
      <p>
        You shall defend, indemnify, and hold harmless us, our affiliates and each of our and their respective
        employees, contractors, directors, suppliers and representatives from all liabilities, claims, and expenses,
        including reasonable attorneys’ fees, that arise from or relate to your use or misuse of, or access to, the
        Services, Content, or otherwise from your User Content and data, violation of these Terms of Service (including,
        without limitation, the purchase, attempt to purchase, receipt, or consumption of alcohol while underage), gross
        negligence, fraud, or infringement by you, or any third party using your Account or identity in the Services, of
        any intellectual property or other right of any person or entity, including your use of the Services to provide
        a link to another website or to upload content or other information to the Services. Without limiting the
        generality of the foregoing, your indemnity obligations hereunder extend to your or a third party’s personal
        injury, illness, or death resulting from the consumption of any food or beverages ordered through the Services,
        including as a result of allergies, and any violation by you or any third party of applicable law in connection
        with orders you have placed through the Services including, without limitation, alcohol purchase and consumption
        laws and regulations. We reserve the right to assume the exclusive defense and control of any matter otherwise
        subject to indemnification by you, in which event you will assist and cooperate with us in asserting any
        available defenses.
      </p>
      <h4>WAIVER</h4>
      <p>
        The Voila app is not selling any products directly, we are just a platform showcasing restaurants and their
        menus and facilitating the process of booking, ordering and paying. The individual restaurants offered in the
        app are liable for the dining experience and all complaints should be addressed in the restaurants and not in
        the Voila app.
      </p>
      <p>
        You acknowledge and agree that the seller of any food or beverage is the applicable Restaurant and, that as the
        seller, the Restaurant will be fully responsible for any and all injuries, illnesses, damages, claims,
        liabilities and costs suffered by you, caused in whole or in part by the Restaurant, as well as for any
        unclaimed property liability arising from unredeemed orders or portions thereof. You waive, and release Voila
        and its officers, directors, employees and agents from, any claim, liabilities, damages, or injury arising from
        or related to any act or omission of a Restaurant in connection with the goods provided in connection therewith
        and/or as it relates to compliance with applicable laws.
      </p>
      <h4>DISPUTES</h4>
      <p>
        “Disputes” are defined as any claim, controversy, or dispute between you and Voila, its partners (or their
        respective affiliates, agents, directors, or employees) including any claims relating in any way to these TOS,
        any Additional Terms, or the Services, or any other aspect of our relationship.
      </p>
      <h5>DISPUTES WITH RESTAURANTS.</h5>
      <p>
        If there is a dispute between users of the Services, or between users and any third party (including any
        Restaurant), you understand and agree that Voila is under no obligation to become involved. In the event that
        you have any such dispute between users and any third party (including any Restaurant), you hereby release Voila
        (including the Site and the Services), its directors, officers, employees, service providers, affiliates, agents
        and successors from all claims, demands, and damages (actual and consequential) of every kind or nature, known
        or unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way related to such
        disputes.
      </p>
      <h4>GOVERNING LAW; EQUITABLE RELIEF AND JURISDICTION</h4>
      <p>
        These Terms of Service shall be governed by and construed in accordance with the laws of the State of New York,
        including its conflicts of law rules, and the United States of America. You acknowledge and agree that in the
        event of a breach or threatened violation of our intellectual property rights and confidential and proprietary
        information by you, we will suffer irreparable harm and will therefore be entitled to injunctive relief to
        enforce this Agreement. Notwithstanding the arbitration provisions herein, we may, without waiving any other
        remedies under this Agreement, seek from any court having jurisdiction any interim, equitable, provisional, or
        injunctive relief that is necessary to protect our rights and property, pending the outcome of the arbitration
        referenced above. You hereby irrevocably and unconditionally consent to the personal and subject matter
        jurisdiction of the federal and state courts in the State of New York, County of Richmond for purposes of any
        such action by us.
      </p>
    </>
  );
}

export default TermsPage;
