import { isEmpty } from "lodash";
import React from "react";
import EmptyReservation from "../../components/reservations/EmptyReservation";
import ReservationListItem from "../../components/reservations/ReservationListItem";
import { booking_reservation_path } from "../../config/pages_paths";

const UpcomingReservationsPage = ({ upcomingReservations, history, isLoading }) => {
  const handleReservationHavigation = (restaurantId, orderId) => {
    history &&
      history.push(booking_reservation_path.replace(":restaurantId", restaurantId).replace(":orderId", orderId));
  };

  return (
    <ul className="reservations-list">
      {!isEmpty(upcomingReservations) ? (
        upcomingReservations.map((props, idx) => (
          <ReservationListItem
            key={idx}
            onClick={() => handleReservationHavigation(props.restaurant_id, props.order_id)}
            {...props}
          />
        ))
      ) : !isLoading ? (
        <EmptyReservation text="No bookings yet" paragraph="Ready when you are" />
      ) : null}
    </ul>
  );
};

export default UpcomingReservationsPage;
