import React from "react";
import { Box, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Switch } from "@mui/material";
import { colorsEnum } from "../../enums/colorsEnum";
const ReservationGuestsPresence = ({ guests, handleReservationUserPresence }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <FormControl component="fieldset" variant="standard" sx={{ margin: "0.5rem 0 2rem", textAlign: "center" }}>
        <FormLabel component="legend">Confirm your presence</FormLabel>

        {guests.map((user) => {
          return (
            <FormGroup
              key={user.user_id}
              sx={{ display: "flex", flexDirection: "row", flexWrap: "no-wrap", alignItems: "center" }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={user.is_present}
                    sx={{
                      "& .MuiSwitch-thumb": {
                        backgroundColor: colorsEnum.navyBlue,
                      },
                      "& .MuiSwitch-track": {
                        backgroundColor: colorsEnum.navyBlue,
                      },
                    }}
                    name={user.first_name ?? "Anonymous guest"}
                    onChange={() => handleReservationUserPresence(user.user_id)}
                  />
                }
                label={user.first_name}
              />
              <FormHelperText sx={{ marginBottom: "0.5rem" }}>{user.user_type}</FormHelperText>
            </FormGroup>
          );
        })}
      </FormControl>
    </Box>
  );
};

export default ReservationGuestsPresence;
