import React from "react";
import CustomButton from "../common/CustomButton";
import Modal from "../modal/Modal";
import ReservationGuestsPresence from "../reservation/ReservationGuestsPresence";
import isEmpty from "lodash.isempty";
import OutlineButton from "../common/OutlineButton";

const YouSureItemModal = ({
  show,
  close,
  action,
  content,
  message,
  guests,
  handleReservationUserPresence,
  hostBookingPresence,
}) => {
  return (
    <>
      {/* <CloseButton onClick={close}/> */}
      <Modal isPopUp className="pop-up-modal" show={show} close={close} ignoreClassName={false}>
        <div>
          {message ? (
            <h4 style={{ textAlign: "center" }}>{message}</h4>
          ) : (
            <h4 style={{ textAlign: "center" }}>Are you sure you want to {content} this item?</h4>
          )}
          {!!hostBookingPresence && !isEmpty(guests) ? (
            <ReservationGuestsPresence guests={guests} handleReservationUserPresence={handleReservationUserPresence} />
          ) : null}
          <div className="you-sure-action-wrapper">
            {/* <CustomButton text='Confirm' onClick={action} /> */}
            <div style={{ display: "flex", alignItems: "center", width: "50%", maxWidth: "10rem" }}>
              <OutlineButton text={"Confirm"} onClick={action} />
            </div>
            <CustomButton text="Cancel" onClick={close} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default YouSureItemModal;
