import React from "react";
import classnames from "classnames";
import { useLocation } from "react-router";
import { profile_page_path } from "../../config/pages_paths";

function InputField({
  label,
  type = "text",
  placeholder,
  onChange,
  onChangePhone,
  disabled,
  name,
  value,
  style,
  error,
  maxLength,
  inputClassName,
  labelClassName,
  containerClassName,
  errorClassName,
  info,
  formGroup = true,
  ...restProps
}) {
  const location = useLocation();
  return (
    <div style={style} className={classnames(formGroup && "form-group", containerClassName)}>
      {label && (
        <label
          className={classnames(
            location.pathname !== profile_page_path ? "input-label" : "input-label-profile",
            labelClassName,
          )}
        >
          {label}
        </label>
      )}
      {type === "textarea" ? (
        <textarea
          disabled={disabled}
          className={classnames("form-control input-style", inputClassName, {
            "is-invalid": error,
          })}
          placeholder={placeholder}
          value={value ?? ""}
          onChange={onChange}
          name={name}
          maxLength={maxLength}
          {...restProps}
        />
      ) : (
        <input
          aria-required={!!restProps.required}
          type={type}
          disabled={disabled}
          className={classnames("form-control input-style", inputClassName, {
            "is-invalid": error,
          })}
          placeholder={placeholder}
          value={value ?? ""}
          onChange={onChange}
          name={name}
          {...restProps}
        />
      )}
      {info ? <div className={"input-info"}>{info}</div> : null}
      {error ? (
        <div className={classnames(location.pathname !== profile_page_path && "invalid-feedback", errorClassName)}>
          {error}
        </div>
      ) : null}
    </div>
  );
}

export default InputField;
