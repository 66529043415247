import {
  SET_FULL_MENU,
  SET_MENU,
  SET_RESTAURANT,
  SET_CURRENT_POSITION,
  SET_MODIFIERS_FOR_MENU_ITEM,
  SET_SELECTED_MODIFIERS_FOR_ORDER_ITEM,
  SET_RESTAURANT_BY_ID,
} from "../../../config/actionTypes";

const initialState = {
  restaurant: {},
  menu: [],
  fullMenu: [],
  currentPosition: {},
  modifiersFromDb: [],
  selectedModifiersFromDb: [],
  restaurantMenu: [],
};

const restaurantReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESTAURANT:
      return {
        ...state,
        restaurant: action.payload,
      };
    case SET_RESTAURANT_BY_ID:
      const { restaurants, restaurantId } = action.payload;
      return {
        ...state,
        restaurant: restaurants.find((restaurant) => restaurant.restaurant_id === +restaurantId),
      };
    case SET_MENU:
      return {
        ...state,
        menu: action.payload,
      };
    case SET_FULL_MENU:
      return { ...state, fullMenu: action.payload };
    case SET_CURRENT_POSITION:
      return {
        ...state,
        currentPosition: action.payload,
      };
    case SET_MODIFIERS_FOR_MENU_ITEM:
      return {
        ...state,
        modifiersFromDb: action.payload,
      };
    case SET_SELECTED_MODIFIERS_FOR_ORDER_ITEM:
      return {
        ...state,
        selectedModifiersFromDb: action.payload,
      };
    default:
      return state;
  }
};

export default restaurantReducer;
