export const orderStatusEnum = {
  orderStarted: "Started",
  orderSent: "Sent",
  orderPaid: "Paid",
  orderPaidPOS: "Paid (POS)",
  orderManuallyClosed: "ManuallyClosed",
  orderExpired: "Expired",
  booked: "Booked",
  cancelled: "Cancelled",
  noshow: "NoShow",
  autoClosed: "AutoClosed",
};
