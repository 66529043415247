import React, { Fragment } from "react";
import useForm from "../../hooks/useForm";
import InputField from "./InputField";
import { useDispatch, useSelector } from "react-redux";
import Button from "./Button";
import { sendContactEmail } from "../../store/actions/feedbackActions";

function ContactForm() {
  const { errors } = useSelector((state) => state);
  const dispatch = useDispatch();

  const sendEmail = (e) => {
    e.preventDefault();
    dispatch(sendContactEmail(values));
  };

  const { values, handleSubmit, handleChange } = useForm({ email: "", name: "", body: "" }, sendEmail);

  return (
    <Fragment>
      <form id="contact-form" onSubmit={handleSubmit}>
        <InputField
          type="text"
          required={true}
          name="name"
          onChange={handleChange}
          label="Name"
          value={values.name}
          error={errors.name}
        />
        <InputField
          type="email"
          required={true}
          name="email"
          onChange={handleChange}
          label="E-mail address"
          value={values.email}
          error={errors.email}
        />
        <InputField
          type="textarea"
          required={true}
          name="body"
          onChange={handleChange}
          label="Message"
          value={values.body}
          error={errors.body}
        />
        <div className="mx-auto text-center">
          <Button type="submit" className="round-btn " text={`Submit`} />
        </div>
      </form>
    </Fragment>
  );
}

export default ContactForm;
