export const autoScrollWithOffset = (ref, offset = 0) => {
  setTimeout(() => {
    if (ref?.current) {
      const elementPosition = ref.current.getBoundingClientRect().top + window.scrollY - offset;

      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    }
  }, 100);
};
