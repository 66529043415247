import { colorsEnum } from "../../../enums/colorsEnum";

const FloatingIslandDownload = ({
  label,
  loading = "Loading",
  downloadURL,
  fileName,
  show,
  className = "floating-island-button",
  leftIcon,
  rightIcon,
}) => (
  <>
    {show && (
      <button type={"button"} disabled={!downloadURL} className={className}>
        {downloadURL ? (
          <>
            {leftIcon && <span>{leftIcon}</span>}
            <a
              href={downloadURL}
              download={`${fileName}.pdf`}
              style={{
                color: `${colorsEnum.navyBlue}`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                borderRadius: "2rem",
              }}
            >
              {label}
            </a>
            {rightIcon && <span>{rightIcon}</span>}
          </>
        ) : (
          <>
            <span style={{ color: "white" }}>{loading}</span>
          </>
        )}
      </button>
    )}
  </>
);

export default FloatingIslandDownload;
