import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ItemsSection from "./sections/ItemsSection";
import DiscountsSection from "./discounts/DiscountsSection";
import TaxesSection from "./sections/TaxesSection";
import ServiceChargesSection from "./sections/ServiceChargesSection";
import PaymentsSection from "./sections/PaymentsSection";
import { justPayPageTypesEnum } from "../../../enums/justPayPageTypesEnum";
import { setCheck } from "../../../store/actions/justPayActions";
import CheckoutModals from "./CheckoutModals";
import CheckoutPaymentPreview from "./CheckoutPaymentPreview";
import CheckoutModalDefinitions from "./CheckoutModalDefinitions";
import { defaultTipPercentage } from "../../../config/keys";
import { calculatePaymentAmounts } from "../../../utils/calculatePaymentAmounts";
import { useHistory, useParams } from "react-router";
import { just_pay_menu_path } from "../../../config/pages_paths";
import useFloatingIslandActions from "../../../hooks/useFloatingIslandActions";
import { useRef } from "react";
import { autoScrollWithOffset } from "../../ordersRounds/helpers/autoScrollWithOffset";

const CheckoutInfo = ({ entity, savedCheckSplit, heading }) => {
  const { entityType, guid } = entity;

  const {
    showCheckSplitModal,
    showPaymentModal,
    showPaymentTipModal,
    onOpenCheckSplitModalHandler,
    onCloseCheckSplitModalHandler,
    onOpenPaymentModalHandler,
    onClosePaymentModalHandler,
    onOpenPaymentTipModalHandler,
    onClosePaymentTipModalHandler,
  } = CheckoutModalDefinitions();

  const { DOMslot1, DOMslot3, DOMslot4, goToJustPayMenu, createButtonPortal } = useFloatingIslandActions();

  const dispatch = useDispatch();
  const history = useHistory();

  const { tableGuid, restaurantGuid, orderGuid, checkGuid } = useParams();

  const paymentsSectionRef = useRef(null);

  const {
    justPay: {
      check: {
        split: existingSplit,
        paymentPercentage: existingPaymentPercentage,
        paymentAmount: existingPaymentAmount,
        paymentTipPercentage: existingPaymentTipPercentage,
      },
    },
  } = useSelector((state) => state);

  const {
    // totalAmount,
    // settledAmount,
    // balanceDue,
    // stepAmount,

    settledPct,
    // balanceDuePct,
    // stepPct,

    actionButtons,
    newPaymentAmount,
    newPaymentPct,
    newPaymentTipAmount,
    newPaymentTipPct,
  } = calculatePaymentAmounts({
    totalAmount: entity?.checkout?.totalAmount,
    balanceDue: entity?.checkout?.balanceDue,
    voilaSubTotalAmount: entity?.checkout?.voilaSubTotalAmount,
    split: existingSplit ?? savedCheckSplit ?? 1,
    paymentPercentage: existingPaymentPercentage ?? 100,
    paymentTipPercentage: existingPaymentTipPercentage ?? defaultTipPercentage,
  });

  // console.log("CheckoutInfo calculatePaymentAmounts",
  //   {
  //     totalAmount,
  //     settledAmount,
  //     balanceDue,
  //     stepAmount,

  //     settledPct,
  //     balanceDuePct,
  //     stepPct,

  //     actionButtons,
  //     currentPaymentAmount,
  //     currentPaymentPct,
  //     currentPaymentTipAmount,
  //     currentPaymentTipPct,
  //   }
  // )

  useEffect(() => {
    if (entityType === justPayPageTypesEnum.check) {
      dispatch(
        setCheck({
          checkGuid: guid,
          split: existingSplit ?? savedCheckSplit,
          paymentPercentage: existingPaymentPercentage ?? newPaymentPct,
          paymentAmount: existingPaymentAmount ?? newPaymentAmount,
          paymentTipPercentage: newPaymentTipPct,
          paymentTipAmount: newPaymentTipAmount,
        }),
      );
    }
  }, [
    dispatch,
    entityType,
    guid,
    existingSplit,
    savedCheckSplit,
    existingPaymentPercentage,
    existingPaymentAmount,
    newPaymentPct,
    newPaymentAmount,
    newPaymentTipPct,
    newPaymentTipAmount,
  ]);

  useEffect(() => {
    if (entity.checkout?.paymentsAmount > 0) {
      autoScrollWithOffset(paymentsSectionRef, 70);
    }
  }, [entity.checkout?.paymentsAmount]);

  const navigateToJustPayMenuPage = () => {
    const url = just_pay_menu_path.replace(":restaurantGuid", restaurantGuid).replace(":tableGuid", tableGuid);
    let params = new URLSearchParams(url.search);

    orderGuid && params.set("order", orderGuid);
    checkGuid && params.set("check", checkGuid);

    history.push({
      pathname: url,
      search: params.toString(),
    });
  };

  return (
    <div className="checkout-info-container">
      <p className="checkout-info-heading">{heading}</p>

      <div className="checkout-info-inner-container">
        <DiscountsSection entity={entity} />

        <ItemsSection entity={entity} />

        <TaxesSection entity={entity} />

        <ServiceChargesSection entity={entity} />

        <PaymentsSection entity={entity} />

        {entityType === justPayPageTypesEnum.check ? (
          <>
            <CheckoutModals
              checkout={entity.checkout}
              showCheckSplitModal={showCheckSplitModal}
              showPaymentModal={showPaymentModal}
              showPaymentTipModal={showPaymentTipModal}
              onCloseCheckSplitModalHandler={onCloseCheckSplitModalHandler}
              onClosePaymentModalHandler={onClosePaymentModalHandler}
              onClosePaymentTipModalHandler={onClosePaymentTipModalHandler}
              onOpenPaymentModalHandler={onOpenPaymentModalHandler}
              actionButtons={actionButtons}
              settledPct={settledPct}
            />
            <div ref={paymentsSectionRef}>
              <CheckoutPaymentPreview
                entity={entity}
                checkout={entity.checkout}
                onOpenCheckSplitModalHandler={onOpenCheckSplitModalHandler}
                onOpenPaymentModalHandler={onOpenPaymentModalHandler}
                onOpenPaymentTipModalHandler={onOpenPaymentTipModalHandler}
                alreadySplittedCheck={savedCheckSplit}
                actionButtons={actionButtons}
              />
            </div>

            {createButtonPortal(
              goToJustPayMenu(navigateToJustPayMenuPage),
              entity?.payments.length > 0 && entity?.checkout?.balanceDue > 0 ? DOMslot4 : DOMslot3,
            )}
          </>
        ) : (
          <>{createButtonPortal(goToJustPayMenu(navigateToJustPayMenuPage), DOMslot1)}</>
        )}
      </div>
    </div>
  );
};

export default CheckoutInfo;
