import isEmpty from "lodash.isempty";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderStatusEnum } from "../../../enums/orderStatusEnum";
import useFloatingIslandActions from "../../../hooks/useFloatingIslandActions";
import { setPayingForOthers } from "../../../pages/book/services/actions";
import {
  getCheckoutInfo,
  getUserLoyaltyProgram,
  getUsersLoyaltyProgramOnOrder,
  postCheckLevelDiscount,
  setAppliedLoyaltyPoints,
  setPendingLoyaltyPoints,
  setLoyaltyOnCurrentRestaurant,
  updateAppliedLoyaltyPoints,
  setLoyaltyProcess,
  setUserLoyaltyStep,
  setAllGuestSelectedLoyaltyOption,
  setChooseIfGuestWantToUseCreditsModal,
} from "../../../pages/order/services/actions";
import { getReceipt, setRefreshCheckout } from "../../../pages/reservations/services/actions";
import { setIsForWhomToPayConfirmed } from "../../../store/actions/feedbackActions";
import { setIsPay, setIsProceededToCheckoutSelection, setUpdateUserReceipt } from "../../../store/actions/usersActions";
import SeparationLine from "../../common/SeparationLine";
import SelectableTilesButton from "../../orders/buttons/SelectableTilesButton";
import SpreedlyCheckoutVault from "../../payment/spreedly/SpreedlyCheckoutVault";
import { autoScrollTo } from "../helpers/autoScrollTo";
import UserLoyaltyProgram from "./UserLoyaltyProgram";
import { maximumLoyaltyPoints } from "../../../utils/loyaltyCreditsUsageCalculation";
import { userLoyaltyStepEnum } from "../../../enums/userLoyaltyStepEnum";
import ApplyLoyaltyCreditsModal from "./ApplyLoyaltyCreditsModal";
import useSocket from "../../../hooks/useSocket";
import UseLoyaltyCreditsModal from "./UseLoyaltyCreditsModal";
import { loyaltyProcessTimerDurationTime } from "../../../config/keys";
import { useTimeLeft } from "../../../hooks/useExpirationTimer";

function SendToKitchen({
  isSendToKitchenMode,
  onSendToKitchen,
  onCompleteOrderClick,
  hasNewOrder,
  onPaymentCheckboxChange,
  payForUsers,
  orderUserIds,
  otherTryingToPay,
  onRefreshVoidedOrders,
  yourCheckRef,
}) {
  const {
    auth: {
      user: { id },
    },
    orders: {
      checkoutInfo: {
        subTotalInt,
        total,
        tip,
        checkoutInfoPerUsers,
        checkoutInfoOnTable,
        isLoyaltyDiscountApplied,
        areAnyDiscountsApplied,
      },
      ordersRounds,
      noTipReason,
      ordersNoRounds,
      // userTransactionLoyaltyProgram,
      userLoyaltyProgram,
      appliedLoyaltyPoints,
      usersLoyaltyProgramOnOrder,
      loyaltyOnCurrentRestaurant,
      loyaltyProcess,
      userLoyaltyStep,
      chooseIfGuestWantToUseCreditsModal,
      allGuestSelectedLoyaltyOption,
    },
    booking: { idsSomeonePaysFor, payingForOthersState, currentOrder },
    user: { isPay, isProceededToCheckoutSelection, updateUserReceipt },
    feedback: { isForWhomToPayConfirmed, onFullPageModal, connectSocketAfterIdleTimeout },
    restaurant: { restaurant },
  } = useSelector((state) => state);

  const [userPayingForMe, setUserPayingForMe] = useState(null);
  const [orderLessThanZeroErrorMsg, setOrderLessThanZeroErrorMsg] = useState(false);
  const [isMoreThanOneGuestOnTable, setIsMoreThanOneGuestOnTable] = useState(false);
  const [isPayForYourselfSelected, setIsPayForYourselfSelected] = useState(false);
  const [isOnlySelectedToPayForYourself, setIsOnlySelectedToPayForYourself] = useState(false);
  const [hasThisUserPaidForHimself, setHasThisUserPaidForHimself] = useState(false);
  const [onSelectingUsersToPayFor, setOnSelectingUsersToPayFor] = useState(false);
  const [payByGuest, setPayByGuest] = useState(false);
  const [selectedUsersForPayment, setSelectedUsersForPayment] = useState([]);
  const [showFeedbackMessages, setShowFeedbackMessages] = useState({
    someoneStartedPaymentProcess: false,
  });
  const [userCheckoutInfo, setUserCheckoutInfo] = useState({});
  const [showReceiptButton, setShowReceiptButton] = useState(false);
  const [receiptURL, setReceiptURL] = useState("");
  const [loyaltyCreditsInput, setLoyaltyCreditsInput] = useState("");
  const loyaltySectionRef = useRef(null);

  const spreedlyCheckoutVaultRef = useRef(null);
  const selectWhoToPayForRef = useRef(null);
  const dispatch = useDispatch();
  const remainingBalance =
    checkoutInfoOnTable &&
    total &&
    (parseFloat(checkoutInfoOnTable.total) - parseFloat(total.split("$")[1])).toFixed(2);
  const reservationDate = new Date(+currentOrder.date).toLocaleString({ hour12: true });
  const userWithPointsInActiveLoyaltyFlow =
    userLoyaltyStep !== userLoyaltyStepEnum.alreadyApplied && userLoyaltyStep !== userLoyaltyStepEnum.skipped;
  const userSelectedLoyaltyStepOption =
    userLoyaltyStep === userLoyaltyStepEnum.alreadyApplied ||
    userLoyaltyStep === userLoyaltyStepEnum.skipped ||
    userLoyaltyStep === userLoyaltyStepEnum.toApplyPending;

  const currentPoints = useMemo(() => +loyaltyOnCurrentRestaurant?.current_points, [loyaltyOnCurrentRestaurant]);
  const minUsablePoints = useMemo(() => restaurant?.loyalty_program_min_redeemable_points, [restaurant]);
  const maxUsablePoints = useMemo(
    () => maximumLoyaltyPoints(subTotalInt, restaurant?.loyalty_program_max_conversion_rate, currentPoints),
    [currentPoints, restaurant?.loyalty_program_max_conversion_rate, subTotalInt],
  );
  const canUserUseLoyaltyProgram = useMemo(
    () =>
      restaurant.is_loyalty_program_enabled &&
      !areAnyDiscountsApplied &&
      +currentPoints > 0 &&
      +currentPoints >= +minUsablePoints &&
      +maxUsablePoints >= +minUsablePoints,
    [areAnyDiscountsApplied, currentPoints, maxUsablePoints, minUsablePoints, restaurant.is_loyalty_program_enabled],
  );

  const { timeLeft } = useTimeLeft(loyaltyProcess.expirationTime, loyaltyProcessTimerDurationTime);

  const { emitUserLoyaltyStepChange } = useSocket({
    socketData: { orderId: currentOrder?.order_id, userId: id },
    shouldConnect: !connectSocketAfterIdleTimeout,
  });

  useEffect(() => {
    if (restaurant && userLoyaltyProgram) {
      dispatch(
        setLoyaltyOnCurrentRestaurant(
          userLoyaltyProgram.find((loyalty) => loyalty.restaurant_id === restaurant.restaurant_id),
        ),
      );
    }
  }, [dispatch, restaurant, userLoyaltyProgram]);

  useEffect(() => {
    dispatch(getUserLoyaltyProgram(id));
  }, [dispatch, id]);

  useEffect(() => {
    // check if any user's order is paid by himself
    // if there is none, hide the receipt button
    // all orders have been paid by other users
    if (currentOrder?.has_receipt) {
      setShowReceiptButton(true);
    }
  }, [ordersRounds, id, currentOrder, showReceiptButton]);

  const fetchReceipt = useCallback(() => {
    if (currentOrder?.order_id && (!receiptURL || updateUserReceipt)) {
      dispatch(getReceipt(currentOrder?.order_id, setReceiptURL));
      dispatch(setUpdateUserReceipt(false));
    }
  }, [dispatch, currentOrder, updateUserReceipt, receiptURL]);

  useEffect(() => {
    fetchReceipt();
  }, [fetchReceipt]);

  useEffect(() => {
    setTimeout(() => {
      if (ordersRounds.length > 0) {
        let currentRound = ordersRounds.find((round) => !round.round);
        let showErrorMsgArr = [];
        if (currentRound) {
          if (
            currentRound.users.length === 0 &&
            ordersNoRounds.some((user) => user.orders.length > 0 && parseFloat(user.sub_total) > 0)
          ) {
            setOrderLessThanZeroErrorMsg(true);
            return;
          }

          currentRound.users.forEach((user) => {
            const orderWithPrice = user.orders.some((order) => order.price > 0);
            if (!orderWithPrice) {
              showErrorMsgArr.push(true);
            } else {
              showErrorMsgArr.push(false);
            }
          });
        } else {
          showErrorMsgArr.push(false);
        }

        if (!showErrorMsgArr.includes(false)) {
          setOrderLessThanZeroErrorMsg(true);
        } else {
          setOrderLessThanZeroErrorMsg(false);
        }
      }
    }, 500);
  }, [ordersNoRounds, ordersRounds]);

  useEffect(() => {
    if (otherTryingToPay && payingForOthersState.length > 0) {
      const payerId = payingForOthersState.find((val) => val.userIds.find((i) => i === id))?.payerId;
      const findUserPayingForMe = ordersRounds?.map((round) =>
        round.users.map((user) => {
          if (user.user_id === payerId) {
            return user.user;
          }
        }),
      );
      setUserPayingForMe(findUserPayingForMe[0]);
    }
  }, [otherTryingToPay, payingForOthersState, id, orderUserIds]);

  useEffect(() => {
    if (!otherTryingToPay) {
      setUserPayingForMe(null);
    }
  }, [otherTryingToPay]);

  const isSpreedlyCheckoutVaultVisible = useMemo(() => {
    if (canUserUseLoyaltyProgram) {
      return (
        !isMoreThanOneGuestOnTable ||
        (isMoreThanOneGuestOnTable && isForWhomToPayConfirmed && isPay && !userWithPointsInActiveLoyaltyFlow)
      );
    } else {
      return !isMoreThanOneGuestOnTable || (isMoreThanOneGuestOnTable && isForWhomToPayConfirmed && isPay);
    }
  }, [
    canUserUseLoyaltyProgram,
    isMoreThanOneGuestOnTable,
    isForWhomToPayConfirmed,
    isPay,
    userWithPointsInActiveLoyaltyFlow,
  ]);

  const userIdsToSelect = orderUserIds.map((user) => {
    const userCheckoutInfo = checkoutInfoPerUsers?.find((info) => info.userId === user.userId && info.total !== "0.00");
    const othersPaying = payingForOthersState?.find((other) => other?.payerId === id);

    return (
      !idsSomeonePaysFor.some((id) => user.userId === id) && (
        <SelectableTilesButton
          setIsPayForYourselfSelected={setIsPayForYourselfSelected}
          key={user.userId}
          userId={user?.userId}
          disabled={isSpreedlyCheckoutVaultVisible}
          onClick={() => handleOnPaymentCheckboxChange(user)}
          active={
            othersPaying?.userIds?.includes(user.userId) !== undefined &&
            othersPaying?.userIds?.includes(user.userId) &&
            payingForOthersState?.payerId !== user?.userId
          }
        >
          <span>
            {user.userName.length > 20 ? user.userName.substring(0, 17).concat("...") : user.userName} {"\u00A0"}{" "}
            <b> {`$${userCheckoutInfo?.total ?? 0}`} </b>
          </span>
        </SelectableTilesButton>
      )
    );
  });

  useEffect(() => {
    checkoutInfoPerUsers && setUserCheckoutInfo(checkoutInfoPerUsers?.find((user) => user.userId === id));
  }, [checkoutInfoPerUsers]);

  useEffect(() => {
    if (
      userIdsToSelect &&
      userIdsToSelect.length > 0 &&
      (parseFloat(userCheckoutInfo.subTotal) > 0 ||
        checkoutInfoPerUsers?.filter((user) => parseFloat(user.subTotal) > 0).length >= 2)
    ) {
      setIsMoreThanOneGuestOnTable(true);
    } else {
      setIsMoreThanOneGuestOnTable(false);

      isProceededToCheckoutSelection && handleCancelPayment(true);
    }
  }, [ordersRounds, userIdsToSelect]);

  useEffect(() => {
    //  look in future to unselect self in paying for other guest without items
    for (let i = 0; i < idsSomeonePaysFor.length; i++) {
      if (payForUsers.includes(idsSomeonePaysFor[i]) && parseFloat(userCheckoutInfo.subTotal) === 0) {
        const index = payForUsers.findIndex((id) => id === idsSomeonePaysFor[i]);
        if (index > -1) {
          payForUsers.splice(index, 1);
        }
        dispatch(getCheckoutInfo(currentOrder?.order_id, payForUsers.length ? payForUsers : [id]));
        break;
      }
    }
  }, [idsSomeonePaysFor, idsSomeonePaysFor]);

  useEffect(() => {
    if (isPay) {
      for (let i = 0; i < payForUsers.length; i++) {
        if (payingForOthersState.length > 0) {
          for (let j = 0; j < payingForOthersState.length; j++) {
            if (payingForOthersState[j].userIds.includes(payForUsers[i]) && payingForOthersState[j].payerId !== id) {
              for (let k = 0; k < payingForOthersState[j].userIds.length; k++) {
                const index = payForUsers.findIndex((id) => id === payingForOthersState[j].userIds[k]);
                if (index > -1) {
                  payForUsers.splice(index, 1);
                }
              }
              dispatch(getCheckoutInfo(currentOrder?.order_id, payForUsers.length ? payForUsers : [id]));
              break;
            }
          }
        }
      }
    }

    if (otherTryingToPay && !isEmpty(userPayingForMe) && !hasThisUserPaidForHimself && isPay) {
      dispatch(setIsPay(false));
      dispatch(setIsProceededToCheckoutSelection(false));
    }
  }, [payingForOthersState, isPay]);

  useEffect(() => {
    // dispatch(getCheckoutInfo(currentOrder?.order_id, payForUsers.length ? payForUsers : [id]))
    dispatch(getCheckoutInfo(currentOrder.order_id, payForUsers));
  }, [dispatch, payForUsers, currentOrder?.order_id]);

  useEffect(() => {
    const checkIfUserHavePaidForHimself = checkoutInfoPerUsers?.find(
      (user) => user.userId === id && user.total === "0.00",
    );
    checkIfUserHavePaidForHimself?.total === "0.00"
      ? setHasThisUserPaidForHimself(true)
      : setHasThisUserPaidForHimself(false);
  }, [checkoutInfoPerUsers, id]);

  useEffect(() => {
    let arr = [];
    const finUsersWhoHavePaid = checkoutInfoPerUsers?.map((user) => {
      if (user.userId !== id && user.total === "0.00") {
        arr.push(user.userId);
      }
    });
    const removeUsersWhoWerePaidFor = arr?.map((id) => {
      const index = payForUsers.indexOf(id);
      if (index > -1) {
        payForUsers.splice(index, 1);
      }
    });
  }, [checkoutInfoPerUsers]);

  useEffect(() => {
    const userWithoutSelection = payingForOthersState?.find((user) => user.payerId === id && user.userIds.length === 0);
    const userCheckoutInfo = checkoutInfoPerUsers?.find((user) => user.userId === id);

    if (
      userWithoutSelection &&
      userCheckoutInfo?.subTotal &&
      subTotalInt !== parseFloat(userCheckoutInfo.subTotal) &&
      otherTryingToPay &&
      payForUsers.length > 0
    ) {
      handleSelectingCancelationConfirmation();
    }
  }, [payingForOthersState, otherTryingToPay, payForUsers]);

  useEffect(() => {
    if (
      payingForOthersState.length === 0 &&
      !otherTryingToPay &&
      payForUsers.length > 0 &&
      !isPay &&
      isPayForYourselfSelected
    ) {
      handleSelectingCancelationConfirmation();
      setOnSelectingUsersToPayFor(false);
      dispatch(setIsPay(false));
    }
  }, [payingForOthersState, otherTryingToPay, isPay, payForUsers, isPayForYourselfSelected]);

  const fetchUsersLoyaltyProgram = useCallback(() => {
    // This will ignore the users with no orders
    let userIds = orderUserIds.map((user) => user.userId);
    userIds.push(id);
    if (id) {
      dispatch(getUsersLoyaltyProgramOnOrder(userIds, currentOrder?.order_id));
      dispatch(getUserLoyaltyProgram(id));
      emitUserLoyaltyStepChange(currentOrder?.order_id);
    }
  }, [orderUserIds, id, dispatch, currentOrder?.order_id, emitUserLoyaltyStepChange]);

  useEffect(() => {
    const checkIfAllUsersAreSelected = userIdsToSelect.find((id) => id !== false);

    if (isPay) {
      let someonePaysForUser = false;
      orderUserIds.forEach((user) => {
        if (payingForOthersState.find((userPaying) => userPaying.userIds.includes(user.userId))) {
          someonePaysForUser = true;
          return;
        }
      });

      if (!someonePaysForUser && showFeedbackMessages.someoneStartedPaymentProcess) {
        setShowFeedbackMessages({
          ...showFeedbackMessages,
          someoneStartedPaymentProcess: false,
        });

        dispatch(setIsPay(false));
      }

      if (checkIfAllUsersAreSelected === undefined && !otherTryingToPay && hasThisUserPaidForHimself) {
        setShowFeedbackMessages({
          ...showFeedbackMessages,
          someoneStartedPaymentProcess: true,
        });
      }
    }

    if (checkIfAllUsersAreSelected !== undefined || !isPay) {
      setShowFeedbackMessages({
        ...showFeedbackMessages,
        someoneStartedPaymentProcess: false,
      });

      if (showFeedbackMessages.someoneStartedPaymentProcess) {
        handleSelectingCancelationConfirmation();
        dispatch(setIsPay(false));
      }
    }
  }, [payingForOthersState, isPay, showFeedbackMessages.someoneStartedPaymentProcess]);

  const payForUsersLogic = useCallback(() => {
    let someonePaysForUser = false;
    orderUserIds.forEach((user) => {
      if (payingForOthersState.find((userPaying) => userPaying.userIds.includes(user.userId))) {
        someonePaysForUser = true;
        return;
      }
      payForUsers.push(user.userId);
    });

    if (!payForUsers.includes(+id)) {
      payForUsers.push(+id);
    }

    if (hasThisUserPaidForHimself && payForUsers.length === 1 && payForUsers.includes(+id) && someonePaysForUser) {
      setShowFeedbackMessages({
        ...showFeedbackMessages,
        someoneStartedPaymentProcess: true,
      });
    }

    if (payForUsers.length === 1 && payForUsers.includes(+id)) {
      setIsOnlySelectedToPayForYourself(true);
    }
    //ako drug plaka stavi uslov
    dispatch(getCheckoutInfo(currentOrder.order_id, payForUsers));
    setSelectedUsersForPayment(orderUserIds);
    onPaymentCheckboxChange({ userId: -1, isProceed: true });
  }, [
    dispatch,
    currentOrder.order_id,
    hasThisUserPaidForHimself,
    id,
    onPaymentCheckboxChange,
    orderUserIds,
    payForUsers,
    payingForOthersState,
    showFeedbackMessages,
  ]);

  const handleProceededToCheckout = useCallback(() => {
    dispatch(setIsPay(true));

    const allGuestsSelected = userIdsToSelect.every((item) => item === false);

    if (canUserUseLoyaltyProgram) {
      fetchUsersLoyaltyProgram();
    }

    if (isMoreThanOneGuestOnTable && !allGuestsSelected) {
      dispatch(setIsProceededToCheckoutSelection(true));
      autoScrollTo(yourCheckRef);
    } else {
      userWithPointsInActiveLoyaltyFlow &&
        !userSelectedLoyaltyStepOption &&
        dispatch(setUserLoyaltyStep(userLoyaltyStepEnum.toDecideWhetherToUse));
      payForUsersLogic();
      dispatch(setIsForWhomToPayConfirmed(true));
      autoScrollTo(spreedlyCheckoutVaultRef);
    }
  }, [
    canUserUseLoyaltyProgram,
    dispatch,
    fetchUsersLoyaltyProgram,
    isMoreThanOneGuestOnTable,
    payForUsersLogic,
    userIdsToSelect,
    userSelectedLoyaltyStepOption,
    userWithPointsInActiveLoyaltyFlow,
    yourCheckRef,
  ]);

  const handleOnPaymentCheckboxChange = (user) => {
    if (payForUsers.length === 0) {
      payForUsers.push(+id);
    }
    let idx = selectedUsersForPayment.findIndex((selected) => selected.userId === user.userId);

    if (idx !== -1) {
      setSelectedUsersForPayment((prevState) => [...prevState.slice(0, idx), ...prevState.slice(idx + 1)]);
    } else {
      setSelectedUsersForPayment((prevState) => [...prevState, user]);
    }

    onPaymentCheckboxChange({ userId: user.userId, isProceed: false });
    setIsOnlySelectedToPayForYourself(false);
    autoScrollTo(selectWhoToPayForRef);
  };

  const checkIfAllGuestsHaveSelectedLoyaltyOption = useCallback(
    (loyaltyProcess) => {
      // CHECK IF ALL GUESTS HAVE SELECTED LOYALTY OPTION, TO TOGGLE THE 'APPLY CREDITS' BUTTON
      const hasAllGuestsSelectedLoyaltyOption = usersLoyaltyProgramOnOrder?.filter((user) => {
        const hasUserInPayingForOthersState = payingForOthersState?.some(
          (stateUser) =>
            stateUser?.userIds?.includes(user?.user_id) &&
            stateUser?.userIds?.length > 1 &&
            stateUser?.payerId !== user?.user_id,
        );

        const userLoyaltyStep =
          loyaltyProcess.usersLoyaltyStep && loyaltyProcess.usersLoyaltyStep[id]
            ? loyaltyProcess.usersLoyaltyStep[user.user_id].userLoyaltyStep
            : "";

        const currentUserCheckoutInfo = checkoutInfoPerUsers?.find((info) => info.userId === user.user_id);
        const userMaxUsablePoints = maximumLoyaltyPoints(
          +currentUserCheckoutInfo?.subTotal,
          restaurant?.loyalty_program_max_conversion_rate,
          +user.current_points,
        );

        return (
          user?.user_id !== id &&
          (!(loyaltyProcess?.usersLoyaltyStep && user?.user_id in loyaltyProcess?.usersLoyaltyStep) ||
            (userLoyaltyStep !== userLoyaltyStepEnum.toApplyPending &&
              userLoyaltyStep !== userLoyaltyStepEnum.skipped)) &&
          !hasUserInPayingForOthersState &&
          +user.current_points >= +minUsablePoints &&
          +userMaxUsablePoints >= +minUsablePoints
        );
      });

      dispatch(setAllGuestSelectedLoyaltyOption(hasAllGuestsSelectedLoyaltyOption.length > 0 ? false : true));
    },
    [
      checkoutInfoPerUsers,
      dispatch,
      id,
      minUsablePoints,
      payingForOthersState,
      restaurant?.loyalty_program_max_conversion_rate,
      usersLoyaltyProgramOnOrder,
    ],
  );

  const handleSelectAllOnTable = useCallback(
    (enableLoyaltySection) => {
      payForUsersLogic();
      dispatch(setIsProceededToCheckoutSelection(false));
      autoScrollTo(spreedlyCheckoutVaultRef);
      onRefreshVoidedOrders(+tip, noTipReason);
      setIsPayForYourselfSelected(true);
      setSelectedUsersForPayment(orderUserIds);
      onPaymentCheckboxChange({ userId: id, isProceed: true });
      dispatch(setIsForWhomToPayConfirmed(true));

      if (enableLoyaltySection && userWithPointsInActiveLoyaltyFlow) {
        if (usersLoyaltyProgramOnOrder.map((user) => user !== null && user?.user_id).includes(id)) {
          const doesUserHaveStep =
            loyaltyProcess.usersLoyaltyStep && loyaltyProcess.usersLoyaltyStep[id] ? true : false;

          if (doesUserHaveStep) {
            dispatch(setUserLoyaltyStep(loyaltyProcess.usersLoyaltyStep[id].userLoyaltyStep));
          } else if (!userSelectedLoyaltyStepOption) {
            dispatch(setUserLoyaltyStep(userLoyaltyStepEnum.toDecideWhetherToUse));
          }

          autoScrollTo(loyaltySectionRef);
          checkIfAllGuestsHaveSelectedLoyaltyOption(loyaltyProcess);
        } else {
          autoScrollTo(loyaltySectionRef);
        }
      }
    },
    [
      payForUsersLogic,
      dispatch,
      onRefreshVoidedOrders,
      tip,
      noTipReason,
      orderUserIds,
      onPaymentCheckboxChange,
      id,
      userWithPointsInActiveLoyaltyFlow,
      usersLoyaltyProgramOnOrder,
      loyaltyProcess,
      userSelectedLoyaltyStepOption,
      checkIfAllGuestsHaveSelectedLoyaltyOption,
    ],
  );

  const handleForWhomToPay = () => {
    onPaymentCheckboxChange({ userId: -1, isProceed: true });
    dispatch(setIsForWhomToPayConfirmed(true));
    setOnSelectingUsersToPayFor(false);
    if (
      usersLoyaltyProgramOnOrder.map((user) => user !== null && user?.user_id).includes(id) &&
      userWithPointsInActiveLoyaltyFlow
    ) {
      const doesUserHaveStep = loyaltyProcess.usersLoyaltyStep && loyaltyProcess.usersLoyaltyStep[id] ? true : false;

      if (doesUserHaveStep) {
        dispatch(setUserLoyaltyStep(loyaltyProcess.usersLoyaltyStep[id].userLoyaltyStep));
      } else if (!userSelectedLoyaltyStepOption) {
        dispatch(setUserLoyaltyStep(userLoyaltyStepEnum.toDecideWhetherToUse));
      }

      autoScrollTo(loyaltySectionRef);
      checkIfAllGuestsHaveSelectedLoyaltyOption(loyaltyProcess);
    } else {
      isProceededToCheckoutSelection && dispatch(setIsProceededToCheckoutSelection(false));
      autoScrollTo(spreedlyCheckoutVaultRef);
    }
  };

  const handleSelectingCancelationConfirmation = useCallback(() => {
    //setters
    setIsPayForYourselfSelected(false);
    setIsOnlySelectedToPayForYourself(false);
    setOnSelectingUsersToPayFor(true);
    setPayByGuest(true);
    setSelectedUsersForPayment([]);
    //dispatchers
    !isPay && dispatch(setIsPay(true));
    isProceededToCheckoutSelection && dispatch(setIsProceededToCheckoutSelection(false));
    dispatch(setIsForWhomToPayConfirmed(false));
    dispatch(setPayingForOthers([]));
    //props functions
    onPaymentCheckboxChange({ userId: id, isProceed: true, isEdit: true });

    autoScrollTo(selectWhoToPayForRef);

    const doesUserHaveStep = loyaltyProcess.usersLoyaltyStep && loyaltyProcess.usersLoyaltyStep[id] ? true : false;

    if (
      userWithPointsInActiveLoyaltyFlow &&
      userLoyaltyStep !== userLoyaltyStepEnum.toDecideWhichToUse &&
      !doesUserHaveStep
    ) {
      dispatch(setUserLoyaltyStep(""));
    }
  }, [
    isPay,
    dispatch,
    isProceededToCheckoutSelection,
    onPaymentCheckboxChange,
    id,
    userWithPointsInActiveLoyaltyFlow,
    userLoyaltyStep,
  ]);

  // {console.log(onSelectingUsersToPayFor && !isSpreedlyCheckoutVaultVisible && !isProceededToCheckoutSelection && !onFullPageModal )}
  const handleSelectingYourself = () => {
    setIsPayForYourselfSelected(true);
    setIsOnlySelectedToPayForYourself(true);

    if (!payForUsers.includes(+id)) {
      payForUsers.push(+id);
    }

    autoScrollTo(selectWhoToPayForRef);
  };

  const handleCancelPayment = (resetLoyaltyStep) => {
    // rip logic
    dispatch(setIsPay(false));
    dispatch(setIsProceededToCheckoutSelection(false));
    dispatch(setIsForWhomToPayConfirmed(false));
    setOnSelectingUsersToPayFor(false);
    setIsPayForYourselfSelected(false);
    setIsOnlySelectedToPayForYourself(false);
    setPayByGuest(false);
    dispatch(setPayingForOthers([]));
    setSelectedUsersForPayment([]);

    if (userWithPointsInActiveLoyaltyFlow && resetLoyaltyStep) {
      dispatch(setUserLoyaltyStep(""));
    }
  };

  const handleCancelLoyalty = () => {
    dispatch(setUserLoyaltyStep(userLoyaltyStepEnum.skipped));
    isProceededToCheckoutSelection && dispatch(setIsProceededToCheckoutSelection(false));
    autoScrollTo(spreedlyCheckoutVaultRef);
    emitUserLoyaltyStepChange(currentOrder?.order_id, id, userLoyaltyStepEnum.skipped);
  };

  const handleProceedToApplingLoyalty = () => {
    dispatch(setUserLoyaltyStep(userLoyaltyStepEnum.toDecideWhichToUse));
    autoScrollTo(loyaltySectionRef);
  };

  const handleOnCustomLoyaltyAmount = () => {
    dispatch(setUserLoyaltyStep(userLoyaltyStepEnum.toTypeAmount));
    dispatch(setAppliedLoyaltyPoints(""));
  };

  const onSetPendingLoyaltyPointsHandler = (useMaxLoyaltyPoints) => {
    let pendingPoints = loyaltyCreditsInput;
    if (useMaxLoyaltyPoints) {
      pendingPoints = maximumLoyaltyPoints(subTotalInt, restaurant?.loyalty_program_max_conversion_rate, currentPoints);
    }

    dispatch(
      setPendingLoyaltyPoints(currentOrder?.order_id, pendingPoints, () =>
        dispatch(setUserLoyaltyStep(userLoyaltyStepEnum.toApplyPending)),
      ),
    );
    emitUserLoyaltyStepChange(currentOrder?.order_id, id, userLoyaltyStepEnum.toApplyPending);

    dispatch(setAppliedLoyaltyPoints(loyaltyCreditsInput));
    setLoyaltyCreditsInput("");
  };

  const handleUseAllPoints = () => {
    onSetPendingLoyaltyPointsHandler(true);

    const maxUsableLoyaltyPoints = maximumLoyaltyPoints(
      subTotalInt,
      restaurant?.loyalty_program_max_conversion_rate,
      currentPoints,
    );

    dispatch(setAppliedLoyaltyPoints(maxUsableLoyaltyPoints));
  };

  const onSetAppliedLoyaltyPointsHandler = () => {
    const orderingProviderId = restaurant?.ordering_provider_id;
    const orderExternalId = currentOrder?.external_id;
    const orderId = currentOrder?.order_id;
    const checkExternalId = currentOrder?.external_check_id;

    dispatch(
      updateAppliedLoyaltyPoints(orderingProviderId, orderId, orderExternalId, checkExternalId, () => {
        dispatch(setUserLoyaltyStep(userLoyaltyStepEnum.alreadyApplied));
        dispatch(setRefreshCheckout(true));
        emitUserLoyaltyStepChange(currentOrder?.order_id, id, userLoyaltyStepEnum.alreadyApplied);
      }),
    );
  };

  const handleCancelForUseLoyaltyCreditsModal = () => {
    dispatch(setChooseIfGuestWantToUseCreditsModal(false));

    emitUserLoyaltyStepChange(currentOrder?.order_id, id, userLoyaltyStepEnum.skipped);
  };

  const handleOnSendForUseLoyaltyCreditsModal = () => {
    handleProceededToCheckout();
    dispatch(setChooseIfGuestWantToUseCreditsModal(false));

    emitUserLoyaltyStepChange(currentOrder?.order_id, id, userLoyaltyStepEnum.toDecideWhichToUse);
  };

  const {
    DOMslot1,
    DOMslot2,
    DOMslot3,
    DOMslot4,
    DOMslot5,
    createButtonPortal,
    createMessagePortal,
    createDownloadPortal,
    goToMenu,
    sendToKitchen,
    checkout,
    payForAllGuests,
    payForSeparateGuests,
    confirm,
    receipt,
    cancel,
    yes,
    no,
    proceed,
    skip,
    customLabel,
  } = useFloatingIslandActions();

  //  useEffect(() => {
  //   console.log({
  //     sendToKitchenGoToMenu: {
  //       ispay: !isPay,
  //       otherTryingToPay: !otherTryingToPay,
  //       isProceededToCheckoutSelection: !isProceededToCheckoutSelection,
  //       hasNewOrder: hasNewOrder,
  //       orderStarted: !(currentOrder.order_status === orderStatusEnum.orderStarted),
  //       orderLessThanZeroErrorMsg,
  //       onFullPageModal: !onFullPageModal
  //     },
  //     checkoutGoToMenu: {
  //       ispay: !isPay,
  //       otherTryingToPay: !otherTryingToPay,
  //       isProceededToCheckoutSelection: !isProceededToCheckoutSelection,
  //       isSendToKitchenMode: !isSendToKitchenMode,
  //       nekojUslov: !((checkoutInfoOnTable?.total && checkoutInfoOnTable.total === '0.00') || hasNewOrder || otherTryingToPay),
  //       checkoutInfoOnTable
  //     },
  //     payAllOrSeperate: {
  //       isPay,
  //       otherTryingToPay: !otherTryingToPay,
  //       showFeedbackMessages: !showFeedbackMessages.someoneStartedPaymentProcess,
  //       onFullPageModal: !onFullPageModal,
  //       isMoreThanOneGuestOnTable,
  //       isProceededToCheckoutSelection
  //     },
  //     confirmSelectionOrSeperate: {
  //       onSelectingUsersToPayFor,
  //       isSpreedlyCheckoutVaultVisible: !isSpreedlyCheckoutVaultVisible,
  //       isProceededToCheckoutSelection: !isProceededToCheckoutSelection,
  //       onFullPageModal: !onFullPageModal
  //     },
  //     confirmPaymentOrSeparate: {
  //       isSpreedlyCheckoutVaultVisible,
  //       isPayForYourselfSelected,
  //     }
  //   })
  // }, [checkoutInfoOnTable.total, currentOrder.order_status, hasNewOrder, isMoreThanOneGuestOnTable, isPay, isProceededToCheckoutSelection, isSendToKitchenMode, onFullPageModal, orderLessThanZeroErrorMsg, otherTryingToPay, showFeedbackMessages.someoneStartedPaymentProcess, onSelectingUsersToPayFor, isSpreedlyCheckoutVaultVisible, isPayForYourselfSelected, checkoutInfoOnTable])

  useEffect(() => {
    fetchUsersLoyaltyProgram();
  }, [fetchUsersLoyaltyProgram]);

  useEffect(() => {
    if (
      loyaltyProcess.usersLoyaltyStep &&
      Object.values(loyaltyProcess.usersLoyaltyStep)?.length > 0 &&
      loyaltyProcess.usersLoyaltyStep[id]
    ) {
      dispatch(setUserLoyaltyStep(loyaltyProcess.usersLoyaltyStep[id].userLoyaltyStep));
      !appliedLoyaltyPoints &&
        loyaltyProcess.usersLoyaltyStep[id].userPoints &&
        dispatch(setAppliedLoyaltyPoints(loyaltyProcess.usersLoyaltyStep[id].userPoints));
    }
  }, [dispatch, id, loyaltyProcess.usersLoyaltyStep]);

  return (
    <div className={`spreedly-credit-card-form-wrapper mb-3`}>
      {isEmpty(ordersRounds) && (
        <>
          {createButtonPortal(goToMenu, DOMslot1)}
          {createMessagePortal("Select Items to place your order", DOMslot2)}
        </>
      )}

      {!isEmpty(ordersRounds) && !onFullPageModal && (
        <>
          {currentOrder.order_status === orderStatusEnum.orderStarted && orderLessThanZeroErrorMsg && (
            <>
              {/* <span className="order-amount-error-message">
                For the initial round the order amount must be more than $0!
              </span> */}

              {createMessagePortal("For the initial round the order amount must be more than $0!", DOMslot1)}
              {createButtonPortal(goToMenu, DOMslot3)}
            </>
          )}

          {otherTryingToPay && !isEmpty(userPayingForMe) && !hasThisUserPaidForHimself && (
            <>
              {/* <h3>Someone is in the payment process,</h3>
              <h3>Please stand by!</h3> */}

              {createMessagePortal("Someone is in the payment process, please stand by!", DOMslot1)}
              {createButtonPortal(goToMenu, DOMslot3)}
            </>
          )}

          {showFeedbackMessages.someoneStartedPaymentProcess && (
            <>
              {/* <h3>Someone has already started the payment process!</h3> */}

              {createMessagePortal("Someone has already started the payment process!", DOMslot1)}
              {createButtonPortal(goToMenu, DOMslot3)}
            </>
          )}

          {showReceiptButton && !isPay && (
            <>
              {/* <div className="pay-or-kitchen-buttons">
                <div className={'checkout-button'}>
                  <button className="btn btn-primary" disabled={!receiptURL} style={{ marginBottom: '15px' }}>
                    {receiptURL
                      ? <a href={receiptURL} download={`Voila-${restaurant.name}-${reservationDate}.pdf`}>Receipt</a>
                      : <span>Loading</span>
                    }
                    </button>
                </div>
              </div> */}

              {createDownloadPortal(receipt(receiptURL, `Voila-${restaurant.name}-${reservationDate}`), DOMslot3)}
            </>
          )}

          {/* {console.log({
            isPay: !isPay,
            otherTryingToPay: !otherTryingToPay,
            isProceededToCheckoutSelection: !isProceededToCheckoutSelection,
            hasNewOrder: hasNewOrder,
            status: !(currentOrder.order_status === orderStatusEnum.orderStarted),
            orderLessThanZeroErrorMsg: !orderLessThanZeroErrorMsg,
            onFullPageModal: !onFullPageModal
          })} */}

          {!isPay && !otherTryingToPay && !isProceededToCheckoutSelection && !onFullPageModal && (
            <>
              {!(
                !hasNewOrder ||
                (currentOrder.order_status === orderStatusEnum.orderStarted && orderLessThanZeroErrorMsg)
              ) && (
                <>
                  {/* <div className="pay-button">
                    <button
                      // disabled={!hasNewOrder || currentOrder.order_status === orderStatusEnum.orderStarted && orderLessThanZeroErrorMsg}
                      onClick={onSendToKitchen} className="btn btn-primary" >
                      Send your Order
                    </button>
                  </div> */}
                  {createButtonPortal(sendToKitchen(onSendToKitchen), DOMslot1)}
                  {createButtonPortal(goToMenu, DOMslot2)}
                </>
              )}
              {!isSendToKitchenMode &&
                !(
                  (checkoutInfoOnTable?.total && checkoutInfoOnTable.total === "0.00") ||
                  hasNewOrder ||
                  otherTryingToPay
                ) && (
                  <>
                    {/* <div className="pay-button">
                    <button onClick={handleProceededToCheckout} className="btn btn-primary"
                    // disabled={checkoutInfoOnTable.total === '0.00' || hasNewOrder || otherTryingToPay}
                    >
                      Checkout
                    </button>
                  </div> */}
                    {createButtonPortal(checkout(handleProceededToCheckout), DOMslot1)}
                    {createButtonPortal(goToMenu, DOMslot2)}
                  </>
                )}
            </>
          )}
        </>
      )}

      {chooseIfGuestWantToUseCreditsModal && userLoyaltyStep === "" && (
        <UseLoyaltyCreditsModal
          show={chooseIfGuestWantToUseCreditsModal}
          close={handleCancelForUseLoyaltyCreditsModal}
          onSend={handleOnSendForUseLoyaltyCreditsModal}
        />
      )}

      {isPay && !otherTryingToPay && !showFeedbackMessages.someoneStartedPaymentProcess && !onFullPageModal && (
        <>
          {isMoreThanOneGuestOnTable && (
            <div>
              {isProceededToCheckoutSelection && (
                <>
                  {/* <div className="payment-selection-wrapper">
                    <div className="checkout-button">
                    <button className="btn btn-primary" onClick={handleSelectAllOnTable}>
                    Pay for all
                    </button>
                    </div>

                    <div className="checkout-button">
                    <button className="btn btn-primary" onClick={handleSelectingCancelationConfirmation}>
                        Pay by guest
                      </button>
                    </div>
                  </div> */}

                  {createButtonPortal(
                    payForAllGuests(() => handleSelectAllOnTable(true)),
                    DOMslot1,
                  )}
                  {createButtonPortal(payForSeparateGuests(handleSelectingCancelationConfirmation), DOMslot2)}
                  {createButtonPortal(cancel(handleCancelPayment, null), DOMslot3)}
                  {createButtonPortal(goToMenu, DOMslot4)}
                </>
              )}

              {onSelectingUsersToPayFor &&
                !isSpreedlyCheckoutVaultVisible &&
                !isProceededToCheckoutSelection &&
                !onFullPageModal && (
                  <>
                    <h5>Select who do you want to pay for?</h5>
                    <div ref={selectWhoToPayForRef} className="selectable-tiles selectable-tiles-block">
                      {!hasThisUserPaidForHimself && (
                        <button
                          disabled={isSpreedlyCheckoutVaultVisible}
                          className={`btn selectable-tiles-button ${isPayForYourselfSelected ? "active" : ""}`}
                          onClick={handleSelectingYourself}
                        >
                          Myself
                        </button>
                      )}
                      {userIdsToSelect}
                    </div>

                    {createButtonPortal(
                      confirm(
                        handleForWhomToPay,
                        (isOnlySelectedToPayForYourself && subTotalInt === 0 && payForUsers.length >= 1) ||
                          !isPayForYourselfSelected ||
                          subTotalInt === 0,
                        "selection",
                      ),
                      DOMslot1,
                    )}
                    {createButtonPortal(payForAllGuests(handleSelectAllOnTable), DOMslot2)}
                    {createButtonPortal(cancel(handleCancelPayment, "selection"), DOMslot3)}
                    {createButtonPortal(goToMenu, DOMslot4)}
                  </>
                )}

              {isSpreedlyCheckoutVaultVisible && !isProceededToCheckoutSelection && (
                <>
                  {!isPayForYourselfSelected ? (
                    <>
                      {isMoreThanOneGuestOnTable &&
                        createButtonPortal(payForSeparateGuests(handleSelectingCancelationConfirmation), DOMslot2)}
                      {/* <div >
                        <button
                        disabled={isPayForYourselfSelected}
                          onClick={handleSelectingCancelationConfirmation} className={"btn btn-primary"}>
                          Cancel Payment
                          </button>
                        </div> */}
                    </>
                  ) : (
                    <>
                      <div className="pay-all-message">
                        {remainingBalance > 0 && (
                          <>
                            <h3>Remaining balance: ${remainingBalance}</h3>
                            <SeparationLine style={{ marginBottom: "15px", width: "100%" }} />
                          </>
                        )}

                        {!isOnlySelectedToPayForYourself &&
                          (selectedUsersForPayment.length === orderUserIds.length ? (
                            <>
                              <span>You are paying for all items on the table</span>
                              {/* <span>OR</span> */}
                            </>
                          ) : (
                            selectedUsersForPayment.length !== 0 && (
                              <>
                                <span>You are also paying for</span>
                                <div className="selectable-tiles selectable-tiles-block">
                                  {selectedUsersForPayment.map((user, idx) => (
                                    <SelectableTilesButton
                                      key={user.userId || idx}
                                      active={true}
                                      showAsParagraph={true}
                                    >
                                      <span>{user.userName}</span>
                                    </SelectableTilesButton>
                                  ))}
                                </div>
                              </>
                            )
                          ))}
                      </div>
                      {/* <div className={classNames("pay-by-guest", !payByGuest && "outline-wrapper")}>
                        <button
                        disabled={!isPayForYourselfSelected}
                        onClick={handleSelectingCancelationConfirmation} className={"btn btn-primary"}>
                        Pay by guest
                        </button>
                      </div> */}

                      {createButtonPortal(payForSeparateGuests(handleSelectingCancelationConfirmation), DOMslot2)}
                      {createButtonPortal(goToMenu, DOMslot4)}
                    </>
                  )}
                </>
              )}
            </div>
          )}

          {canUserUseLoyaltyProgram &&
            userLoyaltyStep !== "" &&
            !isProceededToCheckoutSelection &&
            !onSelectingUsersToPayFor && (
              <>
                {userLoyaltyStep === userLoyaltyStepEnum.toDecideWhetherToUse && (
                  <>
                    {createButtonPortal(customLabel(handleProceedToApplingLoyalty, false, "Yes"), DOMslot1)}
                    {createButtonPortal(customLabel(handleCancelLoyalty, false, "No"), DOMslot2)}
                    {createButtonPortal(cancel(handleCancelPayment, "payment"), DOMslot3)}
                  </>
                )}

                {userLoyaltyStep === userLoyaltyStepEnum.toDecideWhichToUse && (
                  <>
                    {createButtonPortal(customLabel(handleUseAllPoints, false, "Use all"), DOMslot1)}
                    {createButtonPortal(customLabel(handleOnCustomLoyaltyAmount, false, "Custom Amount"), DOMslot2)}
                    {createButtonPortal(customLabel(handleCancelLoyalty, false, "Continue without credits"), DOMslot3)}
                    {createButtonPortal(cancel(handleCancelPayment, "payment"), DOMslot4)}
                  </>
                )}

                {userLoyaltyStep === userLoyaltyStepEnum.toTypeAmount && (
                  <>
                    {createButtonPortal(
                      customLabel(
                        () => {
                          if (
                            loyaltyProcess.usersLoyaltyStep[id] &&
                            loyaltyProcess.usersLoyaltyStep[id].userLoyaltyStep &&
                            loyaltyProcess.usersLoyaltyStep[id].userLoyaltyStep !== userLoyaltyStepEnum.skipped
                          ) {
                            dispatch(setUserLoyaltyStep(loyaltyProcess.usersLoyaltyStep[id].userLoyaltyStep));
                          } else {
                            dispatch(setUserLoyaltyStep(userLoyaltyStepEnum.toDecideWhichToUse));
                          }
                        },
                        false,
                        "Cancel",
                      ),
                      DOMslot2,
                    )}
                    {createButtonPortal(
                      customLabel(
                        () => {
                          if (+loyaltyCreditsInput > 0) {
                            onSetPendingLoyaltyPointsHandler();
                          }
                        },
                        +loyaltyCreditsInput < +minUsablePoints || +loyaltyCreditsInput > +maxUsablePoints,
                        "Confirm",
                      ),
                      DOMslot1,
                    )}
                    {createButtonPortal(cancel(handleCancelPayment, "payment"), DOMslot3)}
                  </>
                )}

                {(userLoyaltyStep === userLoyaltyStepEnum.toApplyPending ||
                  userLoyaltyStep === userLoyaltyStepEnum.toApplyPendingModal) && (
                  <>
                    {createButtonPortal(cancel(handleCancelPayment, "payment"), DOMslot4)}
                    {createButtonPortal(customLabel(handleCancelLoyalty, false, "Remove loyalty credits"), DOMslot3)}
                    {createButtonPortal(
                      customLabel(() => dispatch(setUserLoyaltyStep(userLoyaltyStepEnum.toTypeAmount)), false, "Edit"),
                      DOMslot2,
                    )}
                    {createButtonPortal(
                      customLabel(
                        () => dispatch(setUserLoyaltyStep(userLoyaltyStepEnum.toApplyPendingModal)),
                        isMoreThanOneGuestOnTable
                          ? allGuestSelectedLoyaltyOption
                            ? false
                            : timeLeft.seconds >= 0
                          : false,
                        "Apply credits",
                      ),
                      DOMslot1,
                    )}
                  </>
                )}

                {userLoyaltyStep === userLoyaltyStepEnum.toApplyPendingModal && (
                  <ApplyLoyaltyCreditsModal
                    show={userLoyaltyStep === userLoyaltyStepEnum.toApplyPendingModal}
                    close={() => dispatch(setUserLoyaltyStep(userLoyaltyStepEnum.toApplyPending))}
                    onSend={onSetAppliedLoyaltyPointsHandler}
                  />
                )}

                <div ref={loyaltySectionRef} className="user-loyalty-program">
                  {userWithPointsInActiveLoyaltyFlow && (
                    <UserLoyaltyProgram
                      timeLeft={timeLeft}
                      setLoyaltyCreditsInput={setLoyaltyCreditsInput}
                      loyaltyCreditsInput={loyaltyCreditsInput}
                      isMoreThanOneGuestOnTable={isMoreThanOneGuestOnTable}
                      allGuestSelectedLoyaltyOption={allGuestSelectedLoyaltyOption}
                    />
                  )}
                </div>
              </>
            )}

          {(canUserUseLoyaltyProgram
            ? isSpreedlyCheckoutVaultVisible && !isProceededToCheckoutSelection && !userWithPointsInActiveLoyaltyFlow
            : isSpreedlyCheckoutVaultVisible && !isProceededToCheckoutSelection) && (
            <div ref={spreedlyCheckoutVaultRef}>
              {createButtonPortal(
                cancel(handleCancelPayment, "payment"),
                isMoreThanOneGuestOnTable ? DOMslot3 : DOMslot2,
              )}
              {userLoyaltyStep === userLoyaltyStepEnum.skipped &&
                createButtonPortal(
                  customLabel(
                    () => {
                      dispatch(setUserLoyaltyStep(userLoyaltyStepEnum.toDecideWhichToUse));
                      onSelectingUsersToPayFor && setOnSelectingUsersToPayFor(false);
                    },
                    false,
                    "Use credits",
                  ),
                  DOMslot5,
                )}
              <SpreedlyCheckoutVault onCompleteOrderClick={onCompleteOrderClick} isEditMode={false} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default SendToKitchen;
