import classNames from "classnames";
import { round } from "lodash";

const CheckLabel = ({ label, amount, amountInMinus, divClassName, labelClassName }) => (
  <div className={classNames("check-label d-flex justify-content-between", divClassName)}>
    <p className={labelClassName}>{label}</p>

    {typeof amount === "number" && (!amountInMinus ? <p>${round(amount, 2)}</p> : <p>&#8722; ${round(amount, 2)}</p>)}
  </div>
);

export default CheckLabel;
