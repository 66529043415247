export function isSomeonePayingForCurrentUser(usersTryingToPay = [], userId) {
  return usersTryingToPay.some((value) => +value.payerId !== +userId && value.userIds.some((id) => +id === +userId));
}

export function getIdsSomeonePaysFor(usersTryingToPay = [], userId) {
  return usersTryingToPay
    .filter((value) => +value.payerId !== +userId)
    .reduce((acc, curr) => {
      acc = acc.concat(curr.userIds);
      return acc;
    }, []);
}
