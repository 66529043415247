import React, { useEffect, useState } from "react";
import Row from "../../containers/layout/Row";
import Column from "../../containers/layout/Column";
import TablePlaceholder from "../../assets/table@3x.png";
import InputField from "../../components/common/InputField";
import {
  checkIsTableValid,
  setActiveModal,
  setIsTableValid,
  setPageRoutePath,
} from "../../store/actions/feedbackActions";
import { login_modal } from "../../config/modal_paths";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import "./ChooseTable.css";
import { order_first_time_path, order_page_path } from "../../config/pages_paths";
import { setNewOrderDetails, setTableNumber } from "./services/actions";
import { setRestaurantById } from "../restaurant/services/actions";
import { isEmpty } from "lodash";
import useFloatingIslandActions from "../../hooks/useFloatingIslandActions";

function ChooseTable() {
  // const [tableNumber, setTableNumber] = useState("")
  const [errorMessage, setErrorMessage] = useState("");
  const { restaurantId } = useParams();
  const {
    restaurants: { restaurants },
    restaurant: { restaurant },
    auth: { isAuthenticated },
    feedback: { responseMessage, isTableValid },
    booking: { orderId },
    orders: { tableNumber },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const onTableNumberChange = (e) => {
    +e.target.value < 0
      ? setErrorMessage("Cannot be less than 1")
      : (() => {
          // setTableNumber(e.target.value)
          dispatch(setTableNumber(e.target.value));
          setErrorMessage("");
        })();
  };

  const onProceedHandler = () => {
    dispatch(checkIsTableValid({ restaurantId: +restaurantId, tableNumber: tableNumber }));
  };

  useEffect(() => {
    if (!isEmpty(restaurants) && isEmpty(restaurant) && restaurantId) {
      dispatch(setRestaurantById({ restaurants, restaurantId }));
    }
  }, [dispatch, restaurants, restaurant, restaurantId]);

  useEffect(() => {
    if (isTableValid) {
      dispatch(setIsTableValid(false));
      dispatch(setNewOrderDetails({ tableNumber, restaurantId: +restaurantId }));
      isAuthenticated
        ? history.replace(
            order_first_time_path.replace(":restaurantId", restaurantId).replace(":tableNumber", tableNumber),
          )
        : dispatch(setActiveModal(login_modal));
    }
  }, [isTableValid, dispatch, history, isAuthenticated, tableNumber, restaurantId]);

  useEffect(() => {
    if (orderId && responseMessage) {
      dispatch(setPageRoutePath(order_page_path.replace(":orderId", orderId)));
    }
  }, [orderId, responseMessage, dispatch]);

  const { createButtonPortal, proceed, DOMslot1 } = useFloatingIslandActions();

  return (
    <Row className="m-4 reservations-row text-center">
      <Column className="choose-table-column" md={"10"} lg={8}>
        <img src={TablePlaceholder} alt="table" />
        <h2 className="mt-4">Please scan the QR code or input the table number manually</h2>
        <InputField
          type="text"
          onChange={onTableNumberChange}
          value={tableNumber}
          min={1}
          error={errorMessage}
          errorClassName="text-left"
          inputClassName="table-number"
          placeholder={"Input the table number"}
        />
        {/* <div className="order-next-btn">
          <button onClick={onProceedHandler} className="btn btn-primary">
            Proceed
          </button>
        </div> */}

        {createButtonPortal(proceed(onProceedHandler), DOMslot1)}
      </Column>
    </Row>
  );
}

export default ChooseTable;
