import { SET_ERRORS } from "../../config/actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case SET_ERRORS:
      return action.payload;
    default:
      return {};
  }
};
