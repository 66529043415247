import RestaurantIcon from "@mui/icons-material/Restaurant";
import isEmpty from "lodash.isempty";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import FloatingIslandButton from "../components/common/floatingIsland/FloatingIslandButton";
import FloatingIslandDownload from "../components/common/floatingIsland/FloatingIslandDownload";
import FloatingIslandMessage from "../components/common/floatingIsland/FloatingIslandMessage";
import {
  booking_order_page_path,
  booking_page_path,
  full_menu_path,
  just_pay_menu_path,
  order_page_path,
  reservations_path_now,
  table_number_page_path,
} from "../config/pages_paths";
import { orderStatusEnum } from "../enums/orderStatusEnum";
import { setMenuDrawerOpened, setMenuOrdersDrawerOpened } from "../pages/menu/services/actions";
import { setActiveModal } from "../store/actions/feedbackActions";

const useFloatingIslandActions = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    auth: { isAuthenticated, user },
    booking: {
      currentOrder,
      reservationDetails: { reservation },
      reservations: { ongoing },
    },
    restaurant: { restaurant },
    menu: {
      activeMenuAndSectionInfoApproved: { activeMenuName, activeMenuSectionName },
      menuDrawerOpened,
      menuOrdersDrawerOpened,
    },
    feedback: { activeModal, onFullPageModal },
    orders: { lastRoundOrders, ordersNoRounds },
  } = useSelector((state) => state);

  //#region predefined action buttons

  const seeFullMenu = {
    label: "See full menu",
    action: () => history.push(full_menu_path.replace(":restaurantId", restaurant?.restaurant_id)),
    show: !onFullPageModal,
  };

  const goToMenu = {
    label: "Go to menu",
    action: () => history.push(order_page_path.replace(":orderId", currentOrder.order_id)),
    show: !onFullPageModal,
  };

  const goToJustPayMenu = (action) => ({
    label: "Go to menu",
    action: () => action(),
  });

  const goToBookingMenu = {
    label: "Go to menu",
    action: () => history.push(booking_order_page_path.replace(":orderId", reservation.order_id)),
    show: !onFullPageModal,
  };

  const backToBooking = (action) => ({
    // label: 'Return to booking',
    label: "Your booking",
    action: () => action(),
  });

  const backToOrder = (isSlot2 = false) =>
    // (isSlot2 = false) => currentOrder &&
    // (currentOrder?.order_status === orderStatusEnum.booked ? {
    //     label: 'Return to reservation',
    //     action: () => history.push(booking_reservation_path.replace(':restaurantId', currentOrder.restaurant_id).replace(':orderId', currentOrder.order_id)),
    //     //change to match upcoming orders
    //     rightIcon: lastRoundOrders?.length ?? 0,
    //     rightIconClassName: isSlot2 ? 'floating-island-orders-ball-inverted' : 'floating-island-orders-ball',
    //     show: !onFullPageModal,
    // } :
    ({
      // label: 'Returb to order',
      label: "Your order",
      action: () => history.push(reservations_path_now),
      leftIcon: <RestaurantIcon sx={{ width: "18px", height: "18px" }} />,
      rightIcon: <>{ordersNoRounds.find((obj) => obj.user_id === user.id)?.orders.length ?? 0}</>,
      rightIconClassName: isSlot2 ? "floating-island-orders-ball-inverted" : "floating-island-orders-ball",
      show: !onFullPageModal,
    });

  const toggleMenuDrawer = {
    // label: `${menuDrawerOpened ? 'Close' : 'Open'} menu / ${activeMenuName} / ${activeMenuSectionName}`,
    // label: `${activeMenuName} / ${activeMenuSectionName}`,
    label: `${menuDrawerOpened ? "Close" : "Open"} menu `,
    action: () => {
      menuOrdersDrawerOpened && dispatch(setMenuOrdersDrawerOpened(false));
      dispatch(setMenuDrawerOpened(!menuDrawerOpened));
    },
    show: !onFullPageModal,
  };
  const toggleMenuOrderDrawer = {
    // label: `${menuDrawerOpened ? 'Close' : 'Open'} menu / ${activeMenuName} / ${activeMenuSectionName}`,
    // label: `Preview your orders`,
    label: `${menuOrdersDrawerOpened ? "Close" : "Open"} preview`,
    action: () => {
      menuDrawerOpened && dispatch(setMenuDrawerOpened(false));
      dispatch(setMenuOrdersDrawerOpened(!menuOrdersDrawerOpened));
    },
    show: !onFullPageModal,
  };

  const bookTableInAdvance = {
    label: "Book a table",
    action: () => history.push(booking_page_path.replace(":restaurantId", restaurant.restaurant_id)),
    // show: activeModal !== 'login_modal' && activeModal !== 'register_modal',
    show: isEmpty(activeModal),
  };

  const orderRightNow = {
    label: "Order right now",
    action: () => {
      isAuthenticated
        ? history.push(table_number_page_path.replace(":restaurantId", restaurant.restaurant_id))
        : dispatch(setActiveModal("login_modal"));
    },
    // show: activeModal !== 'login_modal' && activeModal !== 'register_modal',
    show: isEmpty(activeModal),
  };

  //#endregion

  //#region postdefined action buttons

  const openLoginModal = (action) => ({
    label: "Log in / Sign up",
    action: () => action(),
    show: !onFullPageModal,
  });

  const yes = (action) => ({
    label: "Yes",
    action: () => action(),
    show: !onFullPageModal,
  });
  const no = (action) => ({
    label: "No",
    action: () => action(),
    show: !onFullPageModal,
  });

  const createAccount = (action) => ({
    label: "Create an account",
    action: () => action(),
  });

  const skip = (action) => ({
    label: "Skip",
    action: () => action(),
  });

  const continueAsAnonymousUser = (action) => ({
    label: "Continue anonymously",
    action: () => action(),
    show: !onFullPageModal,
  });

  const continueWithoutAccount = (action) => ({
    label: "Continue without an account",
    action: () => action(),
    show: !onFullPageModal,
  });

  const findFreeTable = (action) => ({
    // label: 'Find a free table',
    label: "Find a table",
    action: () => action(),
    show: !onFullPageModal,
  });

  const rebook = (action) => ({
    label: "Re-book",
    action: () => action(),
    show: !onFullPageModal,
  });

  const sendToKitchen = (action) => ({
    label: "Send orders",
    action: () => action(),
    show: !onFullPageModal,
  });
  const checkout = (action) => ({
    label: "Checkout",
    action: () => action(),
    show: !onFullPageModal,
  });
  const payForAllGuests = (action) => ({
    label: "Pay for all guests",
    action: () => action(),
    show: !onFullPageModal,
  });
  const payForSeparateGuests = (action) => ({
    // label: 'Pay for separate guests',
    label: "Pay by guest",
    action: () => action(),
    show: !onFullPageModal,
  });

  const payWholeCheck = (action) => ({
    label: "Pay whole check",
    action: () => action(),
    show: !onFullPageModal,
  });
  const splitCheck = (action) => ({
    label: "Split check",
    action: () => action(),
    show: !onFullPageModal,
  });

  const add = (action, disabled = false, labelSuffix) => ({
    label: labelSuffix ? `Add ${labelSuffix}` : `Add`,
    action: () => action(),
    disabled,
  });
  const edit = (action, disabled = false, labelSuffix) => ({
    label: labelSuffix ? `Edit ${labelSuffix}` : `Edit`,
    action: () => action(),
    disabled,
  });
  const remove = (action, disabled = false, labelSuffix) => ({
    label: labelSuffix ? `Remove ${labelSuffix}` : `Remove`,
    action: () => action(),
    disabled,
  });

  const saveChanges = (action, disabled = false) => ({
    label: "Save changes",
    action: () => action(),
    disabled,
    show: !onFullPageModal,
  });

  const save = (action, disabled = false, labelSuffix) => ({
    label: labelSuffix ? `Save ${labelSuffix}` : `Save`,
    action: () => action(),
    disabled,
    show: !onFullPageModal,
  });

  const proceed = (action, disabled = false, labelSuffix, overrideShow) => ({
    label: labelSuffix ? `Proceed ${labelSuffix}` : `Proceed`,
    action: () => action(),
    disabled,
    show: overrideShow ? overrideShow : !onFullPageModal,
  });

  const confirm = (action, disabled = false, labelSuffix, overrideShow) => ({
    label: labelSuffix ? `Confirm ${labelSuffix}` : `Confirm`,
    action: () => action(),
    disabled,
    show: overrideShow ? overrideShow : !onFullPageModal,
  });

  const cancel = (action, labelSuffix, overrideShow) => ({
    label: labelSuffix ? `Cancel ${labelSuffix}` : `Cancel`,
    action: () => action(),
    show: overrideShow ? overrideShow : !onFullPageModal,
  });

  const closeModal = (action) => ({
    label: "Close",
    action: () => action(),
  });

  const customLabel = (action, disabled = false, label) => ({
    label,
    action: () => action(),
    disabled,
  });

  const addDiscount = (action) => ({
    label: "Add discount",
    action: () => action(),
    show: !onFullPageModal,
  });
  //#endregion

  //#region download buttons

  const receipt = (downloadURL, fileName) => ({
    label: "Download receipt",
    loading: "Loading receipt",
    downloadURL,
    fileName,
    show: !onFullPageModal,
  });

  //#endregion

  const DOMslot1 = document.getElementById("floating-island-slot-1");
  const DOMslot2 = document.getElementById("floating-island-slot-2");
  const DOMslot3 = document.getElementById("floating-island-slot-3");
  const DOMslot4 = document.getElementById("floating-island-slot-4");
  const DOMslot5 = document.getElementById("floating-island-slot-5");

  const createDownloadPortal = (el, DOMel) => DOMel && createPortal(<FloatingIslandDownload {...el} />, DOMel);
  const createButtonPortal = (el, DOMel) =>
    DOMel && createPortal(<FloatingIslandButton DOMel={DOMel} {...el} />, DOMel);
  const createMessagePortal = (el, DOMel) => DOMel && createPortal(<FloatingIslandMessage element={el} />, DOMel);
  const createElementPortal = (el, DOMel) => DOMel && createPortal(el, DOMel);

  const backToOrderShortcut = (DOMslot) =>
    DOMslot &&
    ((!isEmpty(currentOrder) && currentOrder?.order_status !== orderStatusEnum.booked) ||
      (!isEmpty(ongoing) && ongoing?.order_status !== orderStatusEnum.booked)) &&
    createButtonPortal(backToOrder(DOMslot === DOMslot2), DOMslot);

  return {
    openLoginModal,
    continueAsAnonymousUser,
    continueWithoutAccount,
    createAccount,
    skip,

    findFreeTable,
    bookTableInAdvance,
    orderRightNow,
    goToBookingMenu,
    seeFullMenu,
    goToMenu,
    goToJustPayMenu,
    backToOrder,
    toggleMenuDrawer,
    toggleMenuOrderDrawer,
    rebook,
    backToBooking,
    sendToKitchen,
    checkout,
    payForAllGuests,
    payForSeparateGuests,
    receipt,
    addDiscount,
    payWholeCheck,
    splitCheck,

    add,
    edit,
    remove,

    yes,
    no,

    saveChanges,
    save,
    proceed,
    confirm,
    cancel,
    closeModal,
    customLabel,

    DOMslot1,
    DOMslot2,
    DOMslot3,
    DOMslot4,
    DOMslot5,

    createElementPortal,
    createDownloadPortal,
    createButtonPortal,
    createMessagePortal,
    backToOrderShortcut,
  };
};

export default useFloatingIslandActions;
