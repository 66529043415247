import { round } from "lodash";
import { paymentTypeEnum } from "../enums/paymentType";
import { justPayPageTypesEnum } from "../enums/justPayPageTypesEnum";
import { paymentStatuses } from "../enums/paymentStatuses";

export const groupPaymentsByCardAndCash = (data, dataType) => {
  const groupedPayments = {
    cash: 0,
    credit: {},
  };

  const processPayments = (payments) => {
    payments.forEach(({ type, last4Digits, cardType, amount, paymentStatus }) => {
      if (paymentStatus === paymentStatuses.AUTHORIZED || paymentStatus === paymentStatuses.CAPTURED) {
        if (type === paymentTypeEnum.CASH) {
          groupedPayments.cash = round((groupedPayments.cash += amount), 2);
        } else if (type === paymentTypeEnum.CREDIT) {
          const key = `${cardType}, *${last4Digits}`;

          if (!groupedPayments.credit[key]) {
            groupedPayments.credit[key] = 0;
          }
          groupedPayments.credit[key] = round((groupedPayments.credit[key] += amount), 2);
        }
      }
    });
  };

  if (dataType === justPayPageTypesEnum.check) {
    processPayments(data?.payments);
  } else if (dataType === justPayPageTypesEnum.order) {
    const allCheckPayments = data?.checks?.flatMap((check) => check.payments) || [];

    processPayments(allCheckPayments);
  }

  return groupedPayments;
};
