import Row from "../../containers/layout/Row";
import Column from "../../containers/layout/Column";
import NavigationSideMenu from "../../components/navigation/NavigationSideMenu";
import useFloatingIslandActions from "../../hooks/useFloatingIslandActions";

function PrivacyPage() {
  const { backToOrderShortcut, DOMslot1 } = useFloatingIslandActions();

  return (
    <>
      {backToOrderShortcut(DOMslot1)}
      <Row className="m-4 reservations-row">
        <Column lg={12}>
          <Row>
            <Column lg="2">
              <NavigationSideMenu />
            </Column>
            <Column className="scrollable-list" lg="5">
              <PrivacyPolicy />
            </Column>
            <Column lg="2" />
          </Row>
        </Column>
      </Row>
    </>
  );
}

export function PrivacyPolicy() {
  return (
    <>
      <h3>
        <strong>Privacy policy</strong>
      </h3>
      <p>
        Voila Dining, LLC. (“Voila,” “we,” “us,” or “our”) is committed to protecting your privacy. This Privacy Policy
        (“Policy”) explains how we collect, use, disclose and protect Personal Information (as it is defined below) of
        our customers and users. It applies to all Personal Information collected by us on www.voila.nyc and the Voila
        Android and iOS mobile applications, as well as during any written, electronic, and other communications
        (collectively, the “Services”). Voila may collect Personal Information to help you place and receive orders and
        to improve the Voila service.
      </p>
      <p>
        Your access to and use of our Services are subject to our Terms and Conditions, which define some of the terms
        used throughout this Policy. Please make sure that you have carefully read and understand the Terms and
        Conditions before you use our Services. By using our Services, you accept the Terms and Conditions, and accept
        our privacy practices described in this Policy. If you do not feel comfortable with any part of this Policy or
        our Terms and Conditions, you must not use or access our Services.
      </p>
      <p>
        Our Services may change from time to time. As a result, we reserve the right to update or modify this Policy at
        any time and from time to time without prior notice. If we make any material changes to the way we process your
        Personal Information, we will notify you before such changes are effective. If you object to any changes, you
        must cease using our Services. We recommend you review this Policy periodically. Your continued use of our
        Services after any changes or revisions to this Policy indicates your agreement to the terms of the revised
        Policy.
      </p>
      <h4>PERSONAL INFORMATION COLLECTION</h4>
      <p>
        When you use our Services, we may collect or receive Personal Information about you to provide our services and
        operate our business. The types of Personal Information that we may collect about you are:
      </p>
      <p>a. Information You Provide Directly</p>
      <p>
        You may provide your Personal Information to us when you use our Services, including when you register with
        Voila, search within or place orders through our Services, contact us with inquiries, respond to surveys, or
        otherwise access our Services. This information may include:
      </p>
      <p className="pl-4">
        User Profile – We collect profile information associated with your Customer Voila account. This includes
        information such as name, email address, and phone number.
      </p>
      <p className="pl-4">
        Order Details – When you place an order through our Services we collect information related to that order. This
        may include information such as table booking information and preferences, items purchased, special
        instructions, date and time of order, and subtotal.
      </p>
      <p className="pl-4">
        Payment Information – We don’t store any of your payment information. We are just passing the payment
        information to our third party payment processor. In return we receive a encrypted authorisation token from the
        provider to verify the payment.
      </p>
      <p> b. Information Collected Automatically</p>
      <p>
        We also may receive and store certain information about you and your device(s) automatically when you access or
        use our Site and Services. This information may include:
      </p>
      <p className="pl-4">
        Technical Information – We collect technical information associated with your activity on our Site and may
        include information related to your browser and operating system, IP address (the Internet address of your
        computer) unique device identifiers, and other information such as your device type. We use such information for
        diagnostic and statistical purposes and to administer our Services.
      </p>
      <p className="pl-4">
        Site Usage Information – We collect information to better understand customer traffic patterns and Site usage.
        This may include the webpage that you were visiting before accessing our Site or mobile application, the pages
        or features of our Site or mobile application that you browsed to inform us which part of our Site, app and
        Services you visit and how much time you spend there. This information is used to analyze and improve our Site
        and to provide our customers with a better user experience.
      </p>
      <p className="pl-4">
        Site Preferences – We collect information about your preferences to make your use of the Site more productive
        through the use of cookies.
      </p>
      <p>d. Location Information</p>
      <p>
        When you use the Services, we may collect and store general location information (such as IP address). If you
        permit the Services to access location services through the permission system used by your mobile operating
        system (“Platform”) or browser, we may also collect and store the precise location of your device when the Voila
        app is running in the foreground or background of your device. You can choose whether to enable the location
        tracking feature through the settings on your device or platform or when prompted by the Voila mobile app. If
        you choose to disable the location feature, we won't be able to show you locational information.
      </p>
      <p>f. Personal Information from Publicly Available Sources and Third Parties</p>
      <p>
        We may collect or receive Personal Information about you from publicly available sources, social network
        providers, marketing partners, and/or third parties. This may include:
      </p>
      <p className="pl-4">
        Social Network Information – We may receive information about you from other services. For example, we receive
        certain information from Facebook and Google when you use their service to login to Voila.
      </p>
      <h3>2. USE OF COOKIES AND OTHER TRACKING </h3>
      <h3>TECHNOLOGIES</h3>
      <p>
        Cookies are small web files that a site or its provider transfers to your device’s hard drive through your web
        browser that enables the site’s or provider’s system to recognize your browser and remember certain information.
      </p>
      <p>How We Use Cookies for the following purposes:</p>
      <p className="pl-4">
        to authenticate your account; <br />
        to make our Services function properly;
        <br />
        to improve our Services;
        <br />
        to recognize you when you return to Voila and to remember information you have already provided, such as items
        already in your order cart;
      </p>
      <p>
        How to Manage Cookies – You may disable the use of cookies by modifying your browser settings. If you choose to
        disable cookies you may not be able to fully experience the interactive features of our Services. For more
        information on cookies and how to disable cookies visit www.ftc.gov/ftc/cookies.shtm or
        https://www.usa.gov/optout_instructions.shtml. <br />
        <br />
        Besides cookies, we use online analytics and tracking technologies and we collect usage analytics to improve our
        services.
      </p>
      <h4>3. USE OF YOUR PERSONAL INFORMATION</h4>
      <p>
        We may use the information we collect or receive about you for various purposes.
        <br />
        <br />
        a. To Provide You With the Services
        <br />
        <br />
        We use your information to provide you the Services. Sometimes this includes sharing of your information with
        third parties (for booking, ordering and payment). This information is only shared for the purposes of providing
        the service.
        <br />
        <br />
        b. To Maintain, Improve, and Personalize the Services
        <br />
        <br />
        We use your information for our everyday business operations such as auditing, administration of the Services,
        and analytics. Your information may also be used to improve the content and/or functionality of the Services. We
        may use your information to help us improve offerings for Voila. We also use your information to personalize
        your experience. For example, we may personalize the content and features you see when visiting our Services. In
        addition, we may personalize advertisements, marketing communications, and recommendations to make them more
        tailored to your interests.
        <br />
        <br /> c. To Communicate with You
        <br />
        <br />
        We use your information to communicate with you. For example, we may send you text messages or other
        notifications about the status of your orders and bookings. We may also contact you with promotional offerings
        or other communications that may be of interest to you. If we send you marketing emails about services that may
        interest you, each email will contain instructions permitting you to “opt-out” of receiving future email
        marketing communications.
        <br />
        <br />
        In the event you contact us, we use certain information to respond to your questions and assist you with any
        issues that may arise in your use of the Services.
        <br /> d. For Account and Network Security Purposes
        <br />
        <br />
        We care about keeping you secure and safe while using our Services. Keeping you safe requires us to process your
        Personal Information, such as your device information, activity information and other relevant information. We
        use such information to combat spam, malware, malicious activity or security risks; improve and enforce our
        security measures; and to monitor and verify your identity so that unauthorized users do not gain access to your
        information. e. To Maintain Legal and Regulatory Compliance
        <br />
        <br />
        Our Services are subject to certain laws and regulations which may require us to process your Personal
        Information. For example, we process your Personal Information to pay our taxes, to fulfill our business
        obligations, ensure compliance with employment and recruitment laws or as necessary to manage risk as required
        under applicable law.
        <br />
        <br />
        f. To Enforce Compliance with Our Terms and Conditions and Policies
        <br />
        <br />
        When you access or use our Services, you are bound to our Terms and Conditions and this Policy. To ensure you
        comply with them, we process your Personal Information by actively monitoring, investigating, preventing and
        mitigating any alleged or actual prohibited, illicit or illegal activities on our Services. We also process your
        Personal Information to: investigate, prevent or mitigate violations of our internal terms, agreements or
        policies; enforce our agreements with third parties and business partners.
        <br /> g. To Protect You, Others, and Our Business
        <br />
        <br />
        We use your information to protect you, others, and our business, including, without limitation, using
        information for fraud prevention, for enforcement of our Terms of Service, to comply with the law, and to
        protect against security risks.
        <br />
        <br />
        h. For Our Business Purposes
        <br />
        <br />
        We may use your information for any other purpose disclosed to you at the time we collect or receive the
        information, or otherwise with your consent.
      </p>
      <h4>4. SHARING OF PERSONAL INFORMATION WITH THIRD PARTIES</h4>
      <p>
        We share your Personal Information as described below.
        <br />
        <br />
        a. Service Providers
        <br />
        <br />
        We may share your information with our third-party service providers for certain business purposes. This
        information is provided in order for them to provide us services such as table booking, ordering, payment
        processing, advertising services, marketing partners, web analytics, data processing, IT services, customer
        support and other services. These third-party service providers have access to your Personal Information only
        for the purpose of performing services on our behalf and are expressly obligated not to disclose or use your
        Personal Information for any other purpose.
        <br />
        <br />
        c. Related Entities
        <br />
        <br />
        We may share your information with our restaurant partners. This information will only be shared for the
        purposes of providing you with our booking and ordering services.
        <br />
        <br />
        d. When Required by Law
        <br />
        <br />
        We recognize that information related to your orders could contain private information. However, we may be
        required to disclose your information to third parties including law enforcement agencies when required to
        protect and defend our legal rights, protect the safety and security of users of our Services, prevent fraud,
        comply with the law, respond to legal process, or a request for cooperation by a government entity.
        <br />
        <br />
        e. Corporate Transactions
        <br />
        <br />
        In the event of sale, transfer, merger, reorganization, dissolution, or similar event we may transfer your
        information to one or more third parties as part of that transaction, including during the negotiation of an
        actual or contemplated transaction.
        <br />
        <br />
        f. With Your Consent
        <br />
        <br />
        We also may share or disclose your information with your consent or at your direction.
      </p>
      <h4>5. SECURITY</h4>
      <p>
        Voila has implemented administrative, technical, and physical security controls that are designed to safeguard
        Personal Information. However, no online activity is ever fully secure or error-free. While we strive to protect
        your information, we cannot guarantee that your Personal Information is absolutely secure. Please keep this in
        mind when disclosing any information to Voila.
      </p>
      <p>
        Please recognize that protecting your Personal Information is also your responsibility. We urge you to take
        every precaution to protect your information when you are on the Internet, or when you communicate with us and
        with others through the Internet. Change your passwords often, use a combination of letters and numbers, and
        make sure you use a secure browser. If you have reason to believe that your interaction with us is no longer
        secure (for example, if you feel that the security of your account might have been compromised), or if you
        suspect someone else is using your account, please let us know immediately by contacting us as indicated in the
        Contact Us section below.
      </p>
      <h4>6. DATA RETENTION</h4>
      <p>
        We will retain your Personal Information for as long as your account is active or as needed to provide you
        services and to maintain a record of your transactions for financial reporting purposes. We will also retain and
        use your Personal Information as necessary to comply with our legal obligations, resolve disputes, and enforce
        our agreements.
      </p>
      <h4>7. THIRD-PARTY SITES</h4>
      <p>
        Some of the functionality may be provided by third parties and the Services may link to other third-party
        websites that are not controlled by Voila. These third parties are not under our control and Voila is not
        responsible for their privacy policies or practices. If you provide any Personal Information to any third party
        or through any such third-party website, we recommend that you familiarize yourself with the privacy policies
        and practices of that third party.
      </p>
      <h4>8. SOCIAL MEDIA USAGE</h4>
      <p>
        You may choose to enable or log in to our Services via various online services, including social networking
        services like Facebook and Google. Our Services also may enable you to access social networking services such as
        Facebook, Twitter, or Instagram (collectively, “Social Network”) directly or indirectly through our Services.
      </p>
      <p>
        When you link a Social Network account to Voila or log into our Services using your Social Network account, we
        may collect relevant Personal Information necessary to enable our Services to access that Social Network and
        your information contained within that Social Network. A Social Network may provide us with access to certain
        information that you have provided to them, and we will use, store, and disclose such information in accordance
        with this Policy. Additionally, a Social Network may collect Personal Information and information about your use
        of our Site or Services automatically. The manner in which a Social Network collects, uses, stores, and
        discloses your information is governed by the policies of such third parties and Voila shall have no liability
        or responsibility for the privacy practices or other actions of any Social Network that may be enabled within
        our Services.
      </p>
      <p>
        You may also have the option of posting your activities on our Services and other content to a Social Network
        when you access content through our Services. For example, you may post to Facebook that you placed an order
        with Voila. Keep in mind that your usage of Social Network features is governed by applicable Social Networks
        and not by Voila, and may be visible or accessible to the public.
      </p>
      <h4>9. CHILDREN’S INFORMATION</h4>
      <p>
        Our Services are not intended for children under 13 years of age and we do not knowingly collect Personal
        Information from children under the age of 13. If you are a parent or guardian of a child under the age of 13
        and believe he or she has disclosed personally identifiable information to us please contact us at
        contact@voila.nyc. A parent or guardian of a child under age 13 may review and request deletion of the child’s
        Personal Information.
      </p>
      <h4>10. YOUR CHOICES</h4>
      <p>
        You can deactivate your account in your Voila “Profile” area. After you deactivate your account, your personal
        data and activity information will not be visible to anyone in the Voila applications but will still exist in
        the Voila database, so you can reactivate your account and continue using Voila where you left off.
        <br />
        <br />
        We provide our customers and their authorized agents with the ability to access and delete Personal Information.
        In order to exercise these rights, you must send us an email deletion request at contact@voila.nyc from the
        email address associated with Voila, with the subject line “Non-user Data Request”. Customers will not receive
        discriminatory treatment for exercising their rights and can return to the service after deleting their
        information.
        <br />
        <br />
        a. Your Rights
        <br />
        <br />
        This section lists the privacy-related rights (“Rights”) we extend to all Voila customers. Your right to know
        and right to deletion are not absolute and are subject to certain exceptions. For instance, we cannot disclose
        specific pieces of Personal Information if the disclosure would create a substantial, articulable, and
        unreasonable risk to the security of the Personal Information, your account with us or the security of our
        systems.
      </p>
      <p className="pl-4">
        Right to Know – You have the right to know the Personal Information we collect, use and disclose about you.
        <br />
        <br />
        Right to Deletion – You have the right to request that we delete any Personal Information we have collected from
        you or maintain about you. We may save Personal Information when permitted by applicable law including, without
        limitation, when the information is needed for a legal purpose.
        <br />
        <br />
        Right to Rectification – If you are a registered Voila user you may modify certain of your Personal Information
        by logging into our Site using your username and password and visiting the “Account” page. Registered Voila
        users may also access the “Profle” page to manage their subscriptions.
        <br />
        <br />
        Right to Non-Discrimination – Voila will not discriminate against those who exercise their Rights. Specifically,
        if you exercise your Rights, we will not deny you goods or Services, charge you different prices or rates for
        goods or Services or provide you a different level or quality of goods or Services.
      </p>
      <p>
        b. Asserting Your Rights
        <br />
        <br />
        If you have a Voila account, you may exercise your right to know or your right to deletion. You can do this by
        sending us an email deletion request at contact@voila.nyc from the email address associated with Voila, with the
        subject line “Non-user Data Request”.
        <br />
        <br />
        If you do not have a Voila account, you may exercise your right to know or your right to deletion by emailing
        your request to us at contact@voila.nyc from the email address that you believe is associated with Voila, with
        the subject line “Non-user Data Request”. For safety and to otherwise prevent fraud, we may ask that you provide
        us with certain personal information that’s reasonably necessary for us to confirm your identity.
        <br />
        <br />
        You may also designate an authorized agent to make a request to know or a request to delete. In order to be able
        to act, authorized agents have to submit written proof that they are authorized to act on your behalf, or have a
        power of attorney. We may deny requests from authorized agents who do not submit proof that they have been
        authorized by you to act on their behalf.
      </p>
      <h4>11. INTERNATIONAL USERS</h4>
      <p>
        Voila is based in the United States and, regardless of where you use our Services, the information collected as
        part of that use will be transferred to and maintained on servers located in the United States. Any information
        we obtain about you will be stored in accordance with U.S. privacy laws, regulations, and standards, which may
        not be equivalent to the laws in your country of residence. By using our Services, you consent to this
        collection, transfer, storage, and processing of information to and in the United States.
      </p>

      <h4>CONTACT US</h4>
      <p>
        If you have any questions or concerns relating to this Policy or our privacy practices please contact us at:
        contact@voila.nyc or:
        <br />
        <br />
        Voila Dining LLC
        <br /> <br />
        108 7TH AVE S 2ND FL
        <br />
        NEW YORK NY 10014
        <br />
        Effective Date: 10.02.2021
        <br />
      </p>
    </>
  );
}

export default PrivacyPage;
