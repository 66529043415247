export const JOIN = "join";
export const ORDER_MODIFIED = "orderModified";
export const ORDER_MODIFIED_WITHOUT_RESETING_IS_PAY = "orderModifiedWithoutResetingIsPay";
export const PAYMENT_MADE = "paymentMade";
export const PAYMENT_EXPIRIED = "paymentExpired";
export const SELECTED_FRIENDS = "selectedFriends";
export const PAY_FOR_OTHERS = "payForOthers";
export const ORDER_CLOSED = "orderClosed";
export const REFRESH_EXPIRATION_TOKEN = "refreshExpirationToken";
export const ORDER_EXPIRED = "orderExpired";
export const MENU_ITEM_QUANTITY_CHANGED = "menuItemQuantityChanged";
export const GUESTS_HAVE_SELECTES_MORE_QUANTITY_THAN_ITEM_HAS = "guestsHaveSelectedMoreQuantityThanItemHas";
export const MENU_ITEM_IS_OUT_OF_STOCK = "menuItemIsOutOfStock";
export const DELETE_ITEMS_DUE_TO_MENU_TIME_CHANGE = "deleteItemsDueToMenuTimeChange";
export const START_BOOKED_ORDER = "startBookedOrder";
export const USER_INFO_CHANGED = "userInfoChanged";
export const GUESTS_PRESENCE_CHANGED = "guestsPresenceChanged";
export const USER_LOYALTY_STEP_CHANGE = "userLoyaltyStepChange";
export const ON_USER_LOYALTY_STEP_CHANGE = "onUserLoyaltyStepChange";
export const REFRESH_JUST_PAY = "refreshJustPay";
