import CheckLabel from "../../CheckLabel";

const DiscountsForCheck = ({ entity }) => {
  const { appliedDiscounts } = entity;

  return (
    <>
      {appliedDiscounts?.map((discount) => (
        <CheckLabel
          label={discount.name}
          amount={appliedDiscounts.length > 1 ? discount.discountAmount : null}
          key={discount.discountType}
          divClassName="padding-zero check-label-secondary"
        />
      ))}
    </>
  );
};

export default DiscountsForCheck;
