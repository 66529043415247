import { useMemo } from "react";
import { useHistory, useLocation } from "react-router";

function useQuery() {
  const location = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const getQueryParam = (key) => searchParams.get(key);

  const setQueryParam = useMemo(
    () => (key, value) => {
      if (value !== undefined) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }

      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [searchParams, location.pathname, history],
  );

  return {
    getQueryParam,
    setQueryParam,
  };
}

export default useQuery;
