import React from "react";
import Modal from "../modal/Modal";

const UserOrderDetailsModal = ({ show, close, user }) => {
  return (
    <div>
      {/* <CloseButton onClick={close}/> */}
      <Modal className="user-order-details-modal" show={show} close={close} ignoreClassName={false}>
        <div>
          <h6 className="user-orders-details-info">Guest: {user?.user}</h6>
          <h6 className="user-orders-details-info">Subtotal: ${user?.sub_total_due}</h6>
          <h6 className="user-orders-details-info">Items: {user?.total_items}</h6>
          {user?.unpaid_items > 0 && <h6 className="user-orders-details-info">Not Paid: {user?.unpaid_items}</h6>}
        </div>
      </Modal>
    </div>
  );
};

export default UserOrderDetailsModal;
