import CheckSplitModal from "./modals/CheckSplitModal";
import PaymentModal from "./modals/PaymentModal";
import PaymentTipModal from "./modals/PaymentTipModal";

const CheckoutModals = ({
  checkout,
  showCheckSplitModal,
  showPaymentModal,
  showPaymentTipModal,
  onCloseCheckSplitModalHandler,
  onClosePaymentModalHandler,
  onClosePaymentTipModalHandler,
  actionButtons,
  settledPct,
  onOpenPaymentModalHandler,
}) => {
  return (
    <>
      {showCheckSplitModal && (
        <CheckSplitModal
          showModal={showCheckSplitModal}
          onCloseModal={onCloseCheckSplitModalHandler}
          onOpenPaymentModalHandler={onOpenPaymentModalHandler}
        />
      )}
      {showPaymentModal && (
        <PaymentModal
          showModal={showPaymentModal}
          onCloseModal={onClosePaymentModalHandler}
          actionButtons={actionButtons}
          settledPct={settledPct}
        />
      )}
      {showPaymentTipModal && (
        <PaymentTipModal
          voilaSubTotalAmount={checkout?.voilaSubTotalAmount}
          showTipModal={showPaymentTipModal}
          onCloseTipModal={onClosePaymentTipModalHandler}
        />
      )}
    </>
  );
};

export default CheckoutModals;
