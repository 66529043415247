import React from "react";
import OrdersRounds from "../../components/ordersRounds/OrdersRounds";
import EmptyReservation from "../../components/reservations/EmptyReservation";

const CurrentReservationPage = ({ emitFriendSelected, orderId, showOrdersRoundsComponent, isLoading }) => {
  return !!orderId && showOrdersRoundsComponent ? (
    <OrdersRounds emitFriendSelected={emitFriendSelected} orderId={orderId} />
  ) : !isLoading ? (
    <EmptyReservation />
  ) : null;
};

export default CurrentReservationPage;
