import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { booking_page_path, home_path } from "../../config/pages_paths";
import { BOOKING_DATA, PAGE_ROUTE } from "../../constants/index";
import { registerRedirect } from "../../pages/auth/services/actions";
import { setRegisterRedirectResponse } from "../../store/actions/feedbackActions";
import localStorageHelper from "../../utils/localStorageHelper";

function RegisterConfirmation() {
  const { confirmationToken } = useParams();
  const {
    feedback: { isAlreadyRegistered, userRegistered },
  } = useSelector((state) => state);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (confirmationToken) {
      dispatch(registerRedirect("Bearer " + confirmationToken, history));
    }
    const bookingData = localStorageHelper.getItem(BOOKING_DATA);
    const pageRoute = localStorageHelper.getItem(PAGE_ROUTE);

    if (!isAlreadyRegistered || !userRegistered) {
      if (bookingData) {
        history.push(booking_page_path.replace(":restaurantId", bookingData.restaurantId));
      } else if (pageRoute) {
        history.push(pageRoute);
      } else {
        history.push(home_path);
      }
    }
  }, [dispatch, isAlreadyRegistered, userRegistered, history, confirmationToken]);

  useEffect(() => {
    return () => {
      dispatch(setRegisterRedirectResponse({}));
    };
  }, [dispatch]);

  return null;
}

export default RegisterConfirmation;
