import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmail from "validator/lib/isEmail";
import LoginButton from "../../../components/auth/login/LoginButton";
import InputField from "../../../components/common/InputField";
import ModalHeading from "../../../components/common/ModalHeading";
import Modal from "../../../components/modal/Modal";
import { password_reset_request_sent_modal } from "../../../config/modal_paths";
import Column from "../../../containers/layout/Column";
import Row from "../../../containers/layout/Row";
import useForm from "../../../hooks/useForm";
import { setErrors } from "../../../store/actions/errorsActions";
import { setActiveModal, setResponseMessage } from "../../../store/actions/feedbackActions";
import { passwordResetRequest } from "../services/actions";
import "./PasswordReset.css";

function PasswordResetRequest({ show, close }) {
  const {
    errors,
    feedback: {
      responseMessage,
      // activeModalMetadata
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const sendPasswordResetRequest = () => {
    if (isEmail(values.email)) {
      dispatch(passwordResetRequest(values.email));
    } else {
      dispatch(setErrors({ email: "Please enter a valid email." }));
    }
  };

  const { values, handleSubmit, handleChange } = useForm({ email: "" }, sendPasswordResetRequest);

  useEffect(() => {
    if (responseMessage) {
      dispatch(setActiveModal(password_reset_request_sent_modal, { email: values.email }));
      dispatch(setResponseMessage(""));
    }
  }, [responseMessage, dispatch, values]);

  return (
    <Modal className="password-reset-modal" isPopUp show={show} close={close}>
      <ModalHeading
        className="password-request-sent-modal-header"
        onClick={close}
        // title="Log-in"
      />
      <Row>
        <Column className="text-center align-items-center m-4" md={12} lg={12}>
          <div className="password-reset-heading">
            <h3>Where shall we send the re-set password link to?</h3>
          </div>
        </Column>
        <Column md={12} lg={12}>
          <form onSubmit={handleSubmit}>
            <InputField
              name="email"
              onChange={handleChange}
              label="E-mail address"
              value={values.email}
              error={errors.email}
            />

            <button style={{ display: "none" }} type="submit" onSubmit={handleSubmit} />
            <LoginButton style={{ marginTop: "40px" }} text="Send" onClick={sendPasswordResetRequest} />
          </form>
        </Column>
      </Row>
    </Modal>
  );
}

export default PasswordResetRequest;
