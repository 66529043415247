export const paymentTypeEnum = {
  CASH: "CASH",
  CREDIT: "CREDIT",
  GIFTCARD: "GIFTCARD",
  HOUSE_ACCOUNT: "HOUSE_ACCOUNT",
  REWARDCARD: "REWARDCARD",
  LEVELUP: "LEVELUP",
  TOAST_SV: "TOAST_SV",
  OTHER: "OTHER",
  UNDETERMINED: "UNDETERMINED",
};
