import {
  SET_RESPONSE_MESSAGE,
  SET_MODAL,
  SET_PAGE_ROUTE_PATH,
  SET_REGISTER_REDIRECT_RESPONSE,
  SET_TOAST_MESSAGE,
  SET_NUM_OF_LOADING_SPINNER_CALLS,
  SET_IS_TABLE_VALID,
  SET_CURRENT_ACCORDION,
  SET_CURRENT_ACCORDION_ORDER,
  SET_EXPIRATION_TIMER_MODAL,
  SET_TIMER_EXPIRED_MESSAGE_MODAL,
  SET_MENU_ITEM_QUANTITY_CHANGED_MODAL,
  SET_IS_FOR_WHOM_TO_PAY_CONFIRMED,
  SET_ANCHOR_MENU,
  SET_CONNECT_SOCKET_AFTER_IDLE_TIMEOUT,
  SET_REFRESH_DATA_FOR_OPENED_MENU_ITEM,
  SET_ON_FULL_PAGE_MODAL,
} from "../../config/actionTypes";
import { check_table_validity_api, send_support_email_api } from "../../config/api_endpoints";
import axios from "axios";
import { setErrors } from "./errorsActions";

export const setLoader = (payload) => (dispatch) => {
  dispatch({ type: SET_NUM_OF_LOADING_SPINNER_CALLS, payload });
};

export const setResponseMessage = (payload) => (dispatch) => {
  dispatch({ type: SET_RESPONSE_MESSAGE, payload });
};

export const setActiveModal = (modal, metadata, orderId) => (dispatch) => {
  dispatch({ type: SET_MODAL, payload: modal, metadata, orderId });
};

export const setAnchorMenu = (payload) => (dispatch) => {
  dispatch({ type: SET_ANCHOR_MENU, payload });
};

export const setPageRoutePath = (route_path) => (dispatch) => {
  dispatch({ type: SET_PAGE_ROUTE_PATH, payload: route_path });
};

export const setRegisterRedirectResponse = (payload) => (dispatch) => {
  dispatch({ type: SET_REGISTER_REDIRECT_RESPONSE, payload });
};

export const setToastMessage = (payload) => (dispatch) => {
  dispatch({ type: SET_TOAST_MESSAGE, payload });
};

export const sendContactEmail = (data) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    await axios.post(send_support_email_api, data);
    dispatch(setToastMessage({ message: "Email sent!", severity: "info" }));
  } catch (err) {
  } finally {
    dispatch(setLoader(false));
  }
};

export const checkIsTableValid = (data) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    await axios.post(check_table_validity_api, data);
    dispatch(setIsTableValid(true));
  } catch (err) {
    dispatch(setIsTableValid(false));
    dispatch(setErrors(err, () => checkIsTableValid(data)));
  } finally {
    dispatch(setLoader(false));
  }
};

export const setIsTableValid = (payload) => (dispatch) => {
  dispatch({ type: SET_IS_TABLE_VALID, payload });
};

export const setCurrentAccordion = (payload) => (dispatch) => {
  dispatch({ type: SET_CURRENT_ACCORDION, payload });
};

export const setCurrentAccordionOrder = (payload) => (dispatch) => {
  dispatch({ type: SET_CURRENT_ACCORDION_ORDER, payload });
};

export const setExpirationTimerModal = (payload) => (dispatch) => {
  dispatch({ type: SET_EXPIRATION_TIMER_MODAL, payload });
};

export const setTimerExpiredMessageModal = (payload) => (dispatch) => {
  dispatch({ type: SET_TIMER_EXPIRED_MESSAGE_MODAL, payload });
};

export const setIsForWhomToPayConfirmed = (payload) => (dispatch) => {
  dispatch({ type: SET_IS_FOR_WHOM_TO_PAY_CONFIRMED, payload });
};

export const setConnectSocketAfterIdleTimeout = (payload) => (dispatch) => {
  dispatch({ type: SET_CONNECT_SOCKET_AFTER_IDLE_TIMEOUT, payload });
};

export const setMenuItemQuantityChangedModal = (payload) => (dispatch) => {
  dispatch({ type: SET_MENU_ITEM_QUANTITY_CHANGED_MODAL, payload });
};

export const setRefreshDataForOpenedMenuItem = (payload) => (dispatch) => {
  dispatch({ type: SET_REFRESH_DATA_FOR_OPENED_MENU_ITEM, payload });
};

export const setOnFullPageModal = (payload) => (dispatch) => {
  dispatch({ type: SET_ON_FULL_PAGE_MODAL, payload });
};
