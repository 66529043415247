import React, { useEffect } from "react";
import HomeHeader from "../../components/landing/HomeHeader.";
import FeaturedRestaurants from "../../components/landing/FeaturedRestaurants";
import { useSelector, useDispatch } from "react-redux";
import "./Landing.css";
import Footer from "../../components/landing/Footer";
import "../../components/landing/LandingComponents.css";
import { getRestaurants } from "../../store/actions/restaurantsActions";
import isEmpty from "lodash.isempty";

function LandingPage(props) {
  const dispatch = useDispatch();
  const { restaurants } = useSelector((state) => state.restaurants);

  useEffect(() => {
    document.body.setAttribute("class", "landing-header-left-column");

    return () => {
      document.body.removeAttribute("class");
    };
  }, []);

  useEffect(() => {
    isEmpty(restaurants) && dispatch(getRestaurants());
  }, [dispatch, restaurants]);

  return (
    <React.Fragment>
      <HomeHeader />
      <FeaturedRestaurants restaurants={restaurants} />
      <Footer />
    </React.Fragment>
  );
}

export default LandingPage;
