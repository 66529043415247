import React from "react";
import { Global } from "@emotion/react";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Popover,
  Popper,
  Select,
  SwipeableDrawer,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import { darken, lighten, styled } from "@mui/system";

export const CustomDrawer = ({
  id,
  open,
  toggleDrawer,
  className,
  ref,
  disableDiscovery,
  content,
  disableBackdropTransition,
  paperClasses,
  PaperProps,
  paperWidth,
  anchor,
  zIndex,
}) => {
  return (
    <SwipeableDrawer
      style={{ zIndex: zIndex ? zIndex : "inherit" }}
      id={id}
      ref={ref}
      anchor={anchor}
      open={open}
      disableDiscovery={disableDiscovery}
      disableBackdropTransition={disableBackdropTransition}
      onOpen={toggleDrawer}
      onClose={toggleDrawer}
      classes={{ paper: paperClasses }}
      className={className}
      PaperProps={{
        sx: {
          width: paperWidth,
          zIndex: 9009,
        },
      }}
    >
      {content}
    </SwipeableDrawer>
  );
};

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette.mode === "light" ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 6,
  height: "100%",
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 0,
  left: 8, // Adjust this value as needed
}));

export const CustomDrawer2 = ({
  id,
  open,
  toggleDrawer,
  className,
  ref,
  disableDiscovery,
  content,
  disableBackdropTransition,
  paperClasses,
  PaperProps,
  paperWidth,
  anchor,
}) => {
  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            width: `calc(50% - ${56}px)`,
            overflow: "visible",
          },
        }}
      />
      {/* <Box sx={{ textAlign: 'center', pt: 1 }}>
            <Button onClick={toggleDrawer(true)}>Open</Button>
          </Box> */}
      <SwipeableDrawer
        id={id}
        ref={ref}
        anchor={anchor}
        open={open}
        disableDiscovery={disableDiscovery}
        disableBackdropTransition={disableBackdropTransition}
        onOpen={toggleDrawer}
        onClose={toggleDrawer}
        classes={{ paper: paperClasses }}
        className={className}
        PaperProps={{
          sx: {
            width: paperWidth,
          },
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 10,
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            visibility: "visible",
          }}
        >
          <Puller />
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          {content}
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
};

export function SwipeableEdgeDrawerContent() {
  return (
    <StyledBox
      className="brtt"
      sx={{
        position: "absolute",
        top: 0,
        height: 50,
        bottom: 0,
        left: -56,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        visibility: "visible",
      }}
    >
      <Puller />
    </StyledBox>
  );
}

export const CustomPopover = ({
  id,
  paperClasses,
  open,
  togglePopover,
  content,
  anchorEl,
  anchorOrigin,
  transformOrigin,
  popoverEnter,
  popoverLeave,
  popoverAnchor,
  disableRestoreFocus,
  sx,
}) => {
  return (
    <Popover
      id={id}
      classes={{ paper: paperClasses }}
      open={open}
      onClose={togglePopover}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      sx={sx}
      disableRestoreFocus={disableRestoreFocus}
    >
      {content}
    </Popover>
  );
};

export const CustomSelect = ({
  variant,
  id,
  name,
  sx,
  initialWidth,
  autoFocus,
  autoComplete,
  className,
  menuItems = [],
  label,
  labelId,
  onChange,
  value,
  required,
  multiple,
  renderValue,
  onMenuItemsClick,
  color,
  menuItemsName,
  menuItemsValue,
  isChip,
}) => {
  const useStyles = makeStyles((theme) => ({
    select: {
      position: "relative",
    },
    InputLabel: {
      top: "-12%",
      left: "2%",
      color: "#8292a2",
      transform: "translate(0, 1.5px) scale(0.75)",
    },
  }));

  const classes = useStyles();

  return (
    <FormControl className={classes.select} fullWidth={!initialWidth ? true : false}>
      <InputLabel
        required={required}
        id={labelId}
        // classes={classes.InputLabel}
      >
        {label}
      </InputLabel>

      <Select
        fullWidth
        variant={variant ? variant : "outlined"}
        id={id}
        required={required}
        multiple={multiple}
        name={name}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        sx={sx ? sx : { height: "56px" }}
        className={className}
        labelId={labelId}
        value={value}
        color={color}
        onChange={onChange}
        label={label}
        renderValue={
          renderValue
            ? (selected) =>
                !isChip ? (
                  Array.isArray(selected) ? (
                    selected.join(", ")
                  ) : (
                    selected
                  )
                ) : (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )
            : null
        }
        MenuProps={{
          // getContentAnchorEl: () => null,
          disableScrollLock: true,
        }}
      >
        {/* Map the array provided for the select */}

        {menuItems?.map((item, idx) => {
          if (multiple) {
            if (typeof item === "object" && menuItemsName) {
              return (
                <MenuItem key={item?.id ?? idx} value={menuItemsValue ? item[menuItemsValue] : item[menuItemsName]}>
                  <Checkbox onClick={onMenuItemsClick} checked={value.indexOf(item[menuItemsName]) > -1} />
                  <ListItemText primary={item[menuItemsName]} />
                </MenuItem>
              );
            } else {
              return (
                <MenuItem key={idx} value={item}>
                  <Checkbox onClick={onMenuItemsClick} checked={value.indexOf(item) > -1} />
                  <ListItemText primary={item} />
                </MenuItem>
              );
            }
          } else if (typeof item === "string" || typeof item === "number" || typeof item === "boolean") {
            return (
              <MenuItem key={idx} value={item}>
                <ListItemText primary={item.toString()} />
              </MenuItem>
            );
          } else if (typeof item === "object" && menuItemsName) {
            return (
              <MenuItem
                key={item?.name || idx}
                value={item[menuItemsValue] ? item[menuItemsValue] : item[menuItemsName]}
              >
                <ListItemText primary={item[menuItemsName]} />
              </MenuItem>
            );
          } else {
            return null;
          }
        })}
      </Select>
    </FormControl>
  );
};

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

export function CustomAutocomplete({
  id,
  open,
  onToggle,
  menuItems,
  selectedMenuItems,
  onChange,
  multiple,
  className,
  label,
  sx,
  groupItemsBadgeContentLoyalty,
  popupIcon,
  disableCloseOnSelect,
  handleIsAutocompeltePopoverOnClose,
  handleIsAutocompeltePopoverOnOpen,
  mainColor,
  secondaryColor,
  alternativeColor,
  backgroundColor,
  closeIcon,
  mainFontFamily,
  secondaryFontFamily,
}) {
  const options = menuItems.flatMap((item) => {
    return item.data.map((option) => ({
      section: item.label,
      option: option,
    }));
  });

  // Define a custom equality test function
  const isOptionEqualToValue = (option, value) => {
    return option.option.id === value.option.id && option.option.name === value.option.name;
  };

  // function lerp(minScreen, maxScreen, minValue, maxValue, screenWidth) {
  //     // Ensure screenWidth is within the specified range
  //     if (screenWidth <= minScreen) return minValue;
  //     if (screenWidth >= maxScreen) return maxValue;

  //     // Calculate the normalized position of screenWidth between minScreen and maxScreen
  //     const t = (screenWidth - minScreen) / (maxScreen - minScreen);

  //     // Interpolate between minValue and maxValue based on the normalized position
  //     return (1 - t) * minValue + t * maxValue;
  //   }

  //   const minScreen = 1800;
  //   const maxScreen = 3000;
  //   const minValue = 474; // Value for minScreen
  //   const maxValue = 1075; // Value for maxScreen

  //   const screenWidth = window.innerWidth; // Get the current screen width dynamically
  //   const translateValue = lerp(minScreen, maxScreen, minValue, maxValue, screenWidth);
  //   console.log(translateValue)

  const useStyles = makeStyles((theme) => ({
    paper: {
      width: "24rem!important",
      transform: "translate3d(16px, 142.667px, 0px)",
    },
    listbox: {
      overflowY: "scroll!important",
      maxHeight: "50vh!important",
      "&::-webkit-scrollbar": {
        width: "8px",
        display: "block",
        backgroundColor: backgroundColor,
        marginLeft: "4px",
      },
      "&::-webkit-scrollbar-track": {
        boxshadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
        marginLeft: "4px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        "-webkit-box-shadow": "inset 0 0 6px rgba(0, 0, 0, 0.3)",
        backgroundColor: backgroundColor,
        marginLeft: "4px",
      },
    },
    groupHeader: {
      display: "flex",
      alignItems: "center",
      backgroundColor: mainColor,
      color: secondaryColor,
      borderRadius: "0.5rem",
      border: `1px solid ${secondaryColor}`,
      fontFamily: mainFontFamily,
      gap: "0.5rem",
      fontSize: "1.4rem",
      zIndex: "1019",
      width: "100%",
    },
    chipTags: {
      backgroundColor: secondaryColor,
      color: mainColor,
      fontFamily: secondaryFontFamily,
    },
    autocomplete: {
      padding: "0.5rem 0",
    },
  }));

  const classes = useStyles();

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "0.75rem",
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: `${secondaryColor}!important`,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: `${secondaryColor}!important`,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline:hover": {
              borderColor: `${secondaryColor}!important`,
            },
            "&&& $input": {
              padding: "1rem!important",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: secondaryColor,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            ".MuiAutocomplete-endAdornment": {
              top: "calc(50% - 1rem)!important",
            },
            "& .MuiAutocomplete-clearIndicator": {
              color: secondaryColor,
              // margin: "0 0 0.25rem 0",
            },
          },
          popper: {
            zIndex: "1019!important",
            //welcome to hell
            // transform: window.innerWidth > 1920 ? `translate3d(${((window.innerWidth - 1920) / 2) + 534}px, 142px, 0px)!important` : 'inherit'
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        id={id}
        options={options}
        groupBy={(option) => option.section}
        getOptionLabel={(option) => option.option.name}
        isOptionEqualToValue={isOptionEqualToValue}
        multiple={multiple}
        onChange={onChange}
        // open={true}
        open={open}
        popupIcon={popupIcon}
        // classes={classes.paper}
        onOpen={handleIsAutocompeltePopoverOnOpen}
        onClose={handleIsAutocompeltePopoverOnClose}
        fullWidth
        // componentsProps={{ popper: { style: { width: "93%!important" } } }}
        className={classes.autocomplete}
        disableCloseOnSelect={disableCloseOnSelect}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              className={classes.chipTags}
              sx={{
                backgroundColor: secondaryColor,
                color: mainColor,
                fontFamily: secondaryFontFamily,
              }}
              variant="filled"
              deleteIcon={closeIcon}
              label={`${option.option.name}`}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => <TextField {...params} label={label} />}
        renderGroup={(params) => {
          const groupItems = params.children.map((item) => {
            const badgeQuantity = groupItemsBadgeContentLoyalty
              ? (
                  groupItemsBadgeContentLoyalty.find(
                    (content) => content.name === item.key || content.restaurant_name === item.key,
                  ) || {}
                ).current_points
              : 0;

            return (
              <Box key={item.key} sx={{ position: "relative" }}>
                {item}
                {badgeQuantity > 0 && (
                  <Chip
                    label={badgeQuantity}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      right: "0.5rem",
                      transform: "translateY(-50%)",
                      backgroundColor: secondaryColor,
                      color: mainColor,
                      fontFamily: secondaryFontFamily,
                    }}
                  />
                )}
              </Box>
            );
          });

          return (
            <li key={params.key} style={{ marginRight: "0.1rem" }}>
              <GroupHeader className={classes.groupHeader}>
                {menuItems.map(
                  (menuItem) =>
                    menuItem.label === params.group && (
                      <div style={{ display: "flex", alignItems: "center" }} key={menuItem.label}>
                        {menuItem.icon}
                      </div>
                    ),
                )}
                {params.group}
              </GroupHeader>
              <GroupItems
                sx={{
                  fontFamily: "Lato",
                  fontSize: "1rem",
                  margin: "0.5rem 0",
                }}
              >
                {groupItems}
              </GroupItems>
            </li>
          );
        }}
        ListboxProps={{
          className: classes.listbox,
        }}
        renderOption={(item, option) => {
          const isSelected = selectedMenuItems.some((selectedItem) => item.key === selectedItem.option.name);
          return (
            <span
              {...item}
              style={{
                backgroundColor: isSelected ? backgroundColor : "inherit",
                borderRadius: "0.5rem",
                gap: "0.5rem",
              }}
            >
              <div
                style={{
                  width: "0.125rem",
                  height: "1.25rem",
                  borderRadius: "0.5rem",
                  backgroundColor: isSelected ? secondaryColor : "inherit",
                }}
              />

              {option.option.name}
            </span>
          );
        }}
        PopperComponent={(props) => (
          <Popper {...props} placement="bottom">
            <div className={classes.listItems}>{props.children}</div>
          </Popper>
        )}
      />
    </ThemeProvider>
  );
}
