import React, { useCallback, useState } from "react";
import { useGoogleLogout } from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { home_path } from "../../config/pages_paths";
import useForm from "../../hooks/useForm";
import { deactivateAccount, logoutUser, updateUser } from "../../pages/auth/services/actions";
import validateUserProfile from "../../validation/validateUserProfile";
import InputField from "../common/InputField";
import DeactivateAccountModal from "./DeactivateAccountModal";
import "./ProfileDetails.css";
import { Grid } from "@mui/material";
import useFloatingIslandActions from "../../hooks/useFloatingIslandActions";

function ProfileDetails() {
  const {
    user: { user: userDetails },
    errors,
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const history = useHistory();
  const [formErrors, setErrors] = useState({});
  const [deactivateAccountModal, setDeactivateAccountModal] = useState(false);

  const onLogout = () => {
    try {
      dispatch(logoutUser());
      signOut();
      if (window.FB) window.FB.logout();
      history.go(0);
    } catch (err) {}
    history.push(home_path);
  };

  const onLogoutSuccess = () => {
    console.log("Successfully logout from google");
  };

  const onFailure = (err) => {
    console.log("google logout failure", err);
  };

  const { signOut } = useGoogleLogout({
    onFailure,
    clientId: "55700200557-8un2ipb96sqmhpuuf8ilh9riv062st5j.apps.googleusercontent.com",
    onLogoutSuccess,
  });

  const handleSubmit = (e, formName) => {
    // Prevent form submission
    e && e.preventDefault();

    // if (formName === 'update-user-form') {
    onUpdateUserDetails(e);
    // } else if (formName === 'update-user-password') {
    //   onUpdateUserPassword(e);
    // }
  };

  const { values, handleChange, setValues } = useForm(
    {
      email: userDetails.email || "",
      first_name: userDetails.first_name || "",
      last_name: userDetails.last_name || "",
      phone: userDetails.phone || "",
      password: "",
      new_password: "",
      confirm_password: "",
    },
    handleSubmit,
  );

  // useEffect(() => {
  //   if (!isEmpty(userDetails)) {
  //     setValues(values => ({ ...values, ...userDetails }));
  //   }
  // }, [userDetails, setValues]);

  const onDeactivateAccount = () => {
    setDeactivateAccountModal((curState) => !curState);
  };

  const onDeactivateHandler = () => {
    dispatch(deactivateAccount(onLogout));
  };

  const onUpdateUserDetails = useCallback(
    (e) => {
      e && e.preventDefault();

      const newValues = {
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        phone: values.phone.trim(),
      };
      setValues(newValues);

      const { errors, isValid } = validateUserProfile(newValues);

      if (!isValid) {
        setErrors(errors);
        return null;
      } else setErrors({});

      dispatch(updateUser(newValues));
    },
    [dispatch, setValues, values],
  );

  // #region Floating Island

  const { DOMslot1, DOMslot2, createButtonPortal, backToOrderShortcut, saveChanges } = useFloatingIslandActions();

  // #endregion

  return (
    <React.Fragment>
      {deactivateAccountModal ? (
        <DeactivateAccountModal
          show={deactivateAccountModal}
          close={onDeactivateAccount}
          onDeactivate={onDeactivateHandler}
        />
      ) : null}
      <h3 className="account-information">Account information</h3>
      <form id="update-user-form" onSubmit={(e) => handleSubmit(e, "update-user-form")}>
        <InputField
          inputClassName="profile-input"
          labelClassName="profile-input-label"
          errorClassName="profile-input-invalid-feedback"
          disabled={true}
          onChange={handleChange}
          name="email"
          value={values.email}
          error={formErrors.email || errors.email}
          label="E-mail address"
        />
        <InputField
          inputClassName="profile-input"
          labelClassName="profile-input-label"
          errorClassName="profile-input-invalid-feedback"
          onChange={handleChange}
          name="first_name"
          value={values.first_name}
          error={formErrors.first_name || errors.first_name}
          label="First name"
        />
        <InputField
          inputClassName="profile-input"
          labelClassName="profile-input-label"
          errorClassName="profile-input-invalid-feedback"
          onChange={handleChange}
          name="last_name"
          value={values.last_name}
          error={formErrors.last_name || errors.last_name}
          label="Last name"
        />
        <InputField
          inputClassName="profile-input"
          labelClassName="profile-input-label"
          errorClassName="profile-input-invalid-feedback"
          onChange={handleChange}
          name="phone"
          value={values.phone}
          error={formErrors.phone || errors.phone}
          label="Phone"
          placeholder="Enter the phone number with + and the country code"
        />

        <Grid container justifyContent={"center"} gap={2}>
          <Grid item sm={6} xs={12}>
            {/* <button
              style={{ width: "80%", marginLeft: "10%" }}
              className="btn btn-primary big-round-btn-auto-width"
              type="submit"
            >
              <span>Save changes</span>
            </button> */}

            {createButtonPortal(saveChanges(onUpdateUserDetails), DOMslot1)}
            {backToOrderShortcut(DOMslot2)}
          </Grid>
          <Grid item sm={6} xs={12}>
            <button
              style={{ width: "80%", marginLeft: "10%" }}
              className="btn btn-danger big-round-btn"
              onClick={onDeactivateAccount}
              type="button"
            >
              <span>Deactivate account</span>
            </button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

export default ProfileDetails;
