import React from "react";
import ReservationListItem from "../../components/reservations/ReservationListItem";
import { isEmpty } from "lodash";
import EmptyReservation from "../../components/reservations/EmptyReservation";
import { past_order_page_path } from "../../config/pages_paths";

const PastReservationsPage = ({ pastReservations, history, isLoading }) => {
  const handleReservationHavigation = (orderId) => {
    history && history.push(past_order_page_path.replace(":orderId", orderId));
  };

  return (
    <ul className="reservations-list">
      {!isEmpty(pastReservations) ? (
        pastReservations.map((props, idx) => (
          <ReservationListItem key={idx} onClick={() => handleReservationHavigation(props.order_id)} {...props} />
        ))
      ) : !isLoading ? (
        <EmptyReservation text="No booking or orders yet" paragraph="Ready when you are" />
      ) : null}
    </ul>
  );
};

export default PastReservationsPage;
