import NavigationSideMenu from "../../components/navigation/NavigationSideMenu";
import ProfileDetails from "../../components/profile/ProfileDetails";
import Column from "../../containers/layout/Column";
import Row from "../../containers/layout/Row";

export default function ProfilePage() {
  return (
    <Row className="m-4 reservations-row">
      <Column lg={12}>
        <Row>
          <Column lg="2">
            <NavigationSideMenu />
          </Column>
          <Column className="scrollable-list" lg="5">
            <ProfileDetails />
          </Column>
          <Column lg="2" />
        </Row>
      </Column>
    </Row>
  );
}
