import React from "react";
import Row from "../../containers/layout/Row";
import Column from "../../containers/layout/Column";
import CloseIcon from "../../assets/close.svg";
import classnames from "classnames";
import LeftArrow from "../../assets/ico-back.svg";

const iconStyle = {
  float: "right",
  cursor: "pointer",
  width: "14px",
  height: "14px",
  objectFit: "contain",
  position: "absolute",
  top: "-20px",
  margin: "auto 0",
  right: "10px",
};

function ModalHeading2({ onClick, title1, title2, className, goBack, activeTitle, onSignUp, onLogin }) {
  return (
    <Row className="modal-heading-container">
      <Column className={classnames("align-items-center m-0", className)} style={{ marginTop: "24px" }} col={12}>
        {goBack ? (
          <div onClick={goBack} className="align-items-center modal-back-btn">
            <img className="text-center my-auto align-self-center" src={LeftArrow} alt="back" />
          </div>
        ) : null}
        <div style={{ display: "flex", flexFlow: "row no-wrap", gap: "2rem" }}>
          <div onClick={onLogin} style={{ cursor: "pointer" }}>
            <h3 style={{ display: "inline-block" }}>{title1}</h3>{" "}
            {activeTitle === title1 ? <div className="side-menu-x-line-2 active"></div> : null}
          </div>
          <div onClick={onSignUp} style={{ cursor: "pointer" }}>
            <h3 style={{ display: "inline-block" }}>{title2}</h3>{" "}
            {activeTitle === title2 ? <div className="side-menu-x-line-2 active"></div> : null}
          </div>
        </div>
        <img className="text-center" alt="close" src={CloseIcon} onClick={onClick} style={iconStyle} />
      </Column>
    </Row>
  );
}

export default ModalHeading2;
