import isEmpty from "lodash.isempty";
import {
  SET_ACTIVE_MENU_AND_SECTION_AND_ITEMS_INFO_APPROVED,
  SET_HAS_INITIAL_MENU_OPENED,
  SET_IS_MENU_STEPS_FEATURE_ENABLED,
  SET_MENU_DRAWER_OPENED,
  SET_MENU_ITEM_RECOMMENDATIONS,
  SET_MENU_ORDERS_DRAWER_OPENED,
  SET_MENU_SELECTED_STEP,
  SET_ON_MENU_ITEM_RECOMMENDATIONS_MODAL,
  SET_RESTAURANT_FILTERED_MENU_BY_STEPS,
  SET_RESTAURANT_MENU_APPROVED,
} from "../../../config/actionTypes";
import inventoryStatusEnum from "../../../enums/inventoryStatusEnum";

// NOTE:
// REMOVE APPROVED ONCE THE REFACTORING IS COMPLETED !
// :P

const initialState = {
  // array containing the full menu
  restaurantMenu: [],
  uniqueMenuSectionSteps: [],
  selectedMenuAndSectionsByStep: [],
  menuSelectedStep: null,
  restaurantMenuByStep: [],
  onStepMenu: null,
  // object for the name and id of the menu and section
  activeMenuAndSectionInfoApproved: {
    activeMenuId: null,
    activeMenuName: null,
    activeMenuSectionId: null,
    activeMenuSectionName: null,
  },
  // array containing all the items for the active menu and section
  activeMenuSectionItemsApproved: [],
  // array containing all the items for the active menu and section with the information from the last order round
  activeMenuSectionItemsWithRoundForUserApproved: [],
  // array containing recommendations for an individual item that is set once a certain item is shown
  menuItemRecommendations: [],
  // boolean state for handing logic between modals with recommendations
  onMenuItemRecommendationsModal: null,
  // isMenuStepsFeatureEnabled: null,
  lastRoundOrders: [],
  menuDrawerOpened: false,
  menuOrdersDrawerOpened: false,
  hasInitialMenuOpened: false,
  isMenuStepsFeatureEnabled: null,
};

const menu = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_RESTAURANT_MENU_APPROVED: {
      // Check if restaurantMenu is an empty array
      if (payload && Array.isArray(payload) && !isEmpty(payload)) {
        const isRestaurantMenuEmpty = state.restaurantMenu.length === 0;

        // console.log("SET_RESTAURANT_MENU_APPROVED", payload)
        const filteredMenu = payload.filter((menu) => {
          return !isEmpty(menu.menu_sections) && menu.menu_sections.some((section) => !isEmpty(section.menu_items));
        });
        // Extract unique menu_section_step values from all menu_sections and sort them
        let uniqueMenuSectionSteps = filteredMenu
          .flatMap((menu) =>
            menu.menu_sections.map((section) => section?.menu_section_step).filter((step) => step && step.name),
          )
          .reduce((uniqueSteps, step) => {
            const existingStep = uniqueSteps.find((s) => s.name === step.name);

            if (!existingStep) {
              uniqueSteps.push(step);
            } else if (step.sort_id !== -1 && existingStep.sort_id === -1) {
              uniqueSteps[uniqueSteps.indexOf(existingStep)] = step;
            }

            return uniqueSteps;
          }, [])
          .sort((a, b) => {
            if (a.sort_id === -1 && b.sort_id !== -1) {
              return 1;
            } else if (a.sort_id !== -1 && b.sort_id === -1) {
              return -1;
            } else {
              return a.sort_id - b.sort_id;
            }
          });

        let restaurantMenuByStep;

        if (uniqueMenuSectionSteps) {
          restaurantMenuByStep = uniqueMenuSectionSteps.map((step) => {
            const menusForStep = filteredMenu
              .filter((menu) => {
                return menu.menu_sections.some(
                  (section) =>
                    (section.menu_section_step && section.menu_section_step.name === step.name) ||
                    (!section.menu_section_step && step.name === "Uncategorized"),
                );
              })
              .map((menu) => {
                const sectionsForMenu = menu.menu_sections.filter(
                  (section) =>
                    (section.menu_section_step && section.menu_section_step.name === step.name) ||
                    (!section.menu_section_step && step.name === "Uncategorized"),
                );

                return {
                  ...menu,
                  menu_sections: sectionsForMenu,
                };
              });

            return {
              menu_step: step.name,
              menus: menusForStep,
            };
          });

          // Check if "Uncategorized" step is needed
          const hasUncategorizedStep = restaurantMenuByStep.some((step) => step.menu_step === "Uncategorized");

          // Include sections without a step in the "Uncategorized" step only if not already present
          if (!hasUncategorizedStep) {
            const uncategorizedMenus = filteredMenu
              .filter((menu) =>
                menu.menu_sections.some(
                  (section) => !section.menu_section_step || isEmpty(section.menu_section_step.name),
                ),
              )
              .map((menu) => {
                const uncategorizedSections = menu.menu_sections.filter(
                  (section) => !section.menu_section_step || isEmpty(section.menu_section_step.name),
                );

                return {
                  ...menu,
                  menu_sections: uncategorizedSections,
                };
              });

            // // Create an uncategorized step to include sections with no selected step
            // if (uncategorizedMenus.length > 0) {
            //     uniqueMenuSectionSteps.push({
            //         name: 'Uncategorized',
            //         sort_id: -1
            //     })
            //     restaurantMenuByStep.push({
            //         menu_step: 'Uncategorized',
            //         menus: uncategorizedMenus,
            //     });

            // }
          }
        }

        // Set the first found menu, sections and items
        const [firstMenu] = filteredMenu;
        const [firstMenuSection] = firstMenu.menu_sections;
        const { menu_items: itemsFromMenuSection } = firstMenuSection;

        return {
          ...state,
          // Always update restaurantMenu with the new payload
          restaurantMenu: filteredMenu,
          uniqueMenuSectionSteps: uniqueMenuSectionSteps,
          restaurantMenuByStep: restaurantMenuByStep,
          // Update activeMenuAndSectionInfoApproved and activeMenuSectionItemsApproved on the first incoming payload
          ...(isRestaurantMenuEmpty
            ? {
                activeMenuAndSectionInfoApproved: {
                  activeMenuId: firstMenu?.menu_id,
                  activeMenuName: firstMenu?.menu_name,
                  activeMenuSectionId: firstMenuSection?.section_id,
                  activeMenuSectionName: firstMenuSection?.menu_section_name,
                },
                activeMenuSectionItemsApproved: itemsFromMenuSection,
              }
            : {}),
        };
      } else {
        return {
          ...state,
          restaurantMenu: [],
          // restaurantFilteredMenuBySteps: [],
          activeMenuAndSectionInfoApproved: {
            activeMenuId: null,
            activeMenuName: null,
            activeMenuSectionId: null,
            activeMenuSectionName: null,
          },
          // array containing all the items for the active menu and section
          activeMenuSectionItemsApproved: [],
          selectedMenuAndSectionsByStep: [],
          uniqueMenuSectionSteps: [],
          restaurantMenuByStep: [],
        };
      }
    }

    case SET_MENU_SELECTED_STEP: {
      const selectedStep = payload;

      // Find menus based on the selected step
      const selectedMenuAndSections =
        state.restaurantMenuByStep.find((step) => step.menu_step === selectedStep.name)?.menus || [];

      return {
        ...state,
        menuSelectedStep: selectedStep,
        selectedMenuAndSectionsByStep: selectedMenuAndSections,
      };
    }

    case SET_IS_MENU_STEPS_FEATURE_ENABLED:
      return {
        ...state,
        isMenuStepsFeatureEnabled: payload,
      };

    case SET_MENU_DRAWER_OPENED:
      return {
        ...state,
        menuDrawerOpened: payload,
      };

    case SET_MENU_ORDERS_DRAWER_OPENED:
      return {
        ...state,
        menuOrdersDrawerOpened: payload,
      };

    case SET_RESTAURANT_FILTERED_MENU_BY_STEPS: {
      const { menuSectionStep } = payload;

      // Filter the menus and return only the sections with the correlating step
      const filteredMenuBySteps = state.restaurantMenu.filter((menu) => {
        return menu.menu_sections.some((section) => {
          const sectionStep = section.menu_section_step;

          return sectionStep && sectionStep === menuSectionStep;
        });
      });

      return {
        ...state,
        // Update restaurantFilteredMenuBySteps with the new filtered menu
        restaurantFilteredMenuBySteps: filteredMenuBySteps,
      };
    }

    case SET_ACTIVE_MENU_AND_SECTION_AND_ITEMS_INFO_APPROVED: {
      // Note: This Object will always have values as long the menu payload is correctly recieved with proper db props
      // If there is a menu payload we update all values where the first section is selected from the new menu,
      // while when a section payload arrives we only update the section values
      // and update the menu items in both scenarios accordingly to the condition
      let newMenuId = state.activeMenuAndSectionInfoApproved.activeMenuId;
      let newMenuName = state.activeMenuAndSectionInfoApproved.activeMenuName;
      let newMenuSectionId = state.activeMenuAndSectionInfoApproved.activeMenuSectionId;
      let newMenuSectionName = state.activeMenuAndSectionInfoApproved.activeMenuSectionName;
      let newMenuSectionItems = state.activeMenuSectionItemsApproved;
      let restaurantMenu = state.restaurantMenu;
      // let restaurantMenu = state.selectedMenuAndSectionsByStep ?? state.restaurantMenu

      if (payload.menuId) {
        const activeMenu = restaurantMenu.find((menu) => menu.menu_id === payload.menuId);

        if (!!payload.isMenuStepsFeatureEnabled && payload.menuSelectedStep) {
          const firstSection = activeMenu.menu_sections.find(
            (section) => section.menu_section_step.name === payload.menuSelectedStep.name,
          );
          newMenuSectionId = firstSection.section_id;
          newMenuSectionName = firstSection.menu_section_name;
          newMenuSectionItems = firstSection.menu_items;
        } else {
          if (activeMenu && activeMenu.menu_sections && activeMenu.menu_sections.length > 0) {
            // Get the first section from the menu_sections array
            const [firstSection] = activeMenu.menu_sections;
            newMenuSectionId = firstSection.section_id;
            newMenuSectionName = firstSection.menu_section_name;
            newMenuSectionItems = firstSection.menu_items;
          }
        }
      }

      if (payload.sectionId) {
        const activeMenu = restaurantMenu.find(
          (menu) => menu.menu_id === state.activeMenuAndSectionInfoApproved.activeMenuId,
        );
        if (activeMenu && activeMenu.menu_sections && activeMenu.menu_sections.length > 0) {
          const activeSection = activeMenu.menu_sections.find((section) => section.section_id === payload.sectionId);
          if (activeSection) {
            newMenuSectionId = activeSection.section_id;
            newMenuSectionName = activeSection.menu_section_name;
            newMenuSectionItems = activeSection.menu_items;
          }
        }
      }
      // If the steps feature is enabled we pass this payload to make these changes on load and every time a step has changed
      // console.log(state.lastRoundOrders, payload.lastRoundOrders)
      if (!isEmpty(payload.selectedMenuAndSectionsByStep)) {
        const [firstMenu] = payload.selectedMenuAndSectionsByStep;
        const [firstSection] = firstMenu.menu_sections;
        newMenuId = firstMenu.menu_id;
        newMenuName = firstMenu.menu_name;
        newMenuSectionId = firstSection.section_id;
        newMenuSectionName = firstSection.menu_section_name;
        newMenuSectionItems = firstSection.menu_items;
      }

      const updatedActiveMenuAndSectionInfo = {
        ...state.activeMenuAndSectionInfoApproved,
        activeMenuId: payload.menuId ?? newMenuId,
        activeMenuName: payload.menuName ?? newMenuName,
        activeMenuSectionId: payload.sectionId ?? newMenuSectionId,
        activeMenuSectionName: payload.menuSectionName ?? newMenuSectionName,
      };

      // const updatedActiveMenuSectionItems = newMenuSectionItems ?? []

      // If an ongoing reservation is present and the last round orders are  recieved in the payload,
      // we implement this logic to merge the menu items with their order information
      // if (payload.lastRoundOrders && !isEmpty(newMenuSectionItems)) {
      //     const menuSectionItemsWithRoundForUser = newMenuSectionItems.map(item => {
      //         const matchingItem = payload.lastRoundOrders.find(orderItem => orderItem.item_id === item.item_id);

      //         // Check if there's a match in lastRoundOrders
      //         if (matchingItem) {
      //             // Create a new object for the item with its properties and add the following ones:
      //             return {
      //                 ...item,
      //                 user_order_round_id: matchingItem.user_order_round_id,
      //                 round_item_id: matchingItem.round_item_id,
      //                 quantity: matchingItem.quantity,
      //                 remaining_quantity: matchingItem.remaining_quantity,
      //                 selected_quantity: matchingItem.selected_quantity,
      //             };
      //         } else {
      //             // If there's no match, remove the order information
      //             const { user_order_round_id, round_item_id, quantity, remaining_quantity, selected_quantity, ...itemWithoutOrderInfo } = item;
      //             return itemWithoutOrderInfo;
      //         }
      //     });

      //     return {
      //         ...state,
      //         activeMenuAndSectionInfoApproved: updatedActiveMenuAndSectionInfo,
      //         activeMenuSectionItemsApproved: menuSectionItemsWithRoundForUser,
      //         lastRoundOrders: payload.lastRoundOrders,
      //     };
      // }

      return {
        ...state,
        activeMenuAndSectionInfoApproved: updatedActiveMenuAndSectionInfo,
        activeMenuSectionItemsApproved: newMenuSectionItems,
      };

      // // If an ongoing reservation is present and the last round orders are received in the payload,
      // // we implement this logic to merge the menu items with their order information
      // if (payload.lastRoundOrders && !isEmpty(state.restaurantMenu)) {
      //     const updatedMenu = state.restaurantMenu.map(menu => {
      //         // Update each menu section and its items
      //         const updatedSections = menu.menu_sections.map(section => {
      //             const updatedItems = section.menu_items.map(item => {
      //                 const matchingItem = payload.lastRoundOrders.find(orderItem => orderItem.item_id === item.item_id);

      //                 // Check if there's a match in lastRoundOrders
      //                 if (matchingItem) {
      //                     // Create a new object for the item with its properties and add the following ones:
      //                     return {
      //                         ...item,
      //                         user_order_round_id: matchingItem.user_order_round_id,
      //                         round_item_id: matchingItem.round_item_id,
      //                         quantity: matchingItem.quantity,
      //                         remaining_quantity: matchingItem.remaining_quantity,
      //                         selected_quantity: matchingItem.selected_quantity,
      //                     };
      //                 } else {
      //                     // If there's no match, return the item as is
      //                     // If there's no match, remove the order information
      //                     const { user_order_round_id, round_item_id, quantity, remaining_quantity, selected_quantity, ...itemWithoutOrderInfo } = item;
      //                     return itemWithoutOrderInfo;
      //                 }
      //             });

      //             return {
      //                 ...section,
      //                 menu_items: updatedItems,
      //             };
      //         });

      //         return {
      //             ...menu,
      //             menu_sections: updatedSections,
      //         };
      //     });

      //     const updatedActiveMenuSectionItems = updatedMenu.find(menu => menu.menu_id === updatedActiveMenuAndSectionInfo.activeMenuId)
      //         ?.menu_sections.find(section => section.section_id === updatedActiveMenuAndSectionInfo.activeMenuSectionId)
      //         ?.menu_items ?? [];

      //     return {
      //         ...state,
      //         activeMenuAndSectionInfoApproved: updatedActiveMenuAndSectionInfo,
      //         activeMenuSectionItemsApproved: updatedActiveMenuSectionItems,
      //         restaurantMenu: updatedMenu,
      //         lastRoundOrders: payload.lastRoundOrders,
      //     };
      // }

      // return {
      //     ...state,
      //     activeMenuAndSectionInfoApproved: updatedActiveMenuAndSectionInfo,
      //     restaurantMenu: restaurantMenu,
      // };
    }

    // case SET_MENU_ITEMS_ORDERS: {
    //     const lastRoundOrders = payload.lastRoundOrders || state.lastRoundOrders;
    //     const activeMenuSectionItemsApproved = payload.activeMenuSectionItemsApproved || state.activeMenuSectionItemsApproved;

    //     const menuItemsOrders = activeMenuSectionItemsApproved.map(item => {
    //         const matchingItem = lastRoundOrders.find(orderItem => orderItem.item_id === item.item_id);

    //         if (matchingItem) {
    //             return {
    //                 ...item,
    //                 user_order_round_id: matchingItem.user_order_round_id,
    //                 round_item_id: matchingItem.round_item_id,
    //                 quantity: matchingItem.quantity,
    //                 remaining_quantity: matchingItem.remaining_quantity,
    //                 selected_quantity: matchingItem.selected_quantity,
    //             };
    //         } else {
    //             const { user_order_round_id, round_item_id, quantity, remaining_quantity, selected_quantity, ...itemWithoutOrderInfo } = item;
    //             return itemWithoutOrderInfo;
    //         }
    //     });

    //     // Check if there are changes to apply
    //     if (
    //         lastRoundOrders !== state.lastRoundOrders ||
    //         activeMenuSectionItemsApproved !== state.activeMenuSectionItemsApproved
    //     ) {
    //         return {
    //             ...state,
    //             activeMenuSectionItemsApproved: menuItemsOrders,
    //             lastRoundOrders: lastRoundOrders,
    //         };
    //     } else {
    //         // No changes, return state unchanged
    //         return state;
    //     }
    // }

    case SET_MENU_ITEM_RECOMMENDATIONS: {
      if (state.restaurantMenu.length === 0 || !payload || payload.length === 0 || !payload?.menuItemRecommendations) {
        // No menu or empty payload, return the current state
        return state;
      }

      // Find menu items based on external_ids in the payload
      const recommendedItems = payload?.menuItemRecommendations.map((externalId) => {
        // Iterate through menus and sections to find the item
        for (const menu of state.restaurantMenu) {
          if (menu.menu_sections && menu.menu_sections.length > 0) {
            for (const section of menu.menu_sections) {
              if (section.menu_items && section.menu_items.length > 0) {
                const matchingItem = section.menu_items.find((item) => item.external_id === externalId);
                if (matchingItem) {
                  // Find if recommended items have been added in the card and update them with the order values
                  if (payload?.lastRoundOrders) {
                    const matchingItemWithRound = payload.lastRoundOrders.find(
                      (item) => item.item_id === matchingItem.item_id,
                    );
                    return {
                      ...matchingItem,
                      is_recommended: true,
                      user_order_round_id: matchingItemWithRound?.user_order_round_id,
                      round_item_id: matchingItemWithRound?.round_item_id,
                      quantity: matchingItemWithRound?.quantity,
                      remaining_quantity: matchingItemWithRound?.remaining_quantity,
                      selected_quantity: matchingItemWithRound?.selected_quantity,
                    };
                  }
                  return {
                    ...matchingItem,
                    is_recommended: true,
                  };
                }
              }
            }
          }
        }
        return null; // Return null for external_ids not found in the menu
      });

      return {
        ...state,
        menuItemRecommendations: recommendedItems
          .sort((a, b) => {
            const aSpecialId = a && typeof a === "object" && "special_id" in a ? a.special_id : null;
            const bSpecialId = b && typeof b === "object" && "special_id" in b ? b.special_id : null;

            if (aSpecialId !== undefined && bSpecialId !== undefined) {
              if (aSpecialId !== null && bSpecialId !== null) {
                return 0;
              } else if (aSpecialId !== null) {
                return -1;
              } else if (bSpecialId !== null) {
                return 1;
              } else {
                return 0;
              }
            } else if (aSpecialId !== undefined) {
              return -1;
            } else if (bSpecialId !== undefined) {
              return 1;
            } else {
              return 0;
            }
          })
          .filter(
            (item) =>
              !isEmpty(item) && !(item.inventory_status === inventoryStatusEnum.OUT_OF_STOCK || item.is_invalid),
          ),
      };
    }
    case SET_ON_MENU_ITEM_RECOMMENDATIONS_MODAL:
      return {
        ...state,
        onMenuItemRecommendationsModal: payload,
      };

    case SET_HAS_INITIAL_MENU_OPENED:
      return {
        ...state,
        hasInitialMenuOpened: payload,
      };

    default:
      return state;
  }
};

export default menu;
