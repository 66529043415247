import ArrowRight from "../../assets/arrow-right.svg";

const ListCheck = ({ displayNumber, date, guid, onClick }) => {
  return (
    <div>
      <div className="list-item-container" onClick={onClick}>
        <div>
          <p className="display-number-text">#{displayNumber}</p>
        </div>
        <div className="list-item-right-column">
          <div className="list-item-date">Date & Time: {date}</div>
          <div className="list-item-guid">Guid: {guid}</div>
        </div>
        <div className="list-item-arrow">
          <img src={ArrowRight} alt="arrow-right" />
        </div>
      </div>
    </div>
  );
};

export default ListCheck;
