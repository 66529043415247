import authentication from "../../../config/authentication";
import ExternalLoginButton from "../../../components/auth/ExternalLoginButton";
import AppleImg from "../../../assets/appple.png";
import React from "react";
import { loginWithApple } from "../services/actions";
import { useDispatch, useSelector } from "react-redux";
import AppleLoginLib from "react-apple-login";
import { setToastMessage } from "../../../store/actions/feedbackActions";

function AppleLogin() {
  const {
    booking: { invitationToken },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const appleLoginCallback = (response) => {
    const { authorization, error } = response;
    if (error) {
      const message = transformAppleErrorMessage(error.error);
      message && dispatch(setToastMessage({ message, severity: "error" }));
      return;
    }
    if (authorization) {
      dispatch(loginWithApple(authorization.code, invitationToken));
    }
  };

  return (
    <AppleLoginLib
      {...authentication.apple}
      render={(props) => <ExternalLoginButton onClick={props.onClick} img={AppleImg} />}
      callback={appleLoginCallback}
    />
  );
}

export default AppleLogin;

function transformAppleErrorMessage(error = "") {
  switch (error) {
    case "popup_closed_by_user":
      return null;
    default:
      return error.replace(/_/g, " ");
  }
}
