import Image403 from "../../../assets/403.svg";
import Image404 from "../../../assets/404.svg";
import Image500 from "../../../assets/500.svg";
import StatusLayout from "./StatusLayout";

function StatusMessage({ status, statusText, message }) {
  let image;

  switch (status) {
    case 403:
      image = Image403;
      break;
    case 404:
      image = Image404;
      break;
    case 500:
      image = Image500;
      break;
    default:
      image = null;
  }

  return <StatusLayout status={status} statusText={statusText} message={message} image={image} />;
}

export default StatusMessage;
