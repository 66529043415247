import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalHeading from "../../../components/common/ModalHeading";
import Modal from "../../../components/modal/Modal";
import { password_reset_request_modal } from "../../../config/modal_paths";
import Column from "../../../containers/layout/Column";
import Row from "../../../containers/layout/Row";
import { setActiveModal, setResponseMessage } from "../../../store/actions/feedbackActions";
import { passwordResetRequest } from "../services/actions";

function PasswordResetRequestSent({ show, close }) {
  const dispatch = useDispatch();
  const {
    // errors,
    feedback: { responseMessage, activeModalMetadata },
  } = useSelector((state) => state);

  const [canResendEmail, setCanResendEmail] = useState(true);

  const handleResendMail = () => {
    if (activeModalMetadata) {
      dispatch(passwordResetRequest(activeModalMetadata?.email));
    }
  };

  const handleChangeMail = () => {
    dispatch(setActiveModal(password_reset_request_modal));
  };

  useEffect(() => {
    if (responseMessage) {
      setCanResendEmail(false);
      dispatch(setResponseMessage(""));
    }
  }, [dispatch, responseMessage]);

  return (
    <Modal className="password-request-sent-modal" isPopUp show={show} close={close}>
      <ModalHeading
        className="password-request-sent-modal-header"
        onClick={close}
        // title="Log-in"
      />
      <Row>
        <Column className="text-center align-items-center m-4" md={12} lg={12}>
          <div className="password-request-sent-heading">
            <h3>Sent. Check your inbox.</h3>
          </div>
        </Column>
        <Column className="text-center" md={12} lg={12}>
          <p className="password-request-sent-p">
            Follow the instructions in the e-mail we sent to <b>{activeModalMetadata.email}</b> and you’ll be able to
            book and order again in no time.
          </p>
        </Column>
        <Column className="text-center align-items-center m-2" md={12} lg={12}>
          <button
            disabled={!canResendEmail}
            className="btn btn-primary  white-rounded-button"
            onClick={handleResendMail}
            style={{ width: "80%" }}
          >
            Resend Email
          </button>
        </Column>
        <Column className="text-center align-items-center m-2" md={12} lg={12}>
          <button className="btn btn-primary  white-rounded-button" style={{ width: "80%" }} onClick={handleChangeMail}>
            Change Email
          </button>
        </Column>
      </Row>
    </Modal>
  );
}

export default PasswordResetRequestSent;
