import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import queryParamsEnum from "../../enums/queryParamsEnum";
import useQuery from "../../hooks/useQuery";
import localStorageHelper from "../../utils/localStorageHelper";

const MenuSectionsStepper = ({ menuDrawerOpened }) => {
  const {
    menu: { uniqueMenuSectionSteps },
  } = useSelector((state) => state);
  const theme = useTheme();
  // const dispatch = useDispatch()
  const [activeStep, setActiveStep] = useState(0);

  const { getQueryParam, setQueryParam } = useQuery();
  const queryParam = getQueryParam(queryParamsEnum.step);
  // const queryParamStorage = localStorageHelper.getItem(queryParamsEnum.step);

  // const [queryParamState, setQueryParamState] = useState('')

  useEffect(() => {
    if (uniqueMenuSectionSteps && queryParam) {
      const foundIdx = uniqueMenuSectionSteps.findIndex((step) => step.name === queryParam);
      if (foundIdx !== -1) {
        setActiveStep(foundIdx);
      }
    }
  }, [uniqueMenuSectionSteps, queryParam]);

  const handleNext = () => {
    const nextStep = (activeStep + 1) % uniqueMenuSectionSteps.length;
    setActiveStep(nextStep);
    setQueryParam(queryParamsEnum.step, uniqueMenuSectionSteps[nextStep]?.name);
    localStorageHelper.saveItem(queryParamsEnum.step, uniqueMenuSectionSteps[nextStep]?.name);
    // updateSectionAndItemsBasedOnStep()
    // dispatch(setMenuSelectedStep(uniqueMenuSectionSteps[nextStep]))
  };

  const handleBack = () => {
    const prevStep = (activeStep - 1 + uniqueMenuSectionSteps.length) % uniqueMenuSectionSteps.length;
    setActiveStep(prevStep);
    setQueryParam(queryParamsEnum.step, uniqueMenuSectionSteps[prevStep]?.name);
    localStorageHelper.saveItem(queryParamsEnum.step, uniqueMenuSectionSteps[prevStep]?.name);
    // updateSectionAndItemsBasedOnStep()
    // dispatch(setMenuSelectedStep(uniqueMenuSectionSteps[prevStep]))
  };

  return uniqueMenuSectionSteps.length !== 0 ? (
    <div
      style={{
        color: "inherit",
        width: "100%",
        position: "relative",
        // border: `${colorsEnum.navyBlue} 1px solid`, borderRadius:"2rem"
      }}
    >
      <MobileStepper
        variant="dots"
        steps={uniqueMenuSectionSteps.length}
        position="static"
        color="#fff"
        activeStep={activeStep}
        sx={(theme) => ({
          maxWidth: 400,
          maxHeight: 50,
          flexGrow: 1,
          backgroundColor: "inherit",
          color: "#fff",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          "& .MuiMobileStepper-dot": {
            margin: "auto 0.25rem 2rem",
            border: "1px solid #fff",
          },
          "& .MuiMobileStepper-dotActive": {
            backgroundColor: "#fff",
          },
        })}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            sx={{
              color: "#fff",
              border: "none!important",
              "&:focus": {
                outline: "none !important",
              },
            }}
          >
            Next
            {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            sx={{
              color: "#fff",
              border: "none!important",
              "&:focus": {
                outline: "none !important",
              },
            }}
          >
            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
      <Typography
        sx={{
          textAlign: "center",
          fontFamily: "Cormorant",
          fontSize: "1.5rem",
          color: "#fff",
          position: "absolute",
          bottom: "0%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        {uniqueMenuSectionSteps[activeStep]?.name}
      </Typography>
    </div>
  ) : null;
};

export default MenuSectionsStepper;
