import classNames from "classnames";
import { round } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { userJustPayStepEnum } from "../../../enums/userJustPayStepEnum";
import { useCallback } from "react";
import { useParams } from "react-router";
import { addPaymentToCheck, getReceipt, setCheck } from "../../../store/actions/justPayActions";
import SpreedlyCheckoutVault from "../../payment/spreedly/SpreedlyCheckoutVault";
import useFloatingIslandActions from "../../../hooks/useFloatingIslandActions";
import CustomPieChart from "../CustomPieChart";

const CheckoutPaymentPreview = ({
  entity,
  checkout,
  onOpenCheckSplitModalHandler,
  onOpenPaymentModalHandler,
  onOpenPaymentTipModalHandler,
  alreadySplittedCheck,
  actionButtons,
}) => {
  const {
    justPay: {
      check: { split, paymentPercentage, paymentAmount, paymentTipPercentage, paymentTipAmount, userJustPayStep },
    },
  } = useSelector((state) => state);

  const { restaurantGuid, tableGuid, orderGuid, checkGuid } = useParams();

  const dispatch = useDispatch();

  const balanceDuePtc = round((checkout?.balanceDue / checkout?.totalAmount) * 100, 2);
  const settledPtc = round(100 - balanceDuePtc, 2);

  const onConfirmPaymentHandler = useCallback(
    async ({ spreedlyInformation }) => {
      checkGuid &&
        dispatch(
          addPaymentToCheck(
            restaurantGuid,
            tableGuid,
            orderGuid,
            checkGuid,
            spreedlyInformation,
            split,
            paymentAmount,
            paymentTipAmount,
          ),
        );
    },
    [dispatch, restaurantGuid, tableGuid, orderGuid, checkGuid, split, paymentAmount, paymentTipAmount],
  );

  const onPayWholeCheckHandler = () => {
    dispatch(
      setCheck({
        paymentPercentage: actionButtons[actionButtons.length - 1].pct,
        paymentAmount: actionButtons[actionButtons.length - 1].amount,
        userJustPayStep: userJustPayStepEnum.toBeFullyPaid,
      }),
    );
  };

  const handleGenerateAndDownloadReceipt = async () => {
    const receiptDownloadUrl = await dispatch(getReceipt(restaurantGuid, orderGuid, checkGuid));

    if (receiptDownloadUrl) {
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = receiptDownloadUrl;

      link.setAttribute("download", `Voila-Receipt-${checkGuid}.pdf`);

      // Append link to the body
      document.body.appendChild(link);

      // Trigger download
      link.click();

      // Remove the link element
      document.body.removeChild(link);

      // Optionally revoke the object URL if not needed for further use
      window.URL.revokeObjectURL(receiptDownloadUrl);
    }
  };

  const {
    DOMslot1,
    DOMslot2,
    DOMslot3,
    createButtonPortal,
    customLabel,
    payWholeCheck,
    splitCheck,
  } = useFloatingIslandActions();

  return (
    <>
      <p className="checkout-info-heading mb-2 mt-4">Payment Info</p>

      <div className="mb-2">
        {settledPtc === 100
          ? "The check is paid, thank you for dining with us"
          : `Currently ${settledPtc}% of the check has been settled`}
      </div>

      {userJustPayStep === userJustPayStepEnum.beforeSplit && (
        <>
          <p>Would you like to split the check?</p>

          {createButtonPortal(payWholeCheck(onPayWholeCheckHandler), DOMslot1)}
          {createButtonPortal(splitCheck(onOpenCheckSplitModalHandler), DOMslot2)}
        </>
      )}

      {userJustPayStep === userJustPayStepEnum.toBeFullyPaid && (
        <>
          <p>You are about to pay the whole check</p>

          {!alreadySplittedCheck && createButtonPortal(splitCheck(onOpenCheckSplitModalHandler), DOMslot2)}
        </>
      )}

      {userJustPayStep === userJustPayStepEnum.toBePartlyPaid && (
        <>
          <div className="d-flex justify-content-between mb-2">
            <span></span>
            <span>
              The check is divided on {split} equal parts
              {!alreadySplittedCheck && (
                <button className={classNames("editTip-button")} onClick={onOpenCheckSplitModalHandler}>
                  Change Split
                </button>
              )}
            </span>
            <span></span>
          </div>

          <p>{`You are about to pay ${paymentPercentage}% of the check`}</p>

          {paymentPercentage !== actionButtons[actionButtons.length - 1].pct &&
            createButtonPortal(payWholeCheck(onPayWholeCheckHandler), DOMslot2)}
        </>
      )}

      {userJustPayStep === userJustPayStepEnum.toBeFullyPaid ||
      userJustPayStep === userJustPayStepEnum.toBePartlyPaid ? (
        <>
          <div className="checkout-payment-preview-container mt-4">
            {/* <div style={{ marginBottom: "0.5rem" }} className="d-flex justify-content-between">
            <span>
              Check Split:
              <button
                className={classNames("editTip-button", alreadySplittedCheck && "disabled-change-button")}
                onClick={onOpenCheckSplitModalHandler}
                disabled={alreadySplittedCheck}
              >
                Change
              </button>
            </span>
            <span className={"totalTip"}>{split}</span>
          </div> */}

            <div style={{ marginBottom: "0.5rem" }} className="d-flex justify-content-between">
              <span>
                Payment Amount {paymentPercentage}%:{" "}
                <button
                  className={classNames(
                    "editTip-button",

                    (!(alreadySplittedCheck || split) || actionButtons.length <= 1) && "disabled-change-button",
                  )}
                  onClick={onOpenPaymentModalHandler}
                  disabled={!(alreadySplittedCheck || split) || actionButtons.length <= 1}
                >
                  Change
                </button>
              </span>
              <span className={"totalTip"}>${paymentAmount}</span>
            </div>
            <div style={{ marginBottom: "0.5rem" }} className="d-flex justify-content-between">
              <span>
                Payment Tip {paymentTipPercentage}%:{" "}
                <button className={"editTip-button"} onClick={onOpenPaymentTipModalHandler}>
                  Change
                </button>
              </span>
              <span className={"totalTip"}>${paymentTipAmount}</span>
            </div>
            <div style={{ marginBottom: "0.5rem" }} className="d-flex justify-content-between">
              <span>
                <strong>Payment Total:</strong>
              </span>
              <span className={"totalTip"}>${round(paymentAmount + paymentTipAmount, 2)}</span>
            </div>
          </div>

          <div className="pie-chart-container">
            <CustomPieChart data={entity} type={entity.entityType} key={entity.guid} />
          </div>

          {checkout?.balanceDue > 0 && (
            <SpreedlyCheckoutVault onCompleteOrderClick={onConfirmPaymentHandler} isEditMode={false} />
          )}
        </>
      ) : (
        <div className="pie-chart-container">
          <CustomPieChart data={entity} type={entity.entityType} key={entity.guid} />
        </div>
      )}

      {entity?.payments.length > 0 && (
        <>
          {createButtonPortal(
            customLabel(handleGenerateAndDownloadReceipt, false, "Download receipt"),
            checkout?.balanceDue > 0 ? DOMslot3 : DOMslot1,
          )}
        </>
      )}
    </>
  );
};

export default CheckoutPaymentPreview;
