import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { createReservation } from "./services/actions";
import ReturnToReservationModal from "../reservation/ReturnToReservationModal";
import isEmpty from "lodash.isempty";
import { home_path } from "../../config/pages_paths";
import errorMessages from "../../components/messages/errorMessages";

const CreateOrder = () => {
  const {
    booking: { currentReservation },
    orders: { newOrderDetails },
  } = useSelector((state) => state);
  const history = useHistory();
  const dispatch = useDispatch();
  const { restaurantId, tableNumber, orderId } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!orderId) {
      if (restaurantId && tableNumber) {
        dispatch(createReservation({ restaurantId: +restaurantId, tableNumber }, null, history)).then(() => {
          setIsLoaded(true);
        });
      } else {
        dispatch(createReservation(newOrderDetails, null, history)).then(() => {
          setIsLoaded(true);
        });
      }
    }
  }, [dispatch, history, newOrderDetails, orderId, tableNumber, restaurantId]);

  return isLoaded && isEmpty(currentReservation) ? (
    <ReturnToReservationModal
      show={isLoaded && isEmpty(currentReservation)}
      close={() => history.push(home_path)}
      title={"Something went wrong!"}
      description={errorMessages.createReservationError}
    />
  ) : null;
};

export default CreateOrder;
