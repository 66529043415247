import isEmpty from "lodash.isempty";
import {
  SET_ANCHOR_MENU,
  SET_CONNECT_SOCKET_AFTER_IDLE_TIMEOUT,
  SET_CURRENT_ACCORDION,
  SET_CURRENT_ACCORDION_ORDER,
  SET_EXPIRATION_TIMER_MODAL,
  SET_IS_FOR_WHOM_TO_PAY_CONFIRMED,
  SET_IS_TABLE_VALID,
  SET_MENU_ITEM_QUANTITY_CHANGED_MODAL,
  SET_MODAL,
  SET_NUM_OF_LOADING_SPINNER_CALLS,
  SET_PAGE_ROUTE_PATH,
  SET_REFRESH_DATA_FOR_OPENED_MENU_ITEM,
  SET_REGISTER_REDIRECT_RESPONSE,
  SET_RESPONSE_MESSAGE,
  SET_TIMER_EXPIRED_MESSAGE_MODAL,
  SET_TOAST_MESSAGE,
  SET_ON_FULL_PAGE_MODAL,
} from "../../config/actionTypes";

const initialState = {
  numOfLoadingSpinnerCalls: 0,
  activeModal: "",
  pageRoute: "",
  toast: { show: false, timer: 0, message: "", autoHide: true, unrecreatedExternalItems: [], showCloseIcon: false },
  expirationTimerModal: { show: false, text: "", paragraph: "" },
  timerExpiredMessageModal: { show: false, text: "", paragraph: "" },
  activeModalMetadata: {},
  isTableValid: false,
  currentAccordionIdx: -1,
  currentAccordionOrder: -1,
  showMenuItemQuantityChangedModal: {
    show: false,
    message: "",
    extraButton: {
      name: "",
      functionality: null,
    },
  },
  isForWhomToPayConfirmed: false,
  orderId: null,
  anchorMenu: false,
  connectSocketAfterIdleTimeout: false,
  refreshDataForOpenedMenuItem: { itemId: null, refresh: false },
  onFullPageModal: false,
};

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESPONSE_MESSAGE:
      return {
        ...state,
        responseMessage: action.payload,
      };
    case SET_NUM_OF_LOADING_SPINNER_CALLS:
      return {
        ...state,
        numOfLoadingSpinnerCalls: action.payload
          ? state.numOfLoadingSpinnerCalls + 1
          : state.numOfLoadingSpinnerCalls - 1,
      };
    case SET_MODAL:
      return {
        ...state,
        activeModal: action.payload,
        activeModalMetadata: action.metadata || state.activeModalMetadata,
        orderId: action.orderId,
      };
    case SET_ANCHOR_MENU:
      return { ...state, anchorMenu: action.payload };
    case SET_PAGE_ROUTE_PATH:
      return { ...state, pageRoute: action.payload };
    case SET_REGISTER_REDIRECT_RESPONSE:
      return { ...state, ...action.payload };
    case SET_TOAST_MESSAGE:
      if (action.payload?.type === "Body") {
        console.log("here");
        return {
          ...state,
          toast: {
            show: !isEmpty(action.payload),
            message: action.payload?.errors?.issues.map((error) => error.message),
          },
        };
      }
      return {
        ...state,
        toast: {
          show: !isEmpty(action.payload),
          ...action.payload,
        },
      };
    case SET_IS_TABLE_VALID:
      return {
        ...state,
        isTableValid: action.payload,
      };
    case SET_CURRENT_ACCORDION:
      return {
        ...state,
        currentAccordionIdx: action.payload,
      };
    case SET_CURRENT_ACCORDION_ORDER:
      return {
        ...state,
        currentAccordionOrder: action.payload,
      };
    case SET_EXPIRATION_TIMER_MODAL:
      return {
        ...state,
        expirationTimerModal: action.payload,
      };
    case SET_TIMER_EXPIRED_MESSAGE_MODAL:
      return {
        ...state,
        timerExpiredMessageModal: action.payload,
      };
    case SET_MENU_ITEM_QUANTITY_CHANGED_MODAL:
      return {
        ...state,
        showMenuItemQuantityChangedModal: { show: action.payload, ...action.payload },
      };
    case SET_IS_FOR_WHOM_TO_PAY_CONFIRMED:
      return {
        ...state,
        isForWhomToPayConfirmed: action.payload,
      };
    case SET_CONNECT_SOCKET_AFTER_IDLE_TIMEOUT:
      return {
        ...state,
        connectSocketAfterIdleTimeout: action.payload,
      };
    case SET_REFRESH_DATA_FOR_OPENED_MENU_ITEM:
      return {
        ...state,
        refreshDataForOpenedMenuItem: action.payload,
      };
    case SET_ON_FULL_PAGE_MODAL:
      return {
        ...state,
        onFullPageModal: action.payload,
      };
    default:
      return { ...state, responseMessage: null };
  }
};

export default feedbackReducer;
