import React, { useCallback } from "react";
import CheckLabel from "../../CheckLabel";
import { groupPaymentsByCardAndCash } from "../../../../utils/groupPaymentsByCardAndCash";
import { CollapsibleBox } from "../../CollapsibleBox";

const PaymentsSection = ({ entity }) => {
  const { entityType, checkout } = entity;

  const paymentsData = useCallback(() => {
    return groupPaymentsByCardAndCash(entity, entityType);
  }, [entity, entityType]);

  const renderPayments = useCallback(
    (paymentsData) => (
      <>
        {Object.entries(paymentsData.credit).map(([key, amount]) => (
          <CheckLabel
            key={key}
            label={`CREDIT, ${key}`}
            amount={paymentsData.cash > 0 ? amount : Object.entries(paymentsData.credit).length > 1 ? amount : null}
            divClassName="check-label-secondary padding-zero"
          />
        ))}
        {paymentsData.cash > 0 && (
          <CheckLabel
            label="CASH"
            amount={Object.entries(paymentsData.credit).length >= 1 ? paymentsData.cash : null}
            divClassName="check-label-secondary padding-zero"
          />
        )}
      </>
    ),
    [],
  );

  const payments = paymentsData();

  return (
    <>
      {checkout?.totalAmount !== checkout?.paymentsAmount && (
        <CheckLabel label={<strong>Total:</strong>} amount={entity?.checkout?.totalAmount} />
      )}

      <CollapsibleBox
        children={
          checkout?.paymentsAmount > 0 ? (
            renderPayments(payments)
          ) : (
            <CheckLabel label="No previous payments" divClassName="check-label-no-payments padding-zero" />
          )
        }
        heading={checkout?.balanceDue > 0 ? "Payments:" : "Total (payments):"}
        boldHeading={checkout?.totalAmount === checkout?.paymentsAmount}
        headingAmount={checkout?.paymentsAmount}
        amountInMinus={checkout?.balanceDue > 0}
      />

      {checkout?.balanceDue > 0 ? (
        <CheckLabel label={<strong>Balance due:</strong>} amount={checkout?.balanceDue} />
      ) : (
        <>
          <CheckLabel label="Tipped:" amount={entity?.checkout?.tipsAmount} />
          <CheckLabel label={<strong>Grand total:</strong>} amount={entity?.checkout?.grandTotalAmount} />
        </>
      )}
    </>
  );
};

export default PaymentsSection;
