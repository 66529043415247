import { Radio } from "@mui/material";
import { card_types_enum } from "../enums";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import { ReactComponent as TrashIcon } from "../../../../assets/trash-icon.svg";
import React from "react";

const SingleCreditCard = ({
  idx,
  selectedCard,
  onSelectCard,
  onEdit,
  onDeleteCardConfirmation,
  editMode,
  cardData,
  onDeleteCard,
  onCancelDeleteCard,
  isDeleteMode,
}) => {
  const isCurrentCard = () => {
    return idx === selectedCard;
  };

  return (
    <React.Fragment>
      <div className="spreedly-present-cards-card-wrapper">
        {editMode ? (
          <Radio color="default" checked={isCurrentCard()} onClick={() => onSelectCard(idx)} value={idx} />
        ) : null}
        <img className="spreedly-present-cards-logo" alt="cc-card-type" src={card_types_enum[cardData.cardType]} />
        <div className="spreedly-present-cards-meta-info-container">
          <p className="spreedly-present-cards-card-type">{capitalizeFirstLetter(cardData.cardType)}</p>
          <p className="spreedly-present-cards-last-digits">************{cardData.last4Digits}</p>
        </div>
        {!editMode ? (
          <p className="spreedly-checkout-vault-meta-edit" onClick={onEdit}>
            Edit card
          </p>
        ) : isDeleteMode && isCurrentCard() ? null : (
          <div
            className="spreedly-present-cards-delete-card-active paragraph-hover"
            onClick={() => onDeleteCardConfirmation(idx)}
          >
            <TrashIcon fill="#000000" style={{ height: "20px", marginTop: "10px" }} />
          </div>
        )}
        <hr />
      </div>
      {isDeleteMode && isCurrentCard() ? (
        <div className="spreedly-present-cards-delete-container">
          <p>Are you sure you want to delete this card?</p>
          <div className="spreedly-present-cards-delete-buttons">
            <button className="btn spreedly-present-cards-delete-positive-btn" onClick={onDeleteCard}>
              Yes
            </button>
            <button className="btn spreedly-present-cards-delete-negative-btn" onClick={onCancelDeleteCard}>
              No
            </button>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default SingleCreditCard;
