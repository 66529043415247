import classnames from "classnames";
import isEmpty from "lodash.isempty";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { register_success_modal } from "../../../config/modal_paths";
// import useUserDetails from '../../../hooks/useUserDetails'
import { bookTable, setTimesAvailable } from "../../../pages/book/services/actions";
import { setActiveModal, setOnFullPageModal } from "../../../store/actions/feedbackActions";
import { timeToAMPM } from "../../../utils/timeToAMPM";
import CustomButton from "../../common/CustomButton";
import ModalHeading from "../../common/ModalHeading";
import SeparationLine from "../../common/SeparationLine";
import Modal from "../../modal/Modal";
import EmailMarketingOpt from "./EmailMarketingOpt";
import PoweredByOpenTable from "./PoweredByOpenTable";
import TermsOfUse from "./TermsOfUse";
import useFloatingIslandActions from "../../../hooks/useFloatingIslandActions";

function AvailableTimesModal({ close, show }) {
  const { restaurantId } = useParams();
  const [emailMarketingOpt, setEmailMarketingOpt] = useState(true);
  const [specialRequest, setSpecialRequest] = useState("");
  const [dateAndType, setDateAndType] = useState({ date: "", tableType: "" });
  const dispatch = useDispatch();
  const {
    booking: { timesAvailable, bookingData, currentOrder },
    user: { user: userDetails },
  } = useSelector((state) => state);

  // const { hasAllFields, onMissingFields } = useUserDetails({
  //   requireAllFields: true
  // })

  // const onMissingFields = () => {
  //   dispatch(setActiveModal(!user.is_anonymous ? login_modal : update_user_modal))
  // }

  const onBookClickHandler = () => {
    isEmpty(userDetails) || userDetails.is_anonymous
      ? dispatch(setActiveModal(register_success_modal))
      : dispatch(
          bookTable({
            date: dateAndType.date,
            sizeOfParty: bookingData.sizeOfParty,
            specialRequest,
            emailMarketingOpt,
            tableType: dateAndType.tableType,
            reservationIsTipEnabled: currentOrder?.reservation_is_tip_enabled ?? false,
            restaurantId: bookingData.restaurantId ?? restaurantId,
          }),
        );
  };

  // const handleGoBack =() => {
  //   dispatch(setActiveModal(register_success_modal))
  // }

  const onOffersSubscribeSwitchChange = () => {
    setEmailMarketingOpt((val) => !val);
  };

  const onSpecialRequestChange = (e) => {
    e && e.persist();
    setSpecialRequest(e.target.value);
  };

  const onSetDateAndType = ({ date, tableType }) => {
    setDateAndType({ date, tableType });
  };

  useEffect(() => {
    if (!isEmpty(timesAvailable)) {
      timesAvailable.forEach((type) => {
        type.times.forEach((time) => {
          if (time.selected) {
            setDateAndType({ date: time.time, tableType: type.label });
          }
        });
      });
    }
  }, [timesAvailable]);

  //#region FloatingIsland

  useEffect(() => {
    dispatch(setOnFullPageModal(true));
    return () => dispatch(setOnFullPageModal(false));
  }, [dispatch]);

  const { createButtonPortal, confirm, cancel, DOMslot1, DOMslot2 } = useFloatingIslandActions();

  //#endregion

  return (
    <Modal className="times-available-modal" close={close} show={show}>
      <ModalHeading onClick={close} title="Available times" />
      <p>Below you can see the closest available times and additional options related to your booking.</p>
      <ChooseAvailableTime timesAvailable={timesAvailable} setDateAndType={onSetDateAndType} />
      <textarea
        onChange={onSpecialRequestChange}
        name="specialRequest"
        style={{
          width: "100%",
          marginTop: "20px",
          resize: "none",
          height: "80px",
          borderRadius: "1rem",
          padding: "0.25rem 0.5rem",
        }}
        placeholder="Special requests (optional)"
      />
      {/* <CustomButton
        style={{ marginBottom: '15px' }}
        wrapperClassName="booking-submit-btn active mx-auto"
        textClassName="booking-submit-btn-text active"
        text="Back"
        onClick={
          // hasAllFields ?
            handleGoBack
            // : () => onMissingFields()
        }
      /> */}
      <EmailMarketingOpt onChange={onOffersSubscribeSwitchChange} checked={emailMarketingOpt} />

      {/* <CustomButton
        style={{ marginBottom: '15px' }}
        disabled={isEmpty(dateAndType.date)}
        wrapperClassName="booking-submit-btn active mx-auto"
        textClassName="booking-submit-btn-text active"
        text="Book the table"
        onClick={
          // hasAllFields ?
            () => onBookClickHandler()
            // : () => onMissingFields()
        }
      /> */}

      {createButtonPortal(confirm(onBookClickHandler, isEmpty(dateAndType.date), "booking", true), DOMslot1)}
      {createButtonPortal(cancel(close, null, true), DOMslot2)}

      <PoweredByOpenTable />
      <TermsOfUse />
      <div style={{ height: "10px" }} />
    </Modal>
  );
}

function ChooseAvailableTime({ timesAvailable = [], setDateAndType }) {
  const dispatch = useDispatch();

  const onChooseDate = (date, typeIndex = 0, timeIndex) => {
    const updateTimes = JSON.parse(JSON.stringify(timesAvailable));
    updateTimes.forEach((type, _typeIndex) => {
      type.times.forEach((time, idx) => {
        time.selected = +typeIndex === _typeIndex && idx === +timeIndex;
      });
    });

    setDateAndType({ date, tableType: updateTimes[typeIndex].label });
    dispatch(setTimesAvailable(updateTimes));
  };

  return timesAvailable.map((value, typeIndex) => {
    return (
      <div className="times-available-type-container" key={typeIndex}>
        <h2>{value.label}</h2>
        <div className="times-available-container">
          {value.times.map((time, idx) => {
            return (
              <CustomButton
                key={idx}
                wrapperClassName={classnames("booking-btn mt-auto", {
                  active: time.selected,
                })}
                textClassName={classnames("booking-btn-text", {
                  active: time.selected,
                })}
                onClick={() => onChooseDate(time.time, typeIndex, idx)}
                text={timeToAMPM(time.time)}
              />
            );
          })}
        </div>
        <SeparationLine width={"100%"} />
      </div>
    );
  });
}

export default AvailableTimesModal;
