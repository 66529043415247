import { Radio } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { credit_card_information_path } from "../../../../config/pages_paths";
import { deletePaymentMethodForUser } from "../../../../pages/order/services/actions";
import SingleCreditCard from "./SingleCreditCard";
import useFloatingIslandActions from "../../../../hooks/useFloatingIslandActions";

const PresentCardsInVault = ({
  paymentMethods,
  setCardTypeForBackend,
  setSpreedlyToken,
  setLast4Digits,
  editMode,
  isAddNewMethod,
  setIsAddNewMethod,
  setShowAddNewCard,
  onEdit,
  onAddNewPaymentMethod,
  selectedCard,
  setSelectedCard,
  activePaymentMethodIndex,
  onSaveActiveCard,
}) => {
  const dispatch = useDispatch();
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [isCreditCardInformationPath, setIsCreditCardInformationPath] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (!editMode && paymentMethods.length > 0 && selectedCard !== -1) {
      onCancelDeleteCard();
      setPaymentMethodList([paymentMethods[selectedCard]]);
    } else {
      setPaymentMethodList(paymentMethods);
    }
  }, [editMode, paymentMethods, selectedCard]);

  useEffect(() => {
    !isAddNewMethod ? setSelectedCard(0) : setSelectedCard(-1);
  }, [isAddNewMethod, setSelectedCard]);

  useEffect(() => {
    setSelectedCard(activePaymentMethodIndex);
    setIsCreditCardInformationPath(location.pathname === credit_card_information_path);
  }, [location, activePaymentMethodIndex, setSelectedCard]);

  useEffect(() => {
    if (selectedCard !== -1) {
      setCardTypeForBackend(paymentMethods[selectedCard].cardType);
      setSpreedlyToken(paymentMethods[selectedCard].token);
      setLast4Digits(paymentMethods[selectedCard].last4Digits.toString());
    }
  }, [selectedCard, paymentMethods, setCardTypeForBackend, setSpreedlyToken, setLast4Digits]);

  const onSelectCard = (index) => {
    setSelectedCard(index);
    setIsAddNewMethod(false);
    setShowAddNewCard(false);
  };

  const onDeleteCardConfirmation = (idx) => {
    onSelectCard(idx);
    setIsDeleteMode(true);
  };

  const onDeleteCard = () => {
    paymentMethods.length > 0 ? setSelectedCard(0) : setSelectedCard(-1);
    onCancelDeleteCard();
    dispatch(deletePaymentMethodForUser(paymentMethods[selectedCard].token));
  };

  const onCancelDeleteCard = () => {
    setIsDeleteMode(false);
  };

  const { DOMslot1, DOMslot2, createButtonPortal, save, backToOrderShortcut } = useFloatingIslandActions();

  return (
    <div className="spreedly-present-cards-container">
      {paymentMethodList.map((cardData, idx) => {
        return (
          <SingleCreditCard
            key={idx}
            idx={idx}
            cardData={cardData}
            editMode={editMode}
            onEdit={onEdit}
            onSelectCard={onSelectCard}
            selectedCard={selectedCard}
            onDeleteCardConfirmation={onDeleteCardConfirmation}
            onDeleteCard={onDeleteCard}
            onCancelDeleteCard={onCancelDeleteCard}
            isDeleteMode={isDeleteMode}
            onAddNewPaymentMethod={onAddNewPaymentMethod}
            activePaymentMethodIndex={activePaymentMethodIndex}
          />
        );
      })}
      {editMode ? (
        <div>
          <div className="spreedly-present-cards-card-wrapper">
            <Radio
              color="default"
              checked={selectedCard === -1}
              onClick={() => onAddNewPaymentMethod()}
              value={selectedCard}
            />
            <span>Use new card to checkout</span>
          </div>
          {isCreditCardInformationPath && !isAddNewMethod && (
            <div className="submit-button">
              <div className="submit-btn-primary">
                {/* <button
                                    disabled={activePaymentMethodIndex === selectedCard}
                                    className="btn btn-primary auto-width"
                                    onClick={() => onSaveActiveCard(true)}>
                                        Save default card
                                </button> */}

                {createButtonPortal(
                  save(() => onSaveActiveCard(true), activePaymentMethodIndex === selectedCard, "default card"),
                  DOMslot1,
                )}
                {backToOrderShortcut(DOMslot2)}
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default PresentCardsInVault;
