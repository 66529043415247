import React, { useEffect, useMemo, useState } from "react";
import Modal from "../../modal/Modal";
import CustomButton from "../../common/CustomButton";
import OutlineButton from "../../common/OutlineButton";
import "./styles/SendToKitchenModal.css";
import { useSelector } from "react-redux";
import { userLoyaltyStepEnum } from "../../../enums/userLoyaltyStepEnum";
import { maximumLoyaltyPoints } from "../../../utils/loyaltyCreditsUsageCalculation";

function ApplyLoyaltyCreditsModal({ show, close, onSend }) {
  const {
    auth: {
      user: { id },
    },
    orders: {
      usersLoyaltyProgramOnOrder,
      loyaltyProcess,
      checkoutInfo: { checkoutInfoPerUsers },
    },
    booking: { payingForOthersState },
    restaurant: { restaurant },
  } = useSelector((state) => state);

  const [usersLoyaltyOptionSelection, setUsersLoyaltyOptionSelection] = useState({
    usersWhoHaveSelectedCredits: [],
    usersWhoSkippedLoyaltyOption: [],
    usersWithNoResponse: [],
  });

  const usersLoyaltyOptionsSelectionArray = [
    ...usersLoyaltyOptionSelection.usersWhoHaveSelectedCredits,
    ...usersLoyaltyOptionSelection.usersWhoSkippedLoyaltyOption,
    ...usersLoyaltyOptionSelection.usersWithNoResponse,
  ];
  const showGuestsList =
    !(usersLoyaltyOptionsSelectionArray.length === 1 && usersLoyaltyOptionsSelectionArray[0].user_id === id) &&
    (usersLoyaltyOptionSelection.usersWhoHaveSelectedCredits.length ||
      usersLoyaltyOptionSelection.usersWhoSkippedLoyaltyOption.length ||
      usersLoyaltyOptionSelection.usersWithNoResponse.length);

  const minUsablePoints = useMemo(() => restaurant?.loyalty_program_min_redeemable_points, [restaurant]);

  useEffect(() => {
    const selectedCredits = usersLoyaltyProgramOnOrder.filter((user) => {
      const hasUserInPayingForOthersState = payingForOthersState.some(
        (stateUser) => stateUser.userIds.includes(user.user_id) && stateUser.payerId !== user.user_id,
      );

      const userLoyaltyStep = loyaltyProcess.usersLoyaltyStep[user.user_id]?.userLoyaltyStep;

      const currentUserCheckoutInfo = checkoutInfoPerUsers?.find((info) => info.userId === user.user_id);

      const userMaxUsablePoints = maximumLoyaltyPoints(
        +currentUserCheckoutInfo?.subTotal,
        restaurant?.loyalty_program_max_conversion_rate,
        +user.current_points,
      );

      return (
        userLoyaltyStep === userLoyaltyStepEnum.toApplyPending &&
        !hasUserInPayingForOthersState &&
        +user.current_points >= +minUsablePoints &&
        +userMaxUsablePoints >= +minUsablePoints
      );
    });

    const skippedLoyalty = usersLoyaltyProgramOnOrder.filter((user) => {
      const hasUserInPayingForOthersState = payingForOthersState.some(
        (stateUser) => stateUser.userIds.includes(user.user_id) && stateUser.payerId !== user.user_id,
      );

      const userLoyaltyStep = loyaltyProcess.usersLoyaltyStep[user.user_id]?.userLoyaltyStep;

      const currentUserCheckoutInfo = checkoutInfoPerUsers?.find((info) => info.userId === user.user_id);
      const userMaxUsablePoints = maximumLoyaltyPoints(
        +currentUserCheckoutInfo?.subTotal,
        restaurant?.loyalty_program_max_conversion_rate,
        +user.current_points,
      );

      return (
        userLoyaltyStep === userLoyaltyStepEnum.skipped &&
        !hasUserInPayingForOthersState &&
        +user.current_points >= +minUsablePoints &&
        +userMaxUsablePoints >= +minUsablePoints
      );
    });

    const noResponse = usersLoyaltyProgramOnOrder.filter((user) => {
      const hasUserInPayingForOthersState = payingForOthersState.some(
        (stateUser) =>
          stateUser.userIds.includes(user.user_id) &&
          stateUser.userIds.length > 1 &&
          stateUser.payerId !== user.user_id,
      );

      const currentUserCheckoutInfo = checkoutInfoPerUsers?.find((info) => info.userId === user.user_id);
      const userMaxUsablePoints = maximumLoyaltyPoints(
        +currentUserCheckoutInfo?.subTotal,
        restaurant?.loyalty_program_max_conversion_rate,
        +user.current_points,
      );

      return (
        !(user.user_id in loyaltyProcess.usersLoyaltyStep) &&
        !hasUserInPayingForOthersState &&
        +user.current_points >= +minUsablePoints &&
        +userMaxUsablePoints >= +minUsablePoints
      );
    });

    setUsersLoyaltyOptionSelection({
      usersWhoHaveSelectedCredits: selectedCredits,
      usersWhoSkippedLoyaltyOption: skippedLoyalty,
      usersWithNoResponse: noResponse,
    });
  }, [
    usersLoyaltyProgramOnOrder,
    loyaltyProcess.usersLoyaltyStep,
    id,
    payingForOthersState,
    minUsablePoints,
    checkoutInfoPerUsers,
    restaurant?.loyalty_program_max_conversion_rate,
  ]);

  return (
    <Modal isPopUp className="pop-up-modal sent-to-kitchen-modal send-to-kitchen-modal" show={show} close={close}>
      <h3 style={{ display: "inline-block" }}>Apply Loyalty Credits</h3>

      <div className="users-info-wrapper">
        {showGuestsList ? (
          <div>
            {usersLoyaltyOptionSelection.usersWhoHaveSelectedCredits.length ? (
              <div style={{ padding: "10px 0px" }}>
                <p>
                  <b>Guests that intend to use:</b>
                </p>
                {usersLoyaltyOptionSelection.usersWhoHaveSelectedCredits.map((user) => (
                  <p key={user.user_id}>{user.first_name + " " + user.last_name}</p>
                ))}
              </div>
            ) : null}

            {usersLoyaltyOptionSelection.usersWhoSkippedLoyaltyOption.length ? (
              <div style={{ padding: "10px 0px" }}>
                <p>
                  <b>Guests that do not intend to use:</b>
                </p>
                {usersLoyaltyOptionSelection.usersWhoSkippedLoyaltyOption.map((user) => (
                  <p key={user.user_id}>{user.first_name + " " + user.last_name}</p>
                ))}
              </div>
            ) : null}

            {usersLoyaltyOptionSelection.usersWithNoResponse.length ? (
              <div style={{ padding: "10px 0px" }}>
                <p>
                  <b>Guests with no response:</b>
                </p>
                {usersLoyaltyOptionSelection.usersWithNoResponse.map((user) => (
                  <p key={user.user_id}>{user.first_name + " " + user.last_name}</p>
                ))}
              </div>
            ) : null}
          </div>
        ) : null}

        <p>
          Are you sure you want to apply these loyalty credits? Once you proceed you will no longer be able to modify
          them.
        </p>
      </div>

      <div className={"send-to-kitchen-modal-buttons"}>
        <OutlineButton text={"Confirm"} onClick={onSend} />
        <CustomButton text={"Cancel"} onClick={close} />
      </div>
    </Modal>
  );
}

export default ApplyLoyaltyCreditsModal;
