const FloatingIslandButton = ({
  label,
  action,
  disabled = false,
  DOMel,
  className = DOMel.id === "floating-island-slot-2" ? "floating-island-button-2" : "floating-island-button",
  leftIcon,
  rightIcon,
  rightIconClassName,
  show = true,
}) => (
  <>
    {show ? (
      <button type={"button"} onClick={action} disabled={disabled} className={className}>
        {leftIcon && <span>{leftIcon}</span>}
        <span style={leftIcon && rightIcon && { maxWidth: "50%" }}>{label}</span>
        {rightIcon && <span className={rightIconClassName}>{rightIcon}</span>}
      </button>
    ) : null}
  </>
);

export default FloatingIslandButton;
