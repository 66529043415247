import React from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import ArrowRight from "../../assets/arrow-right.svg";
import Image from "../common/Image";
import "./ReservationListItem.css";

function ReservationListItem({
  name,
  address,
  date = "",
  sizeOfParty,
  img,
  onClick,
  table_number,
  is_walk_in,
  booking_date,
}) {
  //const [dateString, time] = date.split('T')
  return (
    <li onClick={onClick}>
      <div className="reservation-item-container">
        <div className="reservation-item-image">
          <Image alt="restaurant-image" src={img} restaurant />
          {!is_walk_in ? <ReservationBoxLabel table_number={table_number} is_walk_in={is_walk_in} /> : null}
        </div>
        <div className="reservation-item-right-column">
          <div className="reservation-item-date">
            {is_walk_in ? date : booking_date} | {sizeOfParty} person
          </div>
          <div className="reservation-item-title">{name}</div>
          <div className="reservation-item-address">{address}</div>
        </div>
        <div className="reservation-item-arrow">
          <img src={ArrowRight} alt="arrow-right" />
        </div>
      </div>
    </li>
  );
}

export default ReservationListItem;

function ReservationBoxLabel({ table_number, is_walk_in }) {
  return (
    <div className="reservation-box-label">
      <span>{!is_walk_in ? "Booked" : null}</span>
      {/* <span>
        Table NO: {table_number}
      </span> */}
    </div>
  );
}
