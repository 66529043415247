import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaymentDoneImage from "../../assets/illustration-payment-done.png";
import RestaurantLogo from "../../assets/logo-boucherie.png";
import { register_modal } from "../../config/modal_paths";
import useFloatingIslandActions from "../../hooks/useFloatingIslandActions";
import { editRating } from "../../pages/order/services/actions";
import { setActiveModal, setOnFullPageModal } from "../../store/actions/feedbackActions";
import CheckMarkPoint from "../common/CheckMarkPoint";
import RatingStars from "../common/rating/RatingStars";
import Modal from "../modal/Modal";
import "./PaymentDoneAnonUserModal.css";

function PaymentDoneAnonUserModal({ show, close }) {
  const {
    restaurant: { restaurant },
    orders: { userRating },
    booking: {
      reservationDetails: { reservation },
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const { ratingContent, ratingStars, ratingDescription } = RatingStars({
    existingRatingStars: userRating.ratingStars,
    existingRatingDescription: userRating.ratingDescription,
  });

  const ratingFeedback = ratingStars || ratingDescription;

  const handleContinueAction = () => {
    ratingFeedback && dispatch(editRating(reservation.order_id, ratingStars, ratingDescription));
    close();
    dispatch(setActiveModal(register_modal));
  };

  const handleCloseModal = () => {
    ratingFeedback && dispatch(editRating(reservation.order_id, ratingStars, ratingDescription));
    close();
  };

  //#region FloatingIsland

  useEffect(() => {
    dispatch(setOnFullPageModal(true));
    return () => dispatch(setOnFullPageModal(false));
  }, [dispatch]);

  const { createButtonPortal, createAccount, skip, DOMslot1, DOMslot2 } = useFloatingIslandActions();

  //#endregion

  return (
    <Modal className="payment-done-anon-modal" show={show} close={handleCloseModal}>
      <div className="modal-wrapper">
        <img alt="chef" className="payment-done-img" src={PaymentDoneImage} />
        <h3>Payment done</h3>
        <img className="restaurant-logo" alt="restaurant_logo" src={restaurant.logo || RestaurantLogo} />
        <p id="restaurant-title">{restaurant.name}</p>
        <p>Thank you for dining with us. We hope you enjoyed your experience.</p>

        {ratingContent}

        <p>Create an account to get even more on your next dining:</p>
        <CheckMarkPoint />

        {/* <div className='btn-wrapper create-account-btn-wrapper white-bg'>
                    <CustomButton
                        onClick={handleContinueAction}
                        text={'Create account'}
                        wrapperClassName="btn btn-primary big-round-btn create-account-modal-btn"
                        textClassName="cancel-reservation-btn-text create-account-modal-btn-text create-account-text"
                    />
                    <OutlineButton
                        onClick={handleCloseModal}
                        text={'Skip'}
                        wrapperClassName="cancel-reservation-btn payment-done-button"
                        textClassName="cancel-reservation-btn-text"
                    />
                </div> */}

        {createButtonPortal(createAccount(handleContinueAction), DOMslot1)}
        {createButtonPortal(skip(handleCloseModal), DOMslot2)}
      </div>
    </Modal>
  );
}

export default PaymentDoneAnonUserModal;
