import React, { useCallback, useEffect } from "react";
import CheckoutInfo from "../../components/justpay/checkout/CheckoutInfo";
import { getOrderByGuid, setCheck } from "../../store/actions/justPayActions";
import { useDispatch, useSelector } from "react-redux";
import useSocket from "../../hooks/useSocket";
import { useHistory, useParams } from "react-router";
import { userJustPayStepEnum } from "../../enums/userJustPayStepEnum";
import StatusMessage from "../../components/common/status/StatusMessage";
import { getPaymentMethodsForUser } from "../order/services/actions";

const JustPayCheckPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { tableGuid, restaurantGuid, orderGuid, checkGuid } = useParams();

  const {
    auth: { isAuthenticated, user },
    justPay: {
      order: { entities: order, loading: orderLoading, error: orderError },
      check: {
        details: { entities: details, error: checkError },
      },
    },
    feedback: { connectSocketAfterIdleTimeout },
  } = useSelector((state) => state);

  useEffect(() => {
    if (checkGuid && order && order.checks) {
      const selectedCheck = order.checks.find((check) => check.guid === checkGuid);
      if (selectedCheck) {
        dispatch(
          setCheck({
            details: {
              entities: selectedCheck,
              error: null,
            },
            userJustPayStep:
              selectedCheck?.checkout?.balanceDue === 0
                ? userJustPayStepEnum.alreadyPaid
                : order?.checkSplit && order.checkSplit[checkGuid]
                ? userJustPayStepEnum.toBePartlyPaid
                : userJustPayStepEnum.beforeSplit,
          }),
        );
      } else {
        dispatch(
          setCheck({
            details: {
              entities: selectedCheck,
              error: {
                status: 404,
                statusText: "Not found",
                message: "Invalid CheckGuid",
              },
            },
            checkGuid: null,
            userJustPayStep: null,
            split: null,
            paymentPercentage: null,
            paymentAmount: null,
            paymentTipPercentage: null,
            paymentTipAmount: null,
          }),
        );
      }
    }

    return () => {
      dispatch(
        setCheck({
          details: {
            entities: {},
            error: null,
          },
          checkGuid: null,
          userJustPayStep: null,
          split: null,
          paymentPercentage: null,
          paymentAmount: null,
          paymentTipPercentage: null,
          paymentTipAmount: null,
        }),
      );
    };
  }, [dispatch, checkGuid, order.checks, order.checkSplit, order]);

  useEffect(() => {
    if (restaurantGuid && tableGuid && orderGuid) {
      dispatch(getOrderByGuid(restaurantGuid, tableGuid, orderGuid, history));
    }
  }, [dispatch, orderGuid, restaurantGuid, tableGuid, history]);

  useEffect(() => {
    if (user.id) {
      dispatch(getPaymentMethodsForUser());
    }
  }, [dispatch, user.id]);

  const refreshJustPay = useCallback(
    ({ orderGuid }) => {
      if (restaurantGuid && tableGuid && orderGuid) {
        dispatch(getOrderByGuid(restaurantGuid, tableGuid, orderGuid));
      }
    },
    [dispatch, restaurantGuid, tableGuid],
  );

  useSocket({
    socketData: { orderId: orderGuid },
    shouldConnect: !connectSocketAfterIdleTimeout,
    refreshJustPay,
  });

  if (orderLoading) return <StatusMessage statusText="Loading..." message="Please wait." />;
  if (orderError)
    return <StatusMessage status={orderError.status} statusText={orderError.statusText} message={orderError.message} />;
  if (checkError)
    return <StatusMessage status={checkError.status} statusText={checkError.statusText} message={checkError.message} />;

  return (
    <div className="just-pay-page-container">
      {/* <p className="just-pay-page-title">Selected check</p> */}
      {details && Object.values(details).length && (
        <>
          <CheckoutInfo
            entity={details}
            savedCheckSplit={order?.checkSplit && order.checkSplit[details.guid]}
            heading="Check Info"
          />
        </>
      )}
    </div>
  );
};

export default JustPayCheckPage;
