import React from "react";
import "./HorizontalTabMenu.css";
import classnames from "classnames";

function HorizontalTabMenu({ menuItems = [], onClick, activeItem }) {
  return (
    <ul className="horizontal-side-menu">
      {menuItems.map((item, idx) => {
        return (
          <li key={idx} onClick={() => onClick(item.id || idx)} className={classnames("horizontal-side-menu-item")}>
            <div
              className={classnames({
                active: activeItem ? activeItem === item.id : item.active,
              })}
            />{" "}
            <span
              className={classnames({
                active: activeItem ? activeItem === item.id : item.active,
              })}
            >
              {item.text || item.label}
            </span>
          </li>
        );
      })}
    </ul>
  );
}

export default HorizontalTabMenu;
