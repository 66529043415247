import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import jwt_decode from "jwt-decode";

import {
  accept_invitation_modal,
  accept_invitation_success_modal,
  available_times_modal,
  cancel_reservation_modal,
  login_modal,
  ordering_not_available_modal,
  orders_paid_modal,
  password_reset_modal,
  password_reset_request_modal,
  password_reset_request_sent_modal,
  register_modal,
  register_success_modal,
  sent_to_kitchen_modal,
  sent_to_kitchen_modal_out_of_stock_items,
  update_user_modal,
  payment_done_modal,
  payment_done_anon_user_modal,
  expiration_timer_modal,
  timer_expired_message_modal,
  update_user_name_modal,
  is_idle_modal,
  booked_reservation_modal,
  recommended_menu_items_modal,
  just_pay_is_idle_modal,
} from "../config/modal_paths";
import { anonymous_restricted_routes } from "../config/pages_paths";

import { setActiveModal, setPageRoutePath } from "../store/actions/feedbackActions";

// Pages
import LoginPage from "../pages/auth/login/LoginPage";
import RegisterPage from "../pages/auth/register/RegisterPage";
import PasswordResetRequest from "../pages/auth/resetPassword/PasswordResetRequest";
import PasswordResetRequestSent from "../pages/auth/resetPassword/PasswordResetRequestSent";
import PasswordResetModal from "../pages/auth/resetPassword/PasswordResetModal";
import { getAccessToken } from "../pages/auth/services/actions";

// Components
import ConfirmationSentModal from "../components/auth/ConfirmationSentModal";
import AcceptInvitationModal from "../components/acceptInvitation/AcceptInvitationModal";
import AcceptInvitationSuccessModal from "../components/acceptInvitation/AcceptInvitationSuccessModal";
import CancelReservationModal from "../components/reservation/CancelReservationModal";
import AdditionalUserInfoModal from "../components/auth/AdditionalUserInfoModal";
import SentToKitchenModal from "../components/orders/SentToKitchenModal";
import OrderingNotAvailableModal from "../components/orders/OrderingNotAvailableModal";
import AvailableTimesModal from "../components/book/components/AvailableTimesModal";
import OrdersPaidModal from "../components/ordersRounds/components/OrdersPaidModal";
import PaymentDoneModal from "../components/orders/PaymentDoneModal";
import PaymentDoneAnonUserModal from "../components/orders/PaymentDoneAnonUserModal";
import ExpirationTimerModal from "../components/orders/ExpirationTimerModal";
import TimerExpiredMessageModal from "../components/orders/TimerExpiredMessageModal";
import UpdateUserNameModal from "../components/auth/UpdateUserNameModal";
import IsIdleModal from "../components/common/IsIdleModal";
import BookedReservationModal from "../pages/order/BookedReservationModal";
import RecommendedMenuItemsModal from "../components/orders/RecommendedMenuItemsModal";
import JustPayIsIdleModal from "../components/common/JustPayIsIdleModal";

export const modals = {
  [login_modal]: LoginPage,
  [register_modal]: RegisterPage,
  [register_success_modal]: ConfirmationSentModal,
  [accept_invitation_modal]: AcceptInvitationModal,
  [accept_invitation_success_modal]: AcceptInvitationSuccessModal,
  [password_reset_request_modal]: PasswordResetRequest,
  [password_reset_request_sent_modal]: PasswordResetRequestSent,
  [password_reset_modal]: PasswordResetModal,
  [cancel_reservation_modal]: CancelReservationModal,
  [update_user_modal]: AdditionalUserInfoModal,
  [update_user_name_modal]: UpdateUserNameModal,
  [sent_to_kitchen_modal]: SentToKitchenModal,
  [sent_to_kitchen_modal_out_of_stock_items]: SentToKitchenModal,
  [ordering_not_available_modal]: OrderingNotAvailableModal,
  [available_times_modal]: AvailableTimesModal,
  [orders_paid_modal]: OrdersPaidModal,
  [payment_done_modal]: PaymentDoneModal,
  [payment_done_anon_user_modal]: PaymentDoneAnonUserModal,
  [expiration_timer_modal]: ExpirationTimerModal,
  [timer_expired_message_modal]: TimerExpiredMessageModal,
  [is_idle_modal]: IsIdleModal,
  [just_pay_is_idle_modal]: JustPayIsIdleModal,
  [booked_reservation_modal]: BookedReservationModal,
  [recommended_menu_items_modal]: RecommendedMenuItemsModal,
};

function useModal() {
  const {
    auth: { isAuthenticated, user },
    feedback: { activeModal, pageRoute, activeModalMetadata },
  } = useSelector((state) => state);
  //   const { modalState, openModal, closeModal } = useModalContext()
  // const { showModal, modalComponent: Modal, modalOptions } = modalState;
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const onCloseModalHandler = useCallback(() => {
    dispatch(setActiveModal("", {}));
    dispatch(setPageRoutePath(""));
    setShowModal(false);
    setModal(null);
  }, [dispatch]);

  useEffect(() => {
    let routeLogic = () => {
      let route = "".concat(pageRoute);
      history.push(route);
      dispatch(setPageRoutePath(""));
      onCloseModalHandler();
    };

    if (isAuthenticated && pageRoute) {
      if (
        user.is_anonymous &&
        Object.values(anonymous_restricted_routes).find((r) =>
          pageRoute.startsWith(r.includes(":") ? r.split(":")[0] : r),
        )
      ) {
        dispatch(setActiveModal(login_modal));
      } else {
        const decoded = jwt_decode(localStorage.jwtToken.split(" ")[1]);
        if (decoded.exp && decoded.exp < Date.now() / 1000) {
          dispatch(getAccessToken()).then(() => {
            routeLogic();
          });
        } else routeLogic();
      }
    } else if (!pageRoute) {
    } else dispatch(setActiveModal(login_modal));
  }, [isAuthenticated, pageRoute, dispatch, history, onCloseModalHandler, user.is_anonymous]);

  useEffect(() => {
    if (activeModal in modals) {
      setShowModal(true);
      let Modal = modals[activeModal];
      setModal(
        <Modal
          key={Modal}
          show={showModal}
          // backdrop={}
          close={onCloseModalHandler}
          metadata={activeModalMetadata}
        />,
      );
    } else setModal(null);
  }, [activeModal, showModal, onCloseModalHandler, activeModalMetadata]);

  return [modal];
}

export default useModal;
