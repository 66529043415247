import React, { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getOrderByGuid, setOrderByGuidSuccess } from "../../store/actions/justPayActions";
import { just_pay_table_order_check_path } from "../../config/pages_paths";
import { timeToAMPM2 } from "../../utils/timeToAMPM";
import useSocket from "../../hooks/useSocket";
import ListCheck from "../../components/justpay/ListCheck";
import CheckoutInfo from "../../components/justpay/checkout/CheckoutInfo";
import CustomPieChart from "../../components/justpay/CustomPieChart";
import StatusMessage from "../../components/common/status/StatusMessage";

const JustPayOrderPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { tableGuid, restaurantGuid, orderGuid } = useParams();

  const {
    justPay: {
      order: { entities: order, loading, error },
    },
    versionConfig: { just_pay_complex_prod_bussiness_logic },
    feedback: { connectSocketAfterIdleTimeout },
  } = useSelector((state) => state);

  const handleOpenCheck = useCallback(
    (checkGuid) => {
      history.replace(
        just_pay_table_order_check_path
          .replace(":tableGuid", tableGuid)
          .replace(":restaurantGuid", restaurantGuid)
          .replace(":orderGuid", orderGuid)
          .replace(":checkGuid", checkGuid),
      );
    },
    [history, orderGuid, restaurantGuid, tableGuid],
  );

  useEffect(() => {
    if (restaurantGuid && tableGuid && orderGuid) {
      dispatch(getOrderByGuid(restaurantGuid, tableGuid, orderGuid, history));
    }

    return () => {
      dispatch(setOrderByGuidSuccess({}));
    };
  }, [dispatch, restaurantGuid, tableGuid, orderGuid, history]);

  useEffect(() => {
    if (just_pay_complex_prod_bussiness_logic && order?.checks?.length > 0) {
      // TBD
      // po otvoranje na smetkata, vo slucaj da se izbrise checkGuid od url (se otvori ovoj ulr na order), da go vrati na smetkata
      handleOpenCheck(order.checks[0].guid);
    }
  }, [just_pay_complex_prod_bussiness_logic, order.checks, handleOpenCheck]);

  const refreshJustPay = useCallback(
    ({ orderGuid }) => {
      if (restaurantGuid && tableGuid && orderGuid) {
        dispatch(getOrderByGuid(restaurantGuid, tableGuid, orderGuid, history));
      }
    },
    [dispatch, restaurantGuid, tableGuid, history],
  );

  useSocket({
    socketData: { orderId: orderGuid },
    shouldConnect: !connectSocketAfterIdleTimeout,
    refreshJustPay,
  });

  if (loading) return <StatusMessage statusText="Loading..." message="Please wait" />;
  if (error) return <StatusMessage status={error.status} statusText={error.statusText} message={error.message} />;

  return (
    <div className="just-pay-page-container">
      <p className="just-pay-page-title">List of checks {/* for the selected order */}</p>
      {Object.values(order).length && (
        <>
          {order?.checks?.map((check) => (
            <ListCheck
              key={check.guid}
              onClick={() => handleOpenCheck(check.guid)}
              guid={check.guid}
              displayNumber={check.displayNumber}
              date={timeToAMPM2(check.createdDate)}
            />
          ))}

          <CheckoutInfo entity={order} heading="Order Info" />

          <div className="pie-chart-container">
            <CustomPieChart data={order} type={order.entityType} key={order.guid} />
          </div>
        </>
      )}
    </div>
  );
};

export default JustPayOrderPage;
