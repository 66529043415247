import React from "react";
import Modal from "../modal/Modal";
import ModalHeading from "../common/ModalHeading";
import CustomButton from "../common/CustomButton";

import "./DeactivateAccountModal.css";

function DeactivateAccountModal({ show, close, onDeactivate }) {
  return (
    <Modal isPopUp className="deactivate-account-modal" show={show} close={close}>
      <ModalHeading title="Deactivate account" onClick={close} />
      <div>
        <p>
          You will lose access to all booking and ordering information. For access, you can re-activate your account by
          creating a new account with the same e-mail address.
          <br />
          <br />
          If you want to delete your account, please send an e-mail to contact@voila.nyc
          <br />
          <br />
          Are you sure you want to deactivate your account?
          <br />
        </p>
      </div>
      <div class="you-sure-action-wrapper">
        <CustomButton text="Confirm" onClick={onDeactivate} />
        <CustomButton text="Cancel" onClick={close} />
      </div>
    </Modal>
  );
}

export default DeactivateAccountModal;
