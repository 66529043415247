import React, { useCallback } from "react";
import CheckLabel from "../../CheckLabel";
import { justPayPageTypesEnum } from "../../../../enums/justPayPageTypesEnum";
import { CollapsibleBox } from "../../CollapsibleBox";

const ServiceChargesSection = ({ entity }) => {
  const { entityType, appliedServiceCharges, checks, checkout } = entity;

  const groupServiceChargesByName = useCallback(
    (entity) => {
      const processCharges = (charges) => {
        return charges.reduce((acc, { name, chargeAmount }) => {
          acc[name] = (acc[name] || 0) + chargeAmount;
          return acc;
        }, {});
      };

      if (entityType === justPayPageTypesEnum.check) {
        return processCharges(entity);
      }

      if (entityType === justPayPageTypesEnum.order) {
        const allCharges = entity.flatMap((check) => check.appliedServiceCharges);
        return processCharges(allCharges);
      }

      return {};
    },
    [entityType],
  );

  const serviceCharges = groupServiceChargesByName(
    entityType === justPayPageTypesEnum.check ? appliedServiceCharges : checks,
  );

  return (
    <>
      {entity?.checkout?.serviceChargesAmount !== 0 && (
        <CollapsibleBox
          children={Object.entries(serviceCharges).map(([name, total]) => (
            <CheckLabel
              key={name}
              label={name}
              amount={Object.entries(serviceCharges).length > 1 ? total : null}
              divClassName="check-label-secondary padding-zero"
            />
          ))}
          heading="Service Charges:"
          headingAmount={entity?.checkout?.serviceChargesAmount}
        />
      )}
    </>
  );
};

export default ServiceChargesSection;
