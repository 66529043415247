import { SET_PASSWORD_RESET_TOKEN } from "../../../../config/actionTypes";
import { setErrors } from "../../../../store/actions/errorsActions";
import { reset_password_redirect_api } from "../../../../config/api_endpoints";
import axios from "axios";
import { setResponseMessage } from "../../../../store/actions/feedbackActions";
import { home_path } from "../../../../config/pages_paths";

export const setPasswordResetToken = (token) => (dispatch) => {
  dispatch({ type: SET_PASSWORD_RESET_TOKEN, payload: token });
};

export const resetPassword = (data, token, history) => async (dispatch) => {
  try {
    await axios.post(
      reset_password_redirect_api,
      { token, ...data },
      { headers: { authorization: `Bearer ${token}` } },
    );
    dispatch(setResponseMessage("Password reset success"));
    history.push(home_path);
  } catch (err) {
    dispatch(setErrors(err));
  }
};
