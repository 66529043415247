import { order_page_path } from "../config/pages_paths";
import {
  getCheckoutInfo,
  getLastRoundOrders,
  getOrders,
  getOrdersNoRounds,
  getOrdersRounds,
} from "../pages/order/services/actions";
import { getCurrentReservation } from "../pages/reservations/services/actions";
import { setConnectSocketAfterIdleTimeout } from "../store/actions/feedbackActions";
import { getOrderByGuid } from "../store/actions/justPayActions";
import { setIsPay, setIsProceededToCheckoutSelection } from "../store/actions/usersActions";

const reconnectSocket = (currentOrder, id, history, dispatch) => {
  if (currentOrder?.order_id) {
    dispatch(setConnectSocketAfterIdleTimeout(true));
    dispatch(
      getCurrentReservation((currentOrder) => {
        if (Object.values(currentOrder).length === 0 && history) {
          history.push(order_page_path.replace(":orderId", currentOrder?.order_id));
        }
      }),
    );
    dispatch(getOrders(currentOrder?.order_id));
    dispatch(getOrdersRounds(currentOrder?.order_id));
    dispatch(getOrdersNoRounds(currentOrder?.order_id));
    dispatch(setIsPay(false));
    dispatch(getLastRoundOrders(currentOrder?.order_id));
    dispatch(setIsProceededToCheckoutSelection(false));
    dispatch(getCheckoutInfo(currentOrder?.order_id, [id]));
  }
};

const reconnectSocketForJustPay = (restaurantGuid, tableGuid, orderGuid, history, dispatch) => {
  if (restaurantGuid && tableGuid && orderGuid) {
    dispatch(setConnectSocketAfterIdleTimeout(true));

    dispatch(getOrderByGuid(restaurantGuid, tableGuid, orderGuid, history));
  }
};

export { reconnectSocket, reconnectSocketForJustPay };
