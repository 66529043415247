import isEmpty from "lodash.isempty";

export function allFriendsHaveOrdered(rounds = [], userId) {
  return rounds.every((round) => {
    if (round.round === null) {
      return round.users.every((user) => user.orders.length > 0);
    }
    return true;
  });
}

export function allFriendsHavePaid(rounds = [], userId) {
  const currentRound = rounds.find((round) => !round.round);
  if (currentRound) {
    return !currentRound.users
      .filter((user) => user.user_id !== userId)
      .some((user) => (isEmpty(user.orders) ? !isEmpty(user.orders) : user.orders.some((order) => !order.paid)));
  }
  return true;
}

export function canSendToKitchen(rounds = [], userIds, userId) {
  const currentRound = rounds.find((round) => !round.round);

  if (currentRound) {
    return currentRound.users
      .filter((user) => user.user_id === userId)
      .some((user) =>
        isEmpty(user.orders)
          ? allFriendsHavePaid(rounds, userId)
          : !user.orders.some((order) => !order.paid) && allSelectedFriendsHavePaid(rounds, userIds),
      );
  }
  return false;
}

function allSelectedFriendsHavePaid(rounds = [], userIds) {
  const currentRound = rounds.find((round) => !round.round);
  if (currentRound) {
    return !currentRound.users
      .filter((user) => userIds.some((id) => id === user.user_id))
      .some((user) => (isEmpty(user.orders) ? !isEmpty(user.orders) : user.orders.some((order) => !order.paid)));
  }
  return true;
}
