import React from "react";

function TermsOfUse() {
  return (
    <div className="terms-conditions">
      <span>
        By continuing you agree to the{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://www.opentable.com/legal/terms-and-conditions">
          OpenTable Terms of Use
        </a>{" "}
        and the{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://www.opentable.com/legal/privacy-policy">
          OpenTable Privacy Policy
        </a>{" "}
        applies.
      </span>
    </div>
  );
}

export default TermsOfUse;
