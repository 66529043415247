import React, { useCallback, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MenuItemPlaceholder from "../../assets/menu_item_placeholder.png";
import RestaurantPlaceholder from "../../assets/restaurant_placeholder.png";

const Image = ({ src, height, width, menu, restaurant, alt, effect, className, style = {} }) => {
  const [image, setImage] = useState(null);
  const [imagePlaceHolder, setImagePlaceHolder] = useState(null);

  const handleImageError = useCallback(() => {
    menu && setImage(MenuItemPlaceholder);
    restaurant && setImage(RestaurantPlaceholder);
  }, [menu, restaurant]);

  useEffect(() => {
    setImage(src);

    setImagePlaceHolder(MenuItemPlaceholder);
    setImagePlaceHolder(RestaurantPlaceholder);

    !src && handleImageError();

    return () => setImage(null);
  }, [src, handleImageError]);

  return (
    <LazyLoadImage
      height={height ? height : "100%"}
      width={width ? width : "100%"}
      alt={alt ? alt : "image"}
      effect={effect ? effect : "blur"}
      src={image || imagePlaceHolder}
      // placeholderSrc={imagePlaceHolder}
      onError={handleImageError}
      className={className ? className : ""}
      style={style ? style : { borderRadius: "1rem" }}
    />
  );
};

export default Image;
