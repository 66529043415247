import isEmpty from "lodash.isempty";
import React from "react";

function RestaurantPriceRange({ price_range = "" }) {
  return (
    <>
      {!isEmpty(price_range) &&
        array_pad(
          price_range.split("").map((el, idx) => <span key={idx}>{el}</span>),
          5,
          (key) => (
            <span key={key} style={{ color: "#f0f0f0" }}>
              $
            </span>
          ),
        )}
    </>
  );
}

function array_pad(array, length, filler) {
  if (array.length < length) while (true) if (array.push(filler(array.length + 1)) >= length) break;
  return array;
}

export default RestaurantPriceRange;
