import { useEffect, useState } from "react";
import React from "react";
import "./FreeModifiers.css";
import classnames from "classnames";
import SeparationLine from "../../common/SeparationLine";
import { useSelector } from "react-redux";

function FreeModifiers({ setModifier, setModifierMeta, setAreAllRequiredGroupsSelected }) {
  const {
    restaurant: { modifiersFromDb, selectedModifiersFromDb },
    versionConfig: { empty_modifier_group },
  } = useSelector((state) => state);

  const [modifiers, setModifiers] = useState([]);
  const [hasNonEmptyModifierGroup, setHasNonEmptyModifierGroup] = useState(false);

  useEffect(() => {
    let tmpModifiers = [],
      alreadySelected = false;
    if (selectedModifiersFromDb && selectedModifiersFromDb.length > 0) {
      tmpModifiers = selectedModifiersFromDb;
      alreadySelected = true;
    } else if (modifiersFromDb && modifiersFromDb.length > 0) {
      tmpModifiers = modifiersFromDb;
      setModifiers(modifiersFromDb);
    } else setModifiers([]);

    tmpModifiers.forEach((modifier) => {
      modifier.selectedModifierIDs = {};
      modifier.isAnyModifierSelected = false;
      alreadySelected &&
        modifier.items.forEach((item) => {
          if (item.active) {
            modifier.selectedModifierIDs[item.externalId] = true;
            modifier.isAnyModifierSelected = true;
          }
        });
    });

    let isSelected = true; // is at least one modifier per required group selected
    for (let i = 0; i < tmpModifiers.length; i++) {
      if (tmpModifiers[i].is_required && !tmpModifiers[i].isAnyModifierSelected) {
        isSelected = false;
        break;
      }
    }
    setAreAllRequiredGroupsSelected(isSelected);

    setModifiers(
      tmpModifiers.sort((a, b) => {
        if (a.sub_type_name === "Courses") return -1;
        else if (b.sub_type_name === "Courses") return 1;
        else return a.modifier_group_id - b.modifier_group_id;
      }),
    );
    setModifierMeta({ modifier: tmpModifiers });
  }, [modifiersFromDb, selectedModifiersFromDb, setAreAllRequiredGroupsSelected, setModifierMeta]);

  const handleModifierClick = (selectedItem, groupIndex) => {
    if (!selectedItem.active) {
      modifiers[groupIndex].selectedModifierIDs[selectedItem.externalId] = true;
      modifiers[groupIndex].isAnyModifierSelected = true;
    } else {
      delete modifiers[groupIndex].selectedModifierIDs[selectedItem.externalId];
      // we have to do some additional check because Object.keys(new Date()).length === 0
      if (
        modifiers[groupIndex].selectedModifierIDs &&
        Object.keys(modifiers[groupIndex].selectedModifierIDs).length === 0 &&
        Object.getPrototypeOf(modifiers[groupIndex].selectedModifierIDs) === Object.prototype
      ) {
        modifiers[groupIndex].isAnyModifierSelected = false;
      }
    }

    // toggle the item's modifier selected status
    selectedItem.active = !selectedItem.active;

    // if not a multi select, unselect all other items
    if (!modifiers[groupIndex].is_multi_select) {
      modifiers[groupIndex].items.forEach((item) => {
        if (item.externalId !== selectedItem.externalId) {
          item.active = false;
        }
      });
    }

    let isSelected = true; // is at least one modifier per required group selected
    for (let i = 0; i < modifiers.length; i++) {
      if (modifiers[i].is_required && !modifiers[i].isAnyModifierSelected) {
        isSelected = false;
        break;
      } else if (modifiers[i].is_required && modifiers[i].isAnyModifierSelected) {
        let isAnyOtherModifierItemActive = false;
        for (let j = 0; j < modifiers[i]?.items?.length; j++) {
          if (modifiers[i].items[j].active) {
            isAnyOtherModifierItemActive = true;
            break;
          }
        }

        if (!isAnyOtherModifierItemActive) {
          isSelected = false;
          modifiers[i].selectedModifierIDs = {};
          modifiers[i].isAnyModifierSelected = false;
          break;
        }
      }
    }
    setAreAllRequiredGroupsSelected(isSelected);

    setModifiers(
      modifiers.sort((a, b) => {
        if (a.sub_type_name === "Courses") return -1;
        else if (b.sub_type_name === "Courses") return 1;
        else return a.modifier_group_id - b.modifier_group_id;
      }),
    );
    setModifierMeta({ modifier: modifiers });
  };

  return (
    <div className="modifiers-container">
      {!empty_modifier_group ? (
        <>
          {modifiers && modifiers.length ? <SeparationLine /> : null}
          {modifiers.map((modifier, idx, _) => {
            return (
              <FreeModifierGroup
                key={modifier.modifier_group_id}
                isRequiredModifier={modifier.is_required && !modifier.items.some((i) => i.active)}
                isGroupRequired={modifier.is_required}
                modifier_group_id={modifier.modifier_group_id}
                groupIndex={idx}
                sub_type_name={modifier.sub_type_name}
                items={modifier.items}
                onClick={handleModifierClick}
              />
            );
          })}
        </>
      ) : (
        <>
          {hasNonEmptyModifierGroup && <SeparationLine />}
          {modifiers.map((modifier, idx, _) => {
            if (modifier.items.length > 0) {
              !hasNonEmptyModifierGroup && setHasNonEmptyModifierGroup(true);
              return (
                <FreeModifierGroup
                  key={modifier.modifier_group_id}
                  isRequiredModifier={modifier.is_required && !modifier.items.some((i) => i.active)}
                  isGroupRequired={modifier.is_required}
                  modifier_group_id={modifier.modifier_group_id}
                  groupIndex={idx}
                  sub_type_name={modifier.sub_type_name}
                  items={modifier.items}
                  onClick={handleModifierClick}
                  // isNewMenuEntetiesChanges={isNewMenuEntetiesChanges}
                />
              );
            }
            return null;
          })}
        </>
      )}
    </div>
  );
}

function FreeModifierGroup({
  sub_type_name,
  items,
  onClick,
  isRequiredModifier,
  isGroupRequired,
  modifier_group_id,
  groupIndex,
}) {
  return (
    <div>
      <h4 className="text-center free-modifier-heading">{sub_type_name}</h4>
      <FreeModifier
        items={items}
        onClick={onClick}
        modifier_group_id={modifier_group_id}
        groupIndex={groupIndex}
        isRequiredModifier={isRequiredModifier}
      />
      <p className="requred-modifiers">
        {isRequiredModifier
          ? items?.length === 0
            ? `This required modifier group contains no options`
            : `Modifiers are required!`
          : null}
        {!isGroupRequired && items?.length === 0 ? `This optional modifier group contains no options` : null}
      </p>
    </div>
  );
}

function FreeModifier({ items, onClick, groupIndex, isNewMenuEntetiesChanges, isRequiredModifier }) {
  return (
    <div className="free-modifier-buttons">
      {
        // isNewMenuEntetiesChanges && items?.length === 0 ?
        // <div style={{color:"red"}}>This modifier group contains no options</div> :
        items.map((item, _, _2) => {
          return (
            <FreeModifierButton
              key={item.modifier_id}
              active={item.active}
              onClick={() => onClick(item, groupIndex)}
              isRequiredModifier={isRequiredModifier}
            >
              {item.name + (item.price !== "0.00" ? " $" + item.price : "")}
            </FreeModifierButton>
          );
        })
      }
    </div>
  );
}

function FreeModifierButton({ active, onClick, isRequiredModifier, ...props }) {
  return (
    <button
      onClick={onClick}
      className={classnames("btn free-modifier-button", { active })}
      style={isRequiredModifier ? { border: "1px solid #ff0000" } : {}}
    >
      {props.children}
    </button>
  );
}

export default FreeModifiers;
