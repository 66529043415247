import React from 'react'



export const SVGIcon = (size, path) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
    >
        <path d={path} />
    </svg>

export const MenuIcon = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="M160 801.384q-12.75 0-21.374-8.629-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.629 8.624 8.628 8.624 21.384 0 12.755-8.624 21.37-8.625 8.616-21.375 8.616H160Zm0-195.385q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Zm0-195.384q-12.75 0-21.374-8.629-8.625-8.628-8.625-21.384 0-12.755 8.625-21.37 8.624-8.616 21.374-8.616h640q12.75 0 21.375 8.629 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Z" />
    </svg>

export const AddIcon = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="M479.987 845.999q-12.756 0-21.371-8.624-8.615-8.625-8.615-21.375V605.999H240q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h210.001V336q0-12.75 8.628-21.375 8.629-8.624 21.384-8.624 12.756 0 21.371 8.624 8.615 8.625 8.615 21.375v210.001H720q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H509.999V816q0 12.75-8.628 21.375-8.629 8.624-21.384 8.624Z" />
    </svg>

export const EditIcon = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="M206.154 856h49.461l370.387-370.386-49.461-49.462-370.387 370.387V856Zm548.152-413.77L619.309 308.463l52.154-52.153q17.615-17.615 42.845-17.615t42.845 17.615l48.692 48.691q17.615 17.615 18.23 42.23.615 24.615-17 42.23l-52.769 52.769Zm-43.383 43.999-429.77 429.77H146.156V781.001l429.769-429.77 134.998 134.998Zm-109.844-25.538-24.538-24.539 49.461 49.462-24.923-24.923Z" />
    </svg>

export const RemoveIcon = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="M240 605.999q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h480q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H240Z" />
    </svg>

export const DeleteIcon = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
        fill={color} fontWeight={600}
    >
        <path d="M292.309 915.999q-30.308 0-51.308-21t-21-51.308V336h-10q-12.769 0-21.384-8.615-8.616-8.615-8.616-21.384t8.616-21.384q8.615-8.616 21.384-8.616H360q0-15.076 10.538-25.23 10.539-10.154 25.616-10.154h167.692q15.077 0 25.616 10.154Q600 260.925 600 276.001h149.999q12.769 0 21.384 8.616 8.616 8.615 8.616 21.384t-8.616 21.384Q762.768 336 749.999 336h-10v507.691q0 30.308-21 51.308t-51.308 21H292.309ZM280 336v507.691q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462h375.382q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847V336H280Zm96.155 410.001q0 12.769 8.615 21.384T406.154 776q12.769 0 21.384-8.615 8.616-8.615 8.616-21.384V445.999q0-12.769-8.616-21.384Q418.923 416 406.154 416t-21.384 8.615q-8.615 8.615-8.615 21.384v300.002Zm147.691 0q0 12.769 8.616 21.384Q541.077 776 553.846 776t21.384-8.615q8.615-8.615 8.615-21.384V445.999q0-12.769-8.615-21.384T553.846 416q-12.769 0-21.384 8.615-8.616 8.615-8.616 21.384v300.002ZM280 336v507.691q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462H280V336Z" />
    </svg>

export const DiscoverTuneIcon = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="M530.001 445.999v-59.998h120v-160h59.998v160h120v59.998H530.001Zm120 480V546.001h59.998v379.998h-59.998Zm-400 0v-160h-120v-59.998h299.998v59.998h-120v160h-59.998Zm0-320V226.001h59.998v379.998h-59.998Z" />
    </svg>

export const ExpandMoreIcon = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="M480 682.461q-6.846 0-13.269-2.308t-12.038-7.923L274.308 491.846q-8.692-8.692-8.384-20.769.307-12.077 9-20.769 8.692-8.692 21.076-8.692t21.076 8.692L480 613.232l163.539-163.539q8.692-8.692 20.769-8.385 12.076.308 20.768 9 8.693 8.692 8.693 21.077 0 12.384-8.693 21.076L505.307 672.23q-5.615 5.615-11.846 7.923-6.23 2.308-13.461 2.308Z" />
    </svg>

export const ExpandLessIcon = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="M274.924 676.461q-8.693-8.692-8.693-21.076 0-12.385 8.693-21.077l179.769-179.769q5.615-5.615 11.846-7.923 6.23-2.307 13.461-2.307t13.461 2.307q6.231 2.308 11.846 7.923l180.769 180.769q8.308 8.308 8.308 20.077 0 11.769-9.308 21.076-8.692 8.692-21.076 8.692t-21.076-8.692L480 513.537 316.076 677.461q-8.307 8.308-20.076 8.308t-21.076-9.308Z" />
    </svg>
export const ExpandMoreIcon200 = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="M480 684.077 267.692 471.769 296 443.461l184 184 184-184 28.308 28.308L480 684.077Z" />
    </svg>

export const ExpandLessIcon200 = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="m296 684.077-28.308-28.308L480 443.461l212.308 212.308L664 684.077l-184-184-184 184Z" />
    </svg>

export const CloseIcon = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="M480 618.153 277.076 821.076q-8.307 8.308-20.884 8.5-12.576.193-21.268-8.5-8.693-8.692-8.693-21.076t8.693-21.076L437.847 576 234.924 373.076q-8.308-8.307-8.5-20.884-.193-12.576 8.5-21.268 8.692-8.693 21.076-8.693t21.076 8.693L480 533.847l202.924-202.923q8.307-8.308 20.884-8.5 12.576-.193 21.268 8.5 8.693 8.692 8.693 21.076t-8.693 21.076L522.153 576l202.923 202.924q8.308 8.307 8.5 20.884.193 12.576-8.5 21.268-8.692 8.693-21.076 8.693t-21.076-8.693L480 618.153Z" />
    </svg>

export const ClockIcon = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="m618.924 757.076 42.152-42.152-151.077-151.087V376h-59.998v212.154l168.923 168.922ZM480.067 955.999q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480 576Zm0 320q133 0 226.5-93.5T800 576q0-133-93.5-226.5T480 256q-133 0-226.5 93.5T160 576q0 133 93.5 226.5T480 896Z" />
    </svg>

export const CheckCircleIcon = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="m423.231 661.847-92.924-92.923q-8.307-8.308-20.884-8.5-12.577-.193-21.269 8.5-8.692 8.692-8.692 21.076t8.692 21.076l109.769 109.769q10.846 10.846 25.308 10.846 14.461 0 25.307-10.846l222.538-222.538q8.308-8.307 8.5-20.884.193-12.577-8.5-21.269-8.692-8.692-21.076-8.692t-21.076 8.692L423.231 661.847Zm56.836 294.152q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480 896q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
    </svg>
export const CheckCircleIcon400 = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="m424 760 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 83-31.5 156T763 859q-54 54-127 85.5T480 976Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
    </svg>

export const ErrorIcon = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="M480 765.23q13.731 0 23.019-9.288 9.288-9.289 9.288-23.019 0-13.731-9.288-23.019-9.288-9.288-23.019-9.288-13.731 0-23.019 9.288-9.288 9.288-9.288 23.019 0 13.73 9.288 23.019 9.288 9.288 23.019 9.288Zm-29.999-146.153h59.998v-240h-59.998v240Zm30.066 336.922q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933Z" />
    </svg>

export const WarningIcon = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="M137.018 915.999q-10.17 0-18.267-4.972-8.097-4.971-12.595-13.105-4.679-8.077-5.147-17.5-.468-9.423 5.085-18.665l342.43-591.512q5.553-9.242 13.899-13.665 8.346-4.424 17.577-4.424 9.231 0 17.577 4.424 8.346 4.423 13.899 13.665l342.43 591.512q5.553 9.242 5.085 18.665-.468 9.423-5.147 17.5-4.498 8.134-12.595 13.105-8.097 4.972-18.267 4.972H137.018ZM178 856h604L480 336 178 856Zm302-47.693q13.731 0 23.019-9.288 9.288-9.288 9.288-23.019 0-13.731-9.288-23.019-9.288-9.288-23.019-9.288-13.731 0-23.019 9.288-9.288 9.288-9.288 23.019 0 13.731 9.288 23.019 9.288 9.288 23.019 9.288Zm.013-104.614q12.756 0 21.371-8.625 8.615-8.625 8.615-21.374V533.692q0-12.75-8.628-21.374-8.629-8.625-21.384-8.625-12.756 0-21.371 8.625-8.615 8.624-8.615 21.374v140.002q0 12.749 8.628 21.374 8.629 8.625 21.384 8.625ZM480 596Z" />
    </svg>

export const DoNotDesturbIcon = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 96 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="M290.001 605.999h379.998v-59.998H290.001v59.998Zm190.066 350q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933ZM480 896q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
    </svg>

export const EditSquareIcon = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 -960 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h357l-80 80H200v560h560v-278l80-80v358q0 33-23.5 56.5T760-120H200Zm280-360ZM360-360v-170l367-367q12-12 27-18t30-6q16 0 30.5 6t26.5 18l56 57q11 12 17 26.5t6 29.5q0 15-5.5 29.5T897-728L530-360H360Zm481-424-56-56 56 56ZM440-440h56l232-232-28-28-29-28-231 231v57Zm260-260-29-28 29 28 28 28-28-28Z" />
    </svg>

export const IosShareIcon = (size, color, pointer = true) =>
    <svg xmlns="http://www.w3.org/2000/svg"
        height={size ? size : "24"}
        width={size ? size : "24"}
        viewBox="0 -960 960 960"
        enableBackground="new 0 0 500 500"
        cursor={pointer ? "pointer" : "auto"}
    >
        <path fill={color} d="M252.309-60.002q-30.308 0-51.308-21-21-20.999-21-51.307v-415.382q0-30.308 21-51.308t51.308-21h102.306V-560H252.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v415.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h455.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-415.382q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H605.385v-59.999h102.306q30.308 0 51.308 21t21 51.308v415.382q0 30.308-21 51.308t-51.308 21H252.309ZM450.001-330v-441.23L376-697.23l-42.153-42.769L480-886.151l146.153 146.152L584-697.23l-74.001-74.001v441.23h-59.998Z" />
    </svg>
