import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../modal/Modal";
import ModalHeading from "../../../common/ModalHeading";
import InputField from "../../../common/InputField";
import CustomButton from "../../../common/CustomButton";
import { setCheck } from "../../../../store/actions/justPayActions";
import { userJustPayStepEnum } from "../../../../enums/userJustPayStepEnum";

function CheckSplitModal({ onCloseModal, showModal, onOpenPaymentModalHandler }) {
  const dispatch = useDispatch();

  const {
    justPay: {
      check: { split: oldValue },
    },
  } = useSelector((state) => state);

  const [newValue, setNewValue] = useState(oldValue ?? 2);
  const [formError, setFormError] = useState(null);

  const onConfirm = () => {
    dispatch(
      setCheck({
        split: newValue,
        paymentPercentage: null,
        paymentAmount: null,
        userJustPayStep: userJustPayStepEnum.toBePartlyPaid,
      }),
    );
    onCloseModal();
    onOpenPaymentModalHandler();
  };

  const onChangeHandler = (e) => {
    if (
      typeof +e.target.value === "number" &&
      +e.target.value >= 0 &&
      +e.target.value <= 10 &&
      !e.target.value.includes(".")
    ) {
      setNewValue(parseInt(e.target.value));

      if (+e.target.value < 2) {
        setFormError("Minimum allowed split is 2");
      } else {
        setFormError(null);
      }
    }
  };

  return (
    <>
      <Modal isPopUp className="pop-up-modal" show={showModal} close={onCloseModal}>
        <ModalHeading title="Split Check" onClick={onCloseModal} />

        <InputField
          type="number"
          containerClassName="mt-4"
          value={newValue}
          onChange={onChangeHandler}
          error={formError}
        />

        <CustomButton
          wrapperClassName="booking-submit-btn active mx-auto mt-3"
          textClassName="booking-submit-btn-text active"
          text="Confirm"
          disabled={formError}
          onClick={onConfirm}
        />
      </Modal>
    </>
  );
}

export default CheckSplitModal;
