import Row from "../../../containers/layout/Row";
import Column from "../../../containers/layout/Column";
import PlaceholderImage from "../../../assets/empty-plate.png";

function StatusLayout({ status, statusText = "Bad request", message = "An error occured", image }) {
  return (
    <Row className="m-4 status-layout-container">
      <Column className="text-center">
        {status && <h2>{status}</h2>}
        <img src={image ?? PlaceholderImage} alt="status-icon" className={image && "status-layout-container-img"} />
        {statusText && <h2 className="status-layout-status-text">{statusText}</h2>}
        {message && <h4>{message}</h4>}
      </Column>
    </Row>
  );
}

export default StatusLayout;
