const moment = require("moment");

export function timeToAMPM(date) {
  return moment(date, ["YYYY-MM-DDTHH:mm A", "YYYY-MM-DD HH:mm A", "YYYY-MM-DDTHH:mm"]).format("hh:mm A");
}

export function timeToAMPM2(date) {
  return date ? moment.utc(date).format("YYYY-MM-DD hh:mm A") : null;
}

export function timeToAMPM3(date) {
  if (!date) return null;

  const formattedDate = moment.utc(date).format("YYYY-MM-DD");
  const formattedTime = moment.utc(date).format("hh:mm A");

  return { date: formattedDate, time: formattedTime };
}
