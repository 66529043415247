import { isEmpty, isNull } from "lodash";
import React from "react";
import useFloatingIslandActions from "../../hooks/useFloatingIslandActions";

function RestaurantActions({ onBook, onOrder, showBookingFlow, showOrderNow, hasOrder, onFullPageModal }) {
  const {
    bookTableInAdvance,
    orderRightNow,
    backToOrderShortcut,
    createButtonPortal,
    DOMslot1,
    DOMslot2,
  } = useFloatingIslandActions();

  return (
    <>
      {
        // this condition causes bug, the island not to show at all
        // !isNull(hasOrder) && !isNull(showBookingFlow) && (

        // <Grid container className='restaurant-order-buttons' justifyContent={'center'}>
        <>
          {showBookingFlow && (
            // !onFullPageModal &&
            <>
              {/* <Grid item sm={6} xs={12}>
                  <button
                  style={{ width: "80%" }}
                  className="btn btn-primary big-round-btn-auto-width"
                  onClick={onBook}
                  >
                  <span>Book a Table</span>
                  </button>
                </Grid> */}

              {createButtonPortal(bookTableInAdvance, DOMslot1)}
            </>
          )}

          {showOrderNow && isEmpty(hasOrder) && (
            // !onFullPageModal &&
            <>
              {/* <Grid item sm={6} xs={12}>
                  <button style={{ width: "80%" }}
                  className="btn btn-primary big-round-btn" onClick={onOrder}
                  >
                  <span>Order Now</span>
                  </button>
                </Grid> */}

              {createButtonPortal(orderRightNow, showBookingFlow ? DOMslot2 : DOMslot1)}
            </>
          )}

          {!isEmpty(hasOrder) &&
            // !onFullPageModal &&
            backToOrderShortcut(showBookingFlow ? DOMslot2 : DOMslot1)}

          {/* {
            createButtonPortal(seeFullMenu, DOMslot3)
          } */}
        </>
        // </Grid>
        //)
      }
    </>
  );
}

export default RestaurantActions;
