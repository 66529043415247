import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSendToKitchenMode } from "../../../pages/reservations/services/actions";
import EditTipModal from "../../orders/EditTipModal";
import { canSendToKitchen } from "../helpers/ordersStatus";
import CheckoutInfo from "./CheckoutInfo";
import SendToKitchen from "./SendToKitchen";

function RoundCompletion({
  onCompleteOrderClick,
  onSendToKitchen,
  checkoutInfo,
  otherTryingToPay,
  payForUsers,
  hasNewOrder,
  onPaymentCheckboxChange,
  orderUserIds,
  onRefreshVoidedOrders,
}) {
  const {
    orders: { ordersRounds },
    booking: { isSendToKitchenMode },
    auth: {
      user: { id },
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [showTipModal, setTipModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const yourCheckRef = useRef(null);

  const showInfoModalHandler = () => {
    setShowInfoModal(true);
  };

  const onCloseInfoModal = () => {
    setShowInfoModal(false);
  };

  const onEditTipHandler = () => {
    setTipModal(true);
  };

  const onCloseTipModal = () => {
    setTipModal(false);
  };

  useEffect(() => {
    if (ordersRounds) dispatch(setSendToKitchenMode(canSendToKitchen(ordersRounds, payForUsers, id)));
  }, [ordersRounds, payForUsers, id, dispatch]);

  return (
    <div className="order-checkout-wrapper">
      {showTipModal ? (
        <EditTipModal
          total={checkoutInfo.subTotalInt}
          showTipModal={showTipModal}
          onCloseTipModal={onCloseTipModal}
          payForUsers={payForUsers}
        />
      ) : null}
      {showInfoModal ? (
        <EditTipModal type="info" showTipModal={showInfoModal} onCloseTipModal={onCloseInfoModal} />
      ) : null}
      {!isSendToKitchenMode && !otherTryingToPay && (
        <CheckoutInfo
          onEditTipHandler={onEditTipHandler}
          showInfoModalHandler={showInfoModalHandler}
          yourCheckRef={yourCheckRef}
        />
      )}

      <SendToKitchen
        onCompleteOrderClick={onCompleteOrderClick}
        onSendToKitchen={onSendToKitchen}
        otherTryingToPay={otherTryingToPay}
        isSendToKitchenMode={isSendToKitchenMode}
        hasNewOrder={hasNewOrder}
        onPaymentCheckboxChange={onPaymentCheckboxChange}
        payForUsers={payForUsers}
        orderUserIds={orderUserIds}
        onRefreshVoidedOrders={onRefreshVoidedOrders}
        yourCheckRef={yourCheckRef}
      />
    </div>
  );
}

export default RoundCompletion;
