import React, { useState } from "react";
import { Rating } from "@mui/material";
import InputField from "../InputField";
import "./RatingStars.css";

function RatingStars({ existingRatingStars, existingRatingDescription }) {
  const [ratingStars, setRatingStars] = useState(existingRatingStars ?? null);
  const [ratingDescription, setRatingDescription] = useState(existingRatingDescription ?? "");

  const handleRatingStars = (e, newValue) => {
    setRatingStars(newValue);
  };

  const handleRatingDescription = (e) => {
    setRatingDescription(e.target.value);
  };

  const ratingContent = (
    <div className="rating-stars-container">
      <h3>Rate your experince</h3>

      <Rating size="large" value={ratingStars} onChange={handleRatingStars} onChangeActive={(e) => e.target.value} />

      <InputField
        type="textarea"
        maxLength={1024}
        placeholder="Please share your feedback"
        value={ratingDescription}
        onChange={handleRatingDescription}
        inputClassName="rating-description"
      />
    </div>
  );

  return {
    ratingContent,
    ratingStars,
    ratingDescription,
  };
}

export default RatingStars;
