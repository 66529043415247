import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router";
import LoadingSpinner from "../common/loader/LoadingSpinner";
import { setLoader } from "../../store/actions/feedbackActions";
import jwt_decode from "jwt-decode";
import { getAccessToken } from "../../pages/auth/services/actions";
import { home_path } from "../../config/pages_paths";

function ProtectedRoute({ path, component: Component, ...rest }) {
  const {
    feedback: { numOfLoadingSpinnerCalls },
    auth: { isAuthenticated },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (localStorage.jwtToken && location.pathname === path) {
      const decoded = jwt_decode(localStorage.jwtToken.split(" ")[1]);
      if (decoded.exp && decoded.exp < Date.now() / 1000) {
        dispatch(getAccessToken());
      }
      dispatch(setLoader(false));
    }
  }, [dispatch, location, path]);

  return (
    <Route
      path={path}
      render={() =>
        numOfLoadingSpinnerCalls > 0 ? (
          <LoadingSpinner />
        ) : isAuthenticated ? (
          <Component {...rest} />
        ) : (
          <Redirect to={{ pathname: home_path }} />
        )
      }
    />
  );
}

export default ProtectedRoute;
