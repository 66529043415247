import isEmpty from "lodash.isempty";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddIcon, RemoveIcon } from "../../assets/svgs-google/GoogleIcons.js";
import SeparationLine from "../common/SeparationLine.js";
import Column from "../../containers/layout/Column.js";
import inventoryStatusEnum from "../../enums/inventoryStatusEnum.js";
import { editOrderItem, getLastRoundOrders, removeOrderItem } from "../../pages/order/services/actions.js";
import InputFieldGroup from "../common/InputFieldGroup.js";
import Modal from "../modal/Modal.js";
import MenuItemDetails, { CloseButton } from "../restaurant/MenuItemDetails.js";
import "./OrdersComponents.css";
import AddItemButton from "./buttons/AddItemButton.js";
import EditItemButton from "./buttons/EditItemButton.js";
import "./buttons/OrderButtons.css";
import RemoveItemButton from "./buttons/RemoveItemButton.js";
import FreeModifiers from "./modifiers/FreeModifier.js";
import useFloatingIslandActions from "../../hooks/useFloatingIslandActions.js";
import { setOnFullPageModal, setRefreshDataForOpenedMenuItem } from "../../store/actions/feedbackActions.js";

function AddMenuItemModalNew({
  show,
  close,
  item,
  activeMenuSectionId,
  onAddClickHandler,
  handleCloseRecommendedMenuItemModal,
  recommendedItemDetails,
  menuItemRecommendations,
}) {
  const [quantity, setQuantity] = useState(item?.quantity || 1);
  const [selectableQuantity, setSelectableQuantity] = useState(0);
  const [oldestQuantity, setOldestQuantity] = useState(null);
  const [description, setDescription] = useState(item?.description || "");
  const [modifier, setModifier] = useState("");
  const [modifierMeta, setModifierMeta] = useState({});
  const [areAllRequiredGroupsSelected, setAreAllRequiredGroupsSelected] = useState(true);

  const {
    booking: {
      currentOrder,
      reservationDetails: { reservation },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (oldestQuantity === null) {
      setOldestQuantity(quantity);
    }
  }, [quantity]);

  useEffect(() => {
    if (item?.inventory_status === inventoryStatusEnum.QUANTITY) {
      if (item?.reservation_selected_quantity) {
        const quantityAvailable = item.remaining_quantity - item?.reservation_selected_quantity;

        const selectableQuantityCalculation =
          item?.reservation_selected_quantity + quantityAvailable >= item.remaining_quantity
            ? item.quantity === undefined
              ? item.remaining_quantity - item?.reservation_selected_quantity
              : oldestQuantity + (item.remaining_quantity - item?.reservation_selected_quantity)
            : item?.reservation_selected_quantity + quantityAvailable;

        setSelectableQuantity(selectableQuantityCalculation);

        if (!item?.quantity && quantity > selectableQuantityCalculation && !item?.round_item_id) {
          setQuantity(selectableQuantityCalculation <= 0 ? 1 : selectableQuantityCalculation);
        }
      } else {
        setSelectableQuantity(item?.remaining_quantity);
      }
    }
  }, [
    oldestQuantity,
    item?.remaining_quantity,
    item?.reservation_selected_quantity,
    item?.round_item_id,
    item?.quantity,
    quantity,
    item?.inventory_status,
  ]);

  useEffect(() => {
    if (item?.quantity) {
      setQuantity(item?.quantity);
    } else if (!item?.quantity && !item?.round_item_id) {
      setQuantity(1);
    }
  }, [item?.quantity, item?.round_item_id]);

  const onIncrease = () => {
    switch (item?.inventory_status) {
      case inventoryStatusEnum.IN_STOCK:
        setQuantity((val) => val + 1);
        break;
      case inventoryStatusEnum.QUANTITY:
        if (quantity < selectableQuantity) {
          setQuantity((val) => val + 1);
        }
        break;
      default:
    }
  };

  const handleClose = async (recommendationsOrItemDetails) => {
    dispatch(setRefreshDataForOpenedMenuItem({ itemId: null, refresh: false }));

    !isEmpty(recommendationsOrItemDetails) ? handleCloseRecommendedMenuItemModal() : close();
  };

  const onDecrease = () => {
    setQuantity((val) => (val - 1 > 0 ? val - 1 : 1));
  };

  const onDescriptionChange = (e) => {
    e.persist();
    setDescription(e.target.value);
  };

  const onAddOrderItemEventHandler = async () => {
    await handleClose(menuItemRecommendations);

    await onAddClickHandler({
      itemId: item.item_id,
      sectionId: item.menu_item_section_id,
      menuId: item.menu_item_menu_id,
      quantity,
      description,
      modifier,
      modifierMeta,
    });
  };

  const onEditOrderItemEventHandler = async () => {
    await handleClose(recommendedItemDetails);

    const orderId = (currentOrder && currentOrder.order_id) || item.order_id || (reservation && reservation.order_id);
    await dispatch(
      editOrderItem(item.round_item_id, orderId, {
        quantity,
        description,
        modifierGroups: modifierMeta.modifier,
        itemId: item.item_id,
      }),
    );
  };
  const onDeleteOrderItemEventHandler = async () => {
    await handleClose(recommendedItemDetails);

    const orderId = (currentOrder && currentOrder.order_id) || item.order_id || (reservation && reservation.order_id);
    await dispatch(removeOrderItem(item.round_item_id, orderId));
  };

  //#region FloatingIsland

  useEffect(() => {
    dispatch(setOnFullPageModal(true));
    return () => dispatch(setOnFullPageModal(false));
  }, [dispatch]);

  const { createButtonPortal, add, edit, remove, DOMslot1, DOMslot2 } = useFloatingIslandActions();

  //#endregion

  return (
    <div className={"add-menu-item-wrapper"}>
      <Modal className="add-menu-item-modal" show={show} close={close}>
        <CloseButton onClick={close} />
        <MenuItemDetails reduceDetailSize={false} {...item} close={close} />
        {item?.has_modifier ? null : (
          <FreeModifiers
            setModifier={(item) => setModifier(`${item.name}: ${item.modifier}`)}
            setModifierMeta={setModifierMeta}
            setAreAllRequiredGroupsSelected={setAreAllRequiredGroupsSelected}
          />
        )}
        <SeparationLine />
        <Column className="text-center" col={12}>
          <div className="add-menu-item-options-title mx-auto">
            <h3>Quantity</h3>
            {item?.inventory_status === inventoryStatusEnum.QUANTITY &&
              selectableQuantity === 0 &&
              !item?.round_item_id &&
              item?.remaining_quantity > 0 && (
                <p style={{ color: "red" }}>
                  {item?.users?.map((name, i, { length }) => (
                    <span>{length - 1 === i ? " " + name : " " + name + ","}</span>
                  ))}{" "}
                  {item?.users?.length > 1 ? "have" : "has"} already selected maximum quantity for ordering.
                </p>
              )}

            {item?.inventory_status === inventoryStatusEnum.OUT_OF_STOCK ||
            (item?.inventory_status === inventoryStatusEnum.QUANTITY && item?.remaining_quantity === 0) ? (
              <p style={{ color: "red", fontSize: "12px" }}>The item is out of stock</p>
            ) : null}
          </div>
          <div className="add-item-btns text-center">
            <div onClick={onDecrease}>{RemoveIcon()}</div>
            {/* <i className="fas fa-solid fa-minus fa-icon" onClick={onDecrease} /> */}
            <div className="item-quantity">
              <span>{quantity}</span>
            </div>
            <div onClick={onIncrease}>{AddIcon()}</div>
            {/* <i className="fas fa-solid fa-plus fa-icon" onClick={onIncrease} /> */}
          </div>
        </Column>
        <SeparationLine />
        <Column col={12} className="p-0">
          <div className="special-requirements-title mx-auto">
            <h4>Special requirements</h4>
          </div>
          <InputFieldGroup
            onChange={onDescriptionChange}
            value={description}
            wrapperClassName="special-requirements-input-wrapper"
            className="special-requirements-input"
            placeholder="Any remarks?"
            name="description"
          />
        </Column>
        {/* {!item?.round_item_id && (<AddItemButton onClick={onAddOrderItemEventHandler} disabled={item?.inventory_status === inventoryStatusEnum.OUT_OF_STOCK || !areAllRequiredGroupsSelected || (item?.inventory_status === inventoryStatusEnum.QUANTITY && (item?.remaining_quantity === 0 || selectableQuantity === 0))} />)}
        <div className='edit-remove-wrapper'>
          {item?.round_item_id && (<EditItemButton onClick={onEditOrderItemEventHandler} disabled={!areAllRequiredGroupsSelected} />)}
          {item?.round_item_id && (<RemoveItemButton onClick={onDeleteOrderItemEventHandler} disabled={false} />)}
          {DOMslot1 && createButtonPortal(edit(() => console.log('edit')), DOMslot1)}
          {DOMslot2 && createButtonPortal(remove(() => console.log('remove')), DOMslot2)}
        </> :
    </div> */}
        {!item?.round_item_id && (
          <>
            {/* <AddItemButton onClick={onAddOrderItemEventHandler} disabled={item?.inventory_status === inventoryStatusEnum.OUT_OF_STOCK || !areAllRequiredGroupsSelected || (item?.inventory_status === inventoryStatusEnum.QUANTITY && (item?.remaining_quantity === 0 || selectableQuantity === 0))} /> */}
            {createButtonPortal(
              add(
                onAddOrderItemEventHandler,
                item?.inventory_status === inventoryStatusEnum.OUT_OF_STOCK ||
                  !areAllRequiredGroupsSelected ||
                  (item?.inventory_status === inventoryStatusEnum.QUANTITY &&
                    (item?.remaining_quantity === 0 || selectableQuantity === 0)),
                "item",
              ),
              DOMslot1,
            )}
          </>
        )}
        <div className="edit-remove-wrapper">
          {/* {console.log(item?.round_item_id)} */}
          {item?.round_item_id && (
            <>
              {createButtonPortal(edit(onEditOrderItemEventHandler, !areAllRequiredGroupsSelected, "item"), DOMslot1)}
              {createButtonPortal(remove(onDeleteOrderItemEventHandler, false, "item"), DOMslot2)}
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default AddMenuItemModalNew;
