import React from "react";
import classnames from "classnames";

function OutlineButton({ text, onClick = () => {}, color = "primary", className = "voila-outline-btn" }) {
  return (
    <button onClick={onClick} className={classnames("btn", "btn-outline-" + color, className)}>
      {text}
    </button>
  );
}

export default OutlineButton;
