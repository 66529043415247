import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import React, { useEffect, useRef, useState } from "react";
import { useAnimate, useAnimateGroup } from "react-simple-animate";
import "./FloatingIsland.css";

const FloatingIsland = () => {
  const DOMslotRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];
  const [hasChildNodes, setHasChildNodes] = useState(new Array(DOMslotRefs.length).fill(false));

  const hasSlot1ChildNodes = hasChildNodes[0];
  const hasSlot2ChildNodes = hasChildNodes[1];
  const hasSlot3ChildNodes = hasChildNodes[2];
  const hasSlot4ChildNodes = hasChildNodes[3];
  const hasSlot5ChildNodes = hasChildNodes[4];

  const DOMslot1Ref = DOMslotRefs[0];
  const DOMslot2Ref = DOMslotRefs[1];
  const DOMslot3Ref = DOMslotRefs[2];
  const DOMslot4Ref = DOMslotRefs[3];
  const DOMslot5Ref = DOMslotRefs[4];

  useEffect(() => {
    const checkChildNodes = () => {
      const childNodes = DOMslotRefs.map((ref) => ref.current && ref.current.hasChildNodes());
      setHasChildNodes((prevState) => {
        // Compare current state with new state to avoid unnecessary updates
        if (JSON.stringify(prevState) !== JSON.stringify(childNodes)) {
          return childNodes;
        }
        return prevState;
      });
    };

    checkChildNodes();
  }, [DOMslotRefs]);

  // useEffect(() => {
  //     console.log(hasSlot2ChildNodes)
  // },[hasSlot2ChildNodes])

  // const [hasHadChild2Nodes, setHasHadChild2Nodes] = useState()
  const isVisible = hasSlot1ChildNodes || hasSlot2ChildNodes;
  const hasTop = hasSlot3ChildNodes || hasSlot4ChildNodes || hasSlot5ChildNodes; // || hasSlot6ChildNodes || hasSlot7ChildNodes || hasSlot8ChildNodes
  const [isTopOpen, setIsTopOpen] = useState(true);
  const [shouldSlideOut, setShouldSlideOut] = useState(false);
  // const [shouldSlideOutTop, setShouldSlideOutTop] = useState(false)

  const { play: buttonPlay, style: buttonStyle, isPlaying: buttonIsPlaying } = useAnimate({
    start: { width: "50%" },
    end: { width: "100%" },
    duration: 0.1,
  });

  const { styles, play, isPlaying } = useAnimateGroup({
    sequences: [DOMslot1Ref, DOMslot2Ref, DOMslot3Ref, DOMslot4Ref, DOMslot5Ref].map(() => ({
      start: { opacity: 1, transform: "translateY(0)" },
      end: { opacity: 0, transform: "translateY(0.75rem)" },
      duration: 0.1,
    })),
  });

  const toggleOptionsHandler = () => {
    // !isTopOpen && setIsTopOpen(true)
    // isTopOpen && setShouldSlideOutTop(true)
    // setTimeout(() => {
    //     isTopOpen && setIsTopOpen(false)
    // }, 400)

    if (isTopOpen) {
      play(!isPlaying);
    } else {
      setTimeout(() => {
        play(!isPlaying);
      }, 10);
    }
    setIsTopOpen((p) => !p);
  };

  const [prevBothSlotsFilled, setPrevBothSlotsFilled] = useState(false);

  useEffect(() => {
    // Check if both slots are filled
    const areBothSlotsFilledNow = hasSlot1ChildNodes && hasSlot2ChildNodes;

    // Trigger animation when both slots were filled and one becomes empty
    if (prevBothSlotsFilled && !areBothSlotsFilledNow) {
      // Reverse animation for slot1
      buttonPlay(!buttonIsPlaying);
    }

    // Update the previous state
    setPrevBothSlotsFilled(areBothSlotsFilledNow);
  }, [hasSlot1ChildNodes, hasSlot2ChildNodes, prevBothSlotsFilled, buttonPlay, buttonIsPlaying]);

  // useEffect(() => {
  //     // Trigger slideIn animation when right becomes not null and the component is not visible
  //     if (right !== null && right !== undefined && !isVisible) {
  //         setIsVisible(true);
  //     }

  //     // Trigger slideOut animation when right becomes null
  //     if ((right === null || right === undefined) && isVisible) {
  //         setShouldSlideOut(true);
  //     }

  //     // Trigger slideIn animation when right changes its value
  //     // if (right !== prevRight && prevRight !== null) {
  //     //     setShouldSlideOut(true);
  //     //     setIsVisible(true);
  //     // }
  //     // setPrevRight(right)
  // }, [right, isVisible, ]);
  // }, [right, isVisible, prevRight]);

  // useEffect(() => {
  //     return () => {
  //         setIsTopOpen(false)
  //     }
  // }, [])

  const onAnimationEnd = () => {
    // Reset shouldSlideOut after the slideOut animation is complete
    if (shouldSlideOut) {
      setShouldSlideOut(false);
      // setIsVisible(false);
    }
  };

  // const onAnimationEndTop = () => {
  //     if (shouldSlideOutTop) {
  //         // Clear the timeout to prevent setting the state after animation end
  //         clearTimeout(timeoutId);
  //         // Set the timeout to update the state after the animation completes
  //         timeoutId = setTimeout(() => {
  //             setShouldSlideOutTop(false);
  //         }, 500); // Adjust the delay as needed based on your animation duration
  //     }
  // }

  // const handleButtonSlide = () => {
  //     playSlot1(!isPlayingSlot1)
  // }

  // ${shouldSlideOut ? 'slideOut' : 'slideIn'}

  // useEffect(() => {
  //     setHasHadChild2Nodes(!!hasSlot2ChildNodes)
  //     if(hasSlot1ChildNodes && hasHadChild2Nodes && !isPlaying) {
  //         play(!isPlaying)
  //         setHasHadChild2Nodes(false)
  //     }
  // },[hasSlot1ChildNodes ,hasSlot2ChildNodes, hasHadChild2Nodes, isPlaying, play])

  useEffect(() => {
    // Set display to none after a delay of 0.2s
    if (!isTopOpen) {
      const timeoutId = setTimeout(() => {
        // Set the display property for each DOM element if needed
        DOMslot3Ref.current.style.display = "none";
        DOMslot4Ref.current.style.display = "none";
        DOMslot5Ref.current.style.display = "none";
      }, 200);

      // Clear the timeout to avoid memory leaks
      return () => clearTimeout(timeoutId);
    } else {
      const timeoutId = setTimeout(() => {
        DOMslot3Ref.current.style.display = "block";
        DOMslot4Ref.current.style.display = "block";
        DOMslot5Ref.current.style.display = "block";
      }, 200);

      // Clear the timeout to avoid memory leaks
      return () => clearTimeout(timeoutId);
    }
  }, [isTopOpen]);

  return (
    <>
      <div
        className={`floating-island-container ${!isVisible ? "floating-island-empty-slot" : ""}`}
        onAnimationEnd={onAnimationEnd}
      >
        {
          <div
            className={hasTop ? `floating-island-more-options-wrapper` : "floating-island-empty-slot"}
            // style={!isTopOpen? { gap:"0", margin: "0" } : {}}
            // onAnimationEnd={onAnimationEndTop}
          >
            {/* {top.map((el, index) => (
                        <div
                        style={{ animationDelay: `${(0.2 + index) / 10}s` }}
                        key={index} className={`floating-island-button-wrapper-2 ${shouldSlideOutTop ? 'slideOutTop' : 'slideInTop'}`}>
                        <FloatingIslandElement className="floating-island-button-top" element={el} />
                        </div>
                    ))} */}

            {/* <div id={"floating-island-slot-8"} ref={DOMslot8Ref} className={hasSlot8ChildNodes && isTopOpen ? '' : 'floating-island-empty-slot'} />
                        <div id={"floating-island-slot-7"} ref={DOMslot7Ref} className={hasSlot7ChildNodes && isTopOpen ? '' : 'floating-island-empty-slot'} />
                        <div id={"floating-island-slot-6"} ref={DOMslot6Ref} className={hasSlot6ChildNodes && isTopOpen ? '' : 'floating-island-empty-slot'} /> */}
            <div id={"floating-island-slot-5"} style={styles[2]} ref={DOMslot5Ref} />
            <div id={"floating-island-slot-4"} style={styles[3]} ref={DOMslot4Ref} />
            <div id={"floating-island-slot-3"} style={styles[4]} ref={DOMslot3Ref} />
          </div>
        }

        {hasTop && (
          <div onClick={toggleOptionsHandler} className="floating-island-more-options-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                userSelect: "none",
              }}
            >
              {isTopOpen ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
              <span style={{ display: "flex", width: "100%", marginLeft: ".4rem", fontSize: "14px" }}>
                {isTopOpen ? "Less" : "More"} Options
              </span>
            </div>
          </div>
        )}

        <div className="floating-island-wrapper">
          <div
            id={"floating-island-slot-2"}
            ref={DOMslot2Ref}
            className={hasSlot2ChildNodes ? "floating-island-button-wrapper-2" : "floating-island-empty-slot"}
          />
          <div
            // style={!!prevBothSlotsFilled ? style : {}}
            id={"floating-island-slot-1"}
            ref={DOMslot1Ref}
            // style={bothSlotsFilled ? {} : buttonStyle}
            className={
              hasSlot1ChildNodes
                ? hasSlot2ChildNodes
                  ? `floating-island-button-wrapper`
                  : `floating-island-button-wrapper-full`
                : "floating-island-empty-slot"
            }
          />
        </div>
        {/* <button onClick={() => play(!isPlaying)}>Play</button> */}
      </div>
    </>
  );
};

export default FloatingIsland;
