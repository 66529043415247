import jwt_decode from "jwt-decode";
import isEmpty from "lodash.isempty";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import "./App.css";
import AcceptInvitation from "./components/acceptInvitation/AcceptInvitation";
import AuthVerify from "./components/auth/AuthVerify";
import RegisterConfirmation from "./components/auth/RegisterConfirmation";
import pathsToExludeUpdateUserNameModal from "./components/auth/pathsToExlucdeUpdateUserNameModal";
import FloatingIsland from "./components/common/floatingIsland/FloatingIsland";
import LoadingSpinner from "./components/common/loader/LoadingSpinner";
import ToastMessage from "./components/common/toast/ToastMessage";
import Navigation from "./components/navigation/Navigation";
import ExpirationTimerModal from "./components/orders/ExpirationTimerModal";
import MenuItemQuantityChangedModal from "./components/orders/MenuItemQuantityChangedModal";
import TimerExpiredMessageModal from "./components/orders/TimerExpiredMessageModal";
import AnonymousRedirectRoute from "./components/routes/AnonymousRedirectRoute";
import AuthRoute from "./components/routes/AuthRoute";
import GuestRoute from "./components/routes/GuestRoute";
import { is_idle_modal, just_pay_is_idle_modal, orders_paid_modal, update_user_name_modal } from "./config/modal_paths";
import {
  aboutus_page_path,
  accept_invitation_path,
  booking_order_page_path,
  booking_page_path,
  booking_reservation_path,
  contact_page_path,
  credit_card_information_path,
  full_menu_path,
  help_page_path,
  home_path,
  just_pay_menu_path,
  just_pay_table_order_check_path,
  just_pay_table_order_path,
  just_pay_table_orders_path,
  landing_path,
  login_path,
  loyalty_program_path,
  order_first_time_path,
  order_page_path,
  password_change_path,
  password_reset_path,
  past_order_page_path,
  privacy_page_path,
  profile_page_path,
  qr_order_landing_path,
  register_confirmation_path,
  reservations_path_now,
  reservations_path_past,
  reservations_path_upcoming,
  restaurant_path,
  table_number_page_path,
  terms_page_path,
} from "./config/pages_paths";
import useExpirationTimer from "./hooks/useExpirationTimer";
import useIsIdle from "./hooks/useIsIdle";
import useIsCurrentTabActive from "./hooks/useIsTabVisible";
import useModal from "./hooks/useModal";
import useSocket from "./hooks/useSocket";
import PasswordReset from "./pages/auth/resetPassword/PasswordReset";
import { getAccessToken, setCurrentUser } from "./pages/auth/services/actions";
import BookingPage from "./pages/book/BookingPage";
import { setPayingForOthers } from "./pages/book/services/actions";
import ContactPage from "./pages/contact/ContactPage";
import HomePage from "./pages/home/HomePage";
import AboutUsPage from "./pages/informations/AboutUsPage";
import HelpPage from "./pages/informations/HelpPage";
import LandingPage from "./pages/landing/LandingPage";
import RestaurantMenu from "./pages/menu/RestaurantMenu";
import ChooseTable from "./pages/order/ChooseTable";
import CreateOrder from "./pages/order/CreateOrder";
import OrderPage from "./pages/order/OrderPage";
import QrOrderLanding from "./pages/order/QrOrderLanding";
import { getLastRoundOrders, getOrdersNoRounds } from "./pages/order/services/actions";
import PrivacyPage from "./pages/privacy/PrivacyPage";
import CreditCardInformationPage from "./pages/profile/CreditCardInformationPage";
import ProfilePWPage from "./pages/profile/ProfilePWPage";
import ProfilePage from "./pages/profile/ProfilePage";
import BookingPageWrapper from "./pages/reservation/BookingPageWrapper";
import ManageBookingPage from "./pages/reservation/ManageBookingPage";
import CurrentReservationPage from "./pages/reservations/CurrentReservationPage";
import PastReservationsPage from "./pages/reservations/PastReservationsPage";
import ReservationsPageWrapper from "./pages/reservations/ReservationsPageWrapper";
import UpcomingReservationsPage from "./pages/reservations/UpcomingReservationsPage";
import {
  closeCurrentReservation,
  getCurrentReservation,
  getReservations,
  setOtherTryingToPay,
  setReservations,
  setUserIdsSomeonePaysFor,
} from "./pages/reservations/services/actions";
import RestaurantPage from "./pages/restaurant/RestaurantPage";
import RestaurantWrapper from "./pages/restaurant/RestaurantWrapper";
import { setCurrentPosition } from "./pages/restaurant/services/actions";
import TermsPage from "./pages/terms/TermsPage";
import {
  setActiveModal,
  setConnectSocketAfterIdleTimeout,
  setMenuItemQuantityChangedModal,
} from "./store/actions/feedbackActions";
import { clearRestaurantReservation, getRestaurants } from "./store/actions/restaurantsActions";
import { getUser, setIsPay, setIsProceededToCheckoutSelection } from "./store/actions/usersActions";
import { getVersionConfig } from "./store/actions/versionConfigActions";
import store from "./store/store";
import localStorageHelper from "./utils/localStorageHelper";
import setAuthToken from "./utils/setAuthToken";
import PastOrderPage from "./pages/order/PastOrderPage";
import LoyaltyProgramPage from "./pages/loyalty/LoyaltyProgramPage";
import JustPayOrderPage from "./pages/justpay/OrderPage";
import JustPayOrdersPage from "./pages/justpay/OrdersPage";
import JustPayCheckPage from "./pages/justpay/CheckPage";
import JustPayMenuPage from "./pages/justpay/MenuPage";

if (localStorage.jwtToken) {
  const decoded = jwt_decode(localStorage.jwtToken.split(" ")[1]);
  setAuthToken(localStorage.jwtToken);
  if (decoded.exp && decoded.exp < Date.now() / 1000) {
    store.dispatch(getAccessToken());
  } else {
    store.dispatch(setCurrentUser(decoded));
  }
}

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    auth: { isAuthenticated, user },
    user: { user: userDetails },
    user: { isPay, isProceededToCheckoutSelection },
    restaurants: { restaurants },
    feedback: {
      toast,
      numOfLoadingSpinnerCalls,
      expirationTimerModal,
      timerExpiredMessageModal,
      showMenuItemQuantityChangedModal,
    },
    booking: {
      currentOrder,
      reservations: { ongoing },
    },
  } = useSelector((state) => state);

  const containerRef = useRef();
  const activeModal = useModal();

  const isJustPayOrderPath = useRouteMatch(just_pay_table_order_path);

  const {
    timeLeft,
    refreshExpirationToken,
    // expirationTime,
    orderExpired,
  } = useExpirationTimer();
  const { isIdle } = useIsIdle();
  const { isCurrentTabActive } = useIsCurrentTabActive();

  useEffect(() => {
    if (isEmpty(userDetails) && localStorageHelper.exists("jwtToken")) {
      dispatch(getUser());
    }
  }, [dispatch, userDetails]);

  useEffect(() => {
    if (isIdle) {
      if (isJustPayOrderPath) {
        dispatch(setActiveModal(just_pay_is_idle_modal, isJustPayOrderPath.params));
      } else {
        if (currentOrder && !expirationTimerModal.show && !timerExpiredMessageModal.show) {
          dispatch(setActiveModal(is_idle_modal));
        } else if (currentOrder && (expirationTimerModal.show || timerExpiredMessageModal.show)) {
          dispatch(setActiveModal(""));
        }
      }
    }
  }, [dispatch, isIdle, currentOrder, expirationTimerModal, timerExpiredMessageModal, isJustPayOrderPath]);

  useEffect(() => {
    // connect socket after switching browser tab
    if (isCurrentTabActive) {
      dispatch(setConnectSocketAfterIdleTimeout(true));
      setTimeout(() => {
        dispatch(setConnectSocketAfterIdleTimeout(false));
      }, 1000);
    }
  }, [dispatch, isCurrentTabActive]);

  useEffect(() => {
    if (!isEmpty(userDetails) && isEmpty(userDetails?.first_name) && ongoing?.isComplete) {
      dispatch(setActiveModal(update_user_name_modal));

      if (pathsToExludeUpdateUserNameModal.includes(location.pathname)) {
        dispatch(setActiveModal(""));
      }
    }
  }, [dispatch, ongoing, location, userDetails]);

  useEffect(() => {
    if (isEmpty(restaurants)) {
      dispatch(getRestaurants());
    }
  }, [dispatch, restaurants]);

  useEffect(() => {
    if (isAuthenticated) {
      if (isEmpty(ongoing)) {
        dispatch(getReservations());
      }
      if (ongoing) {
        dispatch(getLastRoundOrders(ongoing.order_id));
      }
      dispatch(getCurrentReservation());
    }
  }, [dispatch, isAuthenticated, ongoing]);

  useEffect(() => {
    // reset payment if user changes route
    if (location.pathname !== reservations_path_now) {
      isPay && dispatch(setIsPay(false));
      isProceededToCheckoutSelection && dispatch(setIsProceededToCheckoutSelection(false));
    }
  }, [dispatch, location.pathname, isPay, isProceededToCheckoutSelection]);

  useEffect(() => {
    if (isAuthenticated) {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          dispatch(
            setCurrentPosition({
              lon: position.coords.longitude,
              lat: position.coords.latitude,
            }),
          );
        });
      }
      return () => dispatch(setReservations([]));
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    dispatch(getVersionConfig());
  }, [dispatch]);

  const refreshReservations = useCallback(() => {
    dispatch(getReservations());
    dispatch(getCurrentReservation());
  }, [dispatch]);

  function isCurrentUsersAction(userIds, payerId, userId) {
    return +payerId !== userId && userIds.some((id) => +id === userId);
  }

  const onOtherPaid = useCallback(
    ({ userIds, payerId, modalMeta, orderId, isClosed }) => {
      if (isCurrentUsersAction(userIds, payerId, +user.id)) {
        dispatch(setActiveModal(orders_paid_modal, modalMeta, orderId));
        dispatch(setOtherTryingToPay(false));
        dispatch(setUserIdsSomeonePaysFor([]));
        dispatch(setPayingForOthers([]));
        if (isClosed) {
          dispatch(closeCurrentReservation());
          dispatch(clearRestaurantReservation());
        }
      }
    },
    [dispatch, user.id],
  );

  const onOrderClosed = useCallback(
    (orderId) => {
      history.push(past_order_page_path.replace(":orderId", orderId));

      dispatch(closeCurrentReservation());
      dispatch(clearRestaurantReservation());
    },
    [dispatch, history],
  );

  const onMenuItemQuantityChanged = useCallback(
    ({ modalForUsersDict }) => {
      if (modalForUsersDict[user.id]) {
        const modalMessage = {
          heading: "We sincerely apologize",
          message: (
            <>
              <span>
                The available quantity of selected {modalForUsersDict[user.id].names.length > 1 ? "items" : "item"}:{" "}
                <strong>
                  {modalForUsersDict[user.id].names
                    .map((item, i, { length }) =>
                      length - 1 === i
                        ? " " + item.itemName + ` (${item.quantity})`
                        : " " + item.itemName + ` (${item.quantity})`,
                    )
                    .join(", ")}
                </strong>{" "}
                has changed.{" "}
              </span>
              <span style={{ marginTop: ".5rem", marginBottom: ".5rem" }}>
                Please try another tasty option instead.
              </span>
            </>
          ),
        };
        dispatch(setMenuItemQuantityChangedModal({ show: true, message: modalMessage }));
      }
    },
    [dispatch, user.id],
  );

  const onGuestsHaveSelectedMoreQuantityThanItemHas = useCallback(
    ({ guestsSelectedMoreQuantityThanItemHas, userId }) => {
      if (user.id === userId) {
        const modalMessage = {
          heading: "We sincerely apologize",
          message: (
            <>
              <span>
                Currently we don't have that much available quantity for the{" "}
                {guestsSelectedMoreQuantityThanItemHas.length > 1 ? "items" : "item"}:{" "}
                <strong>
                  {guestsSelectedMoreQuantityThanItemHas.map((item, i, { length }) =>
                    length - 1 === i ? " " + item.name : " " + item.name + ",",
                  )}
                </strong>
                .
              </span>
              <span style={{ marginTop: ".5rem", marginBottom: ".5rem" }}>
                Please try another tasty option instead.
              </span>
            </>
          ),
        };
        dispatch(setMenuItemQuantityChangedModal({ show: true, message: modalMessage }));
      }
    },
    [dispatch, user.id],
  );

  const onMenuItemIsOutOfStock = useCallback(
    ({ itemsNames }) => {
      if (itemsNames[user.id] && itemsNames[user.id].show) {
        const modalMessage = {
          heading: "Order something else?",
          message: (
            <>
              <span>
                Oops, we are currently out of stock of the following menu{" "}
                {itemsNames[user.id].names.length > 1 ? "items" : "item"}:{" "}
                <strong>
                  {itemsNames[user.id].names.map((name, i, { length }) =>
                    length - 1 === i ? " " + name : " " + name + ",",
                  )}
                </strong>
                .
              </span>
              <span style={{ marginTop: ".5rem", marginBottom: ".5rem" }}>
                Please try another tasty option instead.
              </span>
            </>
          ),
        };
        dispatch(setMenuItemQuantityChangedModal({ show: true, message: modalMessage }));
      }
    },
    [dispatch, user.id],
  );

  const onDeleteItemsDueToMenuTimeChange = useCallback(
    ({ orderItems }) => {
      const modalMessage = {
        heading: "Order something else?",
        message: (
          <>
            <span>
              Delete items due to menu time change:{" "}
              <strong>
                {orderItems.map((item, i, { length }) => (length - 1 === i ? " " + item.name : " " + item.name + ","))}
              </strong>
              .
            </span>
            <span style={{ marginTop: ".5rem", marginBottom: ".5rem" }}>Please try another tasty option instead.</span>
          </>
        ),
      };
      dispatch(setMenuItemQuantityChangedModal({ show: true, message: modalMessage }));
    },
    [dispatch],
  );

  useSocket({
    socketData: { orderId: currentOrder?.order_id, userId: user.id },
    shouldConnect: true,
    refreshExpirationToken,
    refreshReservations,
    orderExpired,
    onOtherPaid,
    onMenuItemQuantityChanged,
    onGuestsHaveSelectedMoreQuantityThanItemHas,
    onMenuItemIsOutOfStock,
    onOrderClosed,
    onDeleteItemsDueToMenuTimeChange,
  });

  return (
    <>
      <Navigation />
      <div ref={containerRef} className="container-fluid bistro-app mx-auto">
        {toast.show ? <ToastMessage {...toast} /> : null}
        {expirationTimerModal.show ? <ExpirationTimerModal timeLeft={timeLeft} /> : null}
        {timerExpiredMessageModal.show ? <TimerExpiredMessageModal /> : null}
        {showMenuItemQuantityChangedModal.show && (
          <MenuItemQuantityChangedModal
            show={true}
            message={showMenuItemQuantityChangedModal.message}
            extraButton={showMenuItemQuantityChangedModal.extraButton}
          />
        )}
        {numOfLoadingSpinnerCalls > 0 ? <LoadingSpinner /> : null}
        {activeModal}
        {<FloatingIsland />}
        <Switch>
          <Route path={terms_page_path} component={TermsPage} />
          <Route path={contact_page_path} component={ContactPage} />
          <Route path={privacy_page_path} component={PrivacyPage} />
          <Route path={aboutus_page_path} component={AboutUsPage} />
          <Route path={help_page_path} component={HelpPage} />
          <GuestRoute exact path={landing_path} component={LandingPage} />
          <Route exact path={restaurant_path} render={() => <RestaurantWrapper component={RestaurantPage} />} />
          <Route exact path={home_path} component={HomePage} />
          <Route exact path={password_reset_path} component={PasswordReset} />
          <Route exact path={accept_invitation_path} component={AcceptInvitation} />
          <Route exact path={register_confirmation_path} component={RegisterConfirmation} />
          <Route exact path={full_menu_path} component={RestaurantMenu} />
          <AuthRoute
            path={booking_reservation_path}
            render={() => <BookingPageWrapper component={ManageBookingPage} />}
            // component={<ReservationPageWrapper component={ReservationPage} />} />}
          />
          <AuthRoute redirectTo={home_path} exact path={table_number_page_path} component={ChooseTable} />
          <AuthRoute redirectTo={home_path} exact path={qr_order_landing_path} component={QrOrderLanding} />
          <Route redirectTo={home_path} exact path={booking_page_path} component={BookingPage} />
          <AnonymousRedirectRoute redirectTo={login_path} exact path={profile_page_path} component={ProfilePage} />
          <AnonymousRedirectRoute redirectTo={login_path} exact path={password_change_path} component={ProfilePWPage} />
          <AnonymousRedirectRoute
            redirectTo={login_path}
            exact
            path={credit_card_information_path}
            component={CreditCardInformationPage}
          />
          <AnonymousRedirectRoute
            redirectTo={login_path}
            exact
            path={loyalty_program_path}
            component={LoyaltyProgramPage}
          />

          {/* <AuthRoute redirectTo={login_path} exact path={booking_success_path} component={BookingSuccessPage} /> */}
          <AuthRoute redirectTo={login_path} path={[order_page_path, booking_order_page_path]} component={OrderPage} />
          <AuthRoute redirectTo={login_path} path={[past_order_page_path]} component={PastOrderPage} />
          <AuthRoute redirectTo={login_path} path={order_first_time_path} component={CreateOrder} />
          <AuthRoute redirectTo={login_path} path={just_pay_menu_path} component={JustPayMenuPage} />
          <AuthRoute redirectTo={login_path} path={just_pay_table_order_check_path} component={JustPayCheckPage} />
          <AuthRoute redirectTo={login_path} path={just_pay_table_order_path} component={JustPayOrderPage} />
          <AuthRoute redirectTo={login_path} path={just_pay_table_orders_path} component={JustPayOrdersPage} />
          <AuthRoute
            redirectTo={login_path}
            path={[reservations_path_now]}
            render={() => <ReservationsPageWrapper component={CurrentReservationPage} />}
          />
          <AuthRoute
            redirectTo={login_path}
            path={[reservations_path_past]}
            render={() => <ReservationsPageWrapper component={PastReservationsPage} />}
          />
          <AuthRoute
            redirectTo={login_path}
            path={[reservations_path_upcoming]}
            render={() => <ReservationsPageWrapper component={UpcomingReservationsPage} />}
          />
          <Redirect to={{ pathname: landing_path }} />
        </Switch>
        <AuthVerify />
        {/* Used so that the FloatingIsland does not cover the other components */}
        {<div style={{ height: "4rem" }}></div>}
      </div>
    </>
  );
}

export default App;
