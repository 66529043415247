import React from "react";
import Modal from "../../modal/Modal";
import ModalHeading from "../../common/ModalHeading";
import CustomButton from "../../common/CustomButton";
import OutlineButton from "../../common/OutlineButton";
import "./styles/SendToKitchenModal.css";
import { useSelector } from "react-redux";

function UseLoyaltyCreditsModal({ show, close, onSend }) {
  const {
    orders: { loyaltyOnCurrentRestaurant },
  } = useSelector((state) => state);

  const currentPoints = loyaltyOnCurrentRestaurant?.current_points;

  return (
    <Modal isPopUp className="pop-up-modal sent-to-kitchen-modal send-to-kitchen-modal" show={show} close={close}>
      <ModalHeading title={"Loyalty Program"} />

      <div className="users-info-wrapper">
        <p>
          Your current balance is {currentPoints} loyalty credits. Would you like to use loyalty credits on this order?
        </p>
      </div>

      <div className={"send-to-kitchen-modal-buttons"}>
        <OutlineButton text={"No"} onClick={close} />
        <CustomButton text={"Yes"} onClick={onSend} />
      </div>
    </Modal>
  );
}

export default UseLoyaltyCreditsModal;
