import React from "react";
import Modal from "../modal/Modal";
import ChefImage from "../../assets/chef.svg";
import "./SentToKitchenModal.css";
import CustomButton from "../common/CustomButton";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { sent_to_kitchen_modal_out_of_stock_items } from "../../config/modal_paths";
import { useSelector } from "react-redux";

function SentToKitchenModal({ show, close }) {
  const {
    feedback: { activeModalMetadata, activeModal },
  } = useSelector((state) => state);

  return (
    <Modal isPopUp className="sent-to-kitchen-modal chef-is-doing-the-magic-modal" show={show} close={close}>
      <div>
        <LazyLoadImage alt="chef" className="chef-img" src={ChefImage} loading="lazy" width={65} height={86} />
        <h3>Chef is doing the magic</h3>
        <p>You can now sit back, relax and enjoy your dining experience.</p>

        {activeModal === sent_to_kitchen_modal_out_of_stock_items && (
          <>
            <p>
              <span>
                Please note, we are currently out of stock of the following menu{" "}
                {activeModalMetadata?.names?.length > 1 ? "items" : "item"}:{" "}
                <strong>
                  {activeModalMetadata?.names?.map((name, i, { length }) =>
                    length - 1 === i ? " " + name : " " + name + ",",
                  )}
                </strong>
                .
              </span>
            </p>

            <p>Please try another tasty option instead.</p>
          </>
        )}
        <CustomButton
          style={{ marginTop: "1rem" }}
          onClick={close}
          text={"Okay"}
          wrapperClassName="sent-to-kitchen-button"
          textClassName="cancel-reservation-btn-text"
        />
      </div>
    </Modal>
  );
}

export default SentToKitchenModal;
