import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import "../../components/sideMenu/SideMenu.css";
import {
  aboutus_page_path,
  credit_card_information_path,
  help_page_path,
  loyalty_program_path,
  password_change_path,
  privacy_page_path,
  profile_page_path,
  terms_page_path,
} from "../../config/pages_paths";
import { useHistory, useLocation } from "react-router";

import { useSelector } from "react-redux";

function NavigationSideMenu() {
  const {
    auth: { isAuthenticated, user },
    versionConfig: { navbar_links: navbarLinks },
  } = useSelector((state) => state);

  const history = useHistory();
  const location = useLocation();

  const profileActive = useRef("side-menu-x-line.active");

  const listItems = [
    { text: "About us", onClick: () => history.push(aboutus_page_path), path: aboutus_page_path },
    { text: "Help", onClick: () => history.push(help_page_path), path: help_page_path },
    { text: "Terms and conditions", onClick: () => history.push(terms_page_path), path: terms_page_path },
    { text: "Privacy policy", onClick: () => history.push(privacy_page_path), path: privacy_page_path },
    isAuthenticated && {
      text: "Your profile",
      onClick: () => history.push(profile_page_path),
      path: profile_page_path,
    },
    isAuthenticated &&
      !user.is_anonymous && {
        text: "Your password",
        onClick: () => history.push(password_change_path),
        path: password_change_path,
      },
    isAuthenticated &&
      !user.is_anonymous && {
        text: "Credit cards",
        onClick: () => history.push(credit_card_information_path),
        path: credit_card_information_path,
      },
    isAuthenticated &&
      !user.is_anonymous && {
        text: "Loyalty program",
        onClick: () => history.push(loyalty_program_path),
        path: loyalty_program_path,
      },
  ];

  useEffect(() => {
    navbarLinks !== null &&
      navbarLinks &&
      profileActive.current.scrollIntoView({ behavior: "auto", block: "center", inline: "center" });
  }, [profileActive, navbarLinks]);

  return navbarLinks !== null && navbarLinks ? (
    <ul className="side-menu-x">
      {listItems.map((item, idx) => {
        return (
          item && (
            <li key={item.path ?? idx} onClick={item.onClick} className={classnames("side-menu-x-item off-item")}>
              {location.pathname === item.path ? (
                <div className={classnames("side-menu-x-line active")} ref={profileActive} />
              ) : (
                <div className={classnames("side-menu-x-line")} />
              )}
              <span
                className={classnames("side-menu-x-text", {
                  active: location.pathname ? location.pathname === item.path : item.active,
                })}
              >
                {item.text}
              </span>
            </li>
          )
        );
      })}
    </ul>
  ) : null;
}

export default NavigationSideMenu;
