import { BASE_URL } from "./keys";

const authentication = {
  apple: {
    clientId: "com.voilaApp.servicesId",
    redirectURI: `${BASE_URL}/api/auth/login/apple`,
    usePopup: true,
    responseMode: "form_post",
    scope: "name email",
  },
};

export default authentication;
