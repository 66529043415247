import classnames from "classnames";
import React, { useEffect, useState } from "react";
import "./SelectableTiles.css";

const SelectableTilesButton = ({
  onClick,
  children,
  disabled,
  active,
  setIsPayForYourselfSelected,
  showAsParagraph,
}) => {
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    if (active !== undefined) setIsActive(active);
  }, [active]);

  const handleOnClick = () => {
    if (setIsPayForYourselfSelected) setIsPayForYourselfSelected(true);
    setIsActive(!isActive);
    onClick();
  };
  return (
    <>
      {!showAsParagraph ? (
        <button
          disabled={disabled}
          onClick={handleOnClick}
          className={classnames("btn selectable-tiles-button", { active: isActive })}
        >
          {children}
        </button>
      ) : (
        <p className="btn selectable-tiles-names">{children}</p>
      )}
    </>
  );
};

export default SelectableTilesButton;
