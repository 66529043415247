import React from "react";
import classnames from "classnames";
import SeparationLine from "../common/SeparationLine";

function ReservationInfoLabel({ label, info, bookingStatus, remainder, table, style, className }) {
  return (
    <div style={style} className={classnames("reservation-info-container", className)}>
      <div style={{ display: "flex", justifyContent: "center" }}>{bookingStatus}</div>
      {/* <div className="reservation-label">
        <span className="text-right">{label ? label + ':' : null}</span>
      </div> */}
      {/* {remainder} */}
      <SeparationLine />
      {/* <div className={classnames('reservation-info')}>{info}</div> */}
      <div style={{ margin: "2rem 0 0" }}>{info}</div>
      {/* <div className={classnames('reservation-table')}>{table}</div> */}
    </div>
  );
}

export default ReservationInfoLabel;
