import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { booking_reservation_path } from "../../config/pages_paths";
import Column from "../../containers/layout/Column";
import Row from "../../containers/layout/Row";
import { setInvitationDetails, setInvitationToken } from "../../pages/book/services/actions";
import CustomButton from "../common/CustomButton";
import Modal from "../modal/Modal";

function AcceptInvitationSuccessModal({ show, close }) {
  const {
    booking: { invitationDetails },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const onCloseHandler = () => {
    history.push(
      booking_reservation_path
        .replace(":restaurantId", invitationDetails.reservation.restaurant_id)
        .replace(":orderId", invitationDetails.reservation.order_id),
    );
    close();
  };

  useEffect(() => {
    return () => {
      dispatch(setInvitationDetails(""));
      dispatch(setInvitationToken(""));
    };
  }, [dispatch]);

  return (
    <Modal isPopUp className="sent-to-kitchen-modal" show={show} close={onCloseHandler}>
      {/* <ModalHeading onClick={onCloseHandler} title="Invitation accepted" /> */}
      <h3 style={{ display: "flex", justifyContent: "center" }}>Invitation accepted</h3>
      <Row>
        {/* <SeparationLine /> */}
        <Column className="text-center" col={12}>
          <h3 style={{ margin: "1rem 0 0.5rem" }}>Thank you for confirming</h3>
          <p>We will let {invitationDetails.host} know that you are coming</p>
        </Column>
        <CustomButton
          style={{ marginTop: "1rem" }}
          onClick={onCloseHandler}
          text={"Okay"}
          wrapperClassName="sent-to-kitchen-button"
          textClassName="cancel-reservation-btn-text"
        />

        {/* <SeparationLine /> */}
      </Row>
    </Modal>
  );
}

export default AcceptInvitationSuccessModal;
