import React from "react";
import Row from "../../containers/layout/Row";
import Column from "../../containers/layout/Column";
import RestaurantsGrid from "../restaurants/RestaurantsGrid";

function FeaturedRestaurants({ restaurants }) {
  return (
    <Row>
      <Column md="10" className="text-center featured-restaurants-title">
        <h3>Featured restaurants</h3>
      </Column>
      <Column className="landing-page-restaurants-img-wrapper" md="12" lg="12" xl={10}>
        <RestaurantsGrid restaurants={restaurants} />
      </Column>
    </Row>
  );
}

export default FeaturedRestaurants;
