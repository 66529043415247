import { SET_ERRORS } from "../../config/actionTypes";
import { getAccessToken, logoutUser } from "../../pages/auth/services/actions";
import { setTimesAvailable } from "../../pages/book/services/actions";
import { setToastMessage } from "./feedbackActions";

export const setErrors = (err, cb) => (dispatch) => {
  if (err.response) {
    switch (err.response.status) {
      case 401:
        err.response.data === "jwt expired" || err.response.data === "Unauthorized"
          ? dispatch(getAccessToken(cb))
          : dispatch(logoutUser());
        break;
      case 400:
        if (Array.isArray(err.response.data)) {
          err.response.data.forEach((res) => {
            if (res?.errors.name === "ZodError") {
              // if (res.type === 'Body') {
              dispatch(
                setToastMessage({
                  message: res.errors.issues.map((err) => err.message).join(". "),
                  severity: "error",
                  timer: 10000,
                }),
              );
              // }
              //Add check for query and params
            }
          });
        }
        if (err.response.data.message) {
          if (err.response.data.message.hasOwnProperty("timesAvailable")) {
            dispatch(setTimesAvailable(err.response.data.message.timesAvailable));
            dispatch(
              setToastMessage({
                message: "Choose another time period",
                severity: "error",
              }),
            );
          } else if (err.response.data.message.hasOwnProperty("overlappingReservation1")) {
            dispatch(
              setToastMessage({
                message: "Overlapping reservation! Choose another time",
                severity: "error",
              }),
            );
          } else if (
            err.response.data.message.hasOwnProperty("new_password") ||
            err.response.data.message.hasOwnProperty("email") ||
            err.response.data.message.hasOwnProperty("") ||
            err.response.data.message.hasOwnProperty("phone") ||
            err.response.data.message.hasOwnProperty("confirm_password")
          ) {
            // Find the first property that caused the error
            const errorMessageProperty = Object.keys(err.response.data.message).find(
              (key) => err.response.data.message.hasOwnProperty(key) && err.response.data.message[key],
            );

            const errorMessage = err.response.data.message[errorMessageProperty];

            dispatch(
              setToastMessage({
                message: errorMessage || "An error occurred.",
                severity: "error",
              }),
            );
          } else
            dispatch(
              setToastMessage({
                message: err.response.data.short_message
                  ? err.response.data.short_message.toString() !== "[object Object]"
                    ? err.response.data.short_message.toString()
                    : "Error occurred"
                  : "Error occurred",
                severity: "error",
              }),
            );
        }
        dispatch(setErrorsAction(err.response.data ? err.response.data.message : err.response.data));
        break;
      default:
        return dispatch(setErrorsAction(err.response.data.message));
    }
  } else {
    dispatch({
      type: SET_ERRORS,
      payload: err,
    });
  }
};

const setErrorsAction = (err) => (dispatch) => {
  return dispatch({ type: SET_ERRORS, payload: err ? err : {} });
};
