import React from "react";
import classnames from "classnames";

function ExternalLoginButton({ img, onClick, className }) {
  return (
    <div onClick={onClick} className={classnames("external-login-btn", className)}>
      <img alt="fb-login" src={img} className="external-login-img" />
    </div>
  );
}

export default ExternalLoginButton;
