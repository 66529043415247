import React, { useEffect, useState } from "react";
import { reservations_path_now, reservations_path_past, reservations_path_upcoming } from "../../config/pages_paths";
import { useHistory, useLocation } from "react-router";
import classnames from "classnames";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";

// const reservationTypes = [
//     {
//         label: "Past",
//         path: reservations_path_past,
//         active: false,
//         indicator: null,
//     },
//     {
//         label: "Now",
//         path: reservations_path_now,
//         active: false,
//         indicator: null,
//     },
//     {
//         label: "Upcoming",
//         path: reservations_path_upcoming,
//         active: false,
//         indicator: null,
//     },
// ]

const ReservationTypes = ({
  upcomingBadge,
  ongoingBadge,
  // pastBadge,
}) => {
  const history = useHistory();
  const location = useLocation();

  const [reservationTypes, setReservationTypes] = useState([
    {
      label: "Upcoming",
      path: reservations_path_upcoming,
      active: false,
      indicator: null,
    },
    {
      label: "Now",
      path: reservations_path_now,
      active: false,
      indicator: null,
    },
    {
      label: "Past",
      path: reservations_path_past,
      active: false,
      indicator: null,
    },
  ]);

  useEffect(() => {
    setReservationTypes((prevReservationTypes) => {
      return prevReservationTypes.map((type) => ({
        ...type,
        active: type.path === location.pathname,
      }));
    });
  }, [location.pathname]);

  return (
    <ul className="side-menu-x">
      {reservationTypes.map((type, idx) => (
        <li key={idx} onClick={() => history.push(type.path)} className="side-menu-wrapper">
          <div className="side-menu-x-item off-item">
            <div
              className={classnames("side-menu-x-line", {
                active: type.active,
              })}
            />
            <span
              className={classnames("side-menu-x-text", {
                active: type.active,
              })}
            >
              {capitalizeFirstLetter(type.label)}
            </span>
          </div>

          {((type.label === "Upcoming" && upcomingBadge > 0) || (type.label === "Now" && ongoingBadge > 0)) && (
            // || (type.label === "Past" && pastBadge > 0)
            <div className="side-menu-badge-container">
              <div className="side-menu-badge">
                {type.label === "Upcoming" && upcomingBadge}
                {type.label === "Now" && ongoingBadge}
                {/* {type.label === "Past" && pastBadge} */}
              </div>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

export default ReservationTypes;
