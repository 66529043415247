let url = window.location.origin;
url = url.replace("3000", "5005");

export const BASE_URL = process.env.REACT_APP_BASE_URL || url;

export const defaultTipPercentage = 17;

// values for local testing
// if expirationTimer DurationTime is 1 minute and VisibilityStartTime is 50 seconds
// that means the timer will be hidden for 10 seconds, it will be shown the remaining (last) 50 seconds
// export const expirationTimerDurationTime = 1 // in minutes
// export const expirationTimerVisibilityStartTime = 50 // in seconds
// export const expirationTimerShakingAnimationStartTime = 42 // in seconds, the timer should be 2 seconds earlier because of the delay
// export const expirationTimerButtonTiltAndMoveShakingAnimationStartTime = 15 // in seconds

// values for production
// if expirationTimer DurationTime is 20 minutes and VisibilityStartTime is 2 minutes
// that means the timer will be hidden for 18 minutes, it will be shown the remaining (last) 2 minutes
export const expirationTimerDurationTime = 20; // in minutes
export const expirationTimerVisibilityStartTime = 120; // in seconds
export const expirationTimerShakingAnimationStartTime = 112; // 1 * 60 + 52 = "01:52", the timer should be 2 seconds earlier because of the delay
export const expirationTimerButtonTiltAndMoveShakingAnimationStartTime = 15; // in seconds

// if animationShowingTime is 2 seconds and animationTotalAppearingTime is 5 seconds
// that means the animation will occur for 2 seconds, it will be paused for 3 seconds
export const expirationTimerShakingAnimationShowingTime = 2; // in seconds
export const expirationTimerShakingAnimationTotalApperingTime = 5; // in seconds

export const considerUserIdleAfterXAmoutOfTime = 4; // in minutes

// loyalty process
export const loyaltyProcessTimerDurationTime = 1; // in minutes
