import React, { Fragment } from "react";
import Backdrop from "../../modal/Backdrop";
import "./LoadingSpinner.css";

const LoadingSpinner = () => {
  return (
    <Fragment>
      <Backdrop isPopUp show={true} />
      <div className="roller-wrapper">
        <div
          className="lds-roller"
          style={{
            display: "fixed",
            opacity: "1",
          }}
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoadingSpinner;
