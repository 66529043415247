import {
  SET_CHECKOUT_INFO,
  SET_NEW_ORDER_DETAILS,
  SET_ORDER_NEXT_ROUTE,
  SET_ORDERS,
  SET_ORDERS_ROUNDS,
  SET_ORDERS_NO_ROUNDS,
  SET_LAST_ROUND_ORDERS,
  SET_PAYMENT_METHODS_FOR_USER,
  SET_USER_RATING,
  SET_BOOKED_RESERVATION_RETURN,
  SET_CLEAR_RESERVATION_ORDERS,
  SET_TABLE_NUMBER,
  SET_HAS_NEW_ORDER,
  SET_SHOW_ADD_ITEM_MODAL,
  SET_USER_TRANSACTION_LOYALTY_PROGRAM,
  SET_USER_LOYALTY_PROGRAM,
  SET_APPLIED_LOYALTY_POINTS,
  SET_USER_TRANSACTIONS_LOYALTY_PROGRAM,
  SET_USER_LOYALTY_PROGRAM_ACCUMULATED_POINTS,
  SET_USERS_LOYALTY_PROGRAM_ON_ORDER,
  SET_LOYALTY_ON_CURRENT_RESTAURANT,
  SET_LOYALTY_PROCESS,
  SET_USER_LOYALTY_STEP,
  SET_ALL_GUEST_SELECTED_LOYALTY_OPTION,
  SET_CHOOSE_IF_GUEST_WANT_TO_USE_CREDITS_MODAL,
} from "../../../config/actionTypes";
import { reservations_path_now } from "../../../config/pages_paths";

const initialState = {
  orders: [],
  ordersRounds: [],
  ordersNoRounds: [],
  onNextRoute: { path: reservations_path_now, tabIndex: 1 },
  checkoutInfo: { orders: [], totalTip: 10, tip: 0, total: 0, sections: [] },
  newOrderDetails: {},
  paymentMethods: [],
  lastRoundOrders: [],
  menuItemsQuantity: [],
  sectionItemsQuantity: [],
  userRating: {},
  bookedReservationReturn: null,
  tableNumber: null,
  hasNewOrder: false,
  showAddItemModal: false,
  orderRoundsUserQuantityBasedOffStep: [],
  userTransactionLoyaltyProgram: {},
  userTransactionsLoyaltyProgram: [],
  userLoyaltyProgram: [],
  appliedLoyaltyPoints: 0,
  userLoyaltyProgramAccumulatedPoints: {},
  usersLoyaltyProgramOnOrder: [],
  loyaltyOnCurrentRestaurant: {},
  loyaltyProcess: {},
  userLoyaltyStep: "",
  allGuestSelectedLoyaltyOption: false,
  chooseIfGuestWantToUseCreditsModal: false,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERS:
      return { ...state, orders: action.payload };
    case SET_ORDERS_ROUNDS:
      // Step 1: Find the object where the round prop is null
      const roundNullObject = action.payload.find((round) => round.round === null);
      let updatedUsers = [];
      // Only update if the round null object is found
      if (roundNullObject) {
        // Map through the users and update their orders
        updatedUsers = roundNullObject.users
          .map((user) => {
            // Initialize an object to hold the aggregated quantities for each step
            const quantityStepsMap = {};
            let ordersTotalQuantity = 0;

            // Iterate through the orders to aggregate quantities for each step
            user.orders.reduce((accumulator, item) => {
              const { step, quantity } = item;
              const stepKey = step || "Other";
              accumulator[stepKey] = (accumulator[stepKey] || 0) + quantity; // Accumulate quantities
              ordersTotalQuantity += quantity;
              return accumulator;
            }, quantityStepsMap);

            // Convert the quantityStepsMap object to an array of objects
            const quantitySteps = Object.keys(quantityStepsMap).map((step) => ({
              step,
              quantity: quantityStepsMap[step],
            }));

            return {
              ...user,
              quantitySteps,
              ordersTotalQuantity,
            };
          })
          .sort((a, b) => {
            if (a.is_present && b.is_present) {
              if (a.ordersTotalQuantity > 0 && b.ordersTotalQuantity > 0) {
                return 0; // Maintain the original sorting for present users with more than 0 orders
              }
              if (a.ordersTotalQuantity > 0 && b.ordersTotalQuantity === 0) {
                return -1; // Move present users with more than 0 orders before present users with 0 orders
              }
              if (a.ordersTotalQuantity === 0 && b.ordersTotalQuantity > 0) {
                return 1; // Move present users with 0 orders after present users with more than 0 orders
              }
            }
            if (a.is_present && !b.is_present) {
              return -1; // Move present users before not present users
            }
            if (!a.is_present && b.is_present) {
              return 1; // Move not present users after present users
            }
            return 0; // Maintain the original order for users with the same is_present status
          });
      }

      // Return the updated round null object
      return {
        ...state,
        ordersRounds: action.payload,
        orderRoundsUserQuantityBasedOffStep: updatedUsers,
      };

    case SET_ORDERS_NO_ROUNDS:
      return { ...state, ordersNoRounds: action.payload };
    case SET_LAST_ROUND_ORDERS:
      const menuItemsQuantity = action.payload?.reduce(
        (acc, { menu_id: menuId, quantity }) => ({ ...acc, [menuId]: (acc[menuId] || 0) + quantity }),
        {},
      );
      const sectionItemsQuantity = action.payload?.reduce(
        (acc, { section_id: sectionId, quantity }) => ({ ...acc, [sectionId]: (acc[sectionId] || 0) + quantity }),
        {},
      );
      // returns duplicate ids once as Object key and the sum of their quantity as values

      const menuItemsQuantityResult = Object.keys(menuItemsQuantity)?.map((item) => ({
        menuId: +item,
        quantity: menuItemsQuantity[item],
      }));
      const sectionItemsQuantityResult = Object.keys(sectionItemsQuantity)?.map((item) => ({
        sectionId: +item,
        quantity: sectionItemsQuantity[item],
      }));
      // creates keys for the menu and section ids and their quantity so its more accessable

      return {
        ...state,
        lastRoundOrders: action.payload,
        menuItemsQuantity: menuItemsQuantityResult,
        sectionItemsQuantity: sectionItemsQuantityResult,
      };

    case SET_ORDER_NEXT_ROUTE:
      return { ...state, onNextRoute: action.payload };
    case SET_CHECKOUT_INFO:
      return { ...state, checkoutInfo: action.payload };
    case SET_NEW_ORDER_DETAILS:
      return { ...state, newOrderDetails: action.payload };
    case SET_PAYMENT_METHODS_FOR_USER:
      return {
        ...state,
        paymentMethods: action.payload.sort((a, b) => {
          //sorts payment methods by id, always returning the active first
          if (a.isSelected === b.isSelected) {
            return a.id - b.id;
          } else {
            return a.isSelected ? -1 : 1;
          }
        }),
      };
    case SET_USER_RATING:
      return { ...state, userRating: action.payload };
    case SET_BOOKED_RESERVATION_RETURN:
      return { ...state, bookedReservationReturn: action.payload };
    case SET_CLEAR_RESERVATION_ORDERS:
      return initialState;
    case SET_TABLE_NUMBER:
      return { ...state, tableNumber: action.payload };
    case SET_HAS_NEW_ORDER:
      return { ...state, hasNewOrder: action.payload };
    case SET_SHOW_ADD_ITEM_MODAL:
      return { ...state, showAddItemModal: action.payload };
    case SET_USER_TRANSACTION_LOYALTY_PROGRAM:
      return { ...state, userTransactionLoyaltyProgram: action.payload };
    case SET_USER_TRANSACTIONS_LOYALTY_PROGRAM:
      return { ...state, userTransactionsLoyaltyProgram: action.payload };
    case SET_USER_LOYALTY_PROGRAM:
      return { ...state, userLoyaltyProgram: action.payload };
    case SET_APPLIED_LOYALTY_POINTS:
      return { ...state, appliedLoyaltyPoints: action.payload };
    case SET_USER_LOYALTY_PROGRAM_ACCUMULATED_POINTS: {
      let pointsSummary = action.payload.reduce(
        (accumulator, current) => {
          accumulator.total_points += +current.total_points;
          accumulator.current_points += +current.current_points;
          accumulator.redeemed_points += +current.redeemed_points;
          return accumulator;
        },
        { total_points: 0, current_points: 0, redeemed_points: 0 },
      );

      // Convert to fixed-point notation with 2 digits after the decimal point
      pointsSummary.total_points = parseFloat(pointsSummary.total_points.toFixed(2));
      pointsSummary.current_points = parseFloat(pointsSummary.current_points.toFixed(2));
      pointsSummary.redeemed_points = parseFloat(pointsSummary.redeemed_points.toFixed(2));

      return { ...state, userLoyaltyProgramAccumulatedPoints: pointsSummary };
    }
    case SET_USERS_LOYALTY_PROGRAM_ON_ORDER:
      return { ...state, usersLoyaltyProgramOnOrder: action.payload };
    case SET_LOYALTY_ON_CURRENT_RESTAURANT:
      return { ...state, loyaltyOnCurrentRestaurant: action.payload };
    case SET_LOYALTY_PROCESS:
      return { ...state, loyaltyProcess: action.payload };
    case SET_USER_LOYALTY_STEP:
      return { ...state, userLoyaltyStep: action.payload };
    case SET_ALL_GUEST_SELECTED_LOYALTY_OPTION:
      return { ...state, allGuestSelectedLoyaltyOption: action.payload };
    case SET_CHOOSE_IF_GUEST_WANT_TO_USE_CREDITS_MODAL:
      return { ...state, chooseIfGuestWantToUseCreditsModal: action.payload };
    default:
      return state;
  }
};

export default orderReducer;
