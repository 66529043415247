import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { timeToAMPM2 } from "../../utils/timeToAMPM";
import { just_pay_menu_path, just_pay_table_order_path } from "../../config/pages_paths";
import { getOrdersByTable } from "../../store/actions/justPayActions";
import useFloatingIslandActions from "../../hooks/useFloatingIslandActions";
import ListCheck from "../../components/justpay/ListCheck";
import StatusMessage from "../../components/common/status/StatusMessage";
import "../../components/reservations/ReservationListItem.css";
import "./JustPay.css";

const JustPayOrdersPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tableGuid, restaurantGuid } = useParams();
  const {
    justPay: {
      orders: { entities: orders, loading, error },
    },
    versionConfig: { just_pay_complex_prod_bussiness_logic },
  } = useSelector((state) => state);

  const { DOMslot1, goToJustPayMenu, createButtonPortal } = useFloatingIslandActions();

  const unclosedOrders = useMemo(
    () =>
      orders?.filter(
        (order) => order?.checks && order.checks[0].checkout?.balanceDue > 0,
        // TBD if we will use another toggle
        // just_pay_count_order_as_closed_if_the_first_check_was_paid
        //   ? order?.checks && order.checks[0].checkout?.balanceDue > 0
        //   : order?.checkout?.balanceDue > 0,
      ),
    [orders],
  );

  useEffect(() => {
    if (restaurantGuid && tableGuid) {
      dispatch(getOrdersByTable(restaurantGuid, tableGuid));
    }
  }, [dispatch, restaurantGuid, tableGuid]);

  const handleOpenTableOrder = useCallback(
    (orderGuid) => {
      history.replace(
        just_pay_table_order_path
          .replace(":restaurantGuid", restaurantGuid)
          .replace(":tableGuid", tableGuid)
          .replace(":orderGuid", orderGuid),
      );
    },
    [history, restaurantGuid, tableGuid],
  );

  useEffect(() => {
    if (just_pay_complex_prod_bussiness_logic && unclosedOrders.length === 1) {
      // TBD
      // prvo nosi na order, pa ponatamu na check
      // redirect direkno na prv check
      handleOpenTableOrder(unclosedOrders[0].guid);
    }
  }, [just_pay_complex_prod_bussiness_logic, unclosedOrders, handleOpenTableOrder]);

  const navigateToJustPayMenuPage = () => {
    history.push(just_pay_menu_path.replace(":restaurantGuid", restaurantGuid).replace(":tableGuid", tableGuid));
  };

  if (loading) return <StatusMessage statusText="Loading..." message="Please wait" />;
  if (error) return <StatusMessage status={error.status} statusText={error.statusText} message={error.message} />;

  return (
    <div className="just-pay-page-container">
      {just_pay_complex_prod_bussiness_logic !== null && just_pay_complex_prod_bussiness_logic !== undefined && (
        <>
          {just_pay_complex_prod_bussiness_logic ? (
            <>
              {unclosedOrders.length === 1 && (
                <StatusMessage statusText="List of orders" message="Please wait to be redirected" />
              )}

              {unclosedOrders.length > 1 && (
                <>
                  <StatusMessage
                    statusText="More than one open order on this table"
                    message="We sincerely apologize, currently you can not pay on the Voila app. Please contact the restaurant stuff."
                  />
                </>
              )}

              {unclosedOrders.length === 0 && (
                // No open orders on this table yet, Please wait for your server to set it up for you
                <StatusMessage statusText="No open orders on this table" message="Please wait for your server to set it up" />
              )}
            </>
          ) : (
            <>
              {orders.length > 0 ? (
                <>
                  <p className="just-pay-page-title">
                    List of orders
                    {/* on table number */}
                  </p>

                  {orders?.map((order) => (
                    <ListCheck
                      key={order.guid}
                      onClick={() => handleOpenTableOrder(order.guid)}
                      guid={order.guid}
                      displayNumber={order.displayNumber}
                      date={timeToAMPM2(order.createdDate)}
                    />
                  ))}
                </>
              ) : (
                // No orders found on this table for today
                <StatusMessage statusText="No open orders on this table" message="Please wait for your server to set it up" />
              )}
            </>
          )}

          {createButtonPortal(goToJustPayMenu(navigateToJustPayMenuPage), DOMslot1)}
        </>
      )}
    </div>
  );
};

export default JustPayOrdersPage;
