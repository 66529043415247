import React from "react";
import Modal from "../modal/Modal";
import "./OrderingNotAvailableModal.css";

function OrderingNotAvailableModal({ show, close }) {
  return (
    <Modal className="service-not-available-modal" isPopUp show={show} close={close}>
      <h2>
        Unfortunately we can't place your order at the moment <br />
        Please try again later
      </h2>
    </Modal>
  );
}

export default OrderingNotAvailableModal;
