import { Switch } from "@mui/material";
import React from "react";

function EmailMarketingOpt({ onChange, checked = true }) {
  return (
    <div className="offers-switch-container">
      <div>
        <p>Receive restaurant emails</p>
        <span>Sign me up to receive dining offers and news</span>
      </div>
      <Switch
        onChange={onChange}
        checked={checked}
        sx={{
          "& .MuiSwitch-thumb": {
            backgroundColor: "#01003b",
          },
          "& .MuiSwitch-track": {
            backgroundColor: "#01003b",
          },
        }}
      />
    </div>
  );
}

export default EmailMarketingOpt;
