import { roundingTypesEnum } from "../enums/roundingTypesEnum";
import { roundHalfEven } from "./roundHalfEven";

/**
 * Rounds a number based on the rounding type.
 * @param number {number}
 * @param roundingType {'HALF_UP'|'HALF_EVEN'|'ALWAYS_UP'|'ALWAYS_DOWN'}
 * @param decimals {number}
 * @returns {number}
 */
export function roundNumber(number, roundingType, decimals = 2) {
  switch (roundingType) {
    case roundingTypesEnum.HALF_UP:
      return (
        Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals)
      );
    case roundingTypesEnum.HALF_EVEN:
      return roundHalfEven(number);
    case roundingTypesEnum.ALWAYS_UP:
      return (
        Math.ceil(number * Math.pow(10, decimals)) / Math.pow(10, decimals)
      );
    case roundingTypesEnum.ALWAYS_DOWN:
      return (
        Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals)
      );
    default:
      throw new Error("Invalid rounding type");
  }
}
