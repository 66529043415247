const formatPrice = (price) => {
  let numOfDecimals = price.split(".")[price.split(".").length - 1].split("").length;
  return new RegExp(/[.]/g).test(price.toString())
    ? numOfDecimals === 1
      ? price.toString().padEnd(price.length + 1, "0")
      : price.toString()
    : price
        .toString()
        .padEnd(price.length + 1, ".")
        .padEnd(price.length + 3, "0");
};

export default formatPrice;
