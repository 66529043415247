const errorMessages = {
  reservationNotFound: "We apologize for the inconvenience, but we couldn't find the reservation you're looking for.",
  reservationCancelled:
    "We apologize for the inconvenience, your reservation is cancelled. If you believe this is a mistake please contact the restaurant staff.",
  reservationCancelledByGuest: "You cancelled this reservation.",
  reservationManuallyClosed:
    "We apologize for the inconvenience, your order is manually closed. You can pay your check on a POS terminal, please contact the restaurant staff. If your check is already settled, disregard this message.",
  reservationExpired: "Your order session expired. Please order again and complete your order.",
  reservationNoShow:
    "You did not show on time. Please book again. If you believe this is a mistake please contact the restaurant staff.",
  reservationNotEligibleToStartBookedOrder: "You can submit your orders only within 1h of the booked time.",
  reservationNotArrivedYet: "On arrival, please inform the host desk that you booked through Voila",
  moreGuestsThanBookedSizeParty:
    "There are more guests invited than the reserved size of party. Please modify the reservation on OpenTable.",
  createReservationError:
    "We encountered an issue while attempting to set up your order. We apologize for the inconvenience, please contact the restaurant staff.",
};

export default errorMessages;
