import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { booking_order_page_path, home_path, order_page_path, past_order_page_path } from "../../config/pages_paths";
import { orderStatusEnum } from "../../enums/orderStatusEnum";
import useSocket from "../../hooks/useSocket";
import { setRefreshDataForOpenedMenuItem } from "../../store/actions/feedbackActions";
import { getReservationDetails, setReservationDetails } from "../book/services/actions";
import { getCurrentReservation } from "../reservations/services/actions";
import { setModifiersForMenuItem, setSelectedModifiersForOrderItem } from "../restaurant/services/actions";
import "./OrderPage.css";
import { addItemToOrder, getLastRoundOrders } from "./services/actions";
import RestaurantMenu from "../menu/RestaurantMenu";
import { initialReservationDetails } from "../book/services/reducers";

function OrderPage() {
  const {
    // restaurant: { restaurant, },
    auth: {
      user: { id },
    },
    booking: { currentOrder, reservationDetails },
    orders: { lastRoundOrders },
    feedback: { refreshDataForOpenedMenuItem, toast },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const [connectSocket, setConnectSocket] = useState(true);
  const { orderId } = useParams();

  const history = useHistory();
  const location = useLocation();

  // useEffects

  useEffect(() => {
    if (orderId === "undefined" || orderId === undefined) {
      history.push(home_path);
    }
  }, [orderId, history]);

  useEffect(() => {
    // if (orderId)
    dispatch(getReservationDetails(+orderId, history));
    return () => dispatch(setReservationDetails(initialReservationDetails));
  }, [dispatch, orderId, history]);

  // useEffect(() => {
  //   if (!isEmpty(reservationDetails?.reservation)) {
  //     dispatch(getFullMenu(reservationDetails.reservation.restaurant_id, reservationDetails.reservation.order_id, false, history))
  //   }
  // }, [dispatch, reservationDetails, history])

  useEffect(() => {
    if (orderId) {
      dispatch(getCurrentReservation());
      // dispatch(getOrders(orderId))
      // dispatch(getRestaurants())
      // dispatch(getReservations())
    }
  }, [orderId, dispatch]);

  useEffect(() => {
    if (orderId) {
      dispatch(getLastRoundOrders(orderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    if (currentOrder?.order_id === parseInt(orderId)) {
      setConnectSocket(false);

      setTimeout(() => {
        setConnectSocket(true);
      }, 1000);
    }
  }, [dispatch, currentOrder?.order_id, orderId]);

  const onAddClickHandler = async (data) => {
    const addedItem = await dispatch(addItemToOrder(orderId, data));
    if (addedItem && orderId) {
      await dispatch(getLastRoundOrders(orderId));
    }

    // onCloseAddMenuItemHandler()
  };

  const onCloseAddMenuItemHandler = () => {
    dispatch(setSelectedModifiersForOrderItem([]));
    dispatch(setModifiersForMenuItem([]));
    dispatch(setRefreshDataForOpenedMenuItem({ itemId: null, refresh: false }));
  };

  // useEffect(() => {
  //   if (!isEmpty(reservationDetails) && !!reservationDetails?.reservation?.is_closed &&
  //     (location.pathname === order_page_path.replace(':orderId', orderId))
  //   ) {
  //     history.push(past_order_page_path.replace(':orderId', orderId))
  //   }
  //   if (!isEmpty(reservationDetails) && !reservationDetails?.reservation?.is_closed &&
  //     (location.pathname === past_order_page_path.replace(':orderId', orderId))
  //   ) {
  //     history.push(order_page_path.replace(':orderId', orderId))
  //   }
  // }, [history, location, reservationDetails, orderId])

  const onRefresh = useCallback(
    (order_id) => {
      if (currentOrder?.order_id === parseInt(orderId)) {
        if (refreshDataForOpenedMenuItem.itemId) {
          dispatch(setRefreshDataForOpenedMenuItem({ itemId: refreshDataForOpenedMenuItem.itemId, refresh: true }));
        } else {
          dispatch(setRefreshDataForOpenedMenuItem({ itemId: null, refresh: false }));
        }
      }
    },
    [dispatch, currentOrder?.order_id, orderId, refreshDataForOpenedMenuItem.itemId],
  );

  useSocket({
    socketData: { orderId: currentOrder?.order_id, userId: id },
    shouldConnect: connectSocket,
    onRefresh,
  });

  useEffect(() => {
    if (
      location.pathname === order_page_path.replace(":orderId", orderId) ||
      location.pathname === booking_order_page_path.replace(":orderId", orderId)
    ) {
      if (toast.message === "You are not authorized" || toast.message === "Reservation is cancelled") {
        history.push(home_path);
      } else if (reservationDetails.reservation.is_closed) {
        history.push(past_order_page_path.replace(":orderId", orderId));
      }
    }
  }, [location, reservationDetails, orderId, toast, history]);

  return (
    <div className="">
      {location.pathname === order_page_path.replace(":orderId", orderId) ||
      location.pathname === booking_order_page_path.replace(":orderId", orderId) ? (
        <>
          <RestaurantMenu
            onReservation
            onBooking={
              !reservationDetails.reservation?.is_walk_in &&
              reservationDetails.reservation?.order_status === orderStatusEnum.booked
            }
            restaurantIdFromReservation={reservationDetails.reservation?.restaurant_id}
            orderId={orderId}
            lastRoundOrders={lastRoundOrders}
            onAddClickHandler={onAddClickHandler}
            onCloseAddMenuItemHandler={onCloseAddMenuItemHandler}
            // onMenuItemClickHandler={onMenuItemClickHandler}
          />

          {/* <ReturnToReservationModal
            show={!((location.pathname === order_page_path.replace(":orderId", orderId) ||
              location.pathname === booking_order_page_path.replace(":orderId", orderId)) && (isEmpty(reservationDetails.reservation || reservationDetails.reservation.is_closed)))}
            close={() => history.push(past_order_page_path.replace(":orderId", orderId))}
            title={"Invalid session"}
            description={'The reservation is finished'}
          /> */}
        </>
      ) : null}
    </div>
  );
}

export default OrderPage;
