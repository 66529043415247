import RestaurantMenu from "../menu/RestaurantMenu";

const JustPayMenuPage = () => {
  return (
    <>
      <RestaurantMenu />
    </>
  );
};

export default JustPayMenuPage;
