import isEmpty from "lodash.isempty";
import React from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useHistory } from "react-router";
import { CheckCircleIcon, ClockIcon } from "../../assets/svgs-google/GoogleIcons";
// import ClockIcon from '../../assets/clock_white.png'
// import { useSelector } from 'react-redux'
import { restaurant_path } from "../../config/pages_paths";
import Column from "../../containers/layout/Column";
import Row from "../../containers/layout/Row";
import { restaurantAvailabilityEnum } from "../../enums/restaurantAvailabilityEnum";
import Image from "../common/Image";
import RestaurantPriceRange from "../restaurant/RestaurantPriceRange";
import "./Restaurants.css";

function RestaurantItemReservation({ date_string: date, is_walk_in, booking_date, size_of_party, isNow }) {
  //const [dateString, time] = date.split('T')
  // const {
  //   versionConfig: {
  //     show_booking_flow
  //   }
  // } = useSelector(state => state)

  return (
    <div className="restaurant-item-incoming-reservation">
      {/* <img
          className="restaurant-item-reservation-icon"
          src={!is_walk_in ? ClockIcon() : ReservationDateIcon}
          alt="reservation-date"
        />{' '} */}
      {is_walk_in || isNow ? ClockIcon(20, "#fff") : CheckCircleIcon(20, "#fff")}
      <div className="restaurant-reservation-date">
        {is_walk_in || isNow ? (
          <>
            <strong>Order placed.</strong> Something else?
          </>
        ) : is_walk_in ? (
          date
        ) : (
          booking_date + " | " + size_of_party + " people"
        )}
        {/*{parseDate(+date)} | {parseHours(+date)} | {size_of_party} people*/}
      </div>
    </div>
  );
}

export function calcDistance(lat1Destination, lon1Destination, lat2Destination, lon2Destination) {
  let R = 6371; // km
  let dLat = toRad(lat2Destination - lat1Destination);
  let dLon = toRad(lon2Destination - lon1Destination);
  let lat1 = toRad(lat1Destination);
  let lat2 = toRad(lat2Destination);

  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  //km to miles
  const distance = d * 0.621371;
  return isNaN(distance) ? null : distance.toFixed(2);
}

// Converts numeric degrees to radians
export function toRad(Value) {
  return (Value * Math.PI) / 180;
}

function RestaurantItem({
  restaurant_id,
  name,
  about,
  image: img,
  onClick,
  style,
  price_range,
  reservation,
  address,
  availability,
  currentPosition,
  latitude,
  longitude,
  ongoing,
}) {
  return (
    <Column className="text-center restaurant-item-column" md="5" lg="5">
      <div style={{ cursor: "pointer" }} onClick={() => onClick(restaurant_id)}>
        <Row className="p-0 ">
          <Column className="text-center p-1 p-md-2 d-flex justify-content-center">
            <div className="restaurant-image">
              {/* <LazyLoadImage
                height={'100%'}
                width={'100%'}
                style={{
                  borderRadius: '15px',
                  objectFit: 'cover'
                }}
                effect="blur"
                src={image || EmptyRestaurantImagePlaceholder}
                placeholderSrc={EmptyRestaurantImagePlaceholder}
                alt="restaurant"
                onError={onImageError}
              // className="img-fluid"
              /> */}
              <Image
                height={"100%"}
                width={"100%"}
                style={{
                  borderRadius: "15px",
                  objectFit: "cover",
                }}
                src={img}
                restaurant
                alt="restaurant"
              />
            </div>
            {/* {isEmpty(reservation || ongoing ) ? null : (
              <RestaurantItemReservation {...reservation || ongoing} />
            )} */}
            {!isEmpty(ongoing) && ongoing.restaurant_id === restaurant_id ? (
              <RestaurantItemReservation {...ongoing} />
            ) : !isEmpty(reservation) ? (
              <RestaurantItemReservation {...reservation} />
            ) : null}
          </Column>
        </Row>
        <h3 className="restaurant-item-name">{name}</h3>
        <span>
          {style} <RestaurantPriceRange price_range={price_range} />
          {"  "}
          {!isEmpty(currentPosition)
            ? calcDistance(latitude, longitude, currentPosition.lat, currentPosition.lon) + "mi"
            : address}
        </span>
        <p dangerouslySetInnerHTML={{ __html: about }} />
        {availability === restaurantAvailabilityEnum.coming_soon ? (
          <ComingSoon />
        ) : availability === restaurantAvailabilityEnum.unavailable ? (
          <Unavailable />
        ) : null}
      </div>
    </Column>
  );
}

function RestaurantsGrid({ restaurants = [], onClick, currentPosition, ongoing }) {
  const history = useHistory();

  const calculateDistance = (restaurant) => {
    if (currentPosition && restaurant.latitude && restaurant.longitude) {
      const distance = calcDistance(
        currentPosition.lat,
        currentPosition.lon,
        restaurant.latitude,
        restaurant.longitude,
      );
      return distance;
    }
    // If any of the required data is missing, return a large number to sort them last
    return Number.MAX_SAFE_INTEGER;
  };
  const sortedRestaurants = restaurants.slice(); // Create a copy of the array to avoid mutating the original
  sortedRestaurants.sort((restaurantA, restaurantB) => {
    // First, check if there's a reservation
    if (restaurantA.has_booking_or_walkin_order && !restaurantB.has_booking_or_walkin_order) {
      return -1; // restaurantA has a has_booking_or_walkin_order, so it comes first
    } else if (!restaurantA.has_booking_or_walkin_order && restaurantB.has_booking_or_walkin_order) {
      return 1; // restaurantB has a reservation, so it comes next
    } else {
      // If both have reservations or neither do, move on to the next criteria
      const availabilityOrder = {
        available: 0,
        "coming soon": 1,
        unavailable: 2,
      };
      const availabilityComparison =
        availabilityOrder[restaurantA.availability] - availabilityOrder[restaurantB.availability];

      if (availabilityComparison === 0) {
        // If availability is the same, sort by distance
        const distanceA = calculateDistance(restaurantA);
        const distanceB = calculateDistance(restaurantB);

        if (distanceA < distanceB) {
          return -1; // Sort by distance, closest first
        } else if (distanceA > distanceB) {
          return 1;
        } else {
          return 0;
        }
      } else {
        return availabilityComparison;
      }
    }
  });

  const onRestaurantClickHandler = (restaurantId) => {
    const restaurant = restaurants.find((res) => res.restaurant_id === +restaurantId);
    if (restaurant.availability === restaurantAvailabilityEnum.available) {
      history.push(restaurant_path.replace(":restaurantId", restaurant.restaurant_id || +restaurantId));
    }
  };

  return (
    <Row>
      {sortedRestaurants.map((el, idx) => (
        <RestaurantItem
          key={idx}
          onClick={onClick || onRestaurantClickHandler}
          {...el}
          currentPosition={currentPosition}
          ongoing={ongoing}
        />
      ))}
    </Row>
  );
}

function ComingSoon() {
  return (
    <div
      style={{
        width: "150px",
        height: "30px",
        backgroundColor: "#020083",
        color: "#ffffff",
        padding: "7px",
        margin: "0.5rem auto auto",
        borderRadius: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className={"coming-soon"}
    >
      <span>Soon available</span>
    </div>
  );
}
function Unavailable() {
  return (
    <div
      style={{
        width: "150px",
        height: "30px",
        // return for the dipshit
        // backgroundColor: 'rgb(255, 116, 30)',
        //
        backgroundColor: "#020083",
        color: "#ffffff",
        padding: "7px",
        margin: "0.5rem auto auto",
        borderRadius: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className={"coming-soon"}
    >
      <span>Unavailable</span>
    </div>
  );
}

export default RestaurantsGrid;
