import React from "react";
import ReservationPage from "../reservation/ManageBookingPage";
import "./OrderPage.css";

const PastOrderPage = () => {
  return (
    <>
      <ReservationPage />
    </>
  );
};

export default PastOrderPage;
