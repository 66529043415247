import { round } from "lodash";
import { roundingTypesEnum } from "../enums/roundingTypesEnum";
import { roundNumber } from "./roundNumber";

export function calculatePaymentAmounts({ totalAmount, balanceDue, voilaSubTotalAmount, split = 1, paymentPercentage, paymentTipPercentage }) {
  const settledAmount = round(totalAmount - balanceDue, 2);

  const balanceDuePct = round((balanceDue / totalAmount) * 100, 2);
  const settledPct = round(100 - balanceDuePct, 2);

  // round up so that there is no remainder after all parts are paid (last guest will pay a little less)
  const stepAmount = roundNumber(totalAmount / split, roundingTypesEnum.ALWAYS_UP, 2);
  const stepPct = roundNumber((stepAmount / totalAmount) * 100, roundingTypesEnum.ALWAYS_UP, 2);

  const actionButtons = [];
  for (let i = 1; ; i++) {
    let iPct = round(stepPct * i, 2);
    let iAmount = round(stepAmount * i, 2);
    let isLast = false;

    if (iAmount >= balanceDue) {
      iPct = balanceDuePct;
      iAmount = balanceDue;
      isLast = true;
    }

    actionButtons.push({
      key: iAmount,
      pct: iPct,
      amount: iAmount,
      isLast,
    });

    if (iAmount === balanceDue) break;
  }

  let newPaymentAmount, newPaymentPct;
  if (stepAmount > balanceDue) {
    newPaymentAmount = balanceDue;
    newPaymentPct = balanceDuePct;
  } else {
    newPaymentAmount = stepAmount;
    newPaymentPct = stepPct;
  }

  const newPaymentTipAmount = round((((voilaSubTotalAmount * paymentPercentage) / 100) * paymentTipPercentage) / 100, 2);
  const newPaymentTipPct = paymentTipPercentage;

  return {
    split,

    totalAmount,
    settledAmount,
    balanceDue,
    stepAmount,

    settledPct,
    balanceDuePct,
    stepPct,

    actionButtons,
    newPaymentAmount,
    newPaymentPct,
    newPaymentTipAmount,
    newPaymentTipPct,
  };
}
