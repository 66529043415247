const versionConfigEnum = {
  empty_modifier_group: "empty_modifier_group",
  show_table_number: "show_table_number",
  show_booking_flow: "show_booking_flow",
  show_order_now: "show_order_now",
  is_anonymous_user_identification_enabled: "is_anonymous_user_identification_enabled",
  restaurants_filter: "restaurants_filter",
  navbar_links: "navbar_links",
  menu_steps: "menu_steps",
  menu_steps_user: "menu_steps_user",
  host_booking_presence: "host_booking_presence",
  close_order_if_the_first_check_on_toast_is_paid: "close_order_if_the_first_check_on_toast_is_paid",
  redirect_to_just_pay_instead_of_ordering: "redirect_to_just_pay_instead_of_ordering",
  just_pay_complex_prod_bussiness_logic: "just_pay_complex_prod_bussiness_logic",
};

export default versionConfigEnum;
