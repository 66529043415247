import {
  SET_RESTAURANT,
  SET_MENU,
  SET_FULL_MENU,
  SET_CURRENT_POSITION,
  SET_MODIFIERS_FOR_MENU_ITEM,
  SET_SELECTED_MODIFIERS_FOR_ORDER_ITEM,
  SET_RESTAURANT_BY_ID,
} from "../../../config/actionTypes";
import axios from "axios";
import { setErrors } from "../../../store/actions/errorsActions";
import { home_path } from "../../../config/pages_paths";
import { setLoader, setToastMessage } from "../../../store/actions/feedbackActions";
import {
  get_menu_item_modifiers,
  get_menu_item_selected_modifiers,
  restaurant_menu_api,
  restaurant_top_menu_api,
} from "../../../config/api_endpoints";

export const getMenu = (restaurantId) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const result = await axios.get(restaurant_top_menu_api.replace(":restaurantId", restaurantId));
    dispatch(setMenu(result.data.topMenuItems));
    dispatch(setLoader(false));
  } catch (err) {
    dispatch(setLoader(false));
    dispatch(setErrors(err, () => getMenu(restaurantId)));
  }
};

export const setRestaurant = (payload) => (dispatch) => {
  dispatch({ type: SET_RESTAURANT, payload });
};

export const setRestaurantById = (payload) => (dispatch) => {
  dispatch({ type: SET_RESTAURANT_BY_ID, payload });
};

export const setMenu = (payload) => (dispatch) => {
  dispatch({ type: SET_MENU, payload });
};

export const setFullMenu = (payload) => (dispatch) => {
  dispatch({ type: SET_FULL_MENU, payload });
};

export const getFullMenu = (restaurantId, orderId, isNow, history) => async (dispatch) => {
  try {
    dispatch(setLoader(true));

    let query = orderId ? "?orderId=" + orderId : isNow === "now" ? "?now=true" : "";
    const result = await axios.get("/api/restaurants/menu/" + restaurantId + query);
    // console.log(result.data?.menu)
    dispatch(setFullMenu(result.data.menu));
    dispatch(setLoader(false));
  } catch (err) {
    dispatch(setLoader(false));
    if (err.response?.status === 400) {
      if (err.response && err.response.data && err.response.data.short_message) {
        dispatch(
          setToastMessage({
            message: err.response.data.short_message,
            severity: "error",
          }),
        );
      }
      history && history.push(home_path);
    }

    dispatch(setErrors(err, () => getFullMenu(restaurantId, orderId, isNow, history)));
  }
};

// export const getRestaurantMenu = (restaurantId, orderId, isNow) => async dispatch => {
//   try {
//     setLoader(true)
//     const { data } = await axios.post(restaurant_menu_api.replace(":restaurantId", restaurantId), { orderId, isNow })
//     dispatch(setRestaurantMenu(data))
//     console.log(data)
//   } catch (err) {
//     if (err.response.data.message) {
//       dispatch(setToastMessage({
//         message: err.response.data.message,
//         severity: "error",
//       }))
//     }
//   }
//   finally {
//     dispatch(setLoader(false))
//   }
// }

// export const setRestaurantMenu = payload => dispatch => {
//   dispatch({ type: SET_RESTAURANT_MENU_APPROVED, payload })
// }

export const setCurrentPosition = (payload) => (dispatch) => {
  dispatch({ type: SET_CURRENT_POSITION, payload });
};

export const getModifiersForMenuItem = (itemId, sectionId, restaurantId) => async (dispatch) => {
  return await new Promise((resolve, _) => {
    try {
      axios
        .get(get_menu_item_modifiers, { params: { itemId, sectionId, restaurantId } })
        .then(({ data }) => {
          dispatch(setModifiersForMenuItem(data));
          return resolve(null, data);
        })
        .catch((error) => {
          return resolve(error);
        });
    } catch (err) {
      dispatch(setErrors(err, () => getModifiersForMenuItem(itemId, sectionId, restaurantId)));
    }
  });
};

export const setModifiersForMenuItem = (payload) => (dispatch) => {
  dispatch({ type: SET_MODIFIERS_FOR_MENU_ITEM, payload });
};

export const getSelectedModifiersForOrderItem = (roundItemId, restaurantId) => async (dispatch) => {
  return await new Promise((resolve, _) => {
    try {
      axios
        .get(get_menu_item_selected_modifiers, { params: { roundItemId, restaurantId } })
        .then(({ data }) => {
          dispatch(setSelectedModifiersForOrderItem(data));
          return resolve(null, data);
        })
        .catch((error) => {
          return resolve(error);
        });
    } catch (err) {
      dispatch(setErrors(err, () => getSelectedModifiersForOrderItem(roundItemId, restaurantId)));
    }
  });
};

export const setSelectedModifiersForOrderItem = (payload) => (dispatch) => {
  dispatch({ type: SET_SELECTED_MODIFIERS_FOR_ORDER_ITEM, payload });
};
